import React from 'react'
import PropTypes from "prop-types"
const GlobeIcon = ({ height = 24, width = 24, iconColor = "#000" }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 24 24"
        >
            <path
                fill={iconColor}
                d="M12 20.75c.652 0 1.578-.563 2.418-2.238.387-.774.71-1.707.941-2.762H8.641c.23 1.055.554 1.988.941 2.762.84 1.675 1.766 2.238 2.418 2.238ZM8.418 14.5h7.164c.11-.79.168-1.629.168-2.5s-.059-1.71-.168-2.5H8.418c-.11.79-.168 1.629-.168 2.5s.059 1.71.168 2.5Zm.223-6.25h6.718c-.23-1.055-.554-1.988-.941-2.762C13.578 3.812 12.652 3.25 12 3.25c-.652 0-1.578.563-2.418 2.238-.387.774-.71 1.707-.941 2.762Zm8.203 1.25c.101.8.156 1.637.156 2.5s-.055 1.7-.156 2.5h3.547a8.812 8.812 0 0 0 0-5h-3.547Zm3.066-1.25a8.765 8.765 0 0 0-5.113-4.543c.82 1.106 1.469 2.688 1.844 4.543h3.273-.004Zm-12.543 0c.375-1.86 1.024-3.438 1.844-4.543A8.75 8.75 0 0 0 4.094 8.25h3.273ZM3.617 9.5a8.813 8.813 0 0 0 0 5h3.54a19.884 19.884 0 0 1 0-5H3.612h.004Zm11.176 10.793a8.778 8.778 0 0 0 5.113-4.543h-3.27c-.374 1.86-1.023 3.438-1.843 4.543Zm-5.586 0c-.82-1.105-1.465-2.688-1.844-4.543h-3.27a8.765 8.765 0 0 0 5.114 4.543ZM12 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20Z"
            />
        </svg>
    )
}
GlobeIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    iconColor: PropTypes.string
}
export default GlobeIcon