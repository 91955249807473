import React from 'react'
import PropTypes from "prop-types"
const EmojiIcon = ({ width = 24, height = 24, iconColor = "#000" }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 24 24"
        >
            <path
                fill={iconColor}
                d="M7 9.5c0 .83.676 1.5 1.504 1.5C9.33 11 10 10.33 10 9.5S9.331 8 8.504 8H8.49C7.663 8 7 8.67 7 9.5ZM15.504 11C14.676 11 14 10.33 14 9.5S14.663 8 15.49 8h.014C16.33 8 17 8.67 17 9.5s-.669 1.5-1.496 1.5Z"
            />
            <path
                fill={iconColor}
                fillRule="evenodd"
                d="M7 13a5 5 0 0 0 10 0H7Zm8.092 1.641a3.5 3.5 0 0 1-6.184 0 .097.097 0 0 1 .087-.141h6.01c.073 0 .122.076.087.141Z"
                clipRule="evenodd"
            />
            <path
                fill={iconColor}
                fillRule="evenodd"
                d="M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Zm-1.5 0a8.5 8.5 0 1 1-17 0 8.5 8.5 0 0 1 17 0Z"
                clipRule="evenodd"
            />
        </svg>
    )
}
EmojiIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    iconColor: PropTypes.string
}
export default EmojiIcon