import React from "react";
import PropTypes from "prop-types"
function SmallPlayIcon({ height = 16, width = 16, iconColor = "#000" }) {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={width}
                height={height}
                fill="none"
                viewBox="0 0 16 16"
            >
                <path
                    fill={iconColor}
                    d="m3.623 1.4 10.57 6.166a.508.508 0 0 1 0 .868L3.622 14.6a.508.508 0 0 1-.756-.437V1.835c0-.381.427-.627.756-.435Z"
                />
            </svg >
        </>
    );
}
SmallPlayIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    iconColor: PropTypes.string
}
export default SmallPlayIcon;
