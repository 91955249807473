const MONOLITH_PATH = process.env.REACT_APP_MONOLITH_PATH;

const BILLING_PATH = process.env.REACT_APP_BILLING_PATH;

export const ApiRoutes = {
  // MONOLITH ENDPOINT ROUTES
  adminModules: {
    getModules: {
      url: `${MONOLITH_PATH}/v1/admin-modules`,
      method: "GET",
    },
  },
  authentication: {
    ssoLogin: {
      url: `${MONOLITH_PATH}/v1/users/sso-login`,
      method: "POST",
    },
    login: {
      url: `${MONOLITH_PATH}/v1/users/login`,
      method: "POST",
    },
    signup: {
      url: `${MONOLITH_PATH}/v1/users/signup`,
      method: "POST",
    },
    validateLink: {
      url: `${MONOLITH_PATH}/v1/users/validate-link`,
      method: "GET",
    },
    activateAccount: {
      url: `${MONOLITH_PATH}/v1/users/activate-account`,
      method: "POST",
    },
    userEdges: {
      url: `${MONOLITH_PATH}/v1/user-edges`,
      method: "GET",
    },
    userInfo: {
      url: `${MONOLITH_PATH}/v1/users/user-info`,
      method: "GET"
    }
  },
  applicationForms: {
    getApplicationForms: {
      url: `${MONOLITH_PATH}/v1/application-forms`,
      method: "GET",
    },
    getApplicationForm: {
      url: `${MONOLITH_PATH}/v1/application-forms/:application_form_id`,
      method: "GET",
    },
    addApplicationForm: {
      url: `${MONOLITH_PATH}/v1/application-forms`,
      method: "POST",
    },
    editApplicationForm: {
      url: `${MONOLITH_PATH}/v1/application-forms/:application_form_id`,
      method: "PATCH",
    },
    removeApplicationForm: {
      url: `${MONOLITH_PATH}/v1/application-forms/:application_form_id`,
      method: "DELETE",
    },
  },
  applicationFormFields: {
    getApplicationFormFields: {
      url: `${MONOLITH_PATH}/v1/application-form-fields`,
      method: "GET",
    },
    getApplicationFormField: {
      url: `${MONOLITH_PATH}/v1/application-form-fields/:application_form_field_id`,
      method: "GET",
    },
    addApplicationFormField: {
      url: `${MONOLITH_PATH}/v1/application-form-fields`,
      method: "POST",
    },
    editApplicationFormField: {
      url: `${MONOLITH_PATH}/v1/application-form-fields/:application_form_field_id`,
      method: "PATCH",
    },
    removeApplicationFormField: {
      url: `${MONOLITH_PATH}/v1/application-form-fields/:application_form_field_id`,
      method: "DELETE",
    },
  },
  roles: {
    getRoles: {
      url: `${MONOLITH_PATH}/v1/roles`,
      method: "GET",
    },
    getRole: {
      url: `${MONOLITH_PATH}/v1/roles/:role_id`,
      method: "GET",
    },
    addRole: {
      url: `${MONOLITH_PATH}/v1/roles`,
      method: "POST",
    },
    editRole: {
      url: `${MONOLITH_PATH}/v1/roles/:role_id`,
      method: "PATCH",
    },
    removeRole: {
      url: `${MONOLITH_PATH}/v1/roles/:role_id`,
      method: "DELETE",
    },
  },
  platformOwners: {
    getPlatformOwners: {
      url: `${MONOLITH_PATH}/v1/users/platform-owners`,
      method: "GET",
    },
    getPlatformOwner: {
      url: `${MONOLITH_PATH}/v1/users/platform-owners/:platform_owner_id`,
      method: "GET",
    },
    addPlatformOwner: {
      url: `${MONOLITH_PATH}/v1/users/platform-owners`,
      method: "POST",
    },
    editPlatformOwner: {
      url: `${MONOLITH_PATH}/v1/users/platform-owners/:platform_owner_id`,
      method: "PATCH",
    },
    removePlatformOwner: {
      url: `${MONOLITH_PATH}/v1/users/platform-owners/:platform_owner_id`,
      method: "DELETE",
    },
  },
  getPlatformUsers: {
    url: `${MONOLITH_PATH}/v1/users/platform-users`,
    method: "GET",
  },
  getSystemUsers: {
    url: `${MONOLITH_PATH}/v1/users/system-users`,
    method: "GET"
  },
  communityOwners: {
    getPlatformOwners: {
      url: `${MONOLITH_PATH}/v1/users/community-owners`,
      method: "GET",
    },
    getCommunityOwner: {
      url: `${MONOLITH_PATH}/v1/users/community-owners/:community_owner_id`,
      method: "GET",
    },
    addCommunityOwner: {
      url: `${MONOLITH_PATH}/v1/users/community-owners`,
      method: "POST",
    },
    editCommunityOwner: {
      url: `${MONOLITH_PATH}/v1/users/community-owners/:community_owner_id`,
      method: "PATCH",
    },
    removeCommunityOwner: {
      url: `${MONOLITH_PATH}/v1/users/community-owners/:community_owner_id`,
      method: "DELETE",
    },
  },
  getCommunityMembers: {
    url: `${MONOLITH_PATH}/v1/users/community-members`,
    method: "GET",
  },
  importUsers: {
    bulkImportUsers: {
      url: `${MONOLITH_PATH}/v1/users/bulk-import-users`,
      method: "POST"
    },
    singleImportUser: {
      url: `${MONOLITH_PATH}/v1/users/import-user`,
      method: "POST"
    }
  },
  communities: {
    getCommunities: {
      url: `${MONOLITH_PATH}/v1/communities`,
      method: "GET",
    },
    getCommunity: {
      url: `${MONOLITH_PATH}/v1/communities/:community_id`,
      method: "GET",
    },
    createCommunity: {
      url: `${MONOLITH_PATH}/v1/communities`,
      method: "POST",
    },
    editCommunity: {
      url: `${MONOLITH_PATH}/v1/communities/:community_id`,
      method: "PATCH",
    },
    removeCommunity: {
      url: `${MONOLITH_PATH}/v1/communities/:community_id`,
      method: "DELETE",
    },
    generateSharableLink: {
      url: `${MONOLITH_PATH}/v1/communities/sharable-link`,
      method: "POST",
    },
    removeSharableLink: {
      url: `${MONOLITH_PATH}/v1/communities/sharable-link/:sharable_link_id`,
      method: "DELETE",
    },
    getCommunityByNickname: {
      url: `${MONOLITH_PATH}/v1/communities/nickname/:nickname`,
      method: "GET",
    },
    validateSharableLink: {
      url: `${MONOLITH_PATH}/v1/communities/sharable-link/validate`,
      method: "GET",
    },
    joinCommunity: {
      url: `${MONOLITH_PATH}/v1/communities/join`,
      method: "POST",
    },
  },
  platform: {
    getPlatform: {
      url: `${MONOLITH_PATH}/v1/platform`,
      method: "GET",
    },
    editPlatform: {
      url: `${MONOLITH_PATH}/v1/platform`,
      method: "PATCH",
    },
  },
  subscriptionSubmissions: {
    subscriptionSubmissions: {
      url: `${MONOLITH_PATH}/v1/subscription-submissions`,
      method: "POST",
    },
    subscriptionSubmissionsStatus: {
      url: `${MONOLITH_PATH}/v1/subscription-submissions/status`,
      method: "GET",
    },
    getSubscriptionSubmissions: {
      url: `${MONOLITH_PATH}/v1/subscription-submissions`,
      method: "GET",
    },
    getSubscriptionSubmission: {
      url: `${MONOLITH_PATH}/v1/subscription-submissions/:subscription_submission_id`,
      method: "GET",
    },
    editSubscriptionSubmission: {
      url: `${MONOLITH_PATH}/v1/subscription-submissions/:subscription_submission_id`,
      method: "PATCH",
    },
  },
  edgeLogin: {
    edgeLogin: {
      url: `${MONOLITH_PATH}/v1/users/edge-login`,
      method: "POST",
    },
  },
  videos: {
    getVideos: {
      url: `${MONOLITH_PATH}/content-archive/videos`,
      method: "GET"
    },
    getVideoCategoriesByAdmin: {
      url: `${MONOLITH_PATH}/AS/categories`,
      method: "GET"
    },
    getVideoSuggestions: {
      url: `${MONOLITH_PATH}/content-archive/getContentVideoSuggestionList`,
      method: "GET"
    },
    getDeletedVideos: {
      url: `${MONOLITH_PATH}/content-archive/deletedvideos`,
      method: "GET"
    },
    getDeletedVideoSuggestions: {
      url: `${MONOLITH_PATH}/content-archive/getDeletedContentVideoSuggestionList`,
      method: "GET"
    },
    deleteVideo: {
      url: `${MONOLITH_PATH}/video/delete/:video_id`,
      method: "DELETE"
    },
    deleteVideoPermenant: {
      url: `${MONOLITH_PATH}/permenant-delete/content-arcive/:video_id`,
      method: "DELETE"
    },
    restoreVideo: {
      url: `${MONOLITH_PATH}/video/restore/:video_id`,
      method: "PATCH"
    },
    getVideoByIdByAdmin: {
      url: `${MONOLITH_PATH}/content-archive/video/:video_id`,
      method: "GET"
    },
    getVideoCategorySuggestions: {
      url: `${MONOLITH_PATH}/AS/getCategoriesSuggestionList`,
      method: "GET"
    },
    getVideoCategoryById: {
      url: `${MONOLITH_PATH}/category/:category_id`,
      method: "GET"
    },
    createVideoCategory: {
      url: `${MONOLITH_PATH}/category/create`,
      method: "POST"
    },
    editVideoCategory: {
      url: `${MONOLITH_PATH}/category/edit/:category_id`,
      method: "PUT"
    },
    deleteVideoCategory: {
      url: `${MONOLITH_PATH}/delete/categories/:category_id`,
      method: "DELETE"
    },
    getVideoCommentsByAdmin: {
      url: `${MONOLITH_PATH}/AS/video/:video_id/comments/replies/all`,
      method: "GET"
    },
    getAllUserEmailNameByadmin: {
      url: `${MONOLITH_PATH}/user/AS/getalluseremailname`,
      method: "GET"
    },
    getAllCommentsVideoByAdmin: {
      url: `${MONOLITH_PATH}/AS/video/:video_id/comments/replies/all`,
      method: "GET"
    },
    addCommentVideoByAdmin: {
      url: `${MONOLITH_PATH}/AS/video/:video_id/comment`,
      method: "POST"
    },
    addCommentReplyVideoByAdmin: {
      url: `AS/video/:video_id/comments/:comment_id/replies`,
      method: "POST"
    },
    addCommentLikeVideoByAdmin: {
      url: `AS/video/:video_id/comments/:comment_id/like`,
      method: "PUT"
    },
    createVideo: {
      url: `${MONOLITH_PATH}/video/create`,
      method: "POST"
    }

  },
  events: {
    getAllEvents: {
      url: `${MONOLITH_PATH}/event/getAllEvents`,
      method: "GET"
    },
    getAllEventsLimitedFiedls: {
      url: `${MONOLITH_PATH}/event/getAllEventsLimitedFiedls`,
      method: "GET"
    },
    getEventById: {
      url: `${MONOLITH_PATH}/event/getEventById/:event_id`,
      method: "GET"
    },
    createEvent: {
      url: `${MONOLITH_PATH}/event/addEvent`,
      method: "POST"
    },
    editEvent: {
      url: `${MONOLITH_PATH}/event/editEvent/:event_id`,
      method: "PATCH"
    },
    cloneEvent: {
      url: `${MONOLITH_PATH}/event/cloneEvent`,
      method: "POST"
    },
    updateEventStatus: {
      url: `${MONOLITH_PATH}/event/updateStatusEvent/:event_id`,
      method: "PATCH"
    },
    deleteEvent: {
      url: `${MONOLITH_PATH}/event/deleteEvent/:event_id`,
      method: "PATCH"
    },
    getAllEventsSuggestions: {
      url: `${MONOLITH_PATH}/event/getAllEventSuggestionList`,
      method: "GET"
    },
    getAllEventActivity: {
      url: `${MONOLITH_PATH}/event/getAllActivitys/`,
      method: "GET"
    },
    getAllActivitysByEventId: {
      url: `${MONOLITH_PATH}/event/getAllActivitysByEventId/:event_id`,
      method: "GET"
    },
    createEventActivity: {
      url: `${MONOLITH_PATH}/event/createActivity`,
      method: "POST"
    },
    editEventActivity: {
      url: `${MONOLITH_PATH}/event/editActivity/:activity_id`,
      method: "PATCH"
    },
    deleteEventActivity: {
      url: `${MONOLITH_PATH}/event/deleteActivity/:activity_id`,
      method: "PATCH"
    },
    getEventActivityById: {
      url: `${MONOLITH_PATH}/event/getActivityById/:activity_id`,
      method: "GET"
    },
    getAllActivityImages: {
      url: `${MONOLITH_PATH}/event/getAllActivityImages`,
      method: "GET"
    },
    getEventTypes: {
      url: `${MONOLITH_PATH}/event/EventTypelist`,
      method: "GET"
    },
    getAllEventTypeSuggestionList: {
      url: `${MONOLITH_PATH}/event/getAllEventTypeSuggestionList`,
      method: "GET"
    },
    createEventType: {
      url: `${MONOLITH_PATH}/event/createEventType`,
      method: "POST"
    },
    getEventTypeDetail: {
      url: `${MONOLITH_PATH}/event/EventTypedetail/:event_type_id`,
      method: "GET"
    },
    deleteEventType: {
      url: `${MONOLITH_PATH}/event/deleteEventType/:event_type_id`,
      method: "PATCH"
    },
    editEventType: {
      url: `${MONOLITH_PATH}/event/editEventType/:event_type_id`,
      method: "POST"
    },
    getCategoriesList: {
      url: `${MONOLITH_PATH}/event/categories/`,
      method: "GET"
    },
    addCategory: {
      url: `${MONOLITH_PATH}/event/category/create`,
      method: "POST"
    },
    editCategory: {
      url: `${MONOLITH_PATH}/event/category/edit/:category_id`,
      method: "PATCH"
    },
    deleteCategory: {
      url: `${MONOLITH_PATH}/event/category/delete/:category_id`,
      method: "DELETE"
    },
    checkAlredayExistSubCategory: {
      url: `${MONOLITH_PATH}/event/subcategory/checkAlredayExistSubCategory`,
      method: "POST"
    },
    viewCategory: {
      url: `${MONOLITH_PATH}/event/category/getById/:category_id`,
      method: "GET"
    },
    editSubCategory: {
      url: `${MONOLITH_PATH}/event/subcategory/edit/:subcategory_id`,
      method: "POST"
    },
    //Event Location Api
    getAllEventLocations: {
      url: `${MONOLITH_PATH}/event/getalllocation`,
      method: "GET"
    },
    getAllEventLocationsByEventId: {
      url: `${MONOLITH_PATH}/event/getalllocationbyeventid/:event_id`,
      method: "GET"
    },
    createEventLocation: {
      url: `${MONOLITH_PATH}/event/addlocation`,
      method: "POST"
    },
    editEventLocation: {
      url: `${MONOLITH_PATH}/event/editlocation/:location_id`,
      method: "PATCH"
    },
    deleteEventLocation: {
      url: `${MONOLITH_PATH}/event/deletelocation/:location_id`,
      method: "PATCH"
    },
    getEventLocationById: {
      url: `${MONOLITH_PATH}/event/getlocationdetail/:location_id`,
      method: "GET"
    },
    //package crud operation
    getAllPackages: {
      url: `${MONOLITH_PATH}/event/getallpackages`,
      method: "GET"
    },
    getAllPackagesByEventId: {
      url: `${MONOLITH_PATH}/event/getallpackagesByEventId/:event_id`,
      method: "GET"
    },
    createEventPackage: {
      url: `${MONOLITH_PATH}/event/addpackage`,
      method: "POST"
    },
    deleteEventPackage: {
      url: `${MONOLITH_PATH}/event/deletepackage/:package_id`,
      method: "PATCH"
    },
    editEventPackage: {
      url: `${MONOLITH_PATH}/event/editpackage/:package_id`,
      method: "PATCH"
    },
    getEventPackageById: {
      url: `${MONOLITH_PATH}/event/getpackagedetail/:package_id`,
      method: "GET"
    },
    reOrderPackages: {
      url: `${MONOLITH_PATH}/event/packageReorder`,
      method: "POST"
    },
    //room crud operation
    getAllEventRooms: {
      url: `${MONOLITH_PATH}/event/getAllRooms`,
      method: "GET"
    },
    getAllRoomsByEventId: {
      url: `${MONOLITH_PATH}/event/getAllRoomsByEventId/:event_id`,
      method: "GET"
    },
    createEventRoom: {
      url: `${MONOLITH_PATH}/event/createRoom`,
      method: "POST"
    },
    deleteEventRoom: {
      url: `${MONOLITH_PATH}/event/deleteRoom/:room_id`,
      method: "PATCH"
    },
    editEventRoom: {
      url: `${MONOLITH_PATH}/event/editRoom/:room_id`,
      method: "PATCH"
    },
    getEventRoomById: {
      url: `${MONOLITH_PATH}/event/getRoomDetails/:room_id`,
      method: "GET"
    },
    reorderRooms: {
      url: `${MONOLITH_PATH}/event/reorderRoomsByEventId/:event_id`,
      method: "POST"
    },

    //participant types operation
    eventParticipantTypesList: {
      url: `${MONOLITH_PATH}/event/dynamicParticipantTypes/list/:event_id`,
      method: "GET"
    },
    searchEventParticipantTypes: {
      url: `${MONOLITH_PATH}/event/dynamicParticipantTypes/all/:event_id`,
      method: "GET"
    },
    addEventParticipantTypes: {
      url: `${MONOLITH_PATH}/event/dynamicParticipantTypes/create`,
      method: "POST"
    },
    deleteEventParticipantTypes: {
      url: `${MONOLITH_PATH}/event/dynamicParticipantTypes/delete/:type_id`,
      method: "DELETE"
    },
    editEventParticipantTypes: {
      url: `${MONOLITH_PATH}/event/dynamicParticipantTypes/update`,
      method: "PATCH"
    },
    getEventParticipantTypeById: {
      url: `${MONOLITH_PATH}/event/dynamicParticipantTypes/get-by-id/:type_id`,
      method: "GET"
    },

    //attendee types operation
    getAllAttendeesByEventId: {
      url: `${MONOLITH_PATH}/event/getAttendeesByEventId/:event_id`,
      method: "GET"
    },
    importAllAttendees: {
      url: `${MONOLITH_PATH}/event/importAttendees/`,
      method: "POST"
    },
    getAllAttendeesForExport: {
      url: `${MONOLITH_PATH}/event/exportAttendees/`,
      method: "GET"
    },
    addExistingAttendees: {
      url: `${MONOLITH_PATH}/event/addExistingAttendeeToEvent`,
      method: "POST"
    },
    createEventAttendees: {
      url: `${MONOLITH_PATH}/event/createEventAttendees`,
      method: "POST"
    },
    deleteEventAttendees: {
      url: `${MONOLITH_PATH}/event/deleteEventAttendees/:attendee_id`,
      method: "PATCH"
    },
    editEventAttendees: {
      url: `${MONOLITH_PATH}/event/editEventAttendees/:attendee_id`,
      method: "PATCH"
    },
    getEventAttendeeById: {
      url: `${MONOLITH_PATH}/event/getAttendee/:attendee_id`,
      method: "GET"
    },
    deleteBulkEventAttendees: {
      url: `${MONOLITH_PATH}/event/deleteEventAttendees/`,
      method: "PATCH"
    },

    //sessions crud operation
    getAllEventSessions: {
      url: `${MONOLITH_PATH}/event/getAllSessions`,
      method: "GET"
    },
    getAllSessionsByEventId: {
      url: `${MONOLITH_PATH}/event/getAllSessionsByEventId/:event_id`,
      method: "GET"
    },
    getAllSessionsByDate: {
      url: `${MONOLITH_PATH}/event/getSessionListByDate`,
      method: "GET"
    },
    createEventSession: {
      url: `${MONOLITH_PATH}/event/createSession`,
      method: "POST"
    },
    deleteEventSession: {
      url: `${MONOLITH_PATH}/event/deleteSession/:session_id`,
      method: "PATCH"
    },
    editEventSession: {
      url: `${MONOLITH_PATH}/event/editSession/:session_id`,
      method: "PATCH"
    },
    getEventSessionById: {
      url: `${MONOLITH_PATH}/event/getSessionDetails/:session_id`,
      method: "GET"
    },
    addEditEventContactSupport: {
      url: `${MONOLITH_PATH}/event/editContactSupportInEvent/:event_id`,
      method: "PATCH"
    },
    //Faqs Curd
    getAllFaqs: {
      url: `${MONOLITH_PATH}/event/getAllFaqs`,
      method: "GET"
    },
    getAllFaqsByEventId: {
      url: `${MONOLITH_PATH}/event/getAllEventFaqsByEventId/:event_id`,
      method: "GET"
    },
    createEventFaq: {
      url: `${MONOLITH_PATH}/event/createFaq`,
      method: "POST"
    },
    deleteEventFaq: {
      url: `${MONOLITH_PATH}/event/deleteFaq/:faq_id`,
      method: "PATCH"
    },
    editEventFaq: {
      url: `${MONOLITH_PATH}/event/editFaq/:faq_id`,
      method: "PATCH"
    },
    getEventFaqById: {
      url: `${MONOLITH_PATH}/event/getFaqDetail/:faq_id`,
      method: "GET"
    },
    reorderFaqs: {
      url: `${MONOLITH_PATH}/event/reorderFaqsOfEventId/:event_id`,
      method: "POST"
    },

    //Gallery photos Crud
    getAllGalleryByEventId: {
      url: `${MONOLITH_PATH}/event/getEventGallery/:event_id`,
      method: "GET"
    },
    addEventGalleryPhoto: {
      url: `${MONOLITH_PATH}/event/addEventGallery/:event_id`,
      method: "POST"
    },
    deleteGalleryPhoto: {
      url: `${MONOLITH_PATH}/event/deleteEventGallery`,
      method: "PATCH"
    },

  },
  tags: {
    getTags: {
      url: `${MONOLITH_PATH}/tagList`,
      method: "GET"
    },
    createTag: {
      url: `${MONOLITH_PATH}/createTag`,
      method: "POST"
    },
  },
  speakers: {
    getAllSpeakers: {
      url: `${MONOLITH_PATH}/user/getAllSpeakerList`,
      method: "GET"
    },
    createSpeaker: {
      url: `${MONOLITH_PATH}/createSpeakerInUser`,
      method: "POST"
    },

  },
  news: {
    getAllNews: {
      url: `${MONOLITH_PATH}/news/list`,
      method: "GET"
    },
    getNewsSuggestion: {
      url: `${MONOLITH_PATH}/news/getNewsSuggestionList`,
      method: "GET"
    },
    createNews: {
      url: `${MONOLITH_PATH}/news/create`,
      method: "POST",
    },
    editNews: {
      url: `${MONOLITH_PATH}/news/edit/:news_id`,
      method: "POST",
    },
    deleteNews: {
      url: `${MONOLITH_PATH}/news/delete/:news_id`,
      method: "PATCH",
    },
    getNewsById: {
      url: `${MONOLITH_PATH}/news/detailForAdmin/:news_id`,
      method: "GET",
    },
    makeNewsFeatured: {
      url: `${MONOLITH_PATH}/news/makeNewsFeatured/:news_id`,
      method: "PATCH",
    },
    createNewsBanner: {
      url: `${MONOLITH_PATH}/news/createBanner`,
      method: "POST",
    },
    editNewsBanner: {
      url: `${MONOLITH_PATH}/news/editBanner/:banner_id`,
      method: "PATCH",
    },
    getAllNewsBanner: {
      url: `${MONOLITH_PATH}/news/getAllBanner`,
      method: "GET",
    },
    getAllNewsBannerSuggestions: {
      url: `${MONOLITH_PATH}/news/getAllBannerSuggestionList`,
      method: "GET",
    },
    getNewsBannerById: {
      url: `${MONOLITH_PATH}/news/getBannerById/:banner_id`,
      method: "GET",
    },
    deleteNewsBannerById: {
      url: `${MONOLITH_PATH}/news/deleteBanner/:banner_id`,
      method: "PATCH",
    },
    reorderNewsBanner: {
      url: `${MONOLITH_PATH}/news/reorderBanner`,
      method: "POST",
    },
    createNewsPost: {
      url: `${MONOLITH_PATH}/news/createPost`,
      method: "POST",
    },
    editNewsPost: {
      url: `${MONOLITH_PATH}/news/editPost/:post_id`,
      method: "PATCH",
    },
    getAllNewsPosts: {
      url: `${MONOLITH_PATH}/news/getAllPost`,
      method: "GET",
    },
    getNewsPostById: {
      url: `${MONOLITH_PATH}/news/getPostById/:post_id`,
      method: "GET",
    },
    deleteNewsPost: {
      url: `${MONOLITH_PATH}/news/deletePost/:post_id`,
      method: "PATCH",
    },
    getAllPostSuggestionList: {
      url: `${MONOLITH_PATH}/news/getAllPostSuggestionList`,
      method: "GET",
    },
    getContentNewsList: {
      url: `${MONOLITH_PATH}/contentNews/list`,
      method: "GET",
    },
    deleteNewsContent: {
      url: `${MONOLITH_PATH}/news/delete/:content_id`,
      method: "PATCH",
    },
    getNewsContentDetail: {
      url: `${MONOLITH_PATH}/news/detailForAdmin/:content_id`,
      method: "GET",
    },
    getNewsContentSuggestionLists: {
      url: `${MONOLITH_PATH}/contentNews/getContentNewsSuggestionList/`,
      method: "GET",
    },

  },
  users: {
    getAllUsersMembersAndAttendees: {
      url: `${MONOLITH_PATH}/user/getAllUsersMembersAndAttendees`,
      method: "GET"
    }

  },

  partners: {
    //badges crud
    badgeList: {
      url: `${MONOLITH_PATH}/partner/Badge/list`,
      method: "GET"
    },
    allBadgeList: {
      url: `${MONOLITH_PATH}/partner/Badge/partnerBadgelist`,
      method: "GET"
    },
    getBadgeById: {
      url: `${MONOLITH_PATH}/partner/Badge/detail/:badge_id`,
      method: "GET"
    },
    badgeSuggestionList: {
      url: `${MONOLITH_PATH}/partner/Badge/getAllPartnerBadgeSuggestionList`,
      method: "GET"
    },
    createBadge: {
      url: `${MONOLITH_PATH}/partner/Badge/create`,
      method: "POST"
    },
    editBadge: {
      url: `${MONOLITH_PATH}/partner/Badge/edit/:badge_id`,
      method: "POST"
    },
    deleteBadge: {
      url: `${MONOLITH_PATH}/partner/Badge/delete/:badge_id`,
      method: "PATCH"
    },
    restBadge: {
      url: `${MONOLITH_PATH}/partner/Badge/restPartnerBadges/:badge_id`,
      method: "GET"
    },
    badgeReorder: {
      url: `${MONOLITH_PATH}/partner/badgesReorder`,
      method: "POST"
    },
    //partner categories crud
    categoryList: {
      url: `${MONOLITH_PATH}/partner/categories`,
      method: "GET"
    },
    getCategoryById: {
      url: `${MONOLITH_PATH}/partner/category/:category_id`,
      method: "GET"
    },
    categoriesSuggestionList: {
      url: `${MONOLITH_PATH}/partner/categoriesSuggestionList`,
      method: "GET"
    },
    createCategory: {
      url: `${MONOLITH_PATH}/partner/category/create`,
      method: "POST"
    },
    editCategory: {
      url: `${MONOLITH_PATH}/partner/category/edit/:category_id`,
      method: "PUT"
    },
    deleteCategory: {
      url: `${MONOLITH_PATH}/partner/category/delete/:category_id`,
      method: "DELETE"
    },
    categoryForPartnerReorder: {
      url: `${MONOLITH_PATH}/partner/reorderCategoryForPartnerWithOrder`,
      method: "POST"
    },
    //reasons crud
    allReasonList: {
      url: `${MONOLITH_PATH}/partner/getReasonList`,
      method: "GET"
    },
    getReasonListByPartnerId: {
      url: `${MONOLITH_PATH}/partner/getReasonListByPartnerId/:partner_id`,
      method: "GET"
    },
    getReasonById: {
      url: `${MONOLITH_PATH}/partner/getReasonDetail/:reason_id`,
      method: "GET"
    },
    partnerReasonSuggestionList: {
      url: `${MONOLITH_PATH}/partner/getAllPartnerReasonSuggestionList`,
      method: "GET"
    },
    createReason: {
      url: `${MONOLITH_PATH}/partner/createReason`,
      method: "POST"
    },
    editReason: {
      url: `${MONOLITH_PATH}/partner/editReason/:reason_id`,
      method: "POST"
    },
    deleteReason: {
      url: `${MONOLITH_PATH}/partner/deleteReason/:reason_id`,
      method: "PATCH"
    },
    partnerSetting: {
      url: `${MONOLITH_PATH}/updatePartnerBanner/:banner_id`,
      method: "PATCH"
    },
    getPartnerSetting: {
      url: `${MONOLITH_PATH}/partnerBanner/list`,
      method: "GET"
    },
    allReviewList: {
      url: `${MONOLITH_PATH}/partnerReview/list`,
      method: "GET"
    },
    reviewListByPartnerId: {
      url: `${MONOLITH_PATH}/reviewsByPartner/:partner_id`,
      method: "GET"
    },
    reviewDelete: {
      url: `${MONOLITH_PATH}/deleteReview/:review_id`,
      method: "PATCH"
    },
    approveOrRejectReview: {
      url: `${MONOLITH_PATH}/approveOrRejectReview/:review_id`,
      method: "POST"
    },
    partnerReviewSuggestionList: {
      url: `${MONOLITH_PATH}/partnerReview/getPartnerReviewSuggestionList`,
      method: "GET"
    },
    sendApproveOrRejectReviewEmail: {
      url: `${MONOLITH_PATH}/sendApproveOrRejectReviewEmail/:review_id`,
      method: "POST"
    },
    reportReviewCount: {
      url: `${MONOLITH_PATH}/getPartnerReviewReportUserList/:review_id`,
      method: "GET"
    },
    getUnFeaturedPartnerList: {
      url: `${MONOLITH_PATH}/partner/getPublishedPartnersList`,
      method: "GET"
    },
    getFeaturedPartnerList: {
      url: `${MONOLITH_PATH}/partner/getFeaturedOrFreshdealPartnersList`,
      method: "GET"
    },
    addFeaturedPartner: {
      url: `${MONOLITH_PATH}/partner/addFeaturedOrFreshDealPartners`,
      method: "POST"
    },
    removeFeaturedPartner: {
      url: `${MONOLITH_PATH}/partner/removeFeaturedOrFreshDealPartners/:partner_id`,
      method: "PATCH"
    },
    reorderFeaturedPartner: {
      url: `${MONOLITH_PATH}/partner/reorderFeaturedOrFreshdealPartner`,
      method: "POST"
    },
 
    addFreshDealPartner: {
      url: `${MONOLITH_PATH}/partner/addFeaturedOrFreshDealPartners`,
      method: "POST"
    },
    freshDealPartnersList: {
      url: `${MONOLITH_PATH}/partner/getFeaturedOrFreshdealPartnersList`,
      method: "GET"
    },
    removeFreshDealPartner: {
      url: `${MONOLITH_PATH}/partner/removeFeaturedOrFreshDealPartners/:partner_id`,
      method: "PATCH"
    },
    reorderFreshDealPartner: {
      url: `${MONOLITH_PATH}/partner/reorderFeaturedOrFreshdealPartner/`,
      method: "POST"
    },
   
    getAllPartner: {
      url: `${MONOLITH_PATH}/partner/list`,
      method: "GET"
    },
    getPartnerList: {
      url: `${MONOLITH_PATH}/allPartner/list`,
      method: "GET"
    },
    getPartnerSuggestions: {
      url: `${MONOLITH_PATH}/partner/getPartnerSuggestionList`,
      method: "GET"
    },
    getPartnerDetail: {
      url: `${MONOLITH_PATH}/partner/detail/:partner_id`,
      method: "GET"
    },
    addPartner: {
      url: `${MONOLITH_PATH}/partner/create/`,
      method: "POST"
    },
    editPartner: {
      url: `${MONOLITH_PATH}/partner/edit/:partner_id`,
      method: "POST"
    },
    deletePartner: {
      url: `${MONOLITH_PATH}/partner/delete/:partner_id`,
      method: "PATCH"
    },
    clonePartner: {
      url: `${MONOLITH_PATH}/partner/clonePartner/`,
      method: "POST"
    },
    addPartnerPost: {
      url: `${MONOLITH_PATH}/partner/post/create/`,
      method: "POST"
    },
    getAllPartnerPostByPartnerId: {
      url: `${MONOLITH_PATH}/partner/post/list/:partner_id`,
      method: "GET"
    },
    getPartnerPostDetail: {
      url: `${MONOLITH_PATH}/partner/post/detail/:post_id`,
      method: "GET"
    },
    deletePartnerPost: {
      url: `${MONOLITH_PATH}/partner/post/delete/:post_id`,
      method: "PATCH"
    },
    applyUrlToAllPartnerPosts: {
      url: `${MONOLITH_PATH}/partner/post/applyurltoposts`,
      method: "POST"
    },
    editPartnerPost: {
      url: `${MONOLITH_PATH}/partner/post/edit/:post_id`,
      method: "POST"
    },
    partnerPostSuggestions: {
      url: `${MONOLITH_PATH}/partner/post/getPartnerPostByPartnerIdSuggestionList/:partner_id`,
      method: "GET"
    },
    //related video 
    addRelatedvideo: {
      url: `${MONOLITH_PATH}/addVidosInPartners`,
      method: "POST"
    },
    getAllVideoList: {
      url: `${MONOLITH_PATH}/getContentVideolistForPartner`,
      method: "GET"
    },
    getVideoByPartner: {
      url: `${MONOLITH_PATH}/getVideoListByPartner/:partner_id`,
      method: "GET"
    },
    updateRelatedVideoStatus: {
      url: `${MONOLITH_PATH}/partner/updateRelatedVideoStatus/:video_id`,
      method: "POST"
    },
    deleteVideo:{
      url: `${MONOLITH_PATH}/removeVidosFromPartner/:partner_id`,
      method: "PATCH"
    },
    getVideoSuggestionByPartner: {
      url: `${MONOLITH_PATH}/getVideoListByPartnerSuggestionList/:partner_id`,
      method: "GET"
    },
    addVideoByTag:{
      url: `${MONOLITH_PATH}/partner/addVideosByTagInPartners/`,
      method: "POST"
    },
    reorderVideosInPartners:{
      url: `${MONOLITH_PATH}/partner/reorderVideosInPartners/:partner_id`,
      method: "POST"
    },
    partnerStatisticAllCountForPartner:{
      url: `${MONOLITH_PATH}/partnerStatisticAllCountForAdmin`,
      method: "GET"
    },
    partnerStatisticFieldcount:{
      url: `${MONOLITH_PATH}/partnerStatisticFieldcountDatewiseForAdmin/:query`,
      method: "GET"
    },
    partnerStatisticListForAdmin:{
      url: `${MONOLITH_PATH}/partnerStatisticListForAdmin/:query`,
      method: "GET"
    },
    partnerStatisticDateWiseFilterCountForAdmin:{
      url: `${MONOLITH_PATH}/partnerStatisticDateWiseFilterCountForAdmin/:query`,
      method: "GET"
    },
    whoClickGetDetails:{
      url: `${MONOLITH_PATH}/whoClickGetDetails/:query`,
      method: "GET"
    },
    whoClickPartnerData:{
      url: `${MONOLITH_PATH}/whoClickPartnerData/:query`,
      method: "GET"
    }
  },
  documents:{
    //content types
    createContentType:{
      url: `${MONOLITH_PATH}/documents/contentType/add`,
      method: "POST"
    },
    editContentType:{
      url: `${MONOLITH_PATH}/documents/contentType/edit/:type_id`,
      method: "PATCH"
    },
    viewContentType:{
      url: `${MONOLITH_PATH}/documents/contentType/getById/:type_id`,
      method: "GET"
    },
    contentTypeList:{
      url: `${MONOLITH_PATH}/documents/contentType/list/:query`,
      method: "GET"
    },
    deleteContentType:{
      url: `${MONOLITH_PATH}/documents/contentType/delete/:type_id`,
      method: "DELETE"
    },
    
  },
  // BILLING ENDPOINT ROUTES
  modules: {
    getModules: {
      url: `${BILLING_PATH}/api/v1/modules`,
      method: "GET",
    },
    getModule: {
      url: `${BILLING_PATH}/api/v1/modules/:module_id`,
      method: "GET",
    },
    addModule: {
      url: `${BILLING_PATH}/api/v1/modules`,
      method: "POST",
    },
    editModule: {
      url: `${BILLING_PATH}/api/v1/modules/:module_id`,
      method: "PATCH",
    },
    removeModule: {
      url: `${BILLING_PATH}/api/v1/modules/:module_id`,
      method: "DELETE",
    },
  },
  tiers: {
    getTiers: {
      url: `${BILLING_PATH}/api/v1/tiers`,
      method: "GET",
    },
    getTier: {
      url: `${BILLING_PATH}/api/v1/tiers/:tier_id`,
      method: "GET",
    },
    addTier: {
      url: `${BILLING_PATH}/api/v1/tiers`,
      method: "POST",
    },
    editTier: {
      url: `${BILLING_PATH}/api/v1/tiers/:tier_id`,
      method: "PATCH",
    },
    removeTier: {
      url: `${BILLING_PATH}/api/v1/tiers/:tier_id`,
      method: "DELETE",
    },
    getPopularTiers: {
      url: `${BILLING_PATH}/api/v1/tiers/popular`,
      method: "GET",
    },
    tiersByName: {
      url: `${BILLING_PATH}/api/v1/tiers/by-name`,
      method: "GET",
    }
  },
  tierBenefits: {
    getTierBenefits: {
      url: `${BILLING_PATH}/api/v1/tier-benefits`,
      method: "GET",
    },
    getTierBenefit: {
      url: `${BILLING_PATH}/api/v1/tier-benefits/:tier_benefit_id`,
      method: "GET",
    },
    addTierBenefit: {
      url: `${BILLING_PATH}/api/v1/tier-benefits`,
      method: "POST",
    },
    editTierBenefit: {
      url: `${BILLING_PATH}/api/v1/tier-benefits/:tier_benefit_id`,
      method: "PATCH",
    },
    removeTierBenefit: {
      url: `${BILLING_PATH}/api/v1/tier-benefits/:tier_benefit_id`,
      method: "DELETE",
    },
  },
  stripe: {
    config: {
      url: `${BILLING_PATH}/api/v1/stripe/config`,
      method: "GET",
    },
    webhook: {
      url: `${BILLING_PATH}/api/v1/stripe/webhook`,
      method: "POST",
    },
    createCheckoutSession: {
      url: `${BILLING_PATH}/api/v1/stripe/create-checkout-session`,
      method: "POST",
    },
    createAccountLink: {
      url: `${BILLING_PATH}/api/v1/stripe/create-account-link`,
      method: "POST",
    },
    fetchOnboardingStatus: {
      url: `${BILLING_PATH}/api/v1/stripe/fetch-onboarding-status`,
      method: "GET",
    },
    getSessionStatus: {
      url: `${BILLING_PATH}/api/v1/stripe/session-status`,
      method: "GET",
    },
    getSubscriptionChangeConfirmation: {
      url: `${BILLING_PATH}/api/v1/stripe/subscription-change-confirmation`,
      method: "GET",
    },
    subscriptionChange: {
      url: `${BILLING_PATH}/api/v1/stripe/subscription-change`,
      method: "POST",
    },
    subscriptionCancel: {
      url: `${BILLING_PATH}/api/v1/stripe/subscription-opt-out`,
      method: "POST",
    },
    subscriptionRenew: {
      url: `${BILLING_PATH}/api/v1/stripe/subscription-renew`,
      method: "POST",
    },
    fetchStripeAccount: {
      url: `${BILLING_PATH}/api/v1/stripe/fetch-account`,
      method: "GET",
    },
    getSubscriptionCancelConfirmation: {
      url: `${BILLING_PATH}/api/v1/stripe/subscription-cancel-confirmation`,
      method: "GET",
    },
    getBalance: {
      url: `${BILLING_PATH}/api/v1/stripe/balance`,
      method: "GET",
    },
    getMemberBalance: {
      url: `${BILLING_PATH}/api/v1/stripe/member-balance`,
      method: "GET",
    },
    subscriptionCancelByAdmin: {
      url: `${BILLING_PATH}/api/v1/stripe/subscription-cancel`,
      method: "POST",
    },
    getRevenuePayments: {
      url: `${BILLING_PATH}/api/v1/stripe/revenue`,
      method: "GET",
    },
    getGrossVolumePayments: {
      url: `${BILLING_PATH}/api/v1/stripe/gross-volume`,
      method: "GET",
    },
    getNetVolumePayments: {
      url: `${BILLING_PATH}/api/v1/stripe/net-volume`,
      method: "GET",
    },
    getSalesPayments: {
      url: `${BILLING_PATH}/api/v1/stripe/sales`,
      method: "GET",
    },
    createPayout: {
      url: `${BILLING_PATH}/api/v1/stripe/payouts`,
      method: "POST",
    },
    getPayouts: {
      url: `${BILLING_PATH}/api/v1/stripe/payouts`,
      method: "GET",
    },
  },
  subscriptions: {
    getSubscriptions: {
      url: `${BILLING_PATH}/api/v1/subscriptions`,
      method: "GET",
    },
    getSubscription: {
      url: `${BILLING_PATH}/api/v1/subscriptions/:subscription_id`,
      method: "GET",
    },
    getOwnerSubscription: {
      url: `${BILLING_PATH}/api/v1/subscriptions/owner/:owner_id`,
      method: "GET",
    },
    getSubscriptionHistory: {
      url: `${BILLING_PATH}/api/v1/subscriptions/history`,
      method: "GET",
    },
  },
  reminder: {
    getReminder: {
      url: `${BILLING_PATH}/api/v1/subscriptions-renewal-reminders/:subscription_renewal_reminder_config_id`,
      method: "GET",
    },
    editReminder: {
      url: `${BILLING_PATH}/api/v1/subscriptions-renewal-reminders/:subscription_renewal_reminder_config_id`,
      method: "PATCH",
    },
  },

};
