const initialState = {
  selectedTickets: [],
};

const SelectedEventData = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_SELECTED_TICKET_EVENT":
      return {
        ...state,
        selectedTickets: [action.payload],
      };
    case "REMOVE_SELECTED_TICKET_EVENT":
      return {
        ...state,
        selectedTickets: state.selectedTickets.filter(
          (ticket) => ticket.id !== action.payload
        ),
      };
    case "UPDATE_SELECTED_TICKET_QUANTITY":
      return {
        ...state,
        selectedTickets: state.selectedTickets.map((ticket) => {
          if (ticket.id === action.payload.id) {
            return {
              ...ticket,
              quantity: action.payload.quantity,
            };
          }
          return ticket;
        }),
      };
    default:
      return state;
  }
};

export default SelectedEventData;
