import React from 'react'
import PropTypes from "prop-types"
const CalendarIcon = ({ width = 24, height = 24, iconColor = "#000" }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 24 24"
        >
            <path
                fill={iconColor}
                fillRule="evenodd"
                d="M15.75 2.5a.75.75 0 0 0-1.5 0V4h-4.5V2.5a.75.75 0 0 0-1.5 0v1.506A5.5 5.5 0 0 0 3 9.5v6A5.5 5.5 0 0 0 8.5 21h7a5.5 5.5 0 0 0 5.5-5.5v-6a5.5 5.5 0 0 0-5.25-5.494V2.5Zm-7.5 3.008A4 4 0 0 0 4.508 9.25h14.984a4 4 0 0 0-3.742-3.742V6.5a.75.75 0 0 1-1.5 0v-1h-4.5v1a.75.75 0 0 1-1.5 0v-.992ZM4.5 10.75h15v4.75a4 4 0 0 1-4 4h-7a4 4 0 0 1-4-4v-4.75Z"
                clipRule="evenodd"
            />
        </svg>


    )
}
CalendarIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    iconColor: PropTypes.string
}
export default CalendarIcon