const filelist = [];

const ReduFileList = (state = filelist, action) => {
  switch (action.type) {
    case "SETFILELIST":
      if (
        action.payload?.fileList &&
        action.payload?.totalPages &&
        action.payload.totalPages
      ) {
        return {
          ...state,
          [action.payload.chatid]: {
            data: action.payload,
            filterType: "Recent",
          },
        };
      } else {
        return state;
      }
    case "SETMOREMEDIALIST":
      if (
        action.payload?.fileList &&
        action.payload?.totalPages &&
        action.payload.totalPages
      ) {
        return {
          ...state,
          [action.payload.chatid]: {
            data: {
              ...action.payload,
              fileList: [
                ...state[action.payload.chatid].data.fileList,
                ...action.payload.fileList,
              ],
            },
            filterType: "Recent",
          },
        };
      } else {
        return state;
      }
    case "SETFILTERFILELIST":
      if (action.payload?.filterType && action.payload?.chatid) {
        if (action.payload.filterType === "Name") {
          return {
            ...state,
            [action.payload.chatid]: {
              data: {
                ...state[action.payload.chatid].data,
                fileList: state[action.payload.chatid].data.fileList.sort(
                  (a, b) =>
                    a.file
                      .split("-GRPCHATUNIQUE-")[1]
                      .localeCompare(b.file.split("-GRPCHATUNIQUE-")[1])
                ),
              },
              filterType: "Name",
            },
          };
        } else if (action.payload.filterType === "Date") {
          return {
            ...state,
            [action.payload.chatid]: {
              data: {
                ...state[action.payload.chatid].data,
                fileList: state[action.payload.chatid].data.fileList
                  .sort((a, b) => a.createdAt.localeCompare(b.createdAt))
                  .reverse(),
              },
              filterType: "Date",
            },
          };
        } else return state;
      } else {
        return state;
      }
    case "SETNEWMSGFILELIST":
      if (action.payload) {
        var newData = {
          file: action.payload.file,
          file_size: action.payload.file_size,
          createdAt: action.payload.createdAt,
        };
        if (state[action.payload.chatid] !== undefined) {
          var newFileList = [];
          const currentFilterType = state[action.payload.chatid].filterType;
          if (currentFilterType === "Name") {
            newFileList = [
              ...state[action.payload.chatid].data.fileList,
              newData,
            ].sort((a, b) =>
              a.file
                .split("-GRPCHATUNIQUE-")[1]
                .localeCompare(b.file.split("-GRPCHATUNIQUE-")[1])
            );
          } else {
            newFileList = [
              ...state[action.payload.chatid].data.fileList,
              newData,
            ]
              .sort((a, b) => a.createdAt.localeCompare(b.createdAt))
              .reverse();
          }

          return {
            ...state,
            [action.payload.chatid]: {
              data: {
                ...state[action.payload.chatid].data,
                fileList: newFileList,
                totalMessages:
                  state[action.payload.chatid].data.totalMessages + 1,
              },
              filterType: currentFilterType,
            },
          };
        } else {
          return {
            ...state,
            [action.payload.chatid]: {
              data: { fileList: [newData], totalMessages: 1 },
              filterType: "Recent",
            },
          };
        }
      }
      return state;
    case "DELETEFILELIST":
      if (state[action.payload.chatid]) {
        if (state[action.payload.chatid].data.fileList.length == 1) {
          const newState = state;
          delete newState[action.payload.chatid];
          return newState;
        }
        return {
          ...state,
          [action.payload.chatid]: {
            data: {
              ...state[action.payload.chatid].data,
              fileList: state[action.payload.chatid].data.fileList.filter(
                (list) => list._id !== action.payload._id
              ),
              totalMessages:
                state[action.payload.chatid].data.totalMessages - 1,
            },
            filterType: state[action.payload.chatid].filterType,
          },
        };
      }
      return state;
    case "CLEARFILELIST":
      if (state[action.payload.chatid]) {
        const newState = state;
        delete newState[action.payload.chatid];
        return newState;
      }
      return state;
    default:
      return state;
  }
};

export default ReduFileList;
