import React from "react";
import PropTypes from "prop-types";
const IconSmicrophone = ({
  width = 16,
  height = 16,
  isFilled = true,
  iconColor = "#000000",
  scale = 1,
}) => {
  return (
    <>
      {isFilled ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          fill="none"
          viewBox="0 0 16 16"
        >
          <path
            fill={iconColor}
            fillRule="evenodd"
            d="M4.706 4.294a3.294 3.294 0 0 1 6.589 0V6.93a3.294 3.294 0 0 1-6.589 0V4.294Z"
            clipRule="evenodd"
          />
          <path
            fill={iconColor}
            d="M8 11.212a4.282 4.282 0 0 1-4.282-4.283v-.164a.494.494 0 1 0-.988 0v.164a5.271 5.271 0 0 0 4.776 5.248v1.835H6.024a.494.494 0 1 0 0 .988h3.953a.494.494 0 1 0 0-.988H8.495v-1.835A5.271 5.271 0 0 0 13.27 6.93v-.164a.494.494 0 0 0-.988 0v.164A4.282 4.282 0 0 1 8 11.212Z"
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          fill="none"
          viewBox="0 0 16 16"
        >
          <path
            fill={iconColor}
            fillRule="evenodd"
            d="M10.306 4.294V6.93a2.306 2.306 0 0 1-4.611 0V4.294a2.306 2.306 0 1 1 4.611 0Zm-5.6 0a3.294 3.294 0 0 1 6.589 0V6.93a3.294 3.294 0 0 1-6.589 0V4.294Zm3.295 6.918a4.282 4.282 0 0 1-4.283-4.283v-.164a.494.494 0 1 0-.988 0v.164a5.271 5.271 0 0 0 4.776 5.248v1.835H6.024a.494.494 0 1 0 0 .988h3.953a.494.494 0 1 0 0-.988H8.495v-1.835A5.271 5.271 0 0 0 13.27 6.93v-.164a.494.494 0 0 0-.988 0v.164A4.282 4.282 0 0 1 8 11.212Z"
            clipRule="evenodd"
          />
        </svg>
      )}
    </>
  );
};
IconSmicrophone.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  isFilled: PropTypes.bool,
  iconColor: PropTypes.string,
  scale: PropTypes.number,
};
export default IconSmicrophone;
