const linkList = [];

const ReduUrlList = (state = linkList, action) => {
  switch (action.type) {
    case "SETURLLIST":
      if (
        action.payload?.linkList &&
        action.payload?.totalPages &&
        action.payload?.chatid
      ) {
        return {
          ...state,
          [action.payload.chatid]: {
            data: action.payload,
            filterType: "Recent",
          },
        };
      } else {
        return state;
      }
    case "SETMOREMEDIALIST":
      if (
        action.payload?.linkList &&
        action.payload?.totalPages &&
        action.payload.totalPages
      ) {
        return {
          ...state,
          [action.payload.chatid]: {
            data: {
              ...action.payload,
              linkList: [
                ...state[action.payload.chatid].data.linkList,
                ...action.payload.linkList,
              ],
            },
            filterType: "Recent",
          },
        };
      } else {
        return state;
      }
    case "SETFILTERURLLIST":
      if (action.payload?.filterType && action.payload?.chatid) {
        if (action.payload.filterType === "Name") {
          return {
            ...state,
            [action.payload.chatid]: {
              data: {
                ...state[action.payload.chatid].data,
                linkList: state[action.payload.chatid].data.linkList.sort(
                  (a, b) => a.link.localeCompare(b.link)
                ),
              },
              filterType: "Name",
            },
          };
        } else if (action.payload.filterType === "Date") {
          return {
            ...state,
            [action.payload.chatid]: {
              data: {
                ...state[action.payload.chatid].data,
                linkList: state[action.payload.chatid].data.linkList
                  .sort((a, b) => a.createdAt.localeCompare(b.createdAt))
                  .reverse(),
              },
              filterType: "Date",
            },
          };
        } else return state;
      } else {
        return state;
      }
    case "SETNEWMSGURLLIST":
      if (action.payload) {
        var newData = {
          _id: action.payload._id,
          createdAt: action.payload.createdAt,
          link: action.payload.link,
          chatid: action.payload.chatid,
        };
        if (state[action.payload.chatid] !== undefined) {
          var newUrlList = [];
          const currentFilterType = state[action.payload.chatid].filterType;
          if (currentFilterType === "Name") {
            newUrlList = [
              ...state[action.payload.chatid].data.linkList,
              newData,
            ].sort((a, b) => a.link.localeCompare(b.link));
          } else {
            newUrlList = [
              ...state[action.payload.chatid].data.linkList,
              newData,
            ]
              .sort((a, b) => a.createdAt.localeCompare(b.createdAt))
              .reverse();
          }

          return {
            ...state,
            [action.payload.chatid]: {
              data: {
                ...state[action.payload.chatid].data,
                linkList: newUrlList,
                totalMessages:
                  state[action.payload.chatid].data.totalMessages + 1,
              },
              filterType: currentFilterType,
            },
          };
        } else {
          return {
            ...state,
            [action.payload.chatid]: {
              data: { linkList: [newData], totalMessages: 1 },
              filterType: "Recent",
            },
          };
        }
      }
      return state;
    case "DELETEURLLIST":
      if (state[action.payload.chatid]) {
        if (state[action.payload.chatid].data.linkList.length == 1) {
          const newState = state;
          delete newState[action.payload.chatid];
          return newState;
        }
        return {
          ...state,
          [action.payload.chatid]: {
            data: {
              ...state[action.payload.chatid].data,
              linkList: state[action.payload.chatid].data.linkList.filter(
                (list) => list._id !== action.payload._id
              ),
              totalMessages:
                state[action.payload.chatid].data.totalMessages - 1,
            },
            filterType: state[action.payload.chatid].filterType,
          },
        };
      }
      return state;
    case "CLEARURLLIST":
      if (state[action.payload.chatid]) {
        const newState = state;
        delete newState[action.payload.chatid];
        return newState;
      }
      return state;
    default:
      return state;
  }
};

export default ReduUrlList;
