import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import authService from "../../../Services/auth.service";
import authServiceChannel from "../../../Services/auth.header.channel";
import "./ChatStyle/ChatPopup.css";
import {
  getMuteChatIdListForUser,
  updatestarchat,
  clearchatfromlist,
  clearchatredu,
} from "../../../Action";
import ChatHeading from "../Components/ChatHeading";
import ModalPopup from "../../../components/ModalPopup/ModalPopup";
import RadioButton from "../../../components/RadioButton/RadioButton";
import Typography from "../../../components/Typography/Typography";
import useSocket from "Hooks/useSocket";
import useAuth from "Hooks/useAuth";

const ChatHeaderContent = ({
  showRightSidebar,
  setShowRightSidebar,
  allChatHeaderDetail,
  setAllChatHeaderDetail,
  groupMemberList,
  setEditGroup,
}) => {
  const { socket } = useSocket();
  const { auth } = useAuth();
  const { userid, type } = useParams();
  const dispatch = useDispatch();
  const history = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedMuteOption, setSelectedMuteOption] = useState(null);
  const [isModalOpenClearChat, setIsModalOpenClearChat] = useState(false);
  const [leaveClearHistoryLoader, setClearHistoryLoader] = useState(false);
  const [openModelLeave, setOpenModelLeave] = useState(false);
  const [leaveGroupChannelLoader, setGroupChannelLoader] = useState(false);
  const [openDeleteGroupModal, setOpenDeleteGroupModal] = useState(false);
  const [deleteGroupLoader, setDeleteGroupLoader] = useState(false);

  // Get all mute notification data
  const muteNotificationList = useSelector(
    (state) => state?.muteNotificationReducer
  );
  // Get all star Chat user data
  const starchatuser = useSelector((state) => {
    return state.ReduStarChat;
  });

  // Get notification list common function
  const getMuteNotificationList = async () => {
    dispatch(getMuteChatIdListForUser());
  };

  useEffect(() => {
    //calling function for mute notification list
    getMuteNotificationList();
  }, []);

  // For get member profile
  useEffect(() => {
    if (type !== "userChatGroup" && type !== "chatChannel") {
      authService.getmemberprofile(userid).then((response) => {
        if (response.data.status) {
          setAllChatHeaderDetail({
            ...allChatHeaderDetail,
            [response.data.data._id]: { ...response.data.data, type: "user" },
          });
        }
      });
    } else if (type === "chatChannel") {
      authServiceChannel
        .getChannelDetailByIdFrontend(userid)
        .then((response) => {
          if (response.data.status) {
            setAllChatHeaderDetail({
              ...allChatHeaderDetail,
              [userid]: { ...response.data.channelData },
            });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      authService
        .getGroupAndMemberDetails(userid)
        .then((res) => {
          if (res.data.status) {
            if (res.data.data[0])
              setAllChatHeaderDetail({
                ...allChatHeaderDetail,
                [res.data.data[0]._id]: {
                  ...res.data.data[0],
                  type: "userChatGroup",
                },
              });
          }
        })
        .catch((e) => {});
    }
  }, [userid, type, groupMemberList]);

  //handle info click in header content
  const handleInfoClick = () => {
    setShowRightSidebar(!showRightSidebar);
    if (showRightSidebar) {
      localStorage.setItem("isChatInfo", false);
    } else {
      localStorage.setItem("isChatInfo", true);
    }
  };

  //open mute notification modal box
  const handleonClickMuteNotification = () => {
    setIsModalOpen(true);
    setSelectedMuteOption(null);
  };

  //close mute notification modal box
  const handleonClickMuteNotificationCancel = () => {
    setIsModalOpen(false);
    setSelectedMuteOption(null);
  };
  //handle mute radio button change
  const handleRadioChange = (value) => {
    setSelectedMuteOption(value);
  };

  // handle un-mute notification
  const handleonClickUnMuteNotification = () => {
    const payload = {
      chatId: userid,
      unMute: muteNotificationList?.muteNotification?.filter(
        (muteData) => userid === muteData.chatId
      ).length
        ? true
        : false,
      dateTime: "",
      noLimit: "",
    };
    // Call the API with the payload
    authService
      .muteChatForAll(payload)
      .then((response) => {
        if (response.data.status) {
          getMuteNotificationList();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  //handle chat mute notification
  const handleMuteChat = async () => {
    // Calculate UTCDateTime based on selectedMuteOption
    let utcDateTime = null;
    if (selectedMuteOption !== "until") {
      const currentDateTime = new Date();
      const muteDuration = parseInt(selectedMuteOption, 10); // Assuming selectedMuteOption is in minutes
      currentDateTime.setUTCMinutes(
        currentDateTime.getUTCMinutes() + muteDuration
      );
      utcDateTime = currentDateTime.toISOString();
    }

    // Prepare payload
    const payload = {
      chatId: userid,
      unMute: false,
      dateTime: utcDateTime,
      noLimit: selectedMuteOption === "until" ? true : false,
    };

    // Call the API with the payload
    try {
      authService
        .muteChatForAll(payload)
        .then((response) => {
          if (response.data.status) {
            getMuteNotificationList();
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (error) {
      console.error("Error muting chat:", error);
    }
    // Close the modal
    setIsModalOpen(false);
  };

  // handle star user
  const handleStarUser = async () => {
    dispatch(updatestarchat(userid));
    authService.starchat(userid);
  };

  // onselect heaader 3 dot select
  const onSelect = (selectedOption) => {
    selectedOption.name === "Mute notification" &&
      handleonClickMuteNotification();
    selectedOption.name === "Unmute notification" &&
      handleonClickUnMuteNotification();
    selectedOption.name === "Leave channel" && handleLeaveGroup();
    selectedOption.name === "Leave group" && handleLeaveGroup();
    selectedOption.name === "Edit group" && editGroupFun();
    selectedOption.name === "Clear history" && handleClearHistory();
    selectedOption.name === "Delete group" && handleDeleteGroup();
  };

  // handle Leave Group modalbox
  const handleLeaveGroup = () => {
    setOpenModelLeave(true);
  };

  // handle Delete modal box
  const handleDeleteGroup = () => {
    setOpenDeleteGroupModal(true);
  };

  //handle function Clear Chat history
  const handleClearChat = () => {
    dispatch(clearchatredu(userid));
    dispatch(clearchatfromlist(userid));
    setClearHistoryLoader(true);
    authService
      .clearchat({
        id: userid,
        type: type,
        deleteConversation: false,
      })
      .then((res) => {
        if (res.data.status) {
          socket.emit("newChatList", {
            id: auth.userId,
          });
        }
      })
      .catch((e) => {});
    setClearHistoryLoader(false);
    setIsModalOpenClearChat(false);
  };

  // open modalbox for clear chat history
  const handleClearHistory = () => {
    setIsModalOpenClearChat(true);
  };

  // close modalbox for clear chat history
  const handleClose = () => {
    setIsModalOpenClearChat(false);
  };

  // edit group open drawer
  const editGroupFun = () => {
    setEditGroup(true);
    setShowRightSidebar(true);
  };
  // handle close leave group channel popup
  const handleCloseLeaveGroupChannel = () => {
    setOpenModelLeave(false);
  };

  //get Channel Name
  const ChannelName =
    type === "chatChannel"
      ? allChatHeaderDetail[userid]?.channelName
      : type === "userChatGroup"
      ? allChatHeaderDetail[userid]?.groupTitle
      : "";

  // get Group name
  const GroupName =
    type === "userChatGroup" ? allChatHeaderDetail[userid]?.groupTitle : "";

  // get User name for display
  const userName =
    type === "user"
      ? allChatHeaderDetail &&
        allChatHeaderDetail[userid] &&
        (allChatHeaderDetail[userid]?.first_name ||
          allChatHeaderDetail[userid]?.last_name)
        ? `${allChatHeaderDetail[userid]?.first_name} ${allChatHeaderDetail[userid]?.last_name} `
        : ""
      : "";

  // handle Leave channel group fun
  const handleLeaveChannelFun = () => {
    if (type === "userChatGroup") {
      socket.emit("leave-group", {
        groupid: userid,
        authUserId: auth.userId,
        time_stamp: moment.utc().format("YYYY-MM-DDTHH:mm:ss.SSSSSS[Z]"),
      });
      socket.emit("leaveRoom", { roomId: userid });
      dispatch({
        type: "DELETECHATFROMLIST",
        payload: userid,
      });
      dispatch(clearchatredu(userid));

      setOpenModelLeave(false);
      history("/chats/me/me");
    } else if (type === "chatChannel") {
      socket.emit("leave-channel", {
        channelId: userid,
        authUserId: auth.userId,
        time_stamp: moment.utc().format("YYYY-MM-DDTHH:mm:ss.SSSSSS[Z]"),
      });
      socket.emit("leaveRoom", {
        roomId: userid,
      });
      dispatch({
        type: "DELETECHATFROMLIST",
        payload: userid,
      });
      setOpenModelLeave(false);
      dispatch(clearchatredu(userid));

      history("/chats/me/me");
    }
  };

  //handleCloseDeleteGroup
  const handleCloseDeleteGroup = () => {
    setOpenDeleteGroupModal(false);
  };

  //handle delete group func
  //when admin delete and leave a group this get called
  const handleDeleteGroupFun = () => {
    socket.emit("delete-group", {
      groupid: userid,
      authUserId: auth.userId,
    });
    socket.emit("leaveRoom", {
      roomId: userid,
    });
    dispatch({
      type: "DELETECHATFROMLIST",
      payload: userid,
    });
    dispatch(clearchatredu(userid));
    setOpenDeleteGroupModal(false);
    history("/chats/me/me");
  };

  return (
    <>
      <ChatHeading
        onSelect={onSelect}
        isAdmin={
          type === "userChatGroup" &&
          groupMemberList?.members &&
          groupMemberList?.members?.filter(
            (member) =>
              member.userId === auth.userId && member.user_type === "adminuser"
          ).length
            ? true
            : false
        }
        isMuted={
          muteNotificationList?.muteNotification?.filter(
            (muteData) => userid === muteData.chatId
          ).length
            ? true
            : false
        }
        filled={starchatuser?.includes(userid)}
        onlineStatus="online"
        type={
          type === "userChatGroup"
            ? "Group"
            : type === "chatChannel"
            ? "Channel"
            : type === "user"
            ? "AvtarName"
            : ""
        }
        ChannelName={
          type === "chatChannel" && groupMemberList?.channelData?.channelName
            ? groupMemberList.channelData.channelName
            : ChannelName
        }
        GroupName={
          type === "userChatGroup" && groupMemberList?.groupTitle
            ? groupMemberList?.groupTitle
            : GroupName
        }
        AvtarName={userName}
        avtarType={
          type === "chatChannel"
            ? groupMemberList?.channelData &&
              groupMemberList?.channelData?.channelIcon?.length
              ? "photo"
              : "letter"
            : type === "userChatGroup"
            ? groupMemberList?.groupImage && groupMemberList?.groupImage?.length
              ? "photo"
              : "letter"
            : type === "user"
            ? allChatHeaderDetail &&
              allChatHeaderDetail[userid]?.profileImg?.length
              ? "photo"
              : "letter"
            : ""
        }
        avtarSrc={
          type === "chatChannel"
            ? groupMemberList?.channelData &&
              groupMemberList?.channelData?.channelIcon?.length
              ? process.env.REACT_APP_AWS_IMG_VID_PATH +
                groupMemberList?.channelData?.channelIcon
              : ""
            : type === "userChatGroup"
            ? groupMemberList?.groupImage && groupMemberList?.groupImage?.length
              ? groupMemberList?.groupImage.split("?")[0].split("/")[
                  groupMemberList?.groupImage.split("?")[0].split("/").length -
                    1
                ].length && groupMemberList?.groupImage.startsWith("https")
                ? groupMemberList?.groupImage
                : groupMemberList?.groupImage &&
                  groupMemberList?.groupImage.split("?")[0].split("/")[
                    groupMemberList?.groupImage.split("?")[0].split("/")
                      .length - 1
                  ].length
                ? process.env.REACT_APP_AWS_IMG_VID_PATH +
                  groupMemberList?.groupImage
                : ""
              : ""
            : type === "user"
            ? allChatHeaderDetail &&
              allChatHeaderDetail[userid]?.profileImg?.length
              ? allChatHeaderDetail[userid].profileImg
              : ""
            : ""
        }
        avtarLetter={
          type === "chatChannel"
            ? groupMemberList?.channelData &&
              groupMemberList?.channelData?.channelName?.charAt(0)
            : type === "userChatGroup"
            ? groupMemberList?.groupTitle &&
              groupMemberList?.groupTitle?.charAt(0)
            : type === "user"
            ? userName.charAt(0)
            : ""
        }
        handleInfoClick={handleInfoClick}
        handleStarUser={handleStarUser}
        handleonClickMuteNotification={handleonClickMuteNotification}
        handleonClickUnMuteNotification={handleonClickUnMuteNotification}
      />
      <ModalPopup
        onClose={handleonClickMuteNotificationCancel}
        handleClick2ndButton={handleMuteChat}
        isFooter={true}
        isHeader={false}
        modelSize="480"
        disabled={!selectedMuteOption}
        isOpen={isModalOpen}
        buttonText="Cancel"
        buttonText1="Mute"
        buttonType="twobuttons"
      >
        <div className="mute-notification-data">
          <Typography variant="h3">Mute this chat?</Typography>
          <div className="mute-notification-data-list">
            <RadioButton
              checked={selectedMuteOption === "15"}
              hasLabel={true}
              id="15"
              label="For 15 minutes"
              onChange={() => handleRadioChange("15")}
            />
            <RadioButton
              checked={selectedMuteOption === "60"}
              hasLabel={true}
              id="60"
              label="For 1 hour"
              onChange={() => handleRadioChange("60")}
            />
            <RadioButton
              checked={selectedMuteOption === "480"}
              hasLabel={true}
              id="480"
              label="For 8 hours"
              onChange={() => handleRadioChange("480")}
            />
            <RadioButton
              checked={selectedMuteOption === "1440"}
              hasLabel={true}
              id="1440"
              label="For 24 hours"
              onChange={() => handleRadioChange("1440")}
            />
            <RadioButton
              checked={selectedMuteOption === "until"}
              hasLabel={true}
              id="until"
              label="Until I change it"
              onChange={() => handleRadioChange("until")}
            />
          </div>
        </div>
      </ModalPopup>

      {/* clear history Modal box */}
      <ModalPopup
        onClose={handleClose}
        isFooter={true}
        isHeader={false}
        modelSize="648"
        isOpen={isModalOpenClearChat}
        buttonText1="Clear"
        buttonText="Cancel"
        btn2variant="danger"
        handleClick2ndButton={handleClearChat}
        handleClick={handleClose}
        disabled={leaveClearHistoryLoader}
        hasLoading={leaveClearHistoryLoader}
        spinnersType="white"
        disabled1stbtn={leaveClearHistoryLoader}
      >
        <div className="channel-group-wrapper">
          <Typography variant="h3">Clear history</Typography>
          <Typography variant="p">
            Are you sure you want to clear the chat history in&nbsp;
            <b>
              {type === "userChatChannel"
                ? ChannelName
                : type === "user"
                ? userName
                : GroupName}
            </b>
            ?
          </Typography>
        </div>
      </ModalPopup>

      {/* Leave Channel or Group Modal box */}
      <ModalPopup
        onClose={handleCloseLeaveGroupChannel}
        isFooter={true}
        isHeader={false}
        modelSize="648"
        isOpen={openModelLeave}
        buttonText1="Leave"
        buttonText="Cancel"
        btn2variant="danger"
        handleClick2ndButton={handleLeaveChannelFun}
        handleClick={handleCloseLeaveGroupChannel}
        disabled={leaveGroupChannelLoader}
        hasLoading={leaveGroupChannelLoader}
        spinnersType="white"
        disabled1stbtn={leaveGroupChannelLoader}
      >
        <div className="channel-group-wrapper">
          <Typography variant="h3">
            Leave
            {type === "userChatGroup"
              ? " group"
              : type === "chatChannel"
              ? " channel"
              : ""}
          </Typography>
          <Typography variant="p">
            Are you sure you want to leave{" "}
            <b>
              {type === "userChatChannel"
                ? ChannelName
                : type === "user"
                ? userName
                : GroupName}
            </b>
            &nbsp;
            {type === "userChatGroup"
              ? "group"
              : type === "chatChannel"
              ? "channel"
              : ""}
            ?
          </Typography>
        </div>
      </ModalPopup>

      {/* Delete Group Modal box */}
      <ModalPopup
        onClose={handleCloseDeleteGroup}
        isFooter={true}
        isHeader={false}
        modelSize="648"
        isOpen={openDeleteGroupModal}
        buttonText1="Delete"
        buttonText="Cancel"
        btn2variant="danger"
        handleClick2ndButton={handleDeleteGroupFun}
        handleClick={handleCloseDeleteGroup}
        disabled={deleteGroupLoader}
        hasLoading={deleteGroupLoader}
        spinnersType="white"
        disabled1stbtn={deleteGroupLoader}
      >
        <div className="channel-group-wrapper">
          <Typography variant="h3">Delete group</Typography>
          <Typography variant="p">
            Are you sure you want to delete
            <b>{GroupName}</b> ?
          </Typography>
          {/* <div className="delete-group-checkbox-wrapper">
              <Checkbox
                hasLabel={true}
                label="Delete the group for all members"
              />
            </div> */}
        </div>
      </ModalPopup>
    </>
  );
};

export default ChatHeaderContent;
