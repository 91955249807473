import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useRoutes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import "./style.css";
// import newsocket from "./components/SocketConnect.js";
import {
  setsocket,
  addtyper,
  getchatlisting,
  fetchmyprofiledetail,
  deletemessage,
  deleteMediaList,
  offlineUserUpdate,
  onlineUserUpdate,
  deleteFileList,
  deleteUrlList,
  notificationlistAction,
} from "./Action/index.js";

import authService from "./Services/auth.service.js";
import authServiceCollaborator from "./Services/auth.service.collaborator.js";
import useAuth from "./Hooks/useAuth.js";

//routes
import AuthenticatedRoutes from "./routes/AuthenticatedRoutes/index.jsx";
// import AuthenticatedAdminRoutes from "./routes/Admin/community/AuthenticatedAdminRoutes.jsx";
import PublicRoutes from "./routes/PublicRoutes/index.jsx";
import EdgeRoutes from "./routes/EdgeRoutes/index.jsx";
// import PublicAdminRoutes from "./routes/Admin/_PublicAdminRoutes.jsx";
import AuthenticatedGrouposAdminRoutes from "./routes/Admin/groupos/AuthenticatdedGrouposAdminRoutes/index.jsx";
import AuthenticatedAdminRoutes from "./routes/Admin/community/AuthenticatedAdminRoutes/index.jsx";
import { Box } from "@mui/material";
import Loading from "components/Loading.jsx";
import { useAuth0 } from "@auth0/auth0-react";
import CensusDataModal from "pages/PartnerManagement/CensusDataModal.js";
import { onMessage, getToken } from "firebase/messaging";
import { getFirebaseToken, messaging } from "./firebase";
import useSocket from "Hooks/useSocket.js";
import authNotification from "Services/auth.notification.js";

const App = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { auth } = useAuth();
  const { socket } = useSocket();
  const socketRef = useRef();

  const { error, isAuthenticated, isLoading: auth0Loading, user } = useAuth0();
  // console.log(
  //   "--->",
  //   "error",
  //   error,
  //   "isAuthenticated",
  //   isAuthenticated,
  //   "auth0LOading",
  //   auth0Loading,
  //   "user",
  //   user,
  //   "auth",
  //   auth,
  //   "auth.token",
  //   !!auth.token,
  //   "auth.sessionLoaded",
  //   auth.sessionLoaded,
  //   "auth.currentEdge",
  //   auth.currentEdge,
  //   "+++"
  // );

  const authGrouposAdminRoutes = useRoutes(AuthenticatedGrouposAdminRoutes);
  const authAdminRoutes = useRoutes(AuthenticatedAdminRoutes);
  const publicRoutes = useRoutes(PublicRoutes);
  const edgeRoutes = useRoutes(EdgeRoutes);
  const authRoutes = useRoutes(AuthenticatedRoutes);

  const messages = useSelector((state) => {
    return state.ReduChat;
  });
  const unreadMessageIndex = useSelector((state) => {
    return state.ReduUnreadMessageIndex;
  });
  const userIdRedu = useSelector((state) => {
    return state.ReduUserId;
  });
  // const getMessages = (msg) => {
  //   dispatch({ type: "REMOVETYPER", payload: msg.msg });
  //   dispatch(recivemessage(msg.msg));
  // };

  // const useridRef = useRef(localStorage.getItem("userid"));

  const unreadMessageIndexRef = useRef(0);
  const messagesRef = useRef({});
  const [access, setAccess] = useState(false);
  const [access1, setAccess1] = useState(false);
  const [access2, setAccess2] = useState(false);

  /**
   * notification
   *
   */

  useEffect(() => {
    {
      // process.env.REACT_APP_TESTSITE_HOSTNAME !==
      //   "app.milliondollarsellers.com" && getPermissionAndToken();

      getPermissionAndToken();

      onMessage(messaging, (payload) => {
        const {
          notification: { title, body, image },
        } = payload;
        var options = {
          body: body,
          icon: image,
          dir: "ltr",
        };
        if (payload.notification !== undefined && title !== undefined) {
          new Notification(title, options);
          notificationList(1, 10);
        }
      });
    }
    // }
  }, []);

  async function notificationList(page, limit) {
    try {
      console.log("sdsad");
      const notificationList = await authNotification.getNotificationList(
        page,
        limit
      );
      if (notificationList?.status) {
        dispatch(notificationlistAction(notificationList?.data?.data));
      }
    } catch (error) {
      console.log("error:-----------", error);
    }
  }

  const getPermissionAndToken = async () => {
    try {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        const token = await getToken(messaging, {
          vapidKey:
            "BAJnKINmbCDee8cWLVFR-lMg6gF0M4We0gnO7zSjVA0tgy1uR1Kqh6BNqplCE4_CXd3yrAtIZAy1bynvStaHB28",
        });
        // console.log(token, "Token");

        if (token) {
          authServiceCollaborator.getFirebaseDeviceToken({
            deviceToken: token,
          });
        }
      }
    } catch (error) {
      console.error("Error getting permission or token:", error);
    }
  };
  /**
   * Triggers on component mount
   */
  useEffect(() => {
    /**
     * Fetch the access resources if user is collaborator
     */
    if (
      localStorage.getItem("idtoken") !== null &&
      localStorage.getItem("isCollaborator") !== null &&
      JSON.parse(localStorage.getItem("isCollaborator"))
    ) {
      authServiceCollaborator.getCollaboratorResources().then((res) => {
        if (res.data.status) {
          localStorage.setItem(
            "accessResources",
            JSON.stringify(res.data.accessResourceList)
          );
        }
      });
    }

    /**
     *
     */
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    window.addEventListener("resize", appHeight);

    /**
     *
     */
    if (
      localStorage.getItem("username") &&
      localStorage.getItem("idtoken") &&
      localStorage.getItem("role") === "user"
    ) {
      authService.updateRegistrationDetailOnDashboard();
      authService
        .getDaySinceMDSOnly()
        .then((response) => {
          if (
            response.data.status &&
            response.data.data["# of Days Since MDS Only Census"] !== undefined
          ) {
            localStorage.setItem(
              "count",
              response.data.data["# of Days Since MDS Only Census"]
            );
            if (response.data.data["# of Days Since MDS Only Census"] === 364) {
              setAccess1(true);
            } else if (
              response.data.data["# of Days Since MDS Only Census"] === 351
            ) {
              setAccess(true);
            }
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }

    /**
     * Starts a connection with intercom on component mount
     */
    // if(moment().diff(date_time, 'minutes'))
    // intercom for visitors
    if (
      window.location.hostname === "app.milliondollarsellers.com" &&
      !window.location.pathname.startsWith("/chats/")
    ) {
      window.intercomSettings = {
        api_base: "https://api-iam.intercom.io",
        app_id: "yn8h04m3",
      };
    }
    localStorage.getItem("idtoken") !== null &&
      localStorage.getItem("isCollaborator") === null &&
      localStorage.setItem("isCollaborator", false);
  }, []);

  /**
   * Triggers on component mount
   */
  useEffect(() => {
    /**
     * Logout user / admin by clearing local storage if last login time was more than 30 days before current date.
     */
    var endtime = moment();
    var diff_seconds = moment
      .duration(endtime.diff(localStorage.getItem("logintime")))
      .asSeconds();
    // if (diff_seconds > 2592000) {
    //   if (localStorage.getItem("isAdmin") === "true") {
    //     localStorage.clear();
    //     navigate("/adminlogin");
    //   } else {
    //     localStorage.clear();
    //     navigate("/");
    //   }
    // }

    /**
     * Update intercom connection
     */
    if (
      auth.userName &&
      auth.userEmail &&
      // localStorage.getItem("createdAt") &&
      window.location.hostname === "app.milliondollarsellers.com" &&
      !window.location.pathname.startsWith("/chats")
    ) {
      window.Intercom("update", {
        api_base: "https://api-iam.intercom.io",
        app_id: "yn8h04m3",
        name: auth.userName ?? "",
        email: auth.userEmail ?? "", // Email address
        // created_at: localStorage.getItem("createdAt") ?? "", // Signup date as a Unix timestamp
      });
    }
  }, [navigate]);

  useEffect(() => {
    unreadMessageIndexRef.current = unreadMessageIndex;
  }, [unreadMessageIndex]);

  useEffect(() => {
    messagesRef.current = messages;
  }, [messages]);

  useEffect(() => {
    dispatch({ type: "ASSIGNCONNECTINGMESSAGE", payload: "Connecting..." });
    if (
      socket !== null &&
      socket !== undefined &&
      auth.userId !== undefined &&
      auth.userId !== null
    ) {
      socket.on("connect", () => {
        // dispatch(setsocket(socket));
        socket.emit("new-user-joined", auth.userName);
        dispatch(fetchmyprofiledetail());
        socket.emit("newChatList", { id: auth.userId });
        dispatch({ type: "ASSIGNCONNECTINGMESSAGE", payload: "" });
      });
      socket.on("newChatListReceive", (res) => {
        dispatch(getchatlisting(res.message)); // Dispatch the action to update chat listing
        if (res?.message) {
          const allRoomId = res.message.map((data) => {
            if (
              data?.receiverId?._id &&
              (data?.type === "user" || data?.type === "airtable-syncs")
            ) {
              return auth.userId + "-" + data?.receiverId?._id;
            } else if (data?.receiverId?._id) {
              return data?.receiverId?._id;
            }
          });
          socket.emit("joinRoom", { roomId: allRoomId });

          //remove chat loading  data is received properly
          dispatch({
            type: "REMOVECHATLOADING",
          });
        }
      });

      socket.on("create-receive", (msg) => {
        socket.emit("newChatList", { id: auth.userId });
      });
      socket.on("typingoff", (data) => {
        dispatch({ type: "REMOVETYPER", payload: data });
      });
      socket.on("typing", (data) => {
        dispatch(addtyper(data, auth.userId));
      });
      socket.on("delete-receive", (res) => {
        if (res.message.status) {
          if (res.message.messageId === unreadMessageIndexRef.current) {
            let beforeIdOfUnReadMessageId = 0;
            for (const chatid in messagesRef.current) {
              for (
                let index = 0;
                index < messagesRef.current[chatid].length;
                index++
              ) {
                if (
                  index > 0 &&
                  messagesRef.current[chatid][index]._id ===
                    unreadMessageIndexRef.current
                ) {
                  beforeIdOfUnReadMessageId =
                    messagesRef.current[chatid][index - 1]._id;
                }
              }
            }
            dispatch({
              type: "ASSIGNUNREADMESSAGE",
              payload: beforeIdOfUnReadMessageId,
            });
          }
          dispatch({
            type: "DELETEMEDIARECORD",
            payload: res.message.messageId,
          });
          dispatch({ type: "DELETEURLRECORD", payload: res.message.messageId });
          dispatch({
            type: "DELETEFILEFROMLIST",
            payload: res.message.messageId,
          });
          dispatch(deletemessage(res.message.messageId));
          socket.emit("getLastMessage", {
            roomId:
              res.message.type === "user" && res.message.chatId === auth.userId
                ? res.name.name
                : res.message.chatId,
            userId: auth.userId,
          });
          if (res.message.messageType === "media") {
            dispatch(
              deleteMediaList({
                _id: res.message.messageId,
                chatid: res.message.chatId,
              })
            );
          } else if (res.message.messageType === "file") {
            dispatch(
              deleteFileList({
                _id: res.message.messageId,
                chatid: res.message.chatId,
              })
            );
          } else if (res.message.messageType === "url") {
            dispatch(
              deleteUrlList({
                _id: res.message.messageId,
                chatid: res.message.chatId,
              })
            );
          }
        }
      });
      socket.on("userOffline", (response) => {
        dispatch({ type: "SETOFFLINE", payload: response });
        dispatch(offlineUserUpdate(response));
      });
      socket.on("userOnline", (response) => {
        dispatch({ type: "SETONLINE", payload: response });
        dispatch(onlineUserUpdate(response));
      });
      socket.on("block-receive", () => {
        dispatch(fetchmyprofiledetail());
      });
      socket.on("connect_error", (err) => {
        setTimeout(() => {
          socket.connect();
        }, 1000);
      });
      socket.on("authFailed", () => {
        if (auth.userId) {
          socket.io.opts.query = {
            userid: auth.userId,
          };
          socket.disconnect().connect();
        }
      });
    }
  }, [socket, userIdRedu, auth?.userId]);

  const appHeight = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  const showCensusModal = () => {
    return (localStorage.getItem("remindmetomorrowcensus") &&
      moment(localStorage.getItem("remindmetomorrowcensus"), "YYYY-MM-DD").diff(
        moment().format("YYYY-MM-DD"),
        "days"
      ) === 0 &&
      localStorage.getItem("dontshowcensuspopup") === "false" &&
      (access || access1)) ||
      ((localStorage.getItem("remindmetomorrowcensus") === null ||
        localStorage.getItem("remindmetomorrowcensus") === undefined) &&
        (localStorage.getItem("dontshowcensuspopup") === "false" ||
          localStorage.getItem("dontshowcensuspopup") === null ||
          localStorage.getItem("dontshowcensuspopup") === undefined) &&
        (access || access1)) ? (
      <CensusDataModal
        access={access}
        setAccess={setAccess}
        access1={access1}
        setAccess1={setAccess1}
        access2={access2}
        setAccess2={setAccess2}
      />
    ) : null;
  };

  const RenderRoutes = () => {
    // console.log("RenderRoutes");
    switch (auth.currentEdge.type) {
      case "PO":
        return authGrouposAdminRoutes;

      case "CO":
        return authAdminRoutes;

      case "M":
      case "V":
        return authRoutes;

      default:
        return edgeRoutes;
    }
  };

  const selectRoutes = () => {
    if (!auth0Loading && isAuthenticated && auth.token) {
      if (auth.token && auth.currentEdge) {
        // console.log("1");
        return RenderRoutes(); // SSO + token + currentEdge
      } else {
        // console.log("2");
        return edgeRoutes; // SSO + token + No currentEdge
      }
    } else {
      if (!auth0Loading && !auth.token && auth.sessionLoaded) {
        if (
          isAuthenticated &&
          !auth.token &&
          auth.sessionLoaded &&
          auth.currentEdge
        ) {
          // console.log("3");
          return RenderRoutes(); // SSO + NO token + dummy currentEdge
        } else {
          // console.log("4");
          return publicRoutes; // NO SSO + NO token + No currentEdge
        }
      } else {
        if (!auth0Loading && !isAuthenticated && auth.token) {
          // console.log("5");
          return publicRoutes; // NO SSO + token + currentEdge
        } else {
          // console.log("6");
          return (
            <Box
              width="100%"
              height="100vh"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Loading />
            </Box>
          );
        }
      }
    }
  };

  return (
    <div className="cstm-admin-area">
      {showCensusModal()}
      {selectRoutes()}
    </div>
  );
};

export default App;
