import { ApiRoutes } from "./apiRoutes";
import createApiInstance from "./createApiInstance";

export const extendedApi = createApiInstance.injectEndpoints({
  endpoints: (builder) => ({
    configStripe: builder.query({
      query: (params) => {
        return {
          url: ApiRoutes.stripe.config.url,
          method: ApiRoutes.stripe.config.method,
          params,
        };
      },
      transformResponse: (response) => response,
    }),
    stripeWebhook: builder.mutation({
      query(body) {
        return {
          url: ApiRoutes.stripe.webhook.url,
          method: ApiRoutes.stripe.webhook.method,
          body,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
      transformResponse: (response) => response,
    }),
    stripeCheckoutSession: builder.mutation({
      query(body) {
        return {
          url: ApiRoutes.stripe.createCheckoutSession.url,
          method: ApiRoutes.stripe.createCheckoutSession.method,
          body,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
      transformResponse: (response) => response,
    }),
    createAccountLink: builder.mutation({
      query(body) {
        return {
          url: ApiRoutes.stripe.createAccountLink.url,
          method: ApiRoutes.stripe.createAccountLink.method,
          body,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
      transformResponse: (response) => response,
    }),
    fetchOnboardingStatus: builder.query({
      query(params) {
        return {
          url: ApiRoutes.stripe.fetchOnboardingStatus.url,
          method: ApiRoutes.stripe.fetchOnboardingStatus.method,
          params,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
      transformResponse: (response) => response,
    }),
    getSessionStatus: builder.query({
      query(params) {
        return {
          url: ApiRoutes.stripe.getSessionStatus.url,
          method: ApiRoutes.stripe.getSessionStatus.method,
          params,
        };
      },
      transformResponse: (response) => response,
    }),
    getSubscriptionChangeConfirmation: builder.query({
      query(params) {
        return {
          url: ApiRoutes.stripe.getSubscriptionChangeConfirmation.url,
          method: ApiRoutes.stripe.getSubscriptionChangeConfirmation.method,
          params,
        };
      },
      transformResponse: (response) => response,
    }),
    subscriptionChange: builder.mutation({
      query(body) {
        return {
          url: ApiRoutes.stripe.subscriptionChange.url,
          method: ApiRoutes.stripe.subscriptionChange.method,
          body,
        };
      },
      transformResponse: (response) => response,
    }),
    subscriptionCancel: builder.mutation({
      query(body) {
        return {
          url: ApiRoutes.stripe.subscriptionCancel.url,
          method: ApiRoutes.stripe.subscriptionCancel.method,
          body,
        };
      },
      transformResponse: (response) => response,
    }),
    subscriptionRenew: builder.mutation({
      query(body) {
        return {
          url: ApiRoutes.stripe.subscriptionRenew.url,
          method: ApiRoutes.stripe.subscriptionRenew.method,
          body,
        };
      },
      transformResponse: (response) => response,
    }),
    fetchStripeAccount: builder.query({
      query(params) {
        return {
          url: ApiRoutes.stripe.fetchStripeAccount.url,
          method: ApiRoutes.stripe.fetchStripeAccount.method,
          params,
        }
      },
      transformResponse: (response) => response,
    }),
    getSubscriptionCancelConfirmation: builder.query({
      query(params) {
        return {
          url: ApiRoutes.stripe.getSubscriptionCancelConfirmation.url,
          method: ApiRoutes.stripe.getSubscriptionCancelConfirmation.method,
          params
        }
      },
      providesTags: ["BALANCE"],
      transformResponse: (response) => response
    }),
    getBalance: builder.query({
      query(params) {
        return {
          url: ApiRoutes.stripe.getBalance.url,
          method: ApiRoutes.stripe.getBalance.method,
          params
        }
      },
      providesTags: ["BALANCE"],
      transformResponse: (response) => response
    }),
    getMemberBalance: builder.query({
      query(params) {
        return {
          url: ApiRoutes.stripe.getMemberBalance.url,
          method: ApiRoutes.stripe.getMemberBalance.method,
          params
        }
      },
      providesTags: ["BALANCE"],
      transformResponse: (response) => response
    }),
    subscriptionCancelByAdmin: builder.mutation({
      query(body) {
        return {
          url: ApiRoutes.stripe.subscriptionCancelByAdmin.url,
          method: ApiRoutes.stripe.subscriptionCancelByAdmin.method,
          body,
        };
      },
      invalidatesTags: ["SUBSCRIPTIONS"],
      transformResponse: (response) => response,
    }),
    getRevenuePayments: builder.query({
      query(params) {
        return {
          url: ApiRoutes.stripe.getRevenuePayments.url,
          method: ApiRoutes.stripe.getRevenuePayments.method,
          params
        }
      }
    }),
    getGrossVolumePayments: builder.query({
      query(params) {
        return {
          url: ApiRoutes.stripe.getGrossVolumePayments.url,
          method: ApiRoutes.stripe.getGrossVolumePayments.method,
          params
        }
      }
    }),
    getNetVolumePayments: builder.query({
      query(params) {
        return {
          url: ApiRoutes.stripe.getNetVolumePayments.url,
          method: ApiRoutes.stripe.getNetVolumePayments.method,
          params
        }
      }
    }),
    getSalesPayments: builder.query({
      query(params) {
        return {
          url: ApiRoutes.stripe.getSalesPayments.url,
          method: ApiRoutes.stripe.getSalesPayments.method,
          params
        }
      }
    }),
    createPayout: builder.mutation({
      query(body) {
        return {
          url: ApiRoutes.stripe.createPayout.url,
          method: ApiRoutes.stripe.createPayout.method,
          body,
        };
      },
      invalidatesTags: ["PAYOUTS", "BALANCE"],
      transformResponse: (response) => response,
    }),
    getPayouts: builder.query({
      query(params) {
        return {
          url: ApiRoutes.stripe.getPayouts.url,
          method: ApiRoutes.stripe.getPayouts.method,
          params
        }
      },
      providesTags: ["PAYOUTS"]
    }),
  }),
  overrideExisting: false,
});
export const {
  useConfigStripeQuery,
  useStripeWebhookMutation,
  useStripeCheckoutSessionMutation,
  useCreateAccountLinkMutation,
  useFetchOnboardingStatusQuery,
  useLazyFetchOnboardingStatusQuery,
  useGetSessionStatusQuery,
  useGetSubscriptionChangeConfirmationQuery,
  useLazyGetSubscriptionChangeConfirmationQuery,
  useSubscriptionChangeMutation,
  useSubscriptionCancelMutation,
  useSubscriptionRenewMutation,
  useFetchStripeAccountQuery,
  useLazyFetchStripeAccountQuery,
  useGetSubscriptionCancelConfirmationQuery,
  useLazyGetSubscriptionCancelConfirmationQuery,
  useGetBalanceQuery,
  useLazyGetBalanceQuery,
  useLazyGetMemberBalanceQuery, useGetMemberBalanceQuery,
  useSubscriptionCancelByAdminMutation,
  useGetRevenuePaymentsQuery,
  useLazyGetRevenuePaymentsQuery,
  useGetGrossVolumePaymentsQuery,
  useLazyGetGrossVolumePaymentsQuery,
  useGetNetVolumePaymentsQuery,
  useLazyGetNetVolumePaymentsQuery,
  useGetSalesPaymentsQuery,
  useLazyGetSalesPaymentsQuery,
  useCreatePayoutMutation,
  useGetPayoutsQuery
} = extendedApi;
