import React from 'react'
import PropTypes from "prop-types"
const IconScamera = ({ width = 16, height = 16, isFilled = true, iconColor = "#394046" }) => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={width}
                height={height}
                fill="none"
                viewBox="0 0 16 16"
            >
                <path
                    fill={iconColor}
                    fillRule="evenodd"
                    d="M1.333 8c0-3.674 2.993-6.667 6.667-6.667S14.667 4.326 14.667 8v2.345a2.322 2.322 0 0 1-2.312 2.311c-1.19 0-2.142-.928-2.289-2.083A3.352 3.352 0 0 1 8 11.316 3.326 3.326 0 0 1 4.684 8 3.326 3.326 0 0 1 8 4.683 3.326 3.326 0 0 1 11.317 8v2.345c0 .581.457 1.039 1.038 1.039.582 0 1.039-.458 1.039-1.039V8A5.384 5.384 0 0 0 8 2.606 5.384 5.384 0 0 0 2.606 8 5.384 5.384 0 0 0 8 13.394a.637.637 0 0 1 0 1.273c-3.674 0-6.667-2.993-6.667-6.667ZM8 5.956A2.033 2.033 0 0 0 5.957 8c0 1.137.906 2.043 2.043 2.043A2.033 2.033 0 0 0 10.044 8 2.033 2.033 0 0 0 8 5.956Z"
                    clipRule="evenodd"
                />
            </svg>
        </>
    )
}
IconScamera.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    isFilled: PropTypes.bool,
    iconColor: PropTypes.string,
}
export default IconScamera