import { createContext, useEffect, useState } from "react";
import { CONFIGS } from "config";
import Cookies from "js-cookie";
import { subdomainConfig } from "utils";
import { APP_TEST_CONFIG } from "./dummy-data";

const AuthContext = createContext({});

const testAs = "REAL"; // REAL, PO, CO, M, V, DEFAULT

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(APP_TEST_CONFIG[testAs]);
  // console.log(auth, "<");

  useEffect(() => {
    // fetch session data from server if jwt present in the local storage
    if (Cookies.get("x-app-token") && !auth.token) {
      const getSessionData = async () => {
        let url = CONFIGS.GATEWAY_DOMAIN;
        const host = window.location.host.split(".")[0];
        let BASE_URL =
          url.split("//")[0] + "//" + host + "." + url.split("//")[1];
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("authorization", Cookies.get("x-app-token"));
        if (BASE_URL.includes("free.app")) {
          headers.append("ngrok-skip-browser-warning", "69420");
        }
        const sessionData = await fetch(
          `${BASE_URL}/api/v1/users/fetch-session-info`,
          {
            method: "GET",
            headers: headers,
          }
        );

        return sessionData;
      };
      getSessionData()
        .then(async (response) => {
          if (response.ok) {
            // console.log("RESPONSE FROM API - SUCCESS");
            const sessionData = await response.json();
            if (sessionData.token) {
              Cookies.set("x-app-token", sessionData.token, {
                domain: CONFIGS.COOKIE_DOMAIN,
                path: "/",
              });
              setAuth((prev) => {
                return {
                  ...prev,
                  sessionLoaded: true,
                  token: sessionData.token,
                  ...sessionData,
                };
              });
              subdomainConfig(
                sessionData?.currentEdge?.type,
                sessionData?.currentEdge?.relation_id?.nickname
              );
            } else {
              setAuth((prev) => {
                return {
                  ...prev,
                  sessionLoaded: true,
                  token: Cookies.get("x-app-token"),
                  ...sessionData,
                };
              });
            }
          } else {
            // console.log("RESPONSE FROM API - INVALID TOKEN");
            // when invalid jwt detected remove the existing jwt from the local storage and fresh start
            setAuth((prev) => {
              return {
                ...prev,
                sessionLoaded: true,
              };
            });
          }
        })
        .catch((error) => {
          // console.log("RESPONSE FROM API - SERVER ERROR");
          // when request to backend fails because of some error remove the existing jwt from the local storage and fresh start
          setAuth((prev) => {
            return {
              ...prev,
              sessionLoaded: true,
            };
          });
          Cookies.remove("x-app-token", {
            domain: CONFIGS.COOKIE_DOMAIN,
            path: "/",
          });
        });
    }
    if (!Cookies.get("x-app-token") && !auth.token) {
      // console.log("SESSION LOADED");
      // when there is no jwt in local storage
      setAuth((prev) => {
        return {
          ...prev,
          sessionLoaded: true,
        };
      });
      Cookies.remove("x-app-token", {
        domain: CONFIGS.COOKIE_DOMAIN,
        path: "/",
      });
      // If we get more than 3 parts, then we have a subdomain
      // INFO: This could be 4, if you have a co.uk TLD or something like that.
      subdomainConfig("DEFAULT", "app");
    }
  }, [auth.token, Cookies.get("x-app-token")]);

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
