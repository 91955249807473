// Icons
import LoginIcon from "@mui/icons-material/Login";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import ArticleIcon from "@mui/icons-material/Article";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";

// routes
export const ADMIN_SCOPE_ROUTES = {
  authentication: {
    basePath: "",
    actionPath: {
      login: "login",
      signUp: "signup",
      activateAccount: "activate",
    },
  },
  edgeLogin: {
    basePath: "edge-login",
  },
  discover: {
    basePath: "discover",
    actionPath: ":community_name",
  },
  profile: {
    basePath: "settings",
    actionPath: {
      edit: "edit",
      setting: "profile",
    },
  },
  pricing: {
    basePath: "pricing",
  },
  contactUs: {
    basePath: "contact-us",
  },
};

export const SCOPES_ROUTES = {
  events: {
    basePath: "events",
    actionPath: {
      list: "list",
      create: "create",
      edit: "edit/:event_id",
    },
  },
  channels: {
    basePath: "channel",
    actionPath: {
      list: "list",
      create: "create",
      edit: "edit/:channel_id",
    },
  },
  news: {
    basePath: "news",
    actionPath: {
      list: "list",
      create: "create",
      edit: "edit/:news_id",
    },
  },
  partners: {
    basePath: "partners",
    actionPath: {
      list: "list",
      create: "create",
      edit: "edit/:partner_id",
    },
  },
  medias: {
    basePath: "partners",
    actionPath: {
      list: "list",
      create: "create",
      edit: "edit/:partner_id",
    },
  },
  groups: {
    basePath: "groups",
    actionPath: {
      list: "list",
      create: "create",
      edit: "edit/:partner_id",
    },
  },
  videos: {
    basePath: "videos",
    actionPath: {
      list: "list",
      create: "create",
      edit: "edit/:video_id",
    },
  },
  documents: {
    basePath: "documents",
    actionPath: {
      list: "list",
      create: "create",
      edit: "edit/:document_id",
    },
  },
  statistics: {
    basePath: "documents",
    actionPath: {
      list: "list",
      create: "create",
      edit: "edit/:document_id",
    },
  },
  reports: {
    basePath: "reports",
    actionPath: {
      list: "list",
      create: "create",
      edit: "edit/:report_id",
    },
  },
  tags: {
    basePath: "tags",
    actionPath: {
      list: "tags"
    }
  },
  notifications: {
    basePath: "notifications",
    actionPath: {
      list: "list"
    }
  }
};

// list of scopes and their actions
export const scopesList = [
  {
    id: "events",
    name: "Events",
    path: SCOPES_ROUTES.events.basePath,
    icon: <OndemandVideoIcon size="24px" className="admin-left-icons" />,
    actions: [
      {
        name: "Events List",
        path: SCOPES_ROUTES.events.actionPath.list,
      },
      {
        name: "Create Event",
        path: SCOPES_ROUTES.events.actionPath.create,
      },
    ],
  },
  {
    id: "channels",
    name: "Channels",
    path: SCOPES_ROUTES.channels.basePath,
    icon: <OndemandVideoIcon size="24px" className="admin-left-icons" />,
    actions: [
      {
        name: "Channels List",
        path: SCOPES_ROUTES.channels.actionPath.list,
      },
      {
        name: "Create Channel",
        path: SCOPES_ROUTES.channels.actionPath.create,
      },
    ],
  },
  {
    id: "news",
    name: "News",
    path: SCOPES_ROUTES.news.basePath,
    icon: <OndemandVideoIcon size="24px" className="admin-left-icons" />,
    actions: [
      {
        name: "News List",
        path: SCOPES_ROUTES.news.actionPath.list,
      },
      {
        name: "Create News",
        path: SCOPES_ROUTES.news.actionPath.create,
      },
    ],
  },
  {
    id: "partners",
    name: "Partners",
    path: SCOPES_ROUTES.partners.basePath,
    icon: <OndemandVideoIcon size="24px" className="admin-left-icons" />,
    actions: [
      {
        name: "Partners List",
        path: SCOPES_ROUTES.partners.actionPath.list,
      },
      {
        name: "Create Partner",
        path: SCOPES_ROUTES.partners.actionPath.create,
      },
    ],
  },
  {
    id: "medias",
    name: "Medias",
    path: SCOPES_ROUTES.medias.basePath,
    icon: <OndemandVideoIcon size="24px" className="admin-left-icons" />,
    actions: [
      {
        name: "Medias List",
        path: SCOPES_ROUTES.medias.actionPath.list,
      },
      {
        name: "Create Media",
        path: SCOPES_ROUTES.medias.actionPath.create,
      },
    ],
  },
  {
    id: "groups",
    name: "Groups",
    path: SCOPES_ROUTES.groups.basePath,
    icon: <OndemandVideoIcon size="24px" className="admin-left-icons" />,
    actions: [
      {
        name: "Groups List",
        path: SCOPES_ROUTES.groups.actionPath.list,
      },
      {
        name: "Create Group",
        path: SCOPES_ROUTES.groups.actionPath.create,
      },
    ],
  },
  {
    id: "videos",
    name: "Videos",
    path: SCOPES_ROUTES.videos.basePath,
    icon: <OndemandVideoIcon size="24px" className="admin-left-icons" />,
    actions: [
      {
        name: "Videos List",
        path: SCOPES_ROUTES.videos.actionPath.list,
      },
      {
        name: "Create Video",
        path: SCOPES_ROUTES.videos.actionPath.create,
      },
    ],
  },
  {
    id: "documents",
    name: "Documents",
    path: SCOPES_ROUTES.documents.basePath,
    icon: <ArticleIcon size="24px" className="admin-left-icons" />,
    actions: [
      {
        name: "Documents List",
        path: SCOPES_ROUTES.documents.actionPath.list,
      },
      {
        name: "Create Document",
        path: SCOPES_ROUTES.documents.actionPath.create,
      },
    ],
  },
  {
    id: "statistics",
    name: "Statistics",
    path: SCOPES_ROUTES.statistics.basePath,
    icon: <ArticleIcon size="24px" className="admin-left-icons" />,
    actions: [
      {
        name: "Statistics List",
        path: SCOPES_ROUTES.statistics.actionPath.list,
      },
      {
        name: "Create Statistics",
        path: SCOPES_ROUTES.statistics.actionPath.create,
      },
    ],
  },
  {
    id: "reports",
    name: "Reports",
    path: SCOPES_ROUTES.reports.basePath,
    icon: <ArticleIcon size="24px" className="admin-left-icons" />,
    actions: [
      {
        name: "Reports List",
        path: SCOPES_ROUTES.reports.actionPath.list,
      },
      {
        name: "Create Report",
        path: SCOPES_ROUTES.reports.actionPath.create,
      },
    ],
  },
  {
    id: "tags",
    name: "Tags",
    path: SCOPES_ROUTES.tags.basePath,
    icon: <ArticleIcon size="24px" className="admin-left-icons" />,
    actions: [
      {
        name: "Tags",
        path: SCOPES_ROUTES.tags.actionPath.list,
      },
    ],
  },
  {
    id: "notifications",
    name: "Notifications",
    path: SCOPES_ROUTES.notifications.basePath,
    icon: <ArticleIcon size="24px" className="admin-left-icons" />,
    actions: [
      {
        name: "Notifications",
        path: SCOPES_ROUTES.notifications.actionPath.list,
      },
    ],
  },
];

export const edgeScopesList = [
  {
    id: "EDGE_LOGIN",
    name: "Edge Login",
    path: ADMIN_SCOPE_ROUTES.edgeLogin.basePath,
    icon: <LoginIcon size="24px" className="admin-left-icons" />,
    actions: [],
  },
  {
    id: "COMMUNITY_DISCOVERY",
    name: "Discover Communities",
    path: ADMIN_SCOPE_ROUTES.discover.basePath,
    icon: <ViewCarouselIcon size="24px" className="admin-left-icons" />,
    actions: [],
  },
];
