import React, { useRef } from "react";
import PropTypes from "prop-types";
import OutgoingMessageTime from "./HelperComponets/OutgoingMessageTime";
import MessageTail from "./HelperComponets/MessageTail";
import "../../../Styles/Theme/Theme.css";
import "./Styles/ChatMessagesBubble.css";
import Spinners from "../../../components/Spinners/Spinners";

// Styles
import "./Styles/CompressionProgress.css";

const CompressionProgress = ({
  isStacked = false,
  time = "09:12 AM",
  Text = "Compressing...",
}) => {
  const dropdownRef = useRef(null);

  return (
    <div className="chat-message-bubble-sec" ref={dropdownRef}>
      <div
        className={`chat-message-bubble-inner flex outgoing ${
          isStacked ? "stacked-item" : ""
        }`}
      >
        <div className={`chat-message-container`}>
          <div className="chat-avtar-message flex items-end">
            <div className="chat-avtar flex"></div>
            <div className="chat-body flex items-end relative">
              <div className="chat-tail">
                <MessageTail isOutgoing={true} />
              </div>
              <div className={`chat-message ${isStacked ? "stacked" : ""}`}>
                <div className="chat-message-text-sec flex flex-col">
                  <div className="chat-compressionprogress-loading flex items-center justify-center flex-col">
                    <Spinners size="32" type="white" />
                    <span className="comnpressio-text">{Text}</span>
                  </div>
                </div>
                <div className="chat-message-time">
                  <OutgoingMessageTime
                    isRead={false}
                    hasBackdrop={false}
                    isEdited={false}
                    time={time}
                    isInProgress={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CompressionProgress.propTypes = {
  isStacked: PropTypes.bool,
  time: PropTypes.string,
  Text: PropTypes.string,
};

export default CompressionProgress;
