/**
 * @description removes the base path from the current path and returns the current path
 * @param {string} basePath
 * @param {string} path
 * @returns {string}
 */
export const getModuleName = (basePath, path) => {
  return path.replace(basePath, "").split("/")[0];
};

export const isObj = (value) => {
  if (typeof value === "object" && !Array.isArray(value) && value !== null) {
    return true;
  }
  return false;
};

export const isDecimal = (number) => {
  return !Number.isInteger(number);
};

/**
 * @description For converting string boolean value to boolean
 * @param {string} value
 * @returns {Boolean}
 */
export const stringToBoolean = (value) => {
  const isTrueSet = /^true$/i.test(value);
  return isTrueSet;
};

const generateURLAndRedirect = (protocol, subdomain, parts) => {
  window.location = protocol + "//" + subdomain + "." + parts.join(".");
};

const replaceSubdomain = (parts, subdomain) => {
  if (parts.length >= 3 && parts[0] !== subdomain) {
    return true;
  }
  return false;
};

export const subdomainConfig = (type, name) => {
  let host = window.location.host;
  let protocol = window.location.protocol;
  let parts = host.split(".");
  let subdomain = "";
  switch (type) {
    case "DEFAULT": //This case use for when user is logged in and user on edge login and current edge type is default and user is not loggedin
      const DEFAULT_SUBDOMAIN = "app";
      subdomain = DEFAULT_SUBDOMAIN;
      if (parts.length < 3) {
        // Set the location to the new url
        if (parts.includes("localhost:3000")) {
          window.location =
            protocol + `//${DEFAULT_SUBDOMAIN}.groupos.` + parts.join(".");
        } else {
          generateURLAndRedirect(protocol, subdomain, parts);
        }
      }
      if (replaceSubdomain(parts, DEFAULT_SUBDOMAIN)) {
        // Remove the subdomain from the parts list
        parts.splice(0, 1);
        // Set the location to the new url
        generateURLAndRedirect(protocol, subdomain, parts);
      }
      break;
    case "PO":
      const GROUPOS_ADMIN_SUBDOMAIN = "admin";
      subdomain = GROUPOS_ADMIN_SUBDOMAIN;
      if (parts.length < 3) {
        // Set the location to the new url
        generateURLAndRedirect(protocol, subdomain, parts);
      }
      if (replaceSubdomain(parts, GROUPOS_ADMIN_SUBDOMAIN)) {
        // Remove the subdomain from the parts list
        parts.splice(0, 1);
        // Set the location to the new url
        generateURLAndRedirect(protocol, subdomain, parts);
      }
      break;
    case "CO":
    case "M":
    case "V":
      subdomain = name;
      if (parts.length < 3) {
        // Set the location to the new url
        generateURLAndRedirect(protocol, subdomain, parts);
      }
      if (replaceSubdomain(parts, name)) {
        // Remove the subdomain from the parts list
        parts.splice(0, 1);
        // Set the location to the new url
        generateURLAndRedirect(protocol, subdomain, parts);
      }
      break;
    default:
      break;
  }
};
