import React from 'react'
import PropTypes from "prop-types"
const CameraIcon = ({ width = 24, height = 24, isFilled, iconColor = "#000" }) => {
    return (
        <>
            {isFilled ? <svg
                xmlns="http://www.w3.org/2000/svg"
                width={width}
                height={height}
                fill="none"
                viewBox="0 0 24 24"
            >
                <path
                    fill={iconColor}
                    d="M12 10.778c1.342 0 2.429 1.098 2.429 2.453 0 1.356-1.087 2.454-2.429 2.454-1.342 0-2.429-1.098-2.429-2.454 0-1.355 1.087-2.453 2.429-2.453Z"
                />
                <path
                    fill={iconColor}
                    fillRule="evenodd"
                    d="M15.317 3.358c-1.188-.478-5.446-.478-6.635 0-.562.225-.935.849-1.296 1.454-.42.7-.824 1.376-1.489 1.376C3.745 6.188 2 7.951 2 10.124v5.855c0 2.772 2.225 5.02 4.97 5.02h10.06c2.744 0 4.97-2.248 4.97-5.02v-5.855c0-2.173-1.745-3.936-3.897-3.936-.665 0-1.07-.675-1.49-1.377-.361-.604-.735-1.228-1.296-1.453ZM17.5 8.556c.552 0 1.004.452 1.004 1.01 0 .518-.386.945-.883 1.004-.591.034-1.126-.381-1.126-1.004 0-.573.458-.98 1.005-1.01Zm-5.5.706c-2.17 0-3.93 1.777-3.93 3.97 0 2.192 1.76 3.969 3.93 3.969s3.929-1.777 3.929-3.97c0-2.192-1.759-3.969-3.93-3.969Z"
                    clipRule="evenodd"
                />
            </svg> : <svg
                xmlns="http://www.w3.org/2000/svg"
                width={width}
                height={height}
                fill="none"
                viewBox="0 0 24 24"
            >
                <path
                    fill={iconColor}
                    d="M17.5 8.556c.552 0 1.004.452 1.004 1.01 0 .518-.386.945-.883 1.004-.591.034-1.126-.381-1.126-1.004 0-.573.458-.98 1.005-1.01Z"
                />
                <path
                    fill={iconColor}
                    fillRule="evenodd"
                    d="M12 9.262c-2.17 0-3.93 1.777-3.93 3.97 0 2.192 1.76 3.969 3.93 3.969s3.929-1.777 3.929-3.97c0-2.192-1.759-3.969-3.93-3.969Zm0 1.516c1.342 0 2.429 1.098 2.429 2.453 0 1.356-1.087 2.454-2.429 2.454-1.342 0-2.429-1.098-2.429-2.454 0-1.355 1.087-2.453 2.429-2.453Z"
                    clipRule="evenodd"
                />
                <path
                    fill={iconColor}
                    fillRule="evenodd"
                    d="M8.682 3.358c1.189-.478 5.447-.478 6.635 0 1.212.487 1.549 2.83 2.786 2.83 2.152 0 3.897 1.763 3.897 3.936v5.855c0 2.772-2.226 5.02-4.97 5.02H6.97C4.225 21 2 18.752 2 15.98v-5.855c0-2.173 1.745-3.936 3.897-3.936 1.239 0 1.572-2.343 2.785-2.83Zm6.08 1.408c-1.438-.483-4.106-.57-5.526 0-.792.318-.925 1.5-1.456 2.084-.506.556-1.256.854-1.883.854C4.585 7.748 3.5 8.78 3.5 10.124v5.855c0 1.935 1.554 3.505 3.47 3.505h10.06c1.916 0 3.47-1.57 3.47-3.505v-5.855c0-1.336-1.073-2.42-2.397-2.42-.712-.03-1.399-.321-1.884-.855-.53-.585-.664-1.765-1.456-2.083Z"
                    clipRule="evenodd"
                />
            </svg>}
        </>
    )
}
CameraIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    isFilled: PropTypes.bool,
    iconColor: PropTypes.string,
}
export default CameraIcon