const alltypers = {};

const ReduTypers = (state = alltypers, action) => {
  switch (action.type) {
    case "ADDTYPER":
      if (action.payload.data.sender !== action.payload.userId) {
        if (action.payload.data.type === "user") {
          return {
            ...state,
            [action.payload.data.sender]: {
              type: "user",
              username: {
                [action.payload.data.sender]: action.payload.data.sendername,
              },
            },
          };
        } else {
          return {
            ...state,
            [action.payload.data.recipient]: {
              type: "group",
              username:
                state[action.payload.data.recipient] !== undefined &&
                state[action.payload.data.recipient].username !== undefined
                  ? {
                      ...state[action.payload.data.recipient].username,
                      [action.payload.data.sender]:
                        action.payload.data.sendername,
                    }
                  : {
                      [action.payload.data.sender]:
                        action.payload.data.sendername,
                    },
            },
          };
        }
      } else {
        return state;
      }

    case "REMOVETYPER":
      if (action.payload.type === "user") {
        var obj = {};
        for (var key in state) {
          if (key !== action.payload.sender)
            obj = { ...obj, [key]: state[key] };
        }
        return { ...obj };
      } else {
        var obj = {};
        for (var key in state) {
          if (key === action.payload.recipient) {
            var arr = {};
            for (var i in state[key].username) {
              if (i !== action.payload.sender) {
                arr = { ...arr, [i]: state[key].username[i] };
              }
            }
            obj = { ...obj, [key]: { ...state[key], username: arr } };
          } else obj = { ...obj, [key]: state[key] };
        }
        return { ...obj };
      }
    default:
      return state;
  }
};

export default ReduTypers;
