const loader = false;

const ReduLoader = (state = loader, action) => {
  switch (action.type) {
    case "SETLOADER":
      return true;
    case "REMOVELOADER":
      return false;
    default:
      return state;
  }
};

export default ReduLoader;
