import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import GlobalMenuPopup from "components/UserComponents/GlobalMenuPopup";
import SideBar from "components/SideBar";
import useAuth from "Hooks/useAuth";

import { scopesList as edgeScopesList } from "routes/EdgeRoutes/menuList";
import useRefreshNavigation from "Hooks/useRefreshNavigation";
import { getModuleName } from "utils";
import Header from "components/Header";

const EdgeLayout = () => {
  const { auth } = useAuth();
  const [globalmenu, setglobalmenu] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { refreshScopeActions } = useRefreshNavigation();

  const availableScopes = edgeScopesList?.filter((scope) =>
    auth.currentEdge?.AdminScopes.includes(scope.id)
  );

  const [currentScope, setCurrentScope] = useState(
    availableScopes ? availableScopes[0] : []
  );

  useEffect(() => {
    const module = getModuleName("/", location.pathname);
    if (location.pathname === "/") {
      navigate("/edge-login");
    }
    if (currentScope?.path !== module) {
      setCurrentScope(availableScopes.find((alvS) => alvS.path === module));
    }
    if (sessionStorage.getItem("x-app-redirect")) {
      const redirectPath = sessionStorage.getItem("x-app-redirect");

      if (redirectPath !== "/create-community") {
        navigate(sessionStorage.getItem("x-app-redirect"));
      } else {
        // bypass create-community page when user already own a community
        if (!auth.userEdges.find((edge) => edge.type === "CO" && edge.owner)) {
          navigate(sessionStorage.getItem("x-app-redirect"));
        }
      }

      sessionStorage.removeItem("x-app-redirect");
    }
  }, [
    auth.userEdges,
    availableScopes,
    currentScope?.path,
    location,
    navigate,
    refreshScopeActions,
  ]);

  return (
    <>
      <Header type={auth?.currentEdge?.type} setglobalmenu={setglobalmenu} />
      <div
        className={`groups-left-sidebar all-content-main cstm-all-content-main cstm-all-library-content-main cstm-all-content-library-main-only mn-cnt3-wd`}
      >
        <div
          className="sidebar-main"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <SideBar scopes={availableScopes} setCurrentScope={setCurrentScope} />
        </div>
        <div className="all-content-main-center all-content-main-center">
          <Outlet />
        </div>
      </div>
      {globalmenu && (
        <GlobalMenuPopup
          setglobalmenu={setglobalmenu}
          globalmenu={globalmenu}
          type={auth?.currentEdge?.type}
        />
      )}
    </>
  );
};

export default EdgeLayout;
