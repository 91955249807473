import { useContext } from "react";
import AuthContext from "../context/AuthProvider";
import { APP_TEST_CONFIG } from "context/dummy-data";

const useAuth = () => {
  const { auth, setAuth } = useContext(AuthContext);
  const resetAuth = () => {
    setAuth(APP_TEST_CONFIG["REAL"]);
    // localStorage.clear()
  };
  return { auth, setAuth, resetAuth };
};

export default useAuth;
