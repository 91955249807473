import { useState } from "react";
import { useParams } from "react-router-dom";
import useSocket from "Hooks/useSocket";
import useAuth from "Hooks/useAuth";

const useReactionHandlers = () => {
  const { socket } = useSocket();
  const { auth } = useAuth();
  const { userid, type } = useParams();
  const [reactions, setReactions] = useState({});
  const [showEmojis, setShowEmojis] = useState(false);
  const [reactionMsg, setReactionMsg] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [msgData, setMsgData] = useState(null);
  const [storeData, setStoreData] = useState(null);
  const [selectedMessageId, setSelectedMessageId] = useState(null);
  const [modelPlace, setModelPlace] = useState({ x: 0, y: 0 });
  const [hoverEffects, setHoverEffects] = useState();
  const loggedUserId = auth?.userId;
  const initialEmojis = ["🙂", "😆", "😝", "🤑", "😈", "😡"];

  let currentData = [];

  // handle messages reactions
  const handleReactionClick = (emoji) => {
    setShowEmojis(true);
    // setHoverEffects(true);
    setReactionMsg(emoji);
    HandleEvent(emoji);
  };

  // toggle show hide Emoji
  const toggleShowEmojis = () => {
    setShowEmojis((prevState) => !prevState);
  };

  // handle select Emoji
  const handleEmojiClick = (chatIds, e) => {
    currentData = chatIds;
    setStoreData(chatIds);
    setMsgData(chatIds);
    setShowEmojis(!showEmojis);
    // setHoverEffects(false);
    let mainDivElement = document.getElementById("NEW_MAIN_CHAT_CONTAINER");
    let x = e.clientX > mainDivElement.clientWidth ? e.clientX : e.clientX;
    let y =
      e.clientY > mainDivElement.clientHeight ? e.clientY - 20 : e.clientY;
    setModelPlace({
      y: y,
      x: x,
    });
    setSelectedMessageId(chatIds?._id);
    // toggleShowEmojis();
  };

  //open emoji menu
  const showAllEmogis = () => {
    setShowEmojis(false);
    setShowDropdown(true);
    setSelectedMessageId(selectedMessageId);
  };

  // select emoji
  const handleSelectEmoji = (emojiObject) => {
    setHoverEffects(false);
    setShowDropdown(false);
    setShowEmojis(false);
    HandleEvent(emojiObject);
  };

  // socket calling
  const HandleEvent = (emoji) => {
    const EmojiId = emoji?.emoji ? emoji?.emoji : emoji;

    const newMessageId = msgData?._id.slice(0, -1);

    socket.emit("add-message-reaction", {
      messageId: newMessageId,
      senderId: loggedUserId,
      receiverId: userid,
      emojiId: EmojiId,
      type: type,
    });
    // close emoji sections
    setShowEmojis(false);
    setHoverEffects(false);
  };

  // delete reaction
  const deleteReaction = (data, selectedReaction) => {
    socket.emit("remove-message-reaction", {
      // messageId: data?._id,
      messageId: selectedReaction?._id.slice(0, -1),
      senderId: loggedUserId,
      receiverId: userid,
      emojiId: data?.emojiId,
      type: type,
    });
  };

  // export
  return {
    setModelPlace,
    modelPlace,
    initialEmojis,
    reactions,
    setReactions,
    showEmojis,
    setShowEmojis,
    reactionMsg,
    setReactionMsg,
    showDropdown,
    setShowDropdown,
    msgData,
    setMsgData,
    showDropdown,
    showAllEmogis,
    showDropdown,
    handleReactionClick,
    toggleShowEmojis,
    handleEmojiClick,
    handleSelectEmoji,
    selectedMessageId,
    deleteReaction,
    hoverEffects,
    setHoverEffects,
    storeData,
    setStoreData,
    currentData,
  };
};

export default useReactionHandlers;
