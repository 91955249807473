import React from "react";
import { Navigate } from "react-router-dom";

import { ADMIN_SCOPE_ROUTES } from "../menuList.js";

import LazyLoadComponent from "../../components/LazyLoadComponent.jsx";
import SubscriptionLayout from "layouts/SubscriptionLayout";

import { ADMIN_SCOPE_ROUTES as EDGE_SCOPE_ADMIN_SCOPE_ROUTES } from "routes/EdgeRoutes/menuList";
import { ADMIN_SCOPE_ROUTES as SETTINGS_ADMIN_SCOPE_ROUTES } from "routes/SettingsRoutes/menuList";
import { ADMIN_SCOPE_ROUTES as SUBSCRIPTION_ADMIN_SCOPE_ROUTES } from "routes/SubscriptionRoutes/menuList.js";

const NotFound = React.lazy(() => import("../../pages/NotFound/index.jsx"));

const EdgeLayout = React.lazy(() =>
  import("../../layouts/EdgeLayout/index.jsx")
);

const SettingsLayout = React.lazy(() =>
  import("../../layouts/SettingsLayout/index.jsx")
);
const EdgeLogin = React.lazy(() => import("../../pages/EdgeLogin/index.jsx"));
const CreateCommunity = React.lazy(() =>
  import("../../pages/CreateCommunity/index.jsx")
);

const DiscoverCommunities = React.lazy(() =>
  import("../../pages/DiscoverCommunities/DiscoverCommunities.jsx")
);

const CommunityProfile = React.lazy(() =>
  import("../../pages/CommunityProfile/index.jsx")
);

// subscription
const Checkout = React.lazy(() => import("../../pages/CheckoutForm/index.jsx"));
const SubscriptionReturn = React.lazy(() =>
  import("../../pages/SubscriptionReturn/index.jsx")
);

const SubscriptionUpdate = React.lazy(() =>
  import("../../pages/SubscriptionUpdate/index.jsx")
);
const EdgeRoutes = [
  {
    path: "/",
    element: LazyLoadComponent(<EdgeLayout />),
    children: [
      {
        index: true,
        element: LazyLoadComponent(<EdgeLogin />),
      },
      {
        exact: true,
        path: ADMIN_SCOPE_ROUTES.edgeLogin.basePath,
        element: LazyLoadComponent(<EdgeLogin />),
      },
      {
        exact: true,
        path: ADMIN_SCOPE_ROUTES.discover.basePath,
        element: LazyLoadComponent(<DiscoverCommunities />),
      },
      {
        path: ADMIN_SCOPE_ROUTES.discover.actionPath,
        children: [
          { index: true, element: LazyLoadComponent(<CommunityProfile />) },
        ],
      },
      {
        exact: true,
        path: EDGE_SCOPE_ADMIN_SCOPE_ROUTES.edge.basePath,
        element: LazyLoadComponent(<CreateCommunity />),
      },
      {
        path: "*",
        skipLazyLoad: true,
        element: <Navigate to="/" />,
      },
    ],
  },
  {
    path: "/settings",
    element: LazyLoadComponent(<SettingsLayout />),
    children: [
      {
        exact: true,
        path: SETTINGS_ADMIN_SCOPE_ROUTES.settings.actionPath.edit,
        element: LazyLoadComponent(<>edit</>),
      },
      {
        exact: true,
        path: SETTINGS_ADMIN_SCOPE_ROUTES.settings.actionPath.profile,
        element: LazyLoadComponent(<>profile </>),
      },
      {
        exact: true,
        path: SETTINGS_ADMIN_SCOPE_ROUTES.settings.actionPath.users,
        element: LazyLoadComponent(<>users </>),
      },
      {
        exact: true,
        path: SETTINGS_ADMIN_SCOPE_ROUTES.settings.actionPath.contactUs,
        element: LazyLoadComponent(<>contactus </>),
      },
      {
        exact: true,
        path: SETTINGS_ADMIN_SCOPE_ROUTES.settings.actionPath.privacy,
        element: LazyLoadComponent(<>privacy </>),
      },
      {
        exact: true,
        path: SETTINGS_ADMIN_SCOPE_ROUTES.settings.actionPath.terms,
        element: LazyLoadComponent(<>terms </>),
      },
      {
        exact: true,
        path: EDGE_SCOPE_ADMIN_SCOPE_ROUTES.edgeLogin.basePath,
        element: LazyLoadComponent(<EdgeLogin />),
      },
      {
        exact: true,
        path: ADMIN_SCOPE_ROUTES.discover.basePath,
        element: LazyLoadComponent(<DiscoverCommunities />),
      },
    ],
  },
  {
    path: SUBSCRIPTION_ADMIN_SCOPE_ROUTES.subscription.basePath,
    element: LazyLoadComponent(<SubscriptionLayout />),
    children: [
      {
        exact: true,
        path: SUBSCRIPTION_ADMIN_SCOPE_ROUTES.subscription.actionPath
          .subscriptionCheckout,
        element: LazyLoadComponent(<Checkout />),
      },
      {
        exact: true,
        path: SUBSCRIPTION_ADMIN_SCOPE_ROUTES.subscription.actionPath
          .tierUpgrade,
        element: LazyLoadComponent(<>tier upgrade</>),
      },
      {
        exact: true,
        path: SUBSCRIPTION_ADMIN_SCOPE_ROUTES.subscription.actionPath.update,
        element: LazyLoadComponent(<SubscriptionUpdate />),
      },
      {
        exact: true,
        path: SUBSCRIPTION_ADMIN_SCOPE_ROUTES.subscription.actionPath
          .subscriptionReturn,
        element: LazyLoadComponent(<SubscriptionReturn />),
      },
    ],
  },
];

export default EdgeRoutes;
