export const replaceIdWithNameUsingUnformattedList = (
  text,
  unFormattedList,
  spanText = false
) => {
  var mentionIdList = getFormatedListForUserAndAttendees(unFormattedList);

  if (spanText)
    return replaceIdWithNameAndCreateSpanFromText(text, mentionIdList);
  else return replaceIdWithName(text, mentionIdList);
};

export const getFormatedListForUserAndAttendees = (unFormattedList) => {
  var newFormatedData = unFormattedList
    ? unFormattedList.map((u) => {
        const fullName =
          u?.first_name || u?.last_name
            ? `${u?.first_name} ${u?.last_name}`
            : "";
        return {
          fullName,
          id: u?._id,
          profileImg: u?.profileImg ?? "",
          onlineStatus: u?.onlineStatus,
        };
      })
    : [];
  return newFormatedData;
};
export const replaceIdWithNameAndCreateSpanFromText = (text, mentionIdList) => {
  var updatedText = [];
  if (text.includes("@") && mentionIdList.length > 0) {
    text.split(" ").forEach((textItem) => {
      if (textItem.startsWith("@")) {
        let getUser = mentionIdList.filter((user) => {
          if (user.id === textItem.replace("@", "")) {
            return user;
          }
        });
        let changeIdwithname = getUser.length
          ? `@${getUser[0].fullName}`
          : textItem;

        updatedText.push(changeIdwithname);
      } else {
        updatedText.push(textItem);
      }
    });
  } else {
    updatedText.push(text);
  }

  return updatedText;
};

export const replaceIdWithName = (text, mentionIdList) => {
  let updatedText = [];
  if (text.includes("@") && mentionIdList.length > 0) {
    text.split(" ").map((textItem) => {
      if (textItem.startsWith("@")) {
        let getUser = mentionIdList.filter((user) => {
          if (user.id === textItem.replace("@", "")) {
            return user;
          }
        });
        let changeIdwithname = getUser.length
          ? `@${getUser[0].fullName}`
          : textItem;
        updatedText.push(changeIdwithname);
      } else {
        updatedText.push(textItem);
      }
    });
    return updatedText.join(" ");
  } else {
    return text;
  }
};

export const replaceNameWithId = (text, mentionIdList) => {
  let updatedText = [];
  if (text.includes("@") && mentionIdList.length > 0) {
    text.split(" ").map((textItem) => {
      if (textItem.startsWith("@")) {
        let getUser = mentionIdList.filter((user) => {
          if (user.id === textItem.replace("@", "")) {
            return user;
          }
        });
        let changeNameWithId = getUser.length ? `@${getUser[0].id}` : textItem;
        updatedText.push(changeNameWithId);
      } else {
        updatedText.push(textItem);
      }
    });
    return updatedText.join(" ");
  } else {
    return text;
  }
};
