import React from 'react'
import PropTypes from "prop-types"
const IconSoundMute = ({ width = 16, height = 16, iconColor = "#394046" }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 16 16"
        >
            <path
                fill={iconColor}
                fillRule="evenodd"
                d="M11.056.94a.684.684 0 0 1 .972.002c.128.13.2.305.2.489v5.944l3.472 3.42a.789.789 0 1 1-1.111 1.12L4.027 1.351a.792.792 0 1 1 1.12-1.12l3.31 3.31L11.056.94Zm1.172 10.066v3.974a.685.685 0 0 1-1.172.49l-4.46-4.463H3.088a.69.69 0 0 1-.687-.692V6.096a.69.69 0 0 1 .687-.692h3.51l5.631 5.602Z"
                clipRule="evenodd"
            />
        </svg>
    )
}
IconSoundMute.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    iconColor: PropTypes.string,
}
export default IconSoundMute