import Cookies from "js-cookie";

export default function authHeader() {
  //const idtoken = localStorage.getItem("adminidtoken");
  const idtoken = Cookies.get("x-app-token");
  if (idtoken) {
    // for Node.js Express back-end
    return {
      Authorization: idtoken,
      "ngrok-skip-browser-warning": "69420",
    };
  } else {
    return {};
  }
}
