import React from 'react'
import PropTypes from "prop-types"
const IconSNotificationMute = ({ width = 16, height = 16, iconColor = "#394046" }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 16 16"
        >
            <path
                fill={iconColor}
                fillRule="evenodd"
                d="M9.301 1.707a4.854 4.854 0 0 1 1.517.746L3.502 9.769V6c0-1.839.81-2.99 1.802-3.663a4.87 4.87 0 0 1 1.398-.642 1.334 1.334 0 0 1 2.6.013Zm3.072 3.313 2.157-2.158a.75.75 0 0 0-1.06-1.06l-11.5 11.5a.75.75 0 1 0 1.06 1.06L4.893 12.5H14v-1c-.647-.004-1.543-.855-1.543-1.5V6a5.5 5.5 0 0 0-.085-.98ZM6.667 14c0-.368.298-.667.666-.667h1.334a.667.667 0 1 1 0 1.333H7.333A.667.667 0 0 1 6.667 14Z"
                clipRule="evenodd"
            />
        </svg>
    )
}
IconSNotificationMute.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    iconColor: PropTypes.string,
}
export default IconSNotificationMute  