const grouppost = { allpost: [], totalpost: 0 };

const ReduGroupPost = (state = grouppost, action) => {
  const USER_ID = localStorage.getItem("userid");
  switch (action.type) {
    case "ADDPOST":
      console.log({
        allpost: [...state.allpost, ...action.payload.data],
        totalpost: state.totalpost,
        payload: action.payload.data,
      });
      if (state.allpost.length === action.payload.total)
        return { allpost: [...state.allpost], totalpost: state.totalpost };
      else
        return {
          allpost: [...state.allpost, ...action.payload.data],
          totalpost: state.totalpost,
        };
    case "FETCHGROUPPOST":
      return {
        allpost: [...action.payload.data],
        totalpost: action.payload.total,
      };
    case "SINGLEADDPOSTGROUP":
      const data_res = {
        ...action.payload,
        likes: {
          count: action.payload.likes.length,
          list: action.payload.likes,
        },
      };

      return {
        allpost: [data_res, ...state.allpost],
        totalpost: state.totalpost + 1,
      };
    case "HANDLEGROUPLIKE":
      var arr = state.allpost.map((post) => {
        if (post._id === action.payload) {
          if (post.likes.list.includes(USER_ID)) {
            console.log({
              ...post,
              likes: {
                count: post.likes.count - 1,
                list: post.likes.list.splice(
                  post.likes.list.indexOf(USER_ID),
                  1
                ),
              },
            });
            return {
              ...post,
              likes: {
                count: post.likes.count - 1,
                list: post.likes.list.splice(
                  post.likes.list.indexOf(USER_ID),
                  1
                ),
              },
            };
          } else {
            return {
              ...post,
              likes: {
                count: post.likes.count + 1,
                list: [...post.likes.list, USER_ID],
              },
            };
          }
        } else {
          return post;
        }
      });
      return { allpost: arr, totalpost: state.totalpost };
    case "HANDLEGROUPVOTE":
      var arr = state.allpost.map((post) => {
        if (post._id === action.payload.postid) {
          if (!post.pollTotalVotes.includes(USER_ID)) {
            var pchoice = post.pollChoices.map((choice) => {
              if (action.payload.pollid === choice._id) {
                return { ...choice, votes: [...choice.votes, USER_ID] };
              } else return choice;
            });
            return {
              ...post,
              pollTotalVotes: [...post.pollTotalVotes, USER_ID],
              pollChoices: pchoice,
            };
          } else {
            var pchoice = post.pollChoices.map((choice) => {
              if (action.payload.pollid === choice._id) {
                console.log(choice.votes.includes(USER_ID));
                if (!choice.votes.includes(USER_ID)) {
                  return { ...choice, votes: [...choice.votes, USER_ID] };
                } else return choice;
              } else if (choice.votes.includes(USER_ID)) {
                return {
                  ...choice,
                  votes: choice.votes.filter((id) => {
                    if (id !== USER_ID) return id;
                  }),
                };
              } else return choice;
            });
            return {
              ...post,
              pollChoices: pchoice,
            };
            return post;
          }
        } else {
          return post;
        }
      });
      return { allpost: arr, totalpost: state.totalpost };
    case "ADDGROUPCOMMENT":
      var arr = state.allpost.map((post) => {
        if (post._id === action.payload.postid) {
          return {
            ...post,
            comments: [...post.comments, action.payload.commid],
          };
        } else {
          return post;
        }
      });
      return { allpost: arr, totalpost: state.totalpost };
    case "ANNOUCEMENT":
      console.log(action.payload, "redux");
      var arr = state.allpost.map((post) => {
        if (post._id === action.payload) {
          return {
            ...post,
            makeAnnouncement: post.makeAnnouncement ? false : true,
          };
        } else {
          return post;
        }
      });
      return { allpost: arr, totalpost: state.totalpost };
    case "HIDEPOST":
      console.log(action.payload, "redux");
      var arr = state.allpost.map((post) => {
        if (post._id === action.payload) {
          return {
            ...post,
            hideFromFeed: post.hideFromFeed ? false : true,
          };
        } else {
          return post;
        }
      });
      return { allpost: arr, totalpost: state.totalpost };
    case "DELETEGROUPCOMMENT":
      var arr = state.allpost.map((post) => {
        if (post._id === action.payload.postid) {
          console.log({
            ...post,
            comments: post.comments.filter((cmt) => {
              if (cmt !== action.payload.commid) return cmt;
            }),
          });
          return {
            ...post,
            comments: post.comments.filter((cmt) => {
              if (cmt !== action.payload.commid) return cmt;
            }),
          };
        } else {
          console.log(post);
          return post;
        }
      });
      return { allpost: arr, totalpost: state.totalpost };

    default:
      return state;
  }
};

export default ReduGroupPost;
