import React from 'react'
import PropTypes from "prop-types"
const DeleteIcon = ({ width = 24, height = 24, iconColor = "#000" }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 24 24"
        >
            <path
                fill={iconColor}
                fillRule="evenodd"
                d="M6.988 5.49h.004a.897.897 0 0 0 .875-.722C8.12 3.495 8.443 2 10.068 2h3.864c1.636 0 1.946 1.49 2.202 2.768.082.418.45.722.873.722H20.255c.411 0 .745.336.745.75s-.334.75-.745.75H3.745A.748.748 0 0 1 3 6.24c0-.414.334-.75.745-.75h3.243Zm7.811 0c-.207-.515-.26-1.102-.368-1.643-.051-.185-.26-.346-.5-.346h-3.863a.526.526 0 0 0-.509.392c-.104.527-.156 1.096-.358 1.597h5.598Z"
                clipRule="evenodd"
            />
            <path
                fill={iconColor}
                d="M8.47 21.956c-1.662-.034-2.812-1.118-3-2.83-.313-2.841-.849-9.537-.854-9.604a.748.748 0 0 1 .683-.808c.404-.01.77.275.803.687.005.068.54 6.741.85 9.56.106.973.628 1.475 1.548 1.494 2.485.053 4.24.064 6.973.014.978-.02 1.507-.511 1.616-1.508.35-3.176.591-6.367.85-9.552.034-.412.396-.7.803-.687a.75.75 0 0 1 .683.808c-.26 3.2-.503 6.405-.854 9.596-.193 1.748-1.34 2.811-3.071 2.843-2.753.048-4.527.039-7.03-.014Z"
            />
        </svg>
    )
}
DeleteIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    iconColor: PropTypes.string
}
export default DeleteIcon