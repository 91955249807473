// check messages are containing only emoji or not
export const checkEmojiMessage = (text, maxSize) => {
  const regexExp =
    /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/;
  const characters = Array.from(text.replaceAll(" ", ""));
  let onlyContainsEmoji = true;
  // console.log(characters, "characters");
  for (let index = 0; index < characters.length; index++) {
    if (!regexExp.test(characters[index])) {
      onlyContainsEmoji = false;
      break;
    }
  }
  if (onlyContainsEmoji && maxSize >= characters.length) return true;
  else return false;
};
