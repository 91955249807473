import React, { useEffect, useRef, useState } from 'react'
import { PropTypes } from "prop-types";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import DotsIcon from '../../../components/Icons/GeneralIcons/DotsIcon';
import Button from '../../../components/Button/Button';
import NotificationsCard from './NotificationsCard';
import EmptyState from "../../../components/EmptyState/EmptyState";
import NotificationIcon from './NotificationIcon';
import Typography from "../../../components/Typography/Typography";
import DropDownItem from '../../../components/DropDownItem/DropDownItem';
import authNotification from "../../../Services/auth.notification";
import DoubleTickIcon from '../../../components/Icons/GeneralIcons/DoubleTickIcon';
// import actions
import { notificationlistAction } from '../../../Action';
// import css
import '../Styles/notification.css'

const Notifications = ({ title, subTitle, date, type, hasImage, hasNotifications, src = "https://images.unsplash.com/photo-1705392852198-2a2334db0314?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
setshowNotificationList, showNotificationList} ) => {

    const readNotification = true // true:- Notification is Read 
    const wrapperRef = useRef(null);
    const notificationCom = useRef(null);
    const dispatch = useDispatch()

    // use Selectors
    const notificationData = useSelector((state) => {
        return state.NotificationReducer.notificationList;
    });

    const [page, setpage] = useState(1)
    const [limit, setlimit] = useState(10)
    const [isDropDownVisible, setDropDownVisible] = useState(false);

    useEffect(() => {
        notificationList()
    }, [])

    // get time deffercence and show Message
    const timeDiff = (time) => {
        let now = moment(new Date()); //todays date
        let end =   moment(time).utc();
        console.log(now, end, time,'sdfdfdsf')
        //let end = time.split(':')[0].split('T')[0]
        let duration = moment.duration(now.diff(end));
        let minutes = duration.minutes();
        let seconds = duration.seconds();
        let hours = duration.hours();
        let days = duration.asDays();
        let message = days > 1 ? `${days.toString().split('.')[0]} days ago` : days == 1 ? `${days.toString().split('.')[0]} day ago` 
        : (hours > 1 &&  hours <= 24) ? `${hours} hours ago` : hours == 1 ? `${hours} hour ago` 
        : (minutes > 1 && minutes < 60) ? `${minutes} mins ago` : minutes == 1 ? `${minutes} min ago` 
        : (seconds > 1) ? `${seconds} seconds ago`: `${seconds} sec ago`
        return message
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        document.addEventListener("click", handleClickComponentOutside);
        document.addEventListener('keydown', handleEscKey);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
            document.removeEventListener("click", handleClickComponentOutside);
            document.removeEventListener('keydown', handleEscKey);
        };
    }, []);

    // Call Notification List Api
    async function notificationList() {      
        try {
            const notificationList = await authNotification.getNotificationList( page, limit );
            if (notificationList?.status) {
                dispatch(notificationlistAction(notificationList?.data?.data))
            }
        } catch (error) {
            console.log('error:-----------', error)
        }
    }

    const handleClickOutside = (event) => {

        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            onOutsideClick();
        }
        

    };

    const handleClickComponentOutside = (event) => {
        if (notificationCom.current && !notificationCom.current.contains(event.target)) {
            setshowNotificationList(false); 
        }
    }

    const handleEscKey = (event) => {
        if (event.key === 'Escape') {
          // Escape key pressed, hide the component
          setshowNotificationList(false);
        }
      };

    const onOutsideClick = () => {
        setDropDownVisible(false);
        //setshowNotificationList(false)
    };


    // select dropdown options and 
    const onSelect = async (selectedOption) => {
        let data =
        {
            "notificationIds": notificationData?.notification?.map(item => item?.notificationId?._id),
            "isRead": true
        }

        try {
            const markasRead = await authNotification.markNotificationRead(data);
            //if (markasRead.status === 200) {
            notificationList()
            //}
        } catch (error) {
            console.log('error:-----------', error)
        }
        setDropDownVisible(false)
    };

    // show/ hide dropdown menu 
    const handleDotsIconClick = (e) => {
        setDropDownVisible(!isDropDownVisible);
    };

    return (
        <>
            
            <div className='notifications' ref={notificationCom}>
                <div className='notifications-heading' ref={wrapperRef} >
                    <Typography variant='h4'>Notifications</Typography>

                    <Button
                        elementIcon={<DotsIcon iconColor="var(--color-icon-neutral-strong)" horizontal />}
                        onMouseEnter={() => { }}
                        onMouseLeave={() => { }}
                        onContextMenu={() => { }} 
                        handleClick={handleDotsIconClick}
                        size="small"
                        variant="ghost"
                        hasText={false}
                        hasToolTip={false} 
                        buttonId="moreButton" 
                        toolTipText="More"
                        notificationData={notificationData}
                    ></Button>

                    {isDropDownVisible && (
                        <DropDownItem 
                   
                            isIcon
                            onSelect={onSelect}
                            options={[
                                {
                                    id: "1",
                                    icon: <DoubleTickIcon />,
                                    name: "Mark all as read"
                                },
                            ]}
                            size="medium"
                        />
                    )}
                </div>

                <hr />

                {hasNotifications && notificationData ?
                    <div className='notifications-in-body scrollbar'>

                  
                        {
                            notificationData?.notification.length > 0 ?
                                notificationData?.notification?.map((item, index) => (
                                    <>
                                        <NotificationsCard
                                            id={item?.notificationId?._id}
                                            hasPoint={item.isRead && item.isRead==true ? false : true }
                                            title={item?.notificationId?.title}
                                            subTitle={item?.notificationId?.subtitle}
                                            hasImage={true}
                                            date={timeDiff(item?.createdAt)}
                                            icon={src}
                                            src={item?.notificationId?.uploadImg}
                                            deepLink={item?.notificationId?.deepLinkUrl}
                                        />
                                    </>
                                ))
                                :
                                <div className="notification-card-body-list-loading">
                                    <div className="notification-card-body-list-in-loading skeleton-box"></div>
                                    <div className="notification-card-body-list-in-loading skeleton-box"></div>
                                    <div className="notification-card-body-list-in-loading skeleton-box"></div>
                                    <div className="notification-card-body-list-in-loading skeleton-box"></div>
                                    <div className="notification-card-body-list-in-loading skeleton-box"></div>
                                    <div className="notification-card-body-list-in-loading skeleton-box"></div>
                                    <div className="notification-card-body-list-in-loading skeleton-box"></div>
                                    <div className="notification-card-body-list-in-loading skeleton-box"></div>
                                </div>
                        }
                    </div >
                    :
                    <div className='notifications-in-emptystate'>
                        <EmptyState
                            isClear={false}
                            icon={<NotificationIcon />}
                            subTitle="Your notifications will appear here!"
                            title="Looks like you're all caught up!"
                        />
                    </div>
                }
            </div>
        </>
    )
}

Notifications.propTypes = {
    hasNotifications: PropTypes.bool,
    hasImage: PropTypes.bool,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    date: PropTypes.string,
};

export default Notifications