import React from "react";

function IconNavVideoFilled() {
  return (
    <div className="mds-icons filled">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
      >
        <path
          fillRule="evenodd"
          d="M1 7.5A5.5 5.5 0 0 1 6.5 2h11A5.5 5.5 0 0 1 23 7.5v9a5.5 5.5 0 0 1-5.5 5.5h-11A5.5 5.5 0 0 1 1 16.5v-9Zm8.69 7.716c.479.476 1.204.262 1.784.02.839-.35 4.123-2.181 4.123-3.492 0-1.397-3.65-3.266-4.17-3.483-.362-.152-1.214-.507-1.737.01l-.001.001c-.148.148-.593.594-.592 3.56.002 2.8.432 3.224.593 3.383Z"
          clipRule="evenodd"
        />
      </svg>
    </div>
  );
}

export default IconNavVideoFilled;
