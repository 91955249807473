const memberpost = [];

const ReduMemberPost = (state = memberpost, action) => {
  const USER_ID = localStorage.getItem("userid");
  switch (action.type) {
    case "ADDMEMBERPOST":
      return action.payload;
    case "FETCHGROUPPOST":
      return state;
    case "HANDLEMEMBERLIKE":
      var arr = state.map((post) => {
        if (post._id === action.payload) {
          if (post.likes.list.includes(USER_ID)) {
            console.log({
              ...post,
              likes: {
                count: post.likes.count - 1,
                list: post.likes.list.splice(
                  post.likes.list.indexOf(USER_ID),
                  1
                ),
              },
            });
            return {
              ...post,
              likes: {
                count: post.likes.count - 1,
                list: post.likes.list.splice(
                  post.likes.list.indexOf(USER_ID),
                  1
                ),
              },
            };
          } else {
            return {
              ...post,
              likes: {
                count: post.likes.count + 1,
                list: [...post.likes.list, USER_ID],
              },
            };
          }
        } else {
          return post;
        }
      });
      return arr;
    case "HANDLEMEMBERVOTE":
      var arr = state.map((post) => {
        if (post._id === action.payload.postid) {
          if (!post.pollTotalVotes.includes(USER_ID)) {
            var pchoice = post.pollChoices.map((choice) => {
              if (action.payload.pollid === choice._id) {
                return { ...choice, votes: [...choice.votes, USER_ID] };
              } else return choice;
            });
            return {
              ...post,
              pollTotalVotes: [...post.pollTotalVotes, USER_ID],
              pollChoices: pchoice,
            };
          } else {
            var pchoice = post.pollChoices.map((choice) => {
              if (action.payload.pollid === choice._id) {
                console.log(choice.votes.includes(USER_ID));
                if (!choice.votes.includes(USER_ID)) {
                  return { ...choice, votes: [...choice.votes, USER_ID] };
                } else return choice;
              } else if (choice.votes.includes(USER_ID)) {
                return {
                  ...choice,
                  votes: choice.votes.filter((id) => {
                    if (id !== USER_ID) return id;
                  }),
                };
              } else return choice;
            });
            return {
              ...post,
              pollChoices: pchoice,
            };
          }
        } else {
          return post;
        }
      });
      return arr;
    case "ADDMEMBERCOMMENT":
      var arr = state.map((post) => {
        if (post._id === action.payload.postid) {
          return {
            ...post,
            comments: [...post.comments, action.payload.commid],
          };
        } else {
          return post;
        }
      });
      return arr;
    case "DELETEMEMBERCOMMENT":
      var arr = state.map((post) => {
        if (post._id === action.payload.postid) {
          console.log(
            post.comments.splice(
              post.comments.indexOf(action.payload.commid),
              1
            )
          );
          return {
            ...post,
            comments: post.comments.splice(
              post.comments.indexOf(action.payload.commid),
              1
            ),
          };
        } else {
          return post;
        }
      });
      return arr;
    default:
      return state;
  }
};

export default ReduMemberPost;
