import React from "react";

const IconSearchNoResults = () => {
  return (
    <div className="mds-icons-empty">
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={120}
    height={120}
    fill="none"
  >
    <g clipPath="url(#a)">
      <path
        fill="#6FCADF"
        fillOpacity={0.2}
        d="M59.4 119.944c-1.587 0-3.19.147-4.753-.034-2.456-.286-4.913-.635-7.333-1.148A58.98 58.98 0 0 1 23.19 107.52a59.343 59.343 0 0 1-6.402-5.722 62.277 62.277 0 0 1-8.399-11.046 59.11 59.11 0 0 1-4.357-8.818A57.263 57.263 0 0 1 .96 71.122a56.538 56.538 0 0 1-.863-6.085c-.193-2.951-.05-5.928 0-8.898.07-4.133.921-8.158 1.96-12.128a51.266 51.266 0 0 1 3.223-8.898 58.827 58.827 0 0 1 5.202-9.107 61.068 61.068 0 0 1 3.731-4.93 69.78 69.78 0 0 1 5.98-6.017 57.893 57.893 0 0 1 7.47-5.683A60.39 60.39 0 0 1 61.341.016 59.985 59.985 0 0 1 99.554 14.88a59.892 59.892 0 0 1 19.715 35.927c.654 4.11.87 8.28.644 12.435-.746 12.369-4.833 23.51-12.372 33.358a60.38 60.38 0 0 1-18.381 15.882 58.189 58.189 0 0 1-10.315 4.568 57.159 57.159 0 0 1-12.59 2.676 46.69 46.69 0 0 1-6.854.218Z"
      />
      <path
        fill="#060D14"
        fillRule="evenodd"
        d="M72 54.667C72 64.24 64.24 72 54.667 72c-9.573 0-17.334-7.76-17.334-17.333 0-9.573 7.76-17.334 17.334-17.334C64.24 37.333 72 45.093 72 54.667ZM68.271 71.1A21.247 21.247 0 0 1 54.667 76c-11.782 0-21.334-9.551-21.334-21.333 0-11.782 9.552-21.334 21.334-21.334S76 42.885 76 54.667c0 5.17-1.84 9.912-4.9 13.604l14.314 14.315a2 2 0 1 1-2.828 2.828L68.27 71.1Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h120v120H0z" />
      </clipPath>
    </defs>
  </svg>
    </div>
  );
};

export default IconSearchNoResults;
