import React from "react";
import PropTypes from "prop-types";
import SmallDoubleTickIcon from "../../../../components/Icons/GeneralIcons/SmallDoubleTickIcon";
import SmallTickIcon from "../../../../components/Icons/GeneralIcons/SmallTickIcon";
import SmallClockIcon from "../../../../components/Icons/GeneralIcons/SmallClockIcon";

// CSS
import "../../../../Styles/Theme/Theme.css";
import "../Styles/OutgoingMessageTime.css";

const OutgoingMessageTime = ({
  hasBackdrop = false,
  isEdited = false,
  time = "00:00 AM",
  isRead = false,
  isInProgress = false,
}) => {
  return (
    <span
      className={`OutgoingMessageTime-sec flex items-center ${
        hasBackdrop ? "hasBackdrop" : ""
      }`}
    >
      {isEdited ? `edited ${time}` : time}
      {isInProgress ? (
        <SmallClockIcon
          height={16}
          width={16}
          iconColor="var(--color-icon-onbrand-strong)"
        />
      ) : isRead ? (
        <SmallDoubleTickIcon
          height={16}
          width={16}
          iconColor="var(--color-icon-onbrand-strong)"
        />
      ) : (
        <SmallTickIcon
          height={16}
          width={16}
          iconColor="var(--color-icon-onbrand-strong)"
        />
      )}
    </span>
  );
};
OutgoingMessageTime.propTypes = {
  hasBackdrop: PropTypes.bool,
  isEdited: PropTypes.bool,
  isInProgress: PropTypes.bool,
  time: PropTypes.string,
  isRead: PropTypes.bool,
};
export default OutgoingMessageTime;
