import { Link } from "react-router-dom";
import "components/GlobalComponents/LeftSidePanel.css";
import "components/GlobalComponents/GlobalComponent.css";

const SideBar = ({ scopes, setCurrentScope }) => {
  return (
    <ul className="sidebar-main-ul">
      {scopes.map((scope) => {
        return (
          <li key={scope.id}>
            <Link
              to={
                scope.id === "partners"
                  ? `${scope.path}?type=offer&&cat=all&&search=&&sortby=all&&badge=all`
                  : scope.id === "channels"
                  ? `${scope.path}/me/me`
                  : scope.path
              }
              className={`sidebar-link ${
                window.location.pathname.includes(scope.path) ? "active" : ""
              } `}
              onClick={() => {
                // sets the clicked scope as the current scope to reset the actions rendered in sub side bar menu
                setCurrentScope(
                  scopes.find(
                    (availableScope) => availableScope.id === scope.id
                  )
                );
              }}
            >
              <span className="sidebar-icon-img">
                {scope.icon}
                {scope.activeIcon}
              </span>
              <span className="sidebar-label-name">{scope.name}</span>
            </Link>
          </li>
        );
      })}
    </ul>
  );
};
export default SideBar;
