import Services from "../Services/auth.service";
import { getFormatedListForUser } from "../pages/ChatModule/CommonFunctions/formatedUserList";
export const changepopup_MakeTrue = () => {
  return {
    type: "MakeTrue",
  };
};
export const changepopup_MakeFalse = () => {
  return {
    type: "MakeFalse",
  };
};
export const allpost = () => {
  return {
    type: "ALLPOST",
  };
};
export const allfeelact = () => {
  return {
    type: "ALLFEELACT",
  };
};
export const addactfeel = (allactvfeel) => {
  return {
    type: "ADDACTFEEL",
    payload: allactvfeel,
  };
};

export const addpost = (post) => {
  return {
    type: "ADDPOST",
    payload: post,
  };
};

export const alltopics = () => {
  return {
    type: "ALLTOPICS",
  };
};
export const addtopics = (alltpc) => {
  return {
    type: "ADDTOPICS",
    payload: alltpc,
  };
};
export const addsinglepostgroup = (post) => {
  return {
    type: "SINGLEADDPOSTGROUP",
    payload: post,
  };
};
export const addsinglepost = (post) => {
  return {
    type: "SINGLEADDPOST",
    payload: post,
  };
};
export const assignvalue = (val) => {
  return {
    type: "ASSIGN",
    payload: val,
  };
};

export const setloader = () => {
  return {
    type: "SETLOADER",
    payload: true,
  };
};

export const removeloader = () => {
  return {
    type: "REMOVELOADER",
    payload: false,
  };
};
export const handlegrouplike = (val) => {
  return {
    type: "HANDLEGROUPLIKE",
    payload: val,
  };
};

export const handlegroupvote = (val) => {
  return {
    type: "HANDLEGROUPVOTE",
    payload: val,
  };
};

export const addgroupcommt = (val) => {
  return {
    type: "ADDGROUPCOMMENT",
    payload: val,
  };
};

export const deletegroupcommt = (val) => {
  return {
    type: "DELETEGROUPCOMMENT",
    payload: val,
  };
};
export const handlememberlike = (val) => {
  return {
    type: "HANDLEMEMBERLIKE",
    payload: val,
  };
};

export const handlemembervote = (val) => {
  return {
    type: "HANDLEMEMBERVOTE",
    payload: val,
  };
};

export const addmembercommt = (val) => {
  return {
    type: "ADDMEMBERCOMMENT",
    payload: val,
  };
};

export const deletemembercommt = (val) => {
  return {
    type: "DELETEMEMBERCOMMENT",
    payload: val,
  };
};
export const handleredulike = (val) => {
  return {
    type: "HANDLELIKE",
    payload: val,
  };
};

export const handlereduvote = (val) => {
  return {
    type: "HANDLEVOTE",
    payload: val,
  };
};

export const addredcommt = (val) => {
  return {
    type: "ADDCOMMENT",
    payload: val,
  };
};

export const deleteredcommt = (val) => {
  return {
    type: "DELETECOMMENT",
    payload: val,
  };
};
export const handlesearchlike = (val) => {
  return {
    type: "HANDLESEARCHLIKE",
    payload: val,
  };
};

export const handlesearchvote = (val) => {
  return {
    type: "HANDLESEARCHVOTE",
    payload: val,
  };
};

export const addsearchcommt = (val) => {
  return {
    type: "ADDSEARCHCOMMENT",
    payload: val,
  };
};

export const deletesearchcommt = (val) => {
  return {
    type: "DELETESEARCHCOMMENT",
    payload: val,
  };
};
export const handlegroupannoucement = (val) => {
  return {
    type: "ANNOUCEMENT",
    payload: val,
  };
};

export const handlehidepost = (val) => {
  return {
    type: "HIDEPOST",
    payload: val,
  };
};
export const updateprofile = (val) => {
  return {
    type: "UPDATEPROFILE",
    payload: val,
  };
};
export const setsocket = (socket) => {
  return {
    type: "SETSOCKET",
    payload: socket,
  };
};
export const getchatlisting = (data) => {
  return {
    type: "FETCHALLCHATLIST",
    payload: data,
  };
};
export const recivemessage = (data, currentlyOnPage = true, authValue) => {
  console.log("receive message");
  return {
    type: "MDSRECIVEMESSAGE",
    payload: {
      message: data,
      currentlyOnPage: currentlyOnPage,
      userId: authValue,
    },
  };
};
export const sendmessage = (data) => {
  console.log(data, "send message in action");
  return {
    type: "MDSADDMESSAGE",
    payload: data,
  };
};
export const addtyper = (data, userId) => {
  return {
    type: "ADDTYPER",
    payload: { data: data, userId: userId },
  };
};
export const setregidetail = (data) => {
  return {
    type: "SETDETAIL",
    payload: data,
  };
};
export const addregidetail = (data) => {
  return {
    type: "ADDDETAIL",
    payload: data,
  };
};
export const addvideos = (data) => {
  return {
    type: "ADDVIDEOS",
    payload: data,
  };
};
export const fetchvideos = (data) => {
  // console.log("fetchvide called" , data)
  return {
    type: "FETCHVIDEOS",
    payload: data,
  };
};
export const emptycatvideos = () => {
  console.log("emptycatvideos called");
  return {
    type: "EMPTYCATVIDEOS",
  };
};
export const emptyallvideos = () => {
  console.log("emptyallvideos called");
  return {
    type: "EMPTYALLVIDEOS",
  };
};
export const addcatvideos = (data) => {
  return {
    type: "ADDCATVIDEOS",
    payload: data,
  };
};
export const fetchcatvideos = (data) => {
  return {
    type: "FETCHCATVIDEOS",
    payload: data,
  };
};
export const setcurrentlychattingusers = (data) => {
  return {
    type: "SETCHATTINGUSERS",
    payload: data,
  };
};
export const setchatlastsession = (data) => {
  return {
    type: "SETLASTSESSION",
    payload: data,
  };
};
export const setreadmessage = (data) => {
  return {
    type: "SETREADMESSAGE",
    payload: data,
  };
};
export const addmessagereaction = (data, userId) => {
  return {
    type: "ADDMESSAGEREACTION",
    payload: { data: data, userId: userId },
  };
};
export const removereaction = (data, userId) => {
  return {
    type: "REMOVEMESSAGEREACTION",
    payload: { data: data, userId: userId },
  };
};
export const editmessage = (data) => {
  return {
    type: "EDITMESSAGE",
    payload: data,
  };
};
export const deletemessage = (data) => {
  return {
    type: "DELETEMESSAGE",
    payload: data,
  };
};
export function fetchactivityfeelings() {
  return async (dispatch, getState) => {
    const response = await Services.getallfeelings();
    dispatch({
      type: "FETCHACTIFEEL",
      payload: response.data.data,
    });
  };
}
export function fetchtopics() {
  return async (dispatch, getState) => {
    const response = await Services.getalltopics();
    dispatch({
      type: "FETCHTOPICS",
      payload: response.data.data,
    });
  };
}
export function fetchalltopics() {
  return async (dispatch, getState) => {
    const response = await Services.getalltopicsforpost();
    dispatch({
      type: "FETCHALLTOPICS",
      payload: response.data.data,
    });
  };
}
export function fetchgrouptopics(groupid) {
  return async (dispatch, getState) => {
    const response = await Services.gettopicsbygroupid(groupid);
    dispatch({
      type: "FETCHTOPICS",
      payload: response.data.data,
    });
  };
}
export function clearchatredu(data) {
  return {
    type: "CLEARCHAT",
    payload: data,
  };
}
export function clearchatfromlist(data) {
  return {
    type: "CLEARCHATFROMLIST",
    payload: data,
  };
}
export function setLastMessage(data) {
  return {
    type: "SETLASTMESSAGE",
    payload: data,
  };
}
export function setLastMessageOfChat(data) {
  return {
    type: "SETLASTMESSAGEOFCHAT",
    payload: data,
  };
}
export function readMessageCountChange(data) {
  // console.log(data, "readmessage")
  return {
    type: "READMESSAGE",
    payload: data,
  };
}
export function onlineUserUpdate(data) {
  return {
    type: "USERONLINE",
    payload: data,
  };
}
export function offlineUserUpdate(data) {
  return {
    type: "USEROFFLINE",
    payload: data,
  };
}
export function fetchallpost(page) {
  return async (dispatch, getState) => {
    dispatch({
      type: "SETLOADER",
      payload: true,
    });
    await Services.getallpost(page).then((response) => {
      if (response.data.data.length > 0) {
        if (response.data.data[0].posts.length > 0 && page > 1) {
          const data_res = response.data.data[0].posts.map((r) => {
            return { ...r, likes: { count: r.likes.length, list: r.likes } };
          });
          dispatch({
            type: "ADDNORMALPOST",
            payload: {
              data: data_res,
              total: response.data.data[0].totalPosts,
            },
          });
        } else {
          const data_res = response.data.data[0].posts.map((r) => {
            return { ...r, likes: { count: r.likes.length, list: r.likes } };
          });
          if (response.data.data[0].posts.length === 0 && page === 1) {
            dispatch({
              type: "ADDNORMALPOST",
              payload: { data: [], total: response.data.data[0].totalPosts },
            });
          } else if (response.data.data[0].posts.length > 0 && page === 1) {
            dispatch({
              type: "FETCHPOSTS",
              payload: {
                data: data_res,
                total: response.data.data[0].totalPosts,
              },
            });
          }
        }
        dispatch({
          type: "REMOVELOADER",
          payload: false,
        });
      } else {
        dispatch({
          type: "ADDNORMALPOST",
          payload: { data: [], total: 0 },
        });
        dispatch({
          type: "REMOVELOADER",
          payload: false,
        });
      }
    });
  };
}
export function fetchsavedvideos() {
  return async (dispatch, getState) => {
    dispatch({
      type: "SETSAVEDVIDEOLOADER",
    });

    await Services.getsavedvideos()
      .then((response) => {
        if (response.data.status) {
          dispatch({
            type: "FETCHSAVEDVIDEOS",
            payload: response.data.data,
          });

          dispatch({
            type: "REMOVESAVEDVIDEOLOADER",
          });
        } else {
          dispatch({
            type: "REMOVESAVEDVIDEOLOADER",
          });
        }
      })
      .catch((e) => {
        dispatch({
          type: "REMOVESAVEDVIDEOLOADER",
        });
        console.log(e);
      });
  };
}
export function fetchsavedpost() {
  return async (dispatch, getState) => {
    const response = await Services.getsavedpost();
    dispatch({
      type: "FETCHSAVEDPOST",
      payload: response.data.data,
    });
  };
}
export function fetchlatestfiles() {
  return async (dispatch, getState) => {
    const response = await Services.getlatestfiles();
    dispatch({
      type: "FETCHLATESTFILES",
      payload: response.data.data,
    });
  };
}
export function fetchpostbygroupidtopicid(data) {
  return async (dispatch, getState) => {
    await Services.getpostbygroupidtopicid_user(data).then((response) => {
      if (response.data.data.length > 0) {
        if (response.data.data[0].posts.length > 0 && data.page > 1) {
          const data_res = response.data.data[0].posts.map((r) => {
            return { ...r, likes: { count: r.likes.length, list: r.likes } };
          });
          dispatch({
            type: "ADDPOST",
            payload: {
              data: data_res,
              total: response.data.data[0].totalPosts,
            },
          });
        } else {
          const data_res = response.data.data[0].posts.map((r) => {
            return { ...r, likes: { count: r.likes.length, list: r.likes } };
          });
          if (response.data.data[0].posts.length === 0 && data.page === 1) {
            dispatch({
              type: "ADDPOST",
              payload: { data: [], total: response.data.data[0].totalPosts },
            });
          } else if (
            response.data.data[0].posts.length > 0 &&
            data.page === 1
          ) {
            dispatch({
              type: "FETCHGROUPPOST",
              payload: {
                data: data_res,
                total: response.data.data[0].totalPosts,
              },
            });
          }
        }
      } else {
        dispatch({
          type: "ADDPOST",
          payload: { data: [], total: 0 },
        });
      }
    });
  };
}

export function fetchpostbygroupid(data) {
  return async (dispatch, getState) => {
    await Services.getpostbygroupid_user(data).then((response) => {
      if (response.data.data.length > 0) {
        if (response.data.data[0].posts.length > 0 && data.page > 1) {
          const data_res = response.data.data[0].posts.map((r) => {
            return { ...r, likes: { count: r.likes.length, list: r.likes } };
          });
          dispatch({
            type: "ADDPOST",
            payload: {
              data: data_res,
              total: response.data.data[0].totalPosts,
            },
          });
        } else {
          const data_res = response.data.data[0].posts.map((r) => {
            return { ...r, likes: { count: r.likes.length, list: r.likes } };
          });
          if (response.data.data[0].posts.length === 0 && data.page === 1) {
            dispatch({
              type: "ADDPOST",
              payload: { data: [], total: response.data.data[0].totalPosts },
            });
          } else if (
            response.data.data[0].posts.length > 0 &&
            data.page === 1
          ) {
            dispatch({
              type: "FETCHGROUPPOST",
              payload: {
                data: data_res,
                total: response.data.data[0].totalPosts,
              },
            });
          }
        }
      } else {
        dispatch({
          type: "ADDPOST",
          payload: { data: [], total: 0 },
        });
      }
    });
  };
}

export function fetchpostbygroupid_admin(data) {
  return async (dispatch, getState) => {
    await Services.getpostbygroupid(data).then((response) => {
      if (response.data.data.length > 0) {
        if (response.data.data[0].posts.length > 0 && data.page > 1) {
          const data_res = response.data.data[0].posts.map((r) => {
            return { ...r, likes: { count: r.likes.length, list: r.likes } };
          });
          dispatch({
            type: "ADDPOST",
            payload: {
              data: data_res,
              total: response.data.data[0].totalPosts,
            },
          });
        } else {
          const data_res = response.data.data[0].posts.map((r) => {
            return { ...r, likes: { count: r.likes.length, list: r.likes } };
          });
          if (response.data.data[0].posts.length === 0 && data.page === 1) {
            dispatch({
              type: "ADDPOST",
              payload: { data: [], total: response.data.data[0].totalPosts },
            });
          } else if (
            response.data.data[0].posts.length > 0 &&
            data.page === 1
          ) {
            dispatch({
              type: "FETCHGROUPPOST",
              payload: {
                data: data_res,
                total: response.data.data[0].totalPosts,
              },
            });
          }
        }
      } else {
        dispatch({
          type: "ADDPOST",
          payload: { data: [], total: 0 },
        });
      }
    });
  };
}

export function fetchmemberpost(member_uid) {
  return async (dispatch, getState) => {
    await Services.memberposts(member_uid)
      .then((response) => {
        if (response.data.data.length > 0) {
          var arr = [];
          var num = 0;
          for (var i = 0; i < response.data.data.length; i++) {
            for (var n = 0; n < response.data.data[i].length; n++) {
              arr[num] = {
                ...response.data.data[i][n],
                likes: {
                  count: response.data.data[i][n].likes.length,
                  list: response.data.data[i][n].likes,
                },
              };
              num++;
            }
          }
          dispatch({
            type: "ADDMEMBERPOST",
            payload: arr,
          });
        } else {
          dispatch({
            type: "ADDMEMBERPOST",
            payload: [],
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
}
export function fetchmyallpost() {
  return async (dispatch, getState) => {
    await Services.myallpost()
      .then((response) => {
        if (response.data.data.length > 0) {
          var arr = [];
          var num = 0;
          for (var i = 0; i < response.data.data.length; i++) {
            arr[num] = {
              ...response.data.data[i],
              likes: {
                count: response.data.data[i].likes.length,
                list: response.data.data[i].likes,
              },
            };
            num++;
          }
          dispatch({
            type: "ADDMEMBERPOST",
            payload: arr,
          });
        } else {
          dispatch({
            type: "ADDMEMBERPOST",
            payload: [],
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
}

export function fetchmemberprofile(data) {
  return async (dispatch, getState) => {
    await Services.getmemberprofile(data).then((response) => {
      if (response.data.status) {
        dispatch({
          type: "UPDATEPROFILE",
          payload: response.data.data,
        });
      }
    });
  };
}

export function fetchmyaccount() {
  return async (dispatch, getState) => {
    await Services.getmydetail()
      .then((response) => {
        if (response.data.status) {
          dispatch({
            type: "UPDATEPROFILE",
            payload: response.data.data,
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
}
export function fetchmyprofiledetail() {
  return async (dispatch, getState) => {
    await Services.getmydetail()
      .then((response) => {
        if (response.data.status) {
          dispatch({
            type: "UPDATEMYPROFILE",
            payload: response.data.data,
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
}
export function fetchchatmessages(data) {
  return async (dispatch, getState) => {
    if (data.id !== "me" && data.type !== "me") {
      dispatch({
        type: "ASSIGNCONNECTINGMESSAGE",
        payload: "Fetching latest messages...",
      });

      dispatch({
        type: "SETCHATLOADER",
      });
      await Services.chatdetail({
        userid: data.id,
        type: data.type,
        page: data.page,
        limit: data.limit,
      })
        .then((res) => {
          if (res.data.status) {
            // var sortMessage = res.data.data[0].Messages.sort((a, b) => {
            //   if(b.messageTimeStamp && a.messageTimeStamp)
            //   {
            //       console.log(b.messageTimeStamp.localeCompare(a.messageTimeStamp), "compare");
            //       return b.messageTimeStamp.localeCompare(a.messageTimeStamp)
            //   }
            //   else
            //     return a;
            // })
            dispatch({
              type: "FETCHALLMESSAGES",
              payload: { [data.id]: res.data.data[0].Messages },
            });
            dispatch({
              type: "SETTOTALMESSAGES",
              payload: { [data.id]: res.data.data[0].totalMessages },
            });
          } else {
            dispatch({
              type: "FETCHALLMESSAGES",
              payload: { [data.id]: [] },
            });
          }
          dispatch({
            type: "REMOVECHATLOADER",
          });
          dispatch({ type: "ASSIGNCONNECTINGMESSAGE", payload: "" });
        })
        .catch((e) => {
          dispatch({
            type: "REMOVECHATLOADER",
          });
          dispatch({ type: "ASSIGNCONNECTINGMESSAGE", payload: "" });

          console.log(e);
        });
    }
  };
}
export function addchatmessages(data) {
  return async (dispatch, getState) => {
    if (data.id !== "me" && data.type !== "me") {
      dispatch({
        type: "SETCHATMORELOADER",
      });
      await Services.chatdetail({
        userid: data.id,
        type: data.type,
        page: data.page,
        limit: data.limit,
      })
        .then((res) => {
          if (res.data.status) {
            dispatch({
              type: "MDSADDMESSAGE",
              payload: { id: data.id, data: res.data.data[0].Messages },
            });
            dispatch({
              type: "SETTOTALMESSAGES",
              payload: { [data.id]: res.data.data[0].totalMessages },
            });
          }
          dispatch({
            type: "REMOVECHATMORELOADER",
          });
        })
        .catch((e) => {
          dispatch({
            type: "REMOVECHATMORELOADER",
          });
          console.log(e);
        });
    }
  };
}
export function fetchsearchpost(params) {
  return async (dispatch, getState) => {
    await Services.globalsearch(params)
      .then((response) => {
        if (response.data.data.length > 0) {
          var arr = [];
          var num = 0;
          for (var n = 0; n < response.data.data.length; n++) {
            arr[num] = {
              ...response.data.data[n],
              likes: {
                count: response.data.data[n].likes.length,
                list: response.data.data[n].likes,
              },
            };
            num++;
          }
          dispatch({
            type: "ADDSEARCHPOST",
            payload: arr,
          });
        } else {
          dispatch({
            type: "ADDSEARCHPOST",
            payload: [],
          });
        }
      })
      .catch((e) => {
        dispatch({
          type: "ADDSEARCHPOST",
          payload: [],
        });
        console.log(e);
      });
  };
}
export function fetchstarchat() {
  return async (dispatch, getState) => {
    await Services.getstarchatusers()
      .then((res) => {
        if (res.data.status) {
          dispatch({
            type: "FETCHSTARCHAT",
            payload: res.data.data.star_chat,
          });
        } else {
          dispatch({
            type: "FETCHSTARCHAT",
            payload: [],
          });
        }
      })
      .catch((e) => {
        dispatch({
          type: "FETCHSTARCHAT",
          payload: [],
        });
      });
  };
}

export function getMuteChatIdListForUser() {
  return async (dispatch, getState) => {
    await Services.getMuteChatIdListForUser().then((response) => {
      if (response.data.status) {
        dispatch({
          type: "UPDATEMUTESTATE",
          payload: response?.data?.data,
        });
      }
    });
  };
}

export function getAllValidUser() {
  return async (dispatch, getState) => {
    await Services.getAllValidUserAPI().then((response) => {
      if (response?.data?.status) {
        const newFormattedData = getFormatedListForUser(response?.data?.data);
        if (newFormattedData) {
          dispatch({
            type: "SETALLUSERLIST",
            payload: newFormattedData,
          });
        }
      }
    });
  };
}

export const updatestarchat = (data) => {
  return {
    type: "UPDATESTARCHAT",
    payload: data,
  };
};
//set Lists
export const setGroupFileList = (val) => {
  return {
    type: "SETFILELIST",
    payload: val,
  };
};
export const setGroupMediaList = (data) => {
  return {
    type: "SETMEDIALIST",
    payload: data,
  };
};
export const setGroupUrlList = (data) => {
  return {
    type: "SETURLLIST",
    payload: data,
  };
};

//apply filter
export const setFilterGroupFileList = (data) => {
  return {
    type: "SETFILTERFILELIST",
    payload: data,
  };
};
export const setFilterGroupMediaList = (data) => {
  return {
    type: "SETFILTERMEDIALIST",
    payload: data,
  };
};
export const setFilterGroupUrlList = (data) => {
  return {
    type: "SETFILTERURLLIST",
    payload: data,
  };
};

//Add more to list
export const setMoreGroupFileList = (data) => {
  return {
    type: "SETMOREFILELIST",
    payload: data,
  };
};

export const setMoreGroupMediaList = (data) => {
  return {
    type: "SETMOREMEDIALIST",
    payload: data,
  };
};

export const setMoreGroupUrlList = (data) => {
  return {
    type: "SETMOREURLLIST",
    payload: data,
  };
};
//When we get media,files and url in new files
export const setNewMessageFileList = (data) => {
  return {
    type: "SETNEWMSGFILELIST",
    payload: data,
  };
};

export const setNewMessageMediaList = (data) => {
  return {
    type: "SETNEWMSGMEDIALIST",
    payload: data,
  };
};
export const setNewMessageUrlList = (data) => {
  return {
    type: "SETNEWMSGURLLIST",
    payload: data,
  };
};
//clear group media on clear chat
export const clearFileList = (data) => {
  return {
    type: "CLEARFILELIST",
    payload: data,
  };
};

export const clearMediaList = (data) => {
  return {
    type: "CLEARMEDIALIST",
    payload: data,
  };
};
export const clearUrlList = (data) => {
  return {
    type: "CLEARURLLIST",
    payload: data,
  };
};
//delete medialist
export const deleteFileList = (data) => {
  return {
    type: "DELETEFILELIST",
    payload: data,
  };
};

export const deleteMediaList = (data) => {
  return {
    type: "DELETEMEDIALIST",
    payload: data,
  };
};

export const deleteUrlList = (data) => {
  return {
    type: "DELETEURLLIST",
    payload: data,
  };
};

export const notificationlistAction = (data) => {
  return {
    type: "NOTIFICATION_LIST",
    payload: data,
  };
};

export const markNotificationlistAction = (data) => {
  return {
    type: "MARK_NOTIFICATION",
    payload: data,
  };
};

export const getEventParticipantTypesListAction = (data) => {
  return {
    type: "GET_EVENT_PARTICIPANT_TYPE_LIST",
    payload: data,
  };
};

export const toggleMute = () => ({
  type: "TOGGLE_MUTE",
});
