import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import SideBarGrouposAdmin from "../../../../components/Admin/groupos/SideBarGrouposAdmin";
import SubSideBarGrouposAdmin from "../../../../components/Admin/groupos/SubSideBarGrouposAdmin";
import useAuth from "../../../../Hooks/useAuth";
import { grouposAdminScopesList } from "../../../../routes/Admin/groupos/menuList";
import useRefreshNavigation from "../../../../Hooks/useRefreshNavigation";
import { getModuleName } from "../../../../utils";

// Import images
import AdminHeader from "components/Admin/Header";

const AuthenticatedGrouposAdminLayout = () => {
  // console.log("AuthenticatedGrouposAdminLayout------->", window.location.pathname);

  const { auth } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const { refreshScopeActions } = useRefreshNavigation();

  const availableScopes = grouposAdminScopesList.filter((scope) =>
    auth.currentEdge?.AdminScopes.includes(scope.id)
  );

  const [currentScope, setCurrentScope] = useState(availableScopes[0]);

  useEffect(() => {
    const module = getModuleName("/admin/groupos/", location.pathname);
    if (currentScope?.path !== module) {
      setCurrentScope(availableScopes.find((alvS) => alvS.path === module));
    }
    if (sessionStorage.getItem("x-app-redirect")) {
      const redirectPath = sessionStorage.getItem("x-app-redirect");

      // bypass create-community page when logged in as groupos admin
      if (redirectPath !== "/create-community") {
        navigate(sessionStorage.getItem("x-app-redirect"));
      }

      sessionStorage.removeItem("x-app-redirect");
    }
  }, [
    availableScopes,
    currentScope?.path,
    location,
    navigate,
    refreshScopeActions,
  ]);

  return (
    <>
      {/* NAVBAR */}
      <div className="cstm-all-admin">
        <AdminHeader type={auth.currentEdge?.type} />
        <SideBarGrouposAdmin
          scopes={availableScopes}
          setCurrentScope={setCurrentScope}
        />
        <div className="content-main">
          <div className="users-info">
            <SubSideBarGrouposAdmin scope={currentScope} />

            {/* MAIN PAGE AREA */}
            <div className="users-info-content">
              <div className="users-info-content-main">
                <Outlet />
              </div>
            </div>
          </div>
          {/* MAIN PAGE AREA */}
        </div>
      </div>
    </>
  );
};
export default AuthenticatedGrouposAdminLayout;
