import React from "react";
import PropTypes from "prop-types"
function PdfIcon({ height = 24, width = 24, }) {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={width}
                height={height}
                fill="none"
                viewBox="0 0 24 24"
            >
                <path
                    fill="#E5252A"
                    fillRule="evenodd"
                    d="M5.864 1h8.902L21 7.498v12.638A2.861 2.861 0 0 1 18.141 23H5.864A2.862 2.862 0 0 1 3 20.136V3.864A2.862 2.862 0 0 1 5.864 1Z"
                    clipRule="evenodd"
                />
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    d="M14.76 1v6.5H21L14.76 1Z"
                    clipRule="evenodd"
                    opacity={0.3}
                />
                <path
                    fill="#fff"
                    d="M7 17.415v-4.019h1.71c.423 0 .758.116 1.011.352.253.23.38.544.38.935 0 .39-.127.703-.38.934-.253.236-.588.352-1.011.352h-.682v1.446H7Zm1.028-2.32h.566c.154 0 .275-.033.358-.11a.378.378 0 0 0 .126-.302.378.378 0 0 0-.126-.303c-.083-.077-.204-.11-.358-.11h-.566v.825Zm2.496 2.32v-4.019h1.424c.28 0 .544.039.791.121.247.083.473.198.67.357.199.154.358.363.474.627.11.264.17.566.17.907 0 .336-.06.638-.17.902a1.58 1.58 0 0 1-.473.627c-.198.159-.424.274-.67.357-.248.082-.512.12-.793.12h-1.423Zm1.006-.874h.297c.159 0 .307-.017.445-.055.132-.039.258-.1.38-.182a.814.814 0 0 0 .274-.351c.066-.154.099-.336.099-.545 0-.214-.033-.396-.099-.55a.815.815 0 0 0-.275-.351 1.338 1.338 0 0 0-.38-.182 1.647 1.647 0 0 0-.444-.055h-.297v2.27Zm3.04.874v-4.019h2.858v.874h-1.83v.644h1.462v.868h-1.462v1.633H14.57Z"
                />
            </svg>
        </>
    );
}
PdfIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
}
export default PdfIcon;
