// Redux reducer to update chat list data in the state
const initialState = [];
const ReduAllChatListUpdate = (state = initialState, action) => {
  switch (action.type) {
    case "FETCHALLCHATLISTCOUNTFORDOT":
      return action.payload;
    // Handle other actions and state updates as needed
    default:
      return state;
  }
};

export default ReduAllChatListUpdate;
