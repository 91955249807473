import React from 'react';
import PropTypes from 'prop-types';
import "./Typography.css"
import '../../Styles/Theme/Theme.css';
const Typography = ({ variant, children, align }) => {
    const getTag = () => {
        switch (variant) {
            case 'h1':
                return 'h1';
            case 'h2':
                return 'h2';
            case 'h3':
                return 'h3';
            case 'h4':
                return 'h4';
            case 'h5':
                return 'h5';
            case 'h6':
                return 'h6';
            default:
                return 'p';
        }
    };

    const styles = {
        textAlign: align,
    };



    const Tag = getTag();
    return <Tag style={styles}>{children}</Tag>;
};

Typography.propTypes = {
    variant: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'body']),
    align: PropTypes.oneOf(['center', 'inherit', 'justify', 'left', 'right']),
    children: PropTypes.node.isRequired,
};

Typography.defaultProps = {
    variant: 'body',
};

export default Typography;
