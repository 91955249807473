import axios from "axios";
import authHeader from "../Services/auth.header";
import authAdminHeader from './auth.admin.header'
import { CONFIGS } from "config";
//const API_URL = CONFIGS.GATEWAY_DOMAIN + "/api/";
let url = CONFIGS.GATEWAY_DOMAIN;
const host = window.location.host.split(".")[0]
let BASE_URL = url.split("//")[0] + "//" + host + "." + url.split("//")[1];
const API_URL = BASE_URL + "/api/";


// Get
const getNotificationList = (page, limit) => {
  return axios.get(API_URL + `notification/notificationListForUser?page=${page}&limit=${limit}`, { headers: authHeader() })
}

const sentNotificationUserCountList = (id) => {
  return axios.get(API_URL + `notification/sentNotificationUserCountList/${id}`, { headers: authAdminHeader() })
}

const markNotificationRead = (data) => {
  return axios.post(API_URL + 'notification/markNotificationReadOrUnread', data, { headers: authHeader() })
}

const deleteNotificationByUser = (data) => {
  return axios.post(API_URL + 'notification/deleteNotificationByUser', data, { headers: authHeader() })
}

export default {
  getNotificationList,
  markNotificationRead,
  deleteNotificationByUser,
  sentNotificationUserCountList
}
