import React from 'react'
import PropTypes from "prop-types"
const SmallCrossIcon = ({ width = 16, height = 16, iconColor = "#000" }) => {
    return (
        <><svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 16 16"
        >
            <path
                fill={iconColor}
                fillRule="evenodd"
                d="M3.646 3.646a.5.5 0 0 1 .708 0L8 7.293l3.646-3.647a.5.5 0 0 1 .708.708L8.707 8l3.647 3.646a.5.5 0 0 1-.708.708L8 8.707l-3.646 3.647a.5.5 0 0 1-.708-.708L7.293 8 3.646 4.354a.5.5 0 0 1 0-.708Z"
                clipRule="evenodd"
            />
        </svg>
        </>
    )
}
SmallCrossIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    iconColor: PropTypes.string
}
export default SmallCrossIcon