import { useEffect, useState } from "react";
import {
  Link,
  NavLink,
  useNavigate,
  useParams,
  useLocation,
  Navigate,
} from "react-router-dom";
import "./LeftSidePanel.css";
import "./GlobalComponent.css";
import {
  menuArrays,
  eventMenuArray,
  resourceMenuArray,
  directoryMenuArray,
  networkMenuArray,
  programMenuArray,
  infoMenuArray,
} from "./MenusList";

// Icon
import Chat from "../../Images/chat.svg";
import IconSmallLink from "./Icons/IconsGeneralSmall/IconSmallLink";
import IconArrowRightSmall from "./Icons/IconsArrowSmall/IconArrowRightSmall";
import crossIcon from "../../Images/CancelCategoriesPopup.svg";
import { useDispatch, useSelector } from "react-redux";
import Services from "../../Services/auth.service";
import IconNavVideo from "./Icons/IconsNavigation/IconNavVideo";
import IconNavVideoFilled from "./Icons/IconsNavigation/IconNavVideoFilled";

let hoverTimeout;

const LeftSideBarMenu = ({ setShowCategoryMenu }) => {
  const resourceList = localStorage.getItem("accessResources");
  const isCollaborator = JSON.parse(localStorage.getItem("isCollaborator"));
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [loader, setLoader] = useState(true);
  const [subMenuName, setSubMenuName] = useState("");
  const [currentMenuName, setCurrentMenuName] = useState("");
  const [subMenuArray, setSubMenuArray] = useState([]);
  const [videosMenuArray, setVideosMenuArray] = useState([]);
  const pathname = useLocation();
  const [current_path, setcurrent_path] = useState("");
  const search = useLocation().search;
  const [subMenuItemName, setSubMenuItemName] = useState([]);
  const listingtype = new URLSearchParams(search).get("type");
  const [allcategories, setallcategories] = useState([]);
  const categoryId = new URLSearchParams(search).get("catid");
  const [isProcess, setProcess] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentTab, setCuurentTab] = useState("");
  const [context, setContext] = useState({ x: 0, y: 0 });
  const [innerHeight, setInnerHeight] = useState(0);

  const chatListData = useSelector((state) => {
    return state.ReduAllChatList;
  });

  const muteNotificationList = useSelector(
    (state) => state?.muteNotificationReducer
  );

  const countChat = chatListData.reduce((sum, item) => {
    if (!muteNotificationList?.notificationBadgeNotDisplay?.includes(item.id)) {
      let count = item.count ?? 0;
      return sum + count;
    }
    return sum;
  }, 0);

  useEffect(() => {
    let catArr = [];
    catArr.push({
      name: "Library",
      slug: "library",
      to: "/videos?type=all&filter=recent&subfilter=l2h",
      icon: <IconNavVideo />,
      activeIcon: <IconNavVideoFilled />,
      include: "video",
      isTarget: false,
    });

    Services.getallcontentcategories()
      .then((res) => {
        if (res.data.status) {
          setLoader(false);
          const allcats = res.data.data;
          if (allcats.length > 0) {
            allcats.map((cate) => {
              catArr.push({
                name: cate.name,
                slug: cate._id,
                to: `/videos?type=category&filter=recent&catid=${cate._id}&subfilter=l2h`,
                icon: <IconNavVideo />,
                activeIcon: <IconNavVideoFilled />,
                include: "video",
                isTarget: false,
                include: cate._id,
              });
            });
            setVideosMenuArray(catArr);
          }
        } else if (res.data.invalidToken) {
          localStorage.clear();
          history("/");
          setVideosMenuArray([]);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  useEffect(() => {
    currentTab === "videos" && setSubMenuArray(videosMenuArray);
  }, [videosMenuArray]);

  useEffect(() => {
    setcurrent_path(pathname.pathname.split("/")[1]);
  }, [pathname]);

  function getMenuByAccessResource() {
    // Use the filter method to return an array of menu items that match the given accessResource
    const filteredMenu = menuArrays.filter((item) => {
      // Check if the 'name' property exists in accessResources
      return (
        isCollaborator &&
        resourceList !== null &&
        resourceList.length > 0 &&
        JSON.parse(resourceList).some(
          (resource) =>
            resource.name === item.accessResource || item.accessResource === ""
        )
      );
    });

    return filteredMenu;
  }

  const matchingMenus = getMenuByAccessResource();
  const switchSubmenu = (menu) => {
    switch (menu) {
      case "events":
        setSubMenuArray(eventMenuArray);
        break;
      case "resources":
        setSubMenuArray(resourceMenuArray);
        break;
      case "directory":
        setSubMenuArray(directoryMenuArray);
        break;
      case "videos":
        setSubMenuArray(videosMenuArray);
        break;
      case "network":
        setSubMenuArray(networkMenuArray);
        break;
      case "program":
        setSubMenuArray(programMenuArray);
        break;
      case "info":
        setSubMenuArray(infoMenuArray);
        break;
    }
  };
  const handleSetSubmenuPosition = (e) => {
    let id = e.target.id;
    let x = e.clientX > window.innerHeight ? e.clientX + 100 : e.clientX - 100;
    const arr = ["videos", "info", "directory"];
    // let y
    // if (arr.includes(id)) {
    //   y = e.clientY-10
    // } else {
    //   y = e.clientY-10
    // }
    var div = document.getElementById(id);
    var rect = div.getBoundingClientRect();

    console.log("Coordinates: " + rect.left + "px, " + rect.top + "px");

    setContext({ x: x, y: rect.top });
    setInnerHeight(window.innerHeight);
  };
  return (
    <>
      <ul
        className="sidebar-main-ul"
        onMouseLeave={() => {
          hoverTimeout = setTimeout(() => {
            setShowSubMenu(false);
            setSubMenuName("");
          }, 200);
        }}
      >
        {isCollaborator !== null &&
          isCollaborator &&
          resourceList !== null &&
          resourceList.length > 0 &&
          matchingMenus.length > 0
          ? matchingMenus.map(
            (list) =>
              list !== null && (
                <li
                  className={
                    showSubMenu && list.tab === subMenuName
                      ? "sub-menu-open"
                      : ""
                  }
                  onMouseEnter={() => {
                    if (list.include === "frontnews") setCurrentMenuName("");
                    else setCurrentMenuName(list.tab);
                  }}
                >
                  {!list.subMenus ? (
                    window.location.hostname ===
                      "app.milliondollarsellers.com" && list.id === "chat" ? (
                      <></>
                    ) : (
                      <NavLink
                        style={{
                          pointerEvents: `${list.isDisabled === true ? "none" : "pointer"
                            }`,
                        }}
                        onMouseEnter={() => setSubMenuName("")}
                        target={list.isTarget && "_blank"}
                        to={list.to}
                        className={`sidebar-link ${list.include !== undefined &&
                            list.include.includes(location.pathname)
                            ? "active"
                            : ""
                          }`}
                      >
                        <span className="sidebar-icon-img">
                          {list.id === "chat"
                            ? countChat > 0
                              ? list.iconWithBadge
                              : list.icon
                            : list.icon}
                          {list.id === "chat"
                            ? countChat > 0
                              ? list.activeIconWithBadge
                              : list.activeIcon
                            : list.activeIcon}
                        </span>
                        <span className="sidebar-label-name">
                          {list.name}
                        </span>
                        {list.subMenus ? (
                          <div className="cstn-cl-arrow">
                            <IconArrowRightSmall />
                          </div>
                        ) : (
                          ""
                        )}
                      </NavLink>
                    )
                  ) : (
                    <>
                      <a
                        id={list.id}
                        className={`sidebar-link ${list.include !== undefined &&
                            list.include.includes(location.pathname)
                            ? "active"
                            : ""
                          } ${subMenuName === list.tab ? "hovered" : ""}`}
                        onMouseLeave={() => {
                          clearTimeout(hoverTimeout);
                        }}
                        onMouseEnter={(e) => {
                          if (
                            "ontouchstart" in window ||
                            navigator.maxTouchPoints
                          ) {
                            // Code for touch devices (assumed to be mobile devices)
                            console.log(
                              "This is a touch device (possibly a mobile device)"
                            );
                          } else {
                            // Code for devices without touch support (assumed to be desktop devices)
                            hoverTimeout = setTimeout(() => {
                              handleSetSubmenuPosition(e);
                              setCuurentTab(list.tab);
                              setSubMenuName(list.tab);
                              if (list.isTarget && list.tab !== "frontnews") {
                                setShowSubMenu(true);
                              } else {
                                setCuurentTab("");
                                setSubMenuName("");
                              }
                              switchSubmenu(list.tab);
                            }, 200);
                          }
                        }}
                        onClick={(e) => {
                          if (list.tab === "videos") {
                            if (
                              "ontouchstart" in window ||
                              navigator.maxTouchPoints
                            ) {
                              setShowSubMenu(!showSubMenu);
                              setCuurentTab(list.tab);
                              setSubMenuName(list.tab);
                              switchSubmenu(list.tab);
                            } else {
                              navigate(
                                "/videos?type=all&filter=recent&subfilter=l2h"
                              );
                            }
                          } else {
                            handleSetSubmenuPosition(e);
                            setCuurentTab(list.tab);
                            setSubMenuName(list.tab);
                            if (list.isTarget && list.tab !== "frontnews") {
                              setShowSubMenu(!showSubMenu);
                            }
                            switchSubmenu(list.tab);
                          }
                        }}
                      >
                        <span className="sidebar-icon-img">
                          {list.icon}
                          {list.activeIcon}
                          {/* <img src={list.icon} className="sidebar-icon-default" />
                            <img src={list.activeIcon} className="sidebar-icon-active" /> */}
                        </span>
                        <span className="sidebar-label-name">
                          {list.name}
                        </span>
                        {list.isTarget ? (
                          <div className="cstn-cl-arrow">
                            <IconArrowRightSmall />
                          </div>
                        ) : (
                          ""
                        )}
                      </a>
                    </>
                  )}
                  {showSubMenu && list.tab === currentMenuName && (
                    <div className="sidebar-main-all">
                      <ul
                        onMouseEnter={() => clearTimeout(hoverTimeout)}
                        className="sidebar-main-ul-sub scrollbar "
                        style={{
                          top: context?.y ?? "auto",
                          maxHeight: `${innerHeight < 600
                              ? `calc(100vh - ${context?.y ?? 0}px)`
                              : `600px`
                            }`,
                          position: "fixed",
                        }}
                      >
                        {subMenuArray.length > 0 &&
                          subMenuArray.map((subMenuItem) => {
                            return (
                              <li
                                className={
                                  subMenuItemName === subMenuItem.name
                                    ? "active"
                                    : ""
                                }
                              >
                                <Link
                                  className={`${list.tab !== "videos" &&
                                      subMenuItem.include !== undefined &&
                                      location.pathname.split("/")[1] ===
                                      subMenuItem.include
                                      ? "active"
                                      : subMenuItem.include !== undefined &&
                                        subMenuItem.include === categoryId
                                        ? "active"
                                        : ""
                                    }`}
                                  target={subMenuItem.isTarget && "_blank"}
                                  onClick={() => {
                                    setSubMenuItemName(subMenuItem.slug);
                                  }}
                                  to={subMenuItem.to}
                                >
                                  {subMenuItem.name}
                                  {subMenuItem.isTarget && <IconSmallLink />}
                                </Link>
                              </li>
                            );
                          })}
                        {list.tab === "videos" && loader && (
                          <div className="sidebar-main-all-loading">
                            <div className="sidebar-main-all-loading-in skeleton-box"></div>
                            <div className="sidebar-main-all-loading-in skeleton-box"></div>
                            <div className="sidebar-main-all-loading-in skeleton-box"></div>
                            <div className="sidebar-main-all-loading-in skeleton-box"></div>
                            <div className="sidebar-main-all-loading-in skeleton-box"></div>
                            <div className="sidebar-main-all-loading-in skeleton-box"></div>
                          </div>
                        )}
                      </ul>
                    </div>
                  )}
                </li>
              )
          )
          : menuArrays.map(
            (list) =>
              list !== null && (
                <li
                  className={
                    showSubMenu && list.tab === subMenuName
                      ? "sub-menu-open"
                      : ""
                  }
                  onMouseEnter={() => {
                    if (list.include === "frontnews") setCurrentMenuName("");
                    else setCurrentMenuName(list.tab);
                  }}
                >
                  {!list.subMenus ? (
                    // (
                    //   window.location.hostname ===
                    //     "app.milliondollarsellers.com" && list.id === "chat" ? (
                    //     <></>
                    //   ) : (
                    <NavLink
                      style={{
                        pointerEvents: `${list.isDisabled === true ? "none" : "pointer"
                          }`,
                      }}
                      onMouseEnter={() => setSubMenuName("")}
                      target={list.isTarget && "_blank"}
                      to={list.to}
                      className={`sidebar-link ${list.include !== undefined &&
                          list.include.includes(location.pathname)
                          ? "active"
                          : ""
                        }`}
                    >
                      <span className="sidebar-icon-img">
                        {list.id === "chat"
                          ? countChat > 0
                            ? list.iconWithBadge
                            : list.icon
                          : list.icon}
                        {list.id === "chat"
                          ? countChat > 0
                            ? list.activeIconWithBadge
                            : list.activeIcon
                          : list.activeIcon}
                      </span>
                      <span className="sidebar-label-name">{list.name}</span>
                      {list.id === "chat" && (
                        <span className="nav-beta-bedge-chat">Beta</span>
                      )}
                      {list.subMenus ? (
                        <div className="cstn-cl-arrow">
                          <IconArrowRightSmall />
                        </div>
                      ) : (
                        ""
                      )}
                    </NavLink>
                  ) : (
                    //   )
                    // )
                    <>
                      <a
                        id={list.id}
                        className={`sidebar-link ${list.include !== undefined &&
                            list.include.includes(location.pathname)
                            ? "active"
                            : ""
                          } ${subMenuName === list.tab ? "hovered" : ""}`}
                        onMouseLeave={() => {
                          clearTimeout(hoverTimeout);
                        }}
                        onMouseEnter={(e) => {
                          if (
                            "ontouchstart" in window ||
                            navigator.maxTouchPoints
                          ) {
                            // Code for touch devices (assumed to be mobile devices)
                            console.log(
                              "This is a touch device (possibly a mobile device)"
                            );
                          } else {
                            // Code for devices without touch support (assumed to be desktop devices)
                            hoverTimeout = setTimeout(() => {
                              handleSetSubmenuPosition(e);
                              setCuurentTab(list.tab);
                              setSubMenuName(list.tab);
                              if (list.isTarget && list.tab !== "frontnews") {
                                setShowSubMenu(true);
                              }
                              switchSubmenu(list.tab);
                            }, 200);
                          }
                        }}
                        onClick={(e) => {
                          if (list.tab === "videos") {
                            if (
                              "ontouchstart" in window ||
                              navigator.maxTouchPoints
                            ) {
                              setShowSubMenu(!showSubMenu);
                              setCuurentTab(list.tab);
                              setSubMenuName(list.tab);
                              switchSubmenu(list.tab);
                            } else {
                              navigate(
                                "/videos?type=all&filter=recent&subfilter=l2h"
                              );
                            }
                          } else {
                            handleSetSubmenuPosition(e);
                            setCuurentTab(list.tab);
                            setSubMenuName(list.tab);
                            if (list.isTarget && list.tab !== "frontnews") {
                              setShowSubMenu(!showSubMenu);
                            }
                            switchSubmenu(list.tab);
                          }
                        }}
                      >
                        <span className="sidebar-icon-img">
                          {list.icon}
                          {list.activeIcon}
                        </span>
                        <span className="sidebar-label-name">
                          {list.name}
                        </span>
                        {list.isTarget ? (
                          <div className="cstn-cl-arrow">
                            <IconArrowRightSmall />
                          </div>
                        ) : (
                          ""
                        )}
                      </a>
                    </>
                  )}
                  {showSubMenu && list.tab === currentMenuName && (
                    <div className="sidebar-main-all">
                      <ul
                        onMouseEnter={() => clearTimeout(hoverTimeout)}
                        className="sidebar-main-ul-sub scrollbar "
                        style={{
                          top: context?.y ?? "auto",
                          maxHeight: `${innerHeight < 600
                              ? `calc(100vh - ${context?.y ?? 0}px)`
                              : `600px`
                            }`,
                          position: "fixed",
                        }}
                      >
                        {subMenuArray.length > 0 &&
                          subMenuArray.map((subMenuItem) => {
                            return (
                              <li
                                className={
                                  subMenuItemName === subMenuItem.name
                                    ? "active"
                                    : ""
                                }
                              >
                                <Link
                                  className={`${list.tab !== "videos" &&
                                      subMenuItem.include !== undefined &&
                                      location.pathname.split("/")[1] ===
                                      subMenuItem.include
                                      ? "active"
                                      : subMenuItem.include !== undefined &&
                                        subMenuItem.include === categoryId
                                        ? "active"
                                        : ""
                                    }`}
                                  target={subMenuItem.isTarget && "_blank"}
                                  onClick={() => {
                                    setSubMenuItemName(subMenuItem.slug);
                                  }}
                                  to={subMenuItem.to}
                                >
                                  {subMenuItem.name}
                                  {subMenuItem.isTarget && <IconSmallLink />}
                                </Link>
                              </li>
                            );
                          })}
                        {list.tab === "videos" && loader && (
                          <div className="sidebar-main-all-loading">
                            <div className="sidebar-main-all-loading-in skeleton-box"></div>
                            <div className="sidebar-main-all-loading-in skeleton-box"></div>
                            <div className="sidebar-main-all-loading-in skeleton-box"></div>
                            <div className="sidebar-main-all-loading-in skeleton-box"></div>
                            <div className="sidebar-main-all-loading-in skeleton-box"></div>
                            <div className="sidebar-main-all-loading-in skeleton-box"></div>
                          </div>
                        )}
                      </ul>
                    </div>
                  )}
                </li>
              )
          )}
      </ul>
    </>
  );
};
export default LeftSideBarMenu;
