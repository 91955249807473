const allchatlist = [];

const ReduAllChatList = (state = allchatlist, action) => {
  switch (action.type) {
    case "FETCHALLCHATLIST":
      if (action.payload && action.payload.length > 0) {
        let arr = [...action.payload].sort((a, b) => {
          if (b.userTimeStamp && a.userTimeStamp) {
            return b.userTimeStamp.localeCompare(a.userTimeStamp);
          } else if (a.userTimeStamp) return a;
          else if (b.userTimeStamp) return b;
          else return a;
        });

        arr.forEach((item, index, array) => {
          let type = item.type === "airtable-syncs" ? "user" : item.type;
          if (item.receiverId) {
            //fisrt name
            let firstname = "",
              senderFirstName = "";
            let image = "",
              senderImage = "";
            senderImage = item?.senderId?.profileImg ?? "";
            let senderFirstnameUser = item?.senderId?.first_name
              ? item?.senderId?.first_name
              : "";
            let senderLastnameUser = item?.senderId?.last_name
              ? item.senderId.last_name
              : "";
            senderFirstName = senderFirstnameUser.length
              ? senderFirstnameUser + " " + senderLastnameUser
              : item?.senderId?.attendeeDetail?.name;
            if (type === "user") {
              image = item?.receiverId?.profileImg ?? "";
              let firstnameUser = item?.receiverId?.first_name
                ? item?.receiverId?.first_name
                : "";
              let lastnameUser = item?.receiverId?.last_name
                ? item?.receiverId?.last_name
                : "";
              firstname =
                item?.receiverId?.last_name || item?.receiverId?.first_name
                  ? firstnameUser
                    ? firstnameUser + " " + lastnameUser
                    : item?.receiverId?.attendeeDetail?.name
                  : item?.receiverId?.attendeeDetail?.name;
            } else if (type === "chatChannel") {
              firstname = item.receiverId?.channelName ?? "";
              image = item?.receiverId?.channelIcon ?? "";
            } else if (type === "userChatGroup") {
              firstname = item?.receiverId?.groupTitle ?? "";
              image = item?.receiverId?.groupImage ?? "";
            }
            //media-otherfiles logic
            let messageType = item.messageType;
            let image_video = [];
            let otherfiles = [];
            let message = item?.lastMessage ?? "";
            if (messageType === "media") {
              message = "";
              image_video.push(item?.lastMessage ?? "");
            } else if (messageType === "file") {
              otherfiles.push(item?.lastMessage ?? "");
              message = "";
            }
            //assigning the value
            array[index] = {
              ...item,
              id: item?.receiverId ? item?.receiverId?._id : item?.receiverId,
              type,
              firstname,
              image,
              message,
              unreadMsg: item?.count ?? null,
              useronline: item?.offlineOnline ?? "",
              image_video,
              otherfiles,
              sender: {
                id: item?.senderId?._id,
                firstname: senderFirstName,
                image: senderImage,
              },
            };
          }
        });
        return arr;
      } else {
        return state;
      }
    case "CLEARCHATFROMLIST":
      let arr = [
        ...state.map((chatlist) => {
          if (chatlist.id === action.payload)
            return {
              ...chatlist,
              message: "",
              image: "",
              time: "",
              image_video: [],
              otherfiles: [],
              unreadMsg: 0,
              isMention: false,
              activity_status: false,
              activity: {},
            };
          else return chatlist;
        }),
      ];
      arr.sort(function (a, b) {
        if (b.userTimeStamp && a.userTimeStamp) {
          return b.userTimeStamp.localeCompare(a.userTimeStamp);
        } else return a;
      });
      return arr;
    case "SETLASTMESSAGE":
      let messageData = action.payload.message;
      let userId = action.payload.userId;
      let currentPage = action.payload.currentPage;
      let senderName = messageData.sender?.firstname;
      let senderImg = messageData.sender?.image;
      let formattedLastMessage = {
        id:
          messageData?.recipient?.id !== userId
            ? messageData?.recipient?.id
            : messageData?.sender?.id,
        messageType: messageData.message_type,
        lastMessage: messageData.message,
        firstname: messageData.recipient.firstname,
        message: messageData.message,
        userTimeStamp: messageData.userTimeStamp,
        taggedUserId: messageData.taggedUserId,
        type: messageData.type,
        count: currentPage ? 0 : messageData.sender.id !== userId ? 1 : 0,
        unreadMsg: currentPage ? 0 : messageData.sender.id !== userId ? 1 : 0,
        useronline: true,
        offlineOnline: true,
        sender: {
          id: messageData.sender?.id,
          firstname: senderName,
          image: senderImg,
        },
        activity_status: messageData.activity_status,
        activity: messageData.activity,
        isMention: messageData.taggedUserId?.filter(
          (user) => user._id === userId
        ).length
          ? true
          : false,
      };
      let arrary = [...state];
      let isExistingChat = false;
      if (formattedLastMessage) {
        arrary = arrary.map((item) => {
          if (item.id === formattedLastMessage.id) {
            isExistingChat = true;
            return {
              ...item,
              ...formattedLastMessage,
              count: currentPage
                ? 0
                : messageData.sender.id !== userId
                ? item.count
                  ? item.count + 1
                  : 1
                : item.count,
              unreadMsg: currentPage
                ? 0
                : messageData.sender.id !== userId
                ? item.unreadMsg + 1
                : item.unreadMsg,
              isMention: formattedLastMessage.isMention ?? item.isMention,
            };
            // return getStructuredFormat(formattedLastMessage);
          } else {
            return item;
          }
        });
        if (!isExistingChat) {
          arrary.push(formattedLastMessage);
        }
        arrary.sort((a, b) => {
          if (b.userTimeStamp && a.userTimeStamp) {
            return b.userTimeStamp.localeCompare(a.userTimeStamp);
          } else return a;
        });
      }
      return arrary;
    case "SETLASTMESSAGEOFCHAT":
      let item = action.payload;
      let type = item.type === "airtable-syncs" ? "user" : item.type;
      //fisrt name
      let firstname = "",
        senderFirstName = "";
      let image = "",
        senderImage = "";
      senderImage = item?.senderId?.profileImg ?? "";
      let senderFirstnameUser = item?.senderId?.first_name
        ? item?.senderId?.first_name
        : "";
      let senderLastnameUser = item?.senderId?.last_name
        ? item?.senderId?.last_name
        : "";
      senderFirstName = senderFirstnameUser.length
        ? senderFirstnameUser + " " + senderLastnameUser
        : item?.senderId?.attendeeDetail?.name;

      if (type === "user") {
        image = item?.receiverId?.profileImg ?? "";
        let firstnameUser = item?.receiverId?.first_name
          ? item?.receiverId?.first_name
          : "";
        let lastnameUser = item?.receiverId?.last_name
          ? item?.receiverId?.last_name
          : "";
        firstname =
          item?.receiverId?.first_name || item?.receiverId?.last_name
            ? firstnameUser + " " + lastnameUser
            : item?.receiverId?.attendeeDetail?.name;
      } else if (type === "chatChannel") {
        firstname = item.receiverId?.channelName ?? "";
        image = item?.receiverId?.channelIcon ?? "";
      } else if (type === "userChatGroup") {
        firstname = item?.receiverId?.groupTitle ?? "";
        image = item?.receiverId?.groupImage ?? "";
      }

      //media-otherfiles logic
      let messageType = item.messageType;
      let image_video = [];
      let otherfiles = [];
      let message = item?.lastMessage ?? "";
      if (messageType === "media") {
        message = "";
        image_video.push(item?.lastMessage ?? "");
      } else if (messageType === "file") {
        otherfiles.push(item?.lastMessage ?? "");
        message = "";
      }
      //assigning the value
      let formattedData = {
        ...item,
        id: item.receiverId ? item.receiverId._id : item.receiverId,
        type,
        firstname,
        image,
        message,
        unreadMsg: item?.count ?? null,
        useronline: item?.offlineOnline ?? "",
        image_video,
        otherfiles,
        sender: {
          id: item?.senderId?._id,
          firstname: senderFirstName,
          image: senderImage,
        },
        activity_status: item?.activity_status,
        activity: item?.activity,
        isMention: item?.isMention,
        count: item?.count,
      };

      let chatListArray = [];
      for (let index = 0; index < state.length; index++) {
        if (state[index].id === formattedData.id) {
          chatListArray.push(formattedData);
        } else {
          chatListArray.push(state[index]);
        }
      }

      chatListArray.sort((a, b) => {
        if (b.userTimeStamp && a.userTimeStamp) {
          return b.userTimeStamp.localeCompare(a.userTimeStamp);
        } else return a;
      });
      return chatListArray;
    case "READMESSAGE":
      let list = state.map((data) => {
        if (data.id === action.payload) {
          return { ...data, count: 0, unreadMsg: 0, isMention: false };
        } else return data;
      });
      return list;
    case "USERONLINE":
      let chatlistonline = state.map((data) => {
        if (data.id === action.payload) {
          return { ...data, offlineOnline: true, useronline: true };
        } else return data;
      });
      return chatlistonline;
    case "USEROFFLINE":
      let chatlistoffline = state.map((data) => {
        if (data.id === action.payload) {
          return { ...data, offlineOnline: false, useronline: false };
        } else return data;
      });
      return chatlistoffline;
    case "DELETECHATFROMLIST":
      let delarr = state.filter((chatlist) => {
        if (chatlist.id !== action.payload) return chatlist;
      });
      delarr.sort(function (a, b) {
        if (b.userTimeStamp && a.userTimeStamp) {
          return b.userTimeStamp.localeCompare(a.userTimeStamp);
        } else return a;
      });
      return delarr;
    case "EDITMESSAGECHATLIST":
      return state.map((msg) => {
        if (msg.id === action.payload.id) {
          return {
            ...msg,
            lastMessage: action.payload.message,
            taggedUserId: action.payload.taggedUserId,
          };
        } else {
          return msg;
        }
      });
    default:
      return state;
  }
};

export default ReduAllChatList;
