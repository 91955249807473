import React from 'react'
import PropTypes from "prop-types"
const FileIcon = ({ width = 24, height = 24, iconColor = "#000" }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 24 24"
        >
            <path
                fill={iconColor}
                fillRule="evenodd"
                d="M4.5 4.75A2.25 2.25 0 0 1 6.75 2.5H14c.052 0-.051.002 0 .005V5.25A3.75 3.75 0 0 0 17.75 9h1.75v10.25a2.25 2.25 0 0 1-2.25 2.25H6.75a2.25 2.25 0 0 1-2.25-2.25V4.75ZM19.06 7.5c-.026-.035.082.104 0 0L15.5 3.226c-.024-.029.025.027 0 0V5.25a2.25 2.25 0 0 0 2.25 2.25h1.31ZM6.75 1A3.75 3.75 0 0 0 3 4.75v14.5A3.75 3.75 0 0 0 6.75 23h10.5A3.75 3.75 0 0 0 21 19.25V8.836a3.75 3.75 0 0 0-.87-2.4l-3.404-4.087A3.75 3.75 0 0 0 13.846 1H6.75Z"
                clipRule="evenodd"
            />
        </svg>
    )
}
FileIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    iconColor: PropTypes.string
}
export default FileIcon