import React from 'react'
import PropTypes from "prop-types"
const NavEventIcon = ({ height = 24, width = 24, iconColor = "#000", isFilled }) => {
    return (
        <>
            {isFilled ?
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={width}
                    height={height}
                    fill="none"
                    viewBox="0 0 24 24"
                >
                    <path
                        fill={iconColor}
                        fillRule="evenodd"
                        d="M16 1.25a.75.75 0 0 1 .75.75v3a.75.75 0 0 1-1.5 0V2a.75.75 0 0 1 .75-.75Zm2 1.957c2.308.653 4 2.775 4 5.293v8a5.5 5.5 0 0 1-5.5 5.5h-9A5.5 5.5 0 0 1 2 16.5v-8a5.502 5.502 0 0 1 4-5.293V5a2 2 0 1 0 4 0V3h4v2a2 2 0 1 0 4 0V3.207Zm-.47 6.263a.75.75 0 0 1 0 1.06l-5.793 5.793a1.75 1.75 0 0 1-2.474 0L7.47 14.53a.75.75 0 1 1 1.06-1.06l1.793 1.793a.25.25 0 0 0 .354 0L16.47 9.47a.75.75 0 0 1 1.06 0ZM8.75 2a.75.75 0 0 0-1.5 0v3a.75.75 0 0 0 1.5 0V2Z"
                        clipRule="evenodd"
                    />

                </svg > : <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={width}
                    height={height}
                    fill="none"
                    viewBox="0 0 24 24"
                >
                    <path
                        fill={iconColor}
                        fillRule="evenodd"
                        d="M17.53 9.47a.75.75 0 0 1 0 1.06l-5.793 5.793a1.75 1.75 0 0 1-2.474 0L7.47 14.53a.75.75 0 1 1 1.06-1.06l1.793 1.793a.25.25 0 0 0 .354 0L16.47 9.47a.75.75 0 0 1 1.06 0Z"
                        clipRule="evenodd"
                    />
                    <path
                        fill={iconColor}
                        fillRule="evenodd"
                        d="M6 3.207A5.502 5.502 0 0 0 2 8.5v8A5.5 5.5 0 0 0 7.5 22h9a5.5 5.5 0 0 0 5.5-5.5v-8a5.502 5.502 0 0 0-4-5.293v1.584A4.001 4.001 0 0 1 20.5 8.5v8a4 4 0 0 1-4 4h-9a4 4 0 0 1-4-4v-8A4.001 4.001 0 0 1 6 4.79V3.208ZM10 4.5h4V3h-4v1.5Z"
                        clipRule="evenodd"
                    />
                    <path
                        fill={iconColor}
                        fillRule="evenodd"
                        d="M16 1.25a.75.75 0 0 1 .75.75v3a.75.75 0 0 1-1.5 0V2a.75.75 0 0 1 .75-.75ZM8 1.25a.75.75 0 0 1 .75.75v3a.75.75 0 0 1-1.5 0V2A.75.75 0 0 1 8 1.25Z"
                        clipRule="evenodd"
                    />
                </svg>
            }
        </>
    )
}
NavEventIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    iconColor: PropTypes.string,
    isFilled: PropTypes.bool
}
export default NavEventIcon