const userId = null;
const ReduUserId = (state = userId, action) => {
  switch (action.type) {
    case "SETUSERID":
      return action.payload;
    default:
      return state;
  }
};

export default ReduUserId;