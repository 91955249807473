import React from "react";
import { Outlet } from "react-router-dom";

// Import Images
import Header from "./components/Header";
import { Box } from "@mui/material";

const PublicLayout = () => {
  // console.log("PublicLayout------->", window.location.pathname);

  return (
    <>
      <Header />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          gap: "48px",
        }}
      >
        <Box className="collaborator-info-registration">
          <Outlet />
        </Box>
      </Box>
    </>
  );
};
export default PublicLayout;
