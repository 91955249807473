import { ApiRoutes } from "./apiRoutes";
import createApiInstance from "./createApiInstance";

export const extendedApi = createApiInstance.injectEndpoints({
  endpoints: (builder) => ({
    getTiers: builder.query({
      query: (params) => {
        return {
          url: ApiRoutes.tiers.getTiers.url,
          method: ApiRoutes.tiers.getTiers.method,
          params,
        };
      },
      providesTags: ["Tiers"],
      transformResponse: (response) => response,
    }),
    getTier: builder.query({
      query(tier_id) {
        return {
          url: ApiRoutes.tiers.getTier.url.replace(":tier_id", tier_id),
          method: ApiRoutes.tiers.getTier.method,
          headers: { "Content-type": "application/json; charset=UTF-8" },
        };
      },
      providesTags: ["Tier"],
      transformResponse: (response) => response,
    }),
    addTier: builder.mutation({
      query(body) {
        return {
          url: ApiRoutes.tiers.addTier.url,
          method: ApiRoutes.tiers.addTier.method,
          body,
        };
      },
      invalidatesTags: ["Tiers"],
      transformResponse: (response) => response,
    }),
    editTier: builder.mutation({
      query({ tier_id, body }) {
        return {
          url: ApiRoutes.tiers.editTier.url.replace(":tier_id", tier_id),
          method: ApiRoutes.tiers.editTier.method,
          body,
        };
      },
      invalidatesTags: ["Tier", "Tiers"],
      transformResponse: (response) => response,
    }),
    removeTier: builder.mutation({
      query({ tier_id, params }) {
        return {
          url: ApiRoutes.tiers.removeTier.url.replace(":tier_id", tier_id),
          method: ApiRoutes.tiers.removeTier.method,
          params: params ? params : "",
        };
      },
      invalidatesTags: ["Tiers"],
      transformResponse: (response) => response,
    }),
    getPopularTiers: builder.query({
      query(params) {
        return {
          url: ApiRoutes.tiers.getPopularTiers.url,
          method: ApiRoutes.tiers.getPopularTiers.method,
          params
        };
      },
      providesTags: ["Tiers"],
      transformResponse: (response) => response,
    }),
    getTierByName: builder.query({
      query(params) {
        return {
          url: ApiRoutes.tiers.tiersByName.url,
          method: ApiRoutes.tiers.tiersByName.method,
          params
        }
      },
      transformResponse: (response) => response
    })
  }),
  overrideExisting: false,
});

export const {
  useGetTiersQuery,
  useLazyGetTiersQuery,
  useGetTierQuery,
  useAddTierMutation,
  useEditTierMutation,
  useRemoveTierMutation,
  useGetPopularTiersQuery,
  useLazyGetPopularTiersQuery,
  useGetTierByNameQuery,
  useLazyGetTierByNameQuery
} = extendedApi;
