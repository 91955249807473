import React from "react";

const DocumentIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      className="admin-left-icons"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.5 3.5H6.5C4.84315 3.5 3.5 4.84315 3.5 6.5V17.5C3.5 19.1569 4.84315 20.5 6.5 20.5H17.5C19.1569 20.5 20.5 19.1569 20.5 17.5V6.5C20.5 4.84315 19.1569 3.5 17.5 3.5ZM6.5 2C4.01472 2 2 4.01472 2 6.5V17.5C2 19.9853 4.01472 22 6.5 22H17.5C19.9853 22 22 19.9853 22 17.5V6.5C22 4.01472 19.9853 2 17.5 2H6.5Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.25 12C7.25 11.5858 7.58579 11.25 8 11.25H13C13.4142 11.25 13.75 11.5858 13.75 12C13.75 12.4142 13.4142 12.75 13 12.75H8C7.58579 12.75 7.25 12.4142 7.25 12Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.25 15C7.25 14.5858 7.58579 14.25 8 14.25H15C15.4142 14.25 15.75 14.5858 15.75 15C15.75 15.4142 15.4142 15.75 15 15.75H8C7.58579 15.75 7.25 15.4142 7.25 15Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.25 9C7.25 8.58579 7.58579 8.25 8 8.25H15C15.4142 8.25 15.75 8.58579 15.75 9C15.75 9.41421 15.4142 9.75 15 9.75H8C7.58579 9.75 7.25 9.41421 7.25 9Z"
      />
    </svg>
  );
};

export default DocumentIcon;
