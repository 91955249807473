import useAuth from "Hooks/useAuth";
import React, { createContext, useEffect, useState } from "react";
import socketIOClient from "socket.io-client";
import Cookies from "js-cookie";

const SocketContext = createContext({});

export const SocketProvider = ({ children }) => {
  const { auth } = useAuth();
  const [socket, setSocket] = useState(null);
  useEffect(() => {
    // Establish the socket connection
    let newSocket = null;
    const idtoken = Cookies.get("x-app-token");

    const headers = {
      Authorization: idtoken,
      CustomHeader: "CustomValue",
    };
    if (!socket && auth.userId && auth.currentEdge) {
      const nameSpace = auth.currentEdge?.relation_id?._id;
      const socketUrl = `${process.env.REACT_APP_CHAT_BASE_SOCKET_URL}`;
      newSocket = socketIOClient(socketUrl + nameSpace, {
        path: "/socket-new",
        transports: ["websocket"],
        auth: headers,
      });

      // Set the socket instance in state
    }
    setSocket(newSocket);

    // Cleanup function to close the socket connection when component unmounts
    return () => {
      if (newSocket) {
        newSocket.close();
      }
    };
  }, [auth.userId, setSocket, auth.currentEdge]);
  return (
    <SocketContext.Provider value={{ socket, setSocket }}>
      {children}
    </SocketContext.Provider>
  );
};

export default SocketContext;
