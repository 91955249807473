import React from "react";

const IconArrowRightSmall = () => {
  return (
    <div className="mds-icons">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        fill="none"
      >
        <path
          d="M5.862 2.862a.667.667 0 0 0 0 .943L10.057 8l-4.195 4.195a.667.667 0 1 0 .943.943L11.943 8 6.805 2.862a.667.667 0 0 0-.943 0Z"
        />
      </svg>
    </div>
  );
};

export default IconArrowRightSmall;
