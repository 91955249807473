import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Searchmain from "../../../Images/search.svg";
import {
  Avatar,
  Box,
  Button,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { useLazyGetCommunitiesQuery } from "Services/communities";
import LOGO from "Assets/groupos-logo.svg";

const Header = () => {
  const navigate = useNavigate();
  const [suggestionBox, setSuggestionBox] = useState(false);
  const [communities, { data: communitiesList }] = useLazyGetCommunitiesQuery();
  const [searchText, setSearchText] = useState("");

  const timer = useRef(null);
  const handleSearchChange = (e) => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    setSearchText(e.target.value);
    if (e.target.value) {
      timer.current = setTimeout(() => {
        communities({
          page: 1,
          limit: 20,
          expand: true,
          public: true,
          query: e.target.value,
        });
        setSuggestionBox(true);
      }, 500);
    } else {
      setSuggestionBox(false);
    }
  };

  const onKeyUp = (e) => {
    if (e.key === "Enter") {
      navigate(`/discover?search=${e.target.value}`);
      setSearchText("");
      setSuggestionBox(false);
    }
  };

  const onNavigateToInfo = (search) => {
    navigate(`/${search}`);
    setSearchText("");
    setSuggestionBox(false);
  };
  return (
    <>
      <Box
        className="header-collaborator"
        sx={{
          position: "relative",
          boxShadow: "0 2px 4px 0 rgba(0,0,0,.2)",
          padding: "18px 12px",
          margin: "0",
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Link to="/">
            <img src={LOGO} alt="logo" width={"50px"} height={"50px"} />
          </Link>
          <Stack direction="row" alignItems="baseline">
            <Box className={`hf-d`}>
              <Box
                className="header-ft-search-search"
                sx={{
                  width: "400px",
                  "& .header-search-main-icon": {
                    position: "absolute",
                    top: "50%",
                    right: "15px",
                    transform: "translateY(-50%)",
                  },
                }}
              >
                <input
                  type="text"
                  onChange={handleSearchChange}
                  value={searchText}
                  placeholder="Search communities"
                  onKeyUp={onKeyUp}
                  // onBlur={() => setSuggestionBox(false)}
                />
                <img
                  className="header-search-main-icon"
                  src={Searchmain}
                  alt="Searchmain"
                />
              </Box>
              <Box
                sx={{
                  position: "absolute",
                  backgroundColor: "#FFF",
                  marginLeft: "30px",
                  boxShadow:
                  "rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px",
                }}
              >
                {suggestionBox &&
                  communitiesList?.data.length > 0 &&
                  communitiesList?.data.map((search) => (
                    <MenuItem                       sx={{
                        width: "360px",
                        // backgroundColor: "rgb(255, 255, 255)",
                        overflow: "hidden auto",

                      }}
                      onMouseDown={() => onNavigateToInfo(search.nickname)}
                    >
                      <Stack
                        direction="row"
                        alignItems="center"
                        width="100%"
                      >
                        <Avatar src={search.logo} />
                        <Typography marginLeft="10px">{search.name}</Typography>
                      </Stack>
                    </MenuItem>
                  ))}
              </Box>
            </Box>
            <Box className="header-ft-menu">
              <Button onClick={() => navigate("/pricing")}>Pricing</Button>
              <Button onClick={() => navigate("/login")}>Login</Button>
            </Box>
          </Stack>
        </Stack>
      </Box>
    </>
  );
};

export default Header;
