import React from "react";
import { Link, NavLink } from "react-router-dom";

const Sidebar = ({ scopes }) => {
  return (
    <div className="cstm-ac-se-nav">
      <ul>
        {scopes.map((scope) => {
          return (
            <li key={scope.id}>
              <NavLink to={`/settings/${scope.path}`}>
                <span className="sidebar-label-name">{scope.name}</span>
              </NavLink>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Sidebar;
