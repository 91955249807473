// Icons
import KeyIcon from "@mui/icons-material/Key";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import PersonIcon from "@mui/icons-material/Person";
import InsightsIcon from "@mui/icons-material/Insights";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import StarsIcon from "@mui/icons-material/Stars";
import LoginIcon from "@mui/icons-material/Login";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
// Icons
import ArticleIcon from "@mui/icons-material/Article";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import CalendarEventFillIcon from "remixicon-react/CalendarEventFillIcon";
import Chat4FillIcon from "remixicon-react/Chat4FillIcon";
import PartnerIcon from "./components/PartnerIcon";
import DocumentIcon from "./components/DocumentIcon";
import ImageFillIcon from "remixicon-react/ImageFillIcon";
import GroupFillIcon from "remixicon-react/GroupFillIcon";
import BarChartBoxFillIcon from "remixicon-react/BarChartBoxFillIcon";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import NotificationIcon from "components/Icons/GeneralIcons/NotificationIcon";
// routes
export const ADMIN_SCOPE_ROUTES = {
  profile: {
    basePath: "profile",
    actionPath: {
      info: "info",
      edit: "edit/:profile_id",
    },
  },
  edgeLogin: {
    basePath: "edge-login",
    actionPath: {
      list: "",
    },
  },
  discover: {
    basePath: "discover",
    actionPath: {
      list: "discover-communities",
      nickname: ":community_name",
    },
  },
  communityProfile: {
    basePath: "community",
    actionPath: {
      dashboard: "dashboard",
      info: "info",
      subscription: "subscription",
      payouts: "payouts"
    },
  },
  roles: {
    basePath: "roles",
    actionPath: {
      list: "list",
      create: "create",
      edit: "edit/:role_id",
    },
  },
  tiers: {
    basePath: "tiers",
    actionPath: {
      list: "list",
      create: "create",
      edit: "edit/:tier_id",
      viewTiers: "view-tiers",
    },
  },
  subscriptionSubmissions: {
    basePath: "subscription-submissions",
    actionPath: {
      list: "list",
      view: "view/:subscription_submission_id",
    },
  },
  communityMembers: {
    basePath: "community-members",
    actionPath: {
      list: "list",
      subscriptions: "subscriptions",
      view: "view/:member_id",
      invite: "invite"
    },
  },
  applicationForms: {
    basePath: "application-forms",
    actionPath: {
      list: "list",
      view: "view/:application_form_id",
      create: "create",
      edit: "edit/:application_form_id",
    },
  },
  subscriptionCanceled: {
    basePath: "subscription-canceled",
    actionPath: {},
  },
  subscriptionSuccessful: {
    basePath: "subscription-successful",
    actionPath: {},
  },
  submitApplicationForm: {
    basePath: "submit-application-form/:application_form_id",
    actionPath: {},
  },
  communityOwners: {
    basePath: "owners",
    actionPath: {
      list: "list",
      create: "create",
      edit: "edit/:community_owner_id",
    },
  },
  reminder: {
    basePath: "reminder",
    actionPath: {
      list: "",
    },
  },
  community: {
    basePath: ":community_name",
  },
};

export const SCOPE_ROUTES = {
  users: {
    basePath: "user",
    actionPath: {
      registeredUsers: "listing",
      allUsers: "attendees",
      partners: "partnerlist",
      blockedUsers: "blockuserslist",
      registrationForm: "customregisterform",
      questions: "questions",
      deleteRequestUsers: "deleterequestusers",
    },
  },
  events: {
    basePath: "events",
    actionPath: {
      list: "list",
      create: "createEvent",
      type: "eventtype",
      edit: "detail",
      categoeis: "categories"
    },
  },
  channel: {
    basePath: "channel",
    actionPath: {
      list: "channelListing",
      create: "createChannel",
      edit: "detail",
    },
  },
  news: {
    basePath: "news",
    actionPath: {
      banners: "banners",
      posts: "posts",
      news: "news",
      content: "content",
    },
  },
  partner: {
    basePath: "partner",
    actionPath: {
      list: "list",
      create: "createpartner",
      edit: "detail",
      featuredlist: "featured",
      freshdeals: "freshdeals",
      reason: "reason",
      reviews: "reviews",
      badges: "badges",
      maincontent: "maincontent",
      partnercategories: "partnercategories",
      partnerstatistics: "partnerstatistics",
    },
  },
  document: {
    basePath: "document",
    actionPath: {
      list: "list",
      create: "createdocument",
      edit: "detail",
      categories: "documentcategories",
      marcros: "marcros",
      contenttypes: "contenttypes",
      userdownloads: "userdownloads"
    },
  },
  media: {
    basePath: "media",
    actionPath: {},
  },
  membership: {
    basePath: "membership",
    actionPath: {
      list: "list",
      create: "create",
      edit: "detail",
      invite: "invite",
      invitelist: "invitelist",
    },
  },
  notification: {
    basePath: "notification",
    actionPath: {
      list: "list",
      create: "createNotification",
      edit: "detail",
    },
  },
  group: {
    basePath: "group",
    actionPath: {
      list: "list",
      create: "create",
      edit: "edit/:group_id",
    },
  },
  adminmanagement: {
    basePath: "adminmanagement",
    actionPath: {
      list: "list",
      create: "create",
      edit: "edit",
    },
  },
  videos: {
    basePath: "contentlibrary",
    actionPath: {
      list: "listing",
      create: "add",
      detail: "detail/:videoid/:sec",
      edit: "edit/:videoid",
      deletedvideo: "deletedvideo",
      videoevent: "videoevent",
      categories: "videocategories",
    },
  },
  statistic: {
    basePath: "statistic",
    actionPath: {
      videostatistic: "videostatistic",
      categorystatistic: "categorystatistic",
      speakerstatistic: "speakerstatistic",
      tagstatistic: "tagstatistic",
      eventstatistic: "eventstatistic",
      userstatistic: "userstatistic",
    },
  },
  tags: {
    basePath: "tags",
    actionPath: {
      list: "tags"
    },
  },
  messagereport: {
    basePath: "messagereport",
    actionPath: {},
  },
  notifications: {
    basePath: "notification",
    actionPath: {
      list: "list",
      create: "createNotification",
      edit: "detail"
    }
  }
};

export const scopesList = [
  {
    id: "events",
    name: "Events",
    path: SCOPE_ROUTES.events.basePath,
    icon: <CalendarEventFillIcon size="24px" className="admin-left-icons" />,
    actions: [
      {
        name: "Create event",
        path: SCOPE_ROUTES.events.actionPath.create,
        subId: "Create_event"
      },
      {
        name: "All events",
        path: SCOPE_ROUTES.events.actionPath.list,
        subId: "All_events"
      },
      {
        name: "Event types",
        path: SCOPE_ROUTES.events.actionPath.type,
        subId: "Event_types"
      },
      {
        name: "Categories",
        path: SCOPE_ROUTES.events.actionPath.categoeis,
        subId: "Categories"
      },
    ],
  },
  {
    id: "channels",
    name: "Channel",
    path: SCOPE_ROUTES.channel.basePath,
    icon: <Chat4FillIcon size="24px" className="admin-left-icons" />,
    actions: [
      {
        name: "Add channel",
        path: SCOPE_ROUTES.channel.actionPath.create,
        subId: "Add_channel"
      },
      {
        name: "All channels",
        path: SCOPE_ROUTES.channel.actionPath.list,
        subId: "All_channels"
      },
    ],
  },
  {
    id: "news",
    name: "News",
    path: SCOPE_ROUTES.news.basePath,
    icon: <CalendarEventFillIcon size="24px" className="admin-left-icons" />,
    actions: [
      {
        name: "Banners",
        path: SCOPE_ROUTES.news.actionPath.banners,
        subId: "Banners"
      },
      {
        name: "Posts",
        path: SCOPE_ROUTES.news.actionPath.posts,
        subId: "Posts"
      },
      {
        name: "News",
        path: SCOPE_ROUTES.news.actionPath.news,
        subId: "News"
      },
      {
        name: "Content",
        path: SCOPE_ROUTES.news.actionPath.content,
        subId: "Content"
      },
    ],
  },
  {
    id: "partners",
    name: "Partners",
    path: SCOPE_ROUTES.partner.basePath,
    icon: <PartnerIcon />,
    actions: [
      {
        name: "All partners",
        path: SCOPE_ROUTES.partner.actionPath.list,
        subId: "All_partners"
      },
      {
        name: "Featured partners",
        path: SCOPE_ROUTES.partner.actionPath.featuredlist,
        subId: "Featured_partners"
      },
      {
        name: "Fresh deals",
        path: SCOPE_ROUTES.partner.actionPath.freshdeals,
        subId: "Fresh_deals"
      },
      {
        name: "Marcros (Saved replies)",
        path: SCOPE_ROUTES.partner.actionPath.reason,
        subId: "Marcros"
      },
      {
        name: "Reviews",
        path: SCOPE_ROUTES.partner.actionPath.reviews,
        subId: "Reviews"
      },
      {
        name: "Types",
        path: SCOPE_ROUTES.partner.actionPath.badges,
        subId: "Types"
      },
      {
        name: "Banner",
        path: SCOPE_ROUTES.partner.actionPath.maincontent,
        subId: "Banner"
      },
      {
        name: "Categories",
        path: SCOPE_ROUTES.partner.actionPath.partnercategories,
        subId: "Categories"
      },
      {
        name: "Statistics",
        path: SCOPE_ROUTES.partner.actionPath.partnerstatistics,
        subId: "Statistics"
      },
    ],
  },
  {
    id: "documents",
    name: "Documents",
    path: SCOPE_ROUTES.document.basePath,
    icon: <DocumentIcon />,
    actions: [
      {
        name: "Add documents",
        path: SCOPE_ROUTES.document.actionPath.list,
        subId: "Add_documents"
      },
      {
        name: "Categories",
        path: SCOPE_ROUTES.document.actionPath.categories,
        subId: "Categories"
      },
      {
        name: "Marcros (Saved replies)",
        path: SCOPE_ROUTES.document.actionPath.marcros,
        subId: "Marcros"
      },
      {
        name: "Content types",
        path: SCOPE_ROUTES.document.actionPath.contenttypes,
        subId: "Content_types"
      },
      {
        name: "Users download",
        path: SCOPE_ROUTES.document.actionPath.userdownloads,
        subId: "Users_download"
      },
    ],
  },
  {
    id: "medias",
    name: "Media",
    path: SCOPE_ROUTES.media.basePath,
    icon: <ImageFillIcon size="24px" className="admin-left-icons" />,
    actions: [
      {
        name: "Media",
        path: SCOPE_ROUTES.media.basePath,
        subId: "Media"
      },
    ],
  },
  {
    id: "groups",
    name: "Groups",
    path: SCOPE_ROUTES.group.basePath,
    icon: <GroupFillIcon size="24px" className="admin-left-icons" />,
    actions: [
      {
        name: "Add new group",
        path: SCOPE_ROUTES.group.actionPath.create,
        subId: "Add_new_group"
      },
      {
        name: "All groups",
        path: SCOPE_ROUTES.group.actionPath.list,
        subId: "All_groups"
      },
    ],
  },
  {
    id: "videos",
    name: "Videos",
    path: SCOPE_ROUTES.videos.basePath,
    icon: <OndemandVideoIcon size="24px" className="admin-left-icons" />,
    actions: [
      {
        name: "All video content",
        path: SCOPE_ROUTES.videos.actionPath.list,
        subId: "All_video_content"
      },
      {
        name: "Deleted videos",
        path: SCOPE_ROUTES.videos.actionPath.deletedvideo,
        subId: "Deleted_videos"
      },
      {
        name: "Video categories",
        path: SCOPE_ROUTES.videos.actionPath.categories,
        subId: "Video_categories"
      },
    ],
  },
  {
    id: "statistics",
    name: "Statistics",
    path: SCOPE_ROUTES.statistic.basePath,
    icon: <BarChartBoxFillIcon size="24px" className="admin-left-icons" />,
    actions: [
      {
        name: "Videos",
        path: SCOPE_ROUTES.statistic.actionPath.videostatistic,
        subId: "Videos"
      },
      {
        name: "Categories",
        path: SCOPE_ROUTES.statistic.actionPath.categorystatistic,
        subId: "Categories"
      },
      {
        name: "Speakers",
        path: SCOPE_ROUTES.statistic.actionPath.speakerstatistic,
        subId: "Speakers"
      },
      {
        name: "Tags",
        path: SCOPE_ROUTES.statistic.actionPath.tagstatistic,
        subId: "Tags"
      },
      {
        name: "Events",
        path: SCOPE_ROUTES.statistic.actionPath.eventstatistic,
        subId: "Events"
      },
      {
        name: "User statistics",
        path: SCOPE_ROUTES.statistic.actionPath.userstatistic,
        subId: "User_statistics"
      },
    ],
  },
  {
    id: "reports",
    name: "Reports",
    path: SCOPE_ROUTES.messagereport.basePath,
    icon: <BarChartBoxFillIcon size="24px" className="admin-left-icons" />,
    actions: [
      {
        name: "Message Reports",
        path: SCOPE_ROUTES.messagereport.basePath,
        subId: "Message_Reports"
      },
    ],
  },
  {
    id: "tags",
    name: "Tags",
    path: SCOPE_ROUTES.tags.basePath,
    icon: <BarChartBoxFillIcon size="24px" className="admin-left-icons" />,
    actions: [
      {
        name: "Tags",
        path: SCOPE_ROUTES.tags.actionPath.list,
        subId: "Tags"
      },
    ],
  },
  {
    id: "notifications",
    name: "Notifications",
    path: SCOPE_ROUTES.notifications.basePath,
    icon: <NotificationIcon size="24px" className="admin-left-icons" />,
    actions: [
      {
        name: "Notifications",
        path: SCOPE_ROUTES.notifications.actionPath.list,
        subId: "Notifications"
      },
    ],
  },
];

export const adminScopesList = [
  {
    id: "COMMUNITY_PROFILE_MANAGEMENT",
    name: "Community Profile",
    path: ADMIN_SCOPE_ROUTES.communityProfile.basePath,
    icon: <StarsIcon size="24px" className="admin-left-icons" />,
    actions: [
      {
        name: "Community Dashboard",
        path: ADMIN_SCOPE_ROUTES.communityProfile.actionPath.dashboard,
        subId: "Community_Dashboard"
      },
      {
        name: "Community Profile Info",
        path: ADMIN_SCOPE_ROUTES.communityProfile.actionPath.info,
        subId: "Community_Profile_Info"
      },
      {
        name: "Subscription Info",
        path: ADMIN_SCOPE_ROUTES.communityProfile.actionPath.subscription,
        subId: "Subscription_Info"
      },
      {
        name: "Payouts",
        path: ADMIN_SCOPE_ROUTES.communityProfile.actionPath.payouts,
        subId: "Payouts"
      }
    ],
  },
  {
    id: "ROLE_MANAGEMENT",
    name: "Roles",
    path: ADMIN_SCOPE_ROUTES.roles.basePath,
    icon: <KeyIcon size="24px" className="admin-left-icons" />,
    actions: [
      {
        name: "Roles List",
        path: ADMIN_SCOPE_ROUTES.roles.actionPath.list,
        subId: "Roles_List"
      },
    ],
  },
  {
    id: "COMMUNITY_OWNERS_MANAGEMENT",
    name: "Community Owners",
    path: ADMIN_SCOPE_ROUTES.communityOwners.basePath,
    icon: <SupervisedUserCircleIcon size="24px" className="admin-left-icons" />,
    actions: [
      {
        name: "Community Owners List",
        path: ADMIN_SCOPE_ROUTES.communityOwners.actionPath.list,
        subId: "Community_Owners_List"
      },
    ],
  },
  {
    id: "APPLICATION_FORMS_MANAGEMENT",
    name: "Application Forms",
    path: ADMIN_SCOPE_ROUTES.applicationForms.basePath,
    icon: <AttachFileIcon size="24px" className="admin-left-icons" />,
    actions: [
      {
        name: "Application Forms List",
        path: ADMIN_SCOPE_ROUTES.applicationForms.actionPath.list,
        subId: "Application_Forms_List"
      },
    ],
  },
  {
    id: "TIER_MANAGEMENT",
    name: "Tiers",
    path: ADMIN_SCOPE_ROUTES.tiers.basePath,
    icon: <AttachMoneyIcon size="24px" className="admin-left-icons" />,
    actions: [
      {
        name: "Community Tiers List",
        path: ADMIN_SCOPE_ROUTES.tiers.actionPath.list,
        subId: "Community_Tiers_List"
      },
      {
        name: "Preview Tiers",
        path: ADMIN_SCOPE_ROUTES.tiers.actionPath.viewTiers,
        subId: "Preview_Tiers"
      },
    ],
  },
  {
    id: "SUBSCRIPTION_SUBMISSIONS_MANAGEMENT",
    name: "Subscription Submissions",
    path: ADMIN_SCOPE_ROUTES.subscriptionSubmissions.basePath,
    icon: <PendingActionsIcon size="24px" className="admin-left-icons" />,
    actions: [
      {
        name: "Subscription Submissions List",
        path: ADMIN_SCOPE_ROUTES.subscriptionSubmissions.actionPath.list,
        subId: "Subscription_Submissions_List"
      },
    ],
  },
  {
    id: "MEMBER_PROFILE_MANAGEMENT",
    name: "Community Members",
    path: ADMIN_SCOPE_ROUTES.communityMembers.basePath,
    icon: <RecentActorsIcon size="24px" className="admin-left-icons" />,
    actions: [
      {
        name: "Community Members List",
        path: ADMIN_SCOPE_ROUTES.communityMembers.actionPath.list,
        subId: "Community_Members_List"
      },
      {
        name: "Community Members Subscriptions",
        path: ADMIN_SCOPE_ROUTES.communityMembers.actionPath.subscriptions,
        subId: "Community_Members_Subscriptions"
      },
    ],
  },
  {
    id: "PROFILE_MANAGEMENT",
    name: "Profile",
    path: ADMIN_SCOPE_ROUTES.profile.basePath,
    icon: <PersonIcon size="24px" className="admin-left-icons" />,
    actions: [
      {
        name: "Profile Info",
        path: ADMIN_SCOPE_ROUTES.profile.actionPath.info,
        subId: "Profile_Info"
      },
    ],
  },
  {
    id: "SUBSCRIPTION_REMINDER_MANAGEMENT",
    name: "Subscription Reminder",
    path: ADMIN_SCOPE_ROUTES.reminder.basePath,
    icon: <NotificationsNoneIcon size="24px" className="admin-left-icons" />,
    actions: [
      {
        name: "Reminders",
        path: ADMIN_SCOPE_ROUTES.reminder.actionPath.list,
        subId: "Reminders"
      },
    ],
  },
  {
    id: "EDGE_LOGIN",
    name: "Edge Login",
    path: ADMIN_SCOPE_ROUTES.edgeLogin.basePath,
    icon: <LoginIcon size="24px" className="admin-left-icons" />,
    actions: [
      {
        name: "Edge Logins",
        path: ADMIN_SCOPE_ROUTES.edgeLogin.actionPath.list,
        subId: "Edge_Logins"
      },
    ],
  },
  {
    id: "COMMUNITY_DISCOVERY",
    name: "Discover Communities",
    path: ADMIN_SCOPE_ROUTES.discover.basePath,
    icon: <ViewCarouselIcon size="24px" className="admin-left-icons" />,
    actions: [
      {
        name: "Discover Communities",
        path: ADMIN_SCOPE_ROUTES.discover.actionPath.list,
        subId: "Discover_Communities"
      },
    ],
  },
];
