import React from "react";
import PropTypes from "prop-types";
import ChatNotificationCell from "./HelperComponets/ChatNotificationCell";
import Button from "../../../components/Button/Button";
import ArrowleftIcon from "../../../components/Icons/ArrowsIcon/ArrowleftIcon";
import Typography from "../../../components/Typography/Typography";

import "../../../Styles/Theme/Theme.css"
import "./Styles/ChatDetailsMembers.css";
import "./Styles/ChatDetailsNotifications.css";

const ChatDetailsNotifications = ({
  text = "When this setting is off you will not receive notifications.",
  title = "Allow notifications",
  text1 = "Show badges with information about missed messages",
  title1 = "Badges",
  onClickBackButton,
  onChangeNotificationSwitch,
  onChangeBadgesSwitch,
  isMuted = false,
  isBadges = false,
}) => {
    console.log(isMuted,"isMuted")
     console.log(isBadges,"isBadges")
  return (
    <div className="chat-details-members">
      <div className="chat-details-members-heading">
        <div className="chat-details-members-heading-title">
          <Button
            iconColor="#060D14"
            label=""
            size="small"
            variant="ghost"
            elementIcon={<ArrowleftIcon height={24} width={24} />}
            hasText={false}
            toolTipText="Back"
            handleClick={onClickBackButton}
          />
          <Typography variant="h4">Notifications</Typography>
        </div>
      </div>
      <hr />
      <div className="chat-details-notifications-container scrollbar">
        <div className="chat-details-notifications-container-in">
          <ChatNotificationCell
            text={text}
            title={title}
            onChangeSwitch={onChangeNotificationSwitch}
            isMuted={isMuted}
          />
        </div>
        <hr className="chat-details-notifications-hr" />
        <div className="chat-details-notifications-container-in">
            {isMuted &&
          <ChatNotificationCell
            text={text1}
            title={title1}
            onChangeSwitch={onChangeBadgesSwitch}
            isMuted={isBadges}
          />
            }
        </div>
      </div>
    </div>
  );
};

ChatDetailsNotifications.propTypes = {
  onClickBackButton: PropTypes.func,
  onChangeNotificationSwitch: PropTypes.func,
  onChangeBadgesSwitch: PropTypes.func,
  text: PropTypes.string,
  title: PropTypes.string,
  text1: PropTypes.string,
  title1: PropTypes.string,
  isMuted: PropTypes.bool,
  isBadges: PropTypes.bool,
};

export default ChatDetailsNotifications;
