import React from "react";
import PropTypes from "prop-types"
function PlayIcon({ height = 24, width = 24, iconColor = "#000" }) {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={width}
                height={height}
                fill="none"
                viewBox="0 0 24 24"
            >
                <path
                    fill={iconColor}
                    d="m5.435 2.1 15.854 9.249c.487.285.487 1.017 0 1.302L5.435 21.9a.761.761 0 0 1-1.135-.655V2.752c0-.572.64-.94 1.135-.652Z"
                />
            </svg >
        </>
    );
}
PlayIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    iconColor: PropTypes.string
}
export default PlayIcon;
