import React from "react";
import PropTypes from "prop-types";
function CommentEmpty({}) {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={120}
        height={120}
        fill="none"
      >
        <g clipPath="url(#a)">
          <path
            fill="#6FCADF"
            fillOpacity={0.2}
            d="M59.392 119.945c-1.588 0-3.19.147-4.754-.034-2.457-.285-4.913-.635-7.333-1.147a58.985 58.985 0 0 1-24.126-11.242 59.24 59.24 0 0 1-6.402-5.722 62.252 62.252 0 0 1-8.4-11.044 59.098 59.098 0 0 1-4.357-8.818A57.254 57.254 0 0 1 .949 71.126a56.525 56.525 0 0 1-.863-6.084c-.194-2.951-.05-5.927 0-8.897.07-4.133.921-8.158 1.96-12.128A51.255 51.255 0 0 1 5.27 35.12a58.806 58.806 0 0 1 5.202-9.106 61.042 61.042 0 0 1 3.731-4.93 69.764 69.764 0 0 1 5.98-6.017 57.888 57.888 0 0 1 7.47-5.682 60.399 60.399 0 0 1 33.68-9.36 59.992 59.992 0 0 1 38.215 14.863 59.885 59.885 0 0 1 19.715 35.925c.654 4.11.871 8.279.645 12.434-.746 12.368-4.834 23.508-12.373 33.356a60.372 60.372 0 0 1-18.383 15.88 58.274 58.274 0 0 1-10.315 4.569 57.162 57.162 0 0 1-12.59 2.675 46.558 46.558 0 0 1-6.855.218Z"
          />
          <path
            fill="var(--color-icon-neutral-strong)"
            d="M67.94 57.42a2.66 2.66 0 0 0 2.662 2.651 2.648 2.648 0 0 0 2.65-2.651 2.648 2.648 0 0 0-2.65-2.653h-.026a2.638 2.638 0 0 0-2.636 2.652ZM59.981 60.072a2.657 2.657 0 0 1-2.66-2.653 2.637 2.637 0 0 1 2.637-2.652h.023a2.648 2.648 0 0 1 2.65 2.652 2.648 2.648 0 0 1-2.65 2.653ZM46.7 57.42a2.66 2.66 0 0 0 2.663 2.651 2.648 2.648 0 0 0 2.649-2.651 2.648 2.648 0 0 0-2.65-2.653h-.023A2.639 2.639 0 0 0 46.7 57.42Z"
          />
          <path
            fill="var(--color-icon-neutral-strong)"
            fillRule="evenodd"
            d="M33.333 53.437c0-8.158 6.593-14.77 14.727-14.77h23.88c8.133 0 14.727 6.612 14.727 14.77v7.983c0 8.158-6.594 14.77-14.727 14.77H50.47c-2.388 0-4.705.819-6.567 2.32l-6.038 4.868c-1.823 1.47-4.532.169-4.532-2.178V53.437ZM48.06 42.925c-5.789 0-10.481 4.706-10.481 10.512v24.709l3.664-2.955a14.7 14.7 0 0 1 9.227-3.259h21.47c5.789 0 10.481-4.706 10.481-10.512v-7.983c0-5.806-4.692-10.512-10.48-10.512H48.06Z"
            clipRule="evenodd"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0h120v120H0z" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
}

export default CommentEmpty;
