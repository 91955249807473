import ReduActivityFeeling from "./activityfeeling";
import ReduPost from "./post";
import ReduTopics from "./topics";
import ReduLoader from "./loader";
import ReduSavedPost from "./savedpost";
import ReduGroupPost from "./grouppost";
import ReduProfile from "./profile";
import ReduMyProfile from "./myprofile";
import ReduMemberPost from "./memberpost";
import ReduAllTopics from "./alltopics";
import ReduSocket from "./socket";
import ReduChat from "./chat";
import ReduTypers from "./chattyping";
import ReduRegistrationDetail from "./userregistrationdetail";
import ReduLatestFiles from "./latestfiles";
import ReduVideos from "./contentvideo";
import ReduAllChatList from "./chatlist";
import ReduSavedVideos from "./savedvideos";
import ReduSearchPost from "./searchpost";
import ReduVideoCategoryWise from "./videocategorywise";
import ReduChatLoader from "./chatloader";
import ReduCurrentlyChatting from "./currentlychattinguser";
import ReduHistoryVideos from "./historyvideo";
import ReduChatLastSession from "./chatlastsession";
import ReduStarChat from "./starchatuser";
import ReduTotalMessages from "./chattotalmessages";
import ReduHistoryVideoLoader from "./historyvideoloader";
import ReduSavedVideoLoader from "./savedvideoloader";
import ReduChatMoreLoader from "./loadmoremessages";
import ReduSendMsgLoader from "./sendmessageloader";
import ReduVideoInProcess from "./videoprocessing";
import ReduMigrateUserPopup from "./migrateuserpopup";
import ReduChatPageLoader from "./chatpageloader";
import ReduFileList from "./groupfilelist";
import ReduMediaList from "./groupmedialist";
import ReduUrlList from "./groupurllist";
import ReduMDSPartner from "./partner";
import ReduPartnerInProcess from "./partnerProcessing";
import partnerNavigate from "./partnerNavigate";
import isMDS from "./isMDS";
import ReduCustomPageChange from "./customPageChange";
import ReduCustomModalPopupClose from "./customModalPopup";
import ReduStayOnPage from "./stayOnPage";
import ReduModalInProcess from "./modalInProcess";
import ReduHistoryPage from "./historyPage";
import allPendingReview from "./allPendingReview";
import partnerPendingReview from "./partnerPendingReview";
import ReduUnreadMessageIndex from "./unreadMessage";
import ReduChatConnectingMessage from "./chatconnectingmessages";
import ReduPartnerDynamicLabel from "./PartnerDynamicLabel";
import ReduUserId from "./userIdRedu";
import eventUrl from "./eventUrl";
import allPartnerTitle from "./allPartnerTitle";
import ReduInviteeCount from "./inviteesCount";

import NotificationReducer from "../Reducers/notificationReducer";
import muteNotificationReducer from "./muteNotificationReducer";
import ReduAllUsersList from "./allUsersList";
import EventParticipantReducer from "./eventParticipantTypesReducer";
import reducerInProgressMediaMessage from "./inProgressMediaMessage";
import MediaProgressDisplayMessage from "./mediaProgressdisplay";
import AllMediaPreview from "./AllMediaPreview";
import AllChatFileList from "./AllChatFileList";
import AllUrlList from "./AllUrlList";
import chatSidebarLoader from "./chatSidebarLoader";
import allPendingRequest from "./allPendingRequest";
import ReduAllChatListUpdate from "./chatListUpdate";
import SelectedEventData from "./SelectedEventData";

const rootReducer = {
  ReduActivityFeeling,
  ReduPost,
  ReduTopics,
  ReduLoader,
  ReduSavedPost,
  ReduGroupPost,
  ReduProfile,
  ReduMyProfile,
  ReduMemberPost,
  ReduAllTopics,
  ReduSocket,
  ReduChat,
  ReduTypers,
  ReduRegistrationDetail,
  ReduLatestFiles,
  ReduVideos,
  ReduAllChatList,
  ReduSavedVideos,
  ReduSearchPost,
  ReduVideoCategoryWise,
  ReduChatLoader,
  ReduCurrentlyChatting,
  ReduHistoryVideos,
  ReduChatLastSession,
  ReduStarChat,
  ReduTotalMessages,
  ReduHistoryVideoLoader,
  ReduSavedVideoLoader,
  ReduChatMoreLoader,
  ReduSendMsgLoader,
  ReduVideoInProcess,
  ReduMigrateUserPopup,
  ReduChatPageLoader,
  ReduFileList,
  ReduMediaList,
  ReduUrlList,
  ReduMDSPartner,
  ReduModalInProcess,
  partnerNavigate,
  isMDS,
  ReduCustomPageChange,
  ReduCustomModalPopupClose,
  ReduStayOnPage,
  ReduPartnerInProcess,
  ReduHistoryPage,
  allPendingReview,
  partnerPendingReview,
  ReduUnreadMessageIndex,
  ReduChatConnectingMessage,
  ReduUserId,
  eventUrl,
  allPartnerTitle,
  ReduPartnerDynamicLabel,
  ReduInviteeCount,
  NotificationReducer,
  muteNotificationReducer,
  ReduAllUsersList,
  EventParticipantReducer,
  reducerInProgressMediaMessage,
  MediaProgressDisplayMessage,
  AllMediaPreview,
  AllChatFileList,
  AllUrlList,
  chatSidebarLoader,
  allPendingRequest,
  ReduAllChatListUpdate,
  SelectedEventData,
};

export default rootReducer;
