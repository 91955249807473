import { useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";

import Header from "components/Header";
import GlobalMenuPopup from "components/UserComponents/GlobalMenuPopup";
import SideBar from "components/SideBar";
import useAuth from "Hooks/useAuth";

import useRefreshNavigation from "Hooks/useRefreshNavigation";
import { getModuleName } from "utils";
import { useLazyGetUserEdgesQuery } from "Services/authentication";
import { scopesList } from "routes/AuthenticatedRoutes/menuList";
import { useLazyFetchStripeAccountQuery } from "Services/stripe";
import { useStripeCheckoutSessionMutation } from "Services/stripe";
import { useLazyGetTierByNameQuery } from "Services/tiers";
import { Box } from "@mui/material";
import Loading from "components/Loading";
import moment from "moment";

const AuthenticatedLayout = () => {
  // console.log("AuthenticatedLayout------->", window.location.pathname);

  const { auth, setAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { refreshScopeActions } = useRefreshNavigation();
  const [globalmenu, setglobalmenu] = useState(false);
  const [loader, setLoader] = useState(true);

  const authScopes = auth.currentEdge?.scopes ? auth.currentEdge?.scopes : [];
  const availableScopes = scopesList?.filter((scope) =>
    authScopes.includes(scope.id)
  );

  const [currentScope, setCurrentScope] = useState(
    availableScopes ? availableScopes[0] : []
  );

  // get user edges
  const [getUserEdges] = useLazyGetUserEdgesQuery();

  // fetch community owner subscription id
  const [fetchStripeAccounId] = useLazyFetchStripeAccountQuery();

  // get tier by name for mds migration
  const [getTier] = useLazyGetTierByNameQuery();

  // create checkout session
  const [createCheckoutSession] = useStripeCheckoutSessionMutation();

  useEffect(() => {
    const module = getModuleName("/", location.pathname);
    if (currentScope?.path !== module) {
      setCurrentScope(availableScopes.find((alvS) => alvS.path === module));
    }
  }, [availableScopes, currentScope?.path, location, refreshScopeActions]);
  useEffect(() => {
    if (!auth.currentEdge.subscription_id) {
      (async function () {
        // MDS migration
        if (
          auth.currentEdge.relation_id.nickname === "mds" &&
          auth.migrationData
        ) {
          const userEdge = await getUserEdges({
            user_id: auth.currentEdge.relation_id?.owner_id,
            relation_id: auth.currentEdge.relation_id._id,
          });
          // for mds migration when mds user logged in to groupos first time naigate user to direct checkout

          const stripeAccount = await fetchStripeAccounId({
            stripe_account_db_id: userEdge.data.stripe_account_db_id,
          });

          const tier = await getTier({
            name: auth?.migrationData.tier_name,
            relation_id: auth.currentEdge.relation_id._id,
          });
          const days = moment().diff(
            moment(auth?.migrationData?.subscription_end),
            "days"
          );

          let body = {
            tierId: tier?.data?._id,
            type: auth?.migrationData?.subscription_type, // monthly, yearly
            customerId: auth?.currentEdge?.stripe_customer_db_id,
            accountId: userEdge.data.stripe_account_db_id,
          };

          if (new Date(auth?.migrationData?.subscription_end) > new Date()) {
            body = { ...body, freeTrialDays: days > 0 ? 0 : Math.abs(days) };
          }

          const checkoutSessionData = await createCheckoutSession(body);
          navigate("/subscriptions/checkout", {
            state: {
              clientSecret: checkoutSessionData.data,
              stripeAccount: stripeAccount?.data?.id,
              platForm: false,
            },
          });
          setLoader(false);
        } else {
          // if subscription id is not in auth fetch customer id and navigate to pricing page
          if (!auth?.currentEdge?.stripe_customer_db_id) {
            const userEdge = await getUserEdges({
              user_id: auth.userId,
              relation_id: auth.currentEdge.relation_id._id,
            });
            setAuth((prev) => ({
              ...prev,
              currentEdge: {
                ...prev.currentEdge,
                stripe_customer_db_id: userEdge.data.stripe_customer_db_id,
              },
            }));
            setLoader(false);
            navigate("/subscriptions/pricing");
          } else {
            // if subscription is not done and customer id is there so navigate to procing
            setLoader(false);
            navigate("/subscriptions/pricing");
          }
        }
      })();
    } else {
      setLoader(false);
    }
  }, [
    auth.currentEdge?.relation_id?._id,
    auth.currentEdge?.relation_id?.nickname,
    auth.currentEdge?.relation_id?.owner_id,
    auth.currentEdge?.stripe_customer_db_id,
    auth.currentEdge?.subscription_id,
    auth?.migrationData,
    auth?.migrationData?.subscription_end,
    auth?.migrationData?.subscription_type,
    auth?.migrationData?.tier_name,
    auth?.userId,
    createCheckoutSession,
    fetchStripeAccounId,
    getTier,
    getUserEdges,
    navigate,
    setAuth,
  ]);

  return (
    <>
      {loader ? (
        <Box
          width="100%"
          height="100vh"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Loading />
        </Box>
      ) : (
        <>
          <Header type={auth.currentEdge.type} setglobalmenu={setglobalmenu} />
          <div
            className={`groups-left-sidebar all-content-main cstm-all-content-main cstm-all-library-content-main cstm-all-content-library-main-only mn-cnt3-wd`}
          >
            <div
              className="sidebar-main"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <SideBar
                scopes={availableScopes}
                setCurrentScope={setCurrentScope}
              />

              <div className="sidebar-footer">
                <ul className="sidebar-footer-link">
                  <li>
                    <Link
                      to="https://apps.apple.com/app/id1636838955"
                      target="_blank"
                    >
                      Download iOS app
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://play.google.com/store/apps/details?id=com.app.mdscommunity"
                      target="_blank"
                    >
                      Download Android app
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.milliondollarsellers.com/privacy-policy"
                      target="_blank"
                    >
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link
                      target="_blank"
                      to="https://www.milliondollarsellers.com/terms-of-service"
                    >
                      Terms of service
                    </Link>
                  </li>
                </ul>
                <span className="sidebar-copy">
                  Copyright
                  <br />© {new Date().getFullYear()} GROUPOS
                </span>
              </div>
            </div>
            <div className="all-content-main-center all-content-main-center">
              <Outlet />
            </div>
          </div>
        </>
      )}
      {globalmenu && (
        <GlobalMenuPopup
          setglobalmenu={setglobalmenu}
          globalmenu={globalmenu}
        />
      )}
    </>
  );
};

export default AuthenticatedLayout;
