import React from "react";
import { Navigate, Routes } from "react-router-dom";

import LazyLoadComponent from "../../components/LazyLoadComponent.jsx";
import PublicLayout from "../../layouts/PublicLayout/index.jsx";
import { ADMIN_SCOPE_ROUTES } from "routes/PublicRoutes/menuList.js";

// const NotFound = React.lazy(() => import("../Pages/NotFound"));

const Login = React.lazy(() => import("../../pages/Login/index.jsx"));
const SignUp = React.lazy(() => import("../../pages/SignUp/index.jsx"));
const ActivateAccount = React.lazy(() =>
  import("../../pages/ActivateAccount/index.jsx")
);
const LandingPage = React.lazy(() =>
  import("../../pages/LandingPage/index.jsx")
);
const Discover = React.lazy(() =>
  import("../../pages/DiscoverCommunities/DiscoverCommunities.jsx")
);
const CommunityProfile = React.lazy(() =>
  import("../../pages/CommunityProfile/index.jsx")
);
const Pricing = React.lazy(() => import("../../pages/Pricing/index.jsx"));

const PublicRoutes = [
  {
    path: "/",
    element: LazyLoadComponent(<PublicLayout />),
    children: [
      {
        index: true,
        element: LazyLoadComponent(<LandingPage />),
      },
      {
        path: ADMIN_SCOPE_ROUTES.discover.basePath,
        children: [{ index: true, element: LazyLoadComponent(<Discover />) }],
      },
      {
        path: ADMIN_SCOPE_ROUTES.communityProfile.basePath,
        children: [
          { index: true, element: LazyLoadComponent(<CommunityProfile />) },
        ],
      },
      {
        path: ADMIN_SCOPE_ROUTES.pricing.basePath,
        children: [{ index: true, element: LazyLoadComponent(<Pricing />) }],
      },
      {
        path: ADMIN_SCOPE_ROUTES.authentication.basePath,
        children: [
          {
            exact: true,
            path: ADMIN_SCOPE_ROUTES.authentication.actionPath.login,
            element: LazyLoadComponent(<Login />),
          },
          {
            exact: true,
            path: ADMIN_SCOPE_ROUTES.authentication.actionPath.signUp,
            element: LazyLoadComponent(<SignUp />),
          },
          {
            exact: true,
            path: ADMIN_SCOPE_ROUTES.authentication.actionPath.activateAccount,
            element: LazyLoadComponent(<ActivateAccount />),
          },
        ],
      },
      {
        path: "*",
        skipLazyLoad: true,
        element: <Navigate to="/login" />,
      },
    ],
  },
];

const PublicRoutesx = () => {
  console.log("public routes------->", window.location.pathname);
  return (
    <Routes>
      {/* <Route path="/postauthenticate" exact element={<PostAuthenticate />} />
      <Route path="/appleauthenticate" exact element={<AppleAuthenticate />} /> */}
      {/* <Route path="/admin/adminlogin" exact element={<AdminLogin />} /> */}
      {/* <Route
        path="/admin/postauthenticate"
        exact
        element={<AdminPostAuthenticate />}
      /> */}

      {/* <Route path="/" exact element={LazyLoadComponent(<Login />)} /> */}
      {/* <Route path="*" element={LazyLoadComponent(<NotFound />)} /> */}
    </Routes>
  );
};
export default PublicRoutes;
