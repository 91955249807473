import React from "react";
import PropTypes from "prop-types";
const DotsIcon = ({
  width = 24,
  height = 24,
  iconColor = "#000",
  horizontal,
}) => {
  return (
    <>
      {horizontal ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            fill={iconColor}
            d="M7.8 12a1.8 1.8 0 1 0-3.6 0 1.8 1.8 0 0 0 3.6 0ZM13.8 12a1.8 1.8 0 1 0-3.6 0 1.8 1.8 0 0 0 3.6 0ZM18 10.2a1.8 1.8 0 1 1 0 3.6 1.8 1.8 0 0 1 0-3.6Z"
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            fill={iconColor}
            fillRule="evenodd"
            d="M12 7.8a1.8 1.8 0 1 0 0-3.6 1.8 1.8 0 0 0 0 3.6Zm0 6a1.8 1.8 0 1 0 0-3.6 1.8 1.8 0 0 0 0 3.6Zm0 6a1.8 1.8 0 1 0 0-3.6 1.8 1.8 0 0 0 0 3.6Z"
            clipRule="evenodd"
          />
        </svg>
      )}
    </>
  );
};
DotsIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  iconColor: PropTypes.string,
  horizontal: PropTypes.bool,
};
export default DotsIcon;
