import axios from "axios";
import authHeader from "../Services/auth.header";
import authAdminHeader from "./auth.admin.header";
import { CONFIGS } from "config";
// const API_URL = CONFIGS.GATEWAY_DOMAIN + "/api/";

let url = CONFIGS.GATEWAY_DOMAIN;
const host = window.location.host.split(".")[0];
let BASE_URL = url.split("//")[0] + "//" + host + "." + url.split("//")[1];
const API_URL = BASE_URL + "/api/";

const CHAT_API_URL = BASE_URL + "/api/chat/";

const createChannel = (data) => {
  return axios.post(API_URL + "create/channel", data, {
    headers: authAdminHeader(),
  });
};

const getAllChannelList = (query) => {
  return axios.get(API_URL + "list/channel" + query, {
    headers: authAdminHeader(),
  });
};

const deleteChannel = (data) => {
  return axios.delete(API_URL + "delete/channel/" + data, {
    headers: authAdminHeader(),
  });
};

const editChannel = (data) => {
  return axios.patch(API_URL + "edit/channel/" + data.id, data.data, {
    headers: authAdminHeader(),
  });
};
const getChannelDetailById = (data) => {
  return axios.get(API_URL + "get/channel/members/" + data, {
    headers: authAdminHeader(),
  });
};
const getChannelDetailByIdFrontend = (data) => {
  return axios.get(API_URL + "get/channel/members/frondend/" + data, {
    headers: authHeader(),
  });
};
const createChannelSendMessage = (data) => {
  return axios.post(CHAT_API_URL + "channel/createChannelSendMessage", data, {
    headers: authAdminHeader(),
  });
};
const editChannelSendMessage = (data) => {
  return axios.post(CHAT_API_URL + "channel/editChannelSendMessage", data, {
    headers: authAdminHeader(),
  });
};
const deleteChannelSendMessage = (data) => {
  return axios.post(CHAT_API_URL + "channel/deleteChannelSendMessage", data, {
    headers: authAdminHeader(),
  });
};

export default {
  createChannel,
  getAllChannelList,
  deleteChannel,
  editChannel,
  getChannelDetailById,
  getChannelDetailByIdFrontend,
  createChannelSendMessage,
  editChannelSendMessage,
  deleteChannelSendMessage,
};
