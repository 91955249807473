import React from "react";
import Button from "../../../components/Button/Button";
import DownloadIcon from "../../../components/Icons/GeneralIcons/DownloadIcon";
const NoPreview = ({
  handleDownload,
  data,
  restricted,
  setNoPreview,
  handleClosePreview,
  showFileData,
}) => {


  return (
    <>
      <div className="preview-not-available-main">
        <div className="preview-not-available">
          <div className="preview-not-available-body">
            <h4>
              {restricted ? `Download Limit Reached` : `Preview not available`}
            </h4>
            {!showFileData?.name && (
              <p className="you-can-downlod-text">
                {restricted
                  ? `Oops! It seems you've hit the documents download limit.`
                  : "You can download this file"}
              </p>
            )}
          </div>

          {restricted ? (
            <div className="preview-block-btns-container">
              <Button
                label="Cancel"
                variant="secondary"
                size="medium"
                handleClick={() => {
                  handleClosePreview();
                  setNoPreview(false);
                }}
              ></Button>
              <Button
                label="Contact Us"
                variant="secondary"
                size="medium"
                handleClick={() => {
                  if (window.Intercom) {
                    window.Intercom("show");
                  }
                }}
              ></Button>
            </div>
          ) : (
            <div className="preview-not-available-btn">
              {!showFileData?.name && (
                <Button
                  hasIconLeft
                  label="Download"
                  variant="secondary"
                  size="medium"
                  handleClick={() => handleDownload(data, data.fileUrl)}
                  elementIcon={<DownloadIcon />}
                  onContextMenu={() => {}}
                  disabled={restricted}
                ></Button>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default NoPreview;
