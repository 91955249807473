import React from "react";
import PropTypes from "prop-types"
function NavFolderIcon({ height = 24, width = 24, iconColor = "#000", isFilled }) {
    return (
        <>
            {isFilled ?
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={width}
                    height={height}
                    fill="none"
                    viewBox="0 0 24 24"
                >
                    <path
                        fill={iconColor}
                        fillRule="evenodd"
                        d="M16.088 5.823a.254.254 0 0 0 .245.177H20c1.833 0 3 1.167 3 3v9c0 2.485-1.515 4-4 4H5c-2.486 0-4-1.514-4-4l.001-12c0-2.485 1.514-4 3.999-4h8c1.08 0 2.046.696 2.388 1.721l.7 2.102Z"
                        clipRule="evenodd"
                    />
                </svg>
                :
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={width}
                    height={height}
                    fill="none"
                    viewBox="0 0 24 24"
                >
                    <path
                        fill={iconColor}
                        fillRule="evenodd"
                        d="M16.088 5.823a.254.254 0 0 0 .245.177H20c1.833 0 3 1.167 3 3v9c0 2.485-1.515 4-4 4H5c-2.486 0-4-1.514-4-4l.001-12c0-2.485 1.514-4 3.999-4h8c1.08 0 2.046.696 2.388 1.721l.7 2.102ZM2.5 18l.001-12c0-1.657.842-2.5 2.499-2.5h8c.435 0 .827.283.965.696l.7 2.101A1.754 1.754 0 0 0 16.333 7.5H20c1.004 0 1.5.495 1.5 1.5v9c0 1.657-.843 2.5-2.5 2.5H5c-1.657 0-2.5-.843-2.5-2.5Z"
                        clipRule="evenodd"
                    />
                </svg >
            }
        </>
    );
}
NavFolderIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    iconColor: PropTypes.string,
    isFilled: PropTypes.bool

}
export default NavFolderIcon;
