import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyBGz65mOWpvQInPlcD5pNMf_Ceq7xiDI2k",
  authDomain: "mds-community-app.firebaseapp.com",
  projectId: "mds-community-app",
  storageBucket: "mds-community-app.appspot.com",
  messagingSenderId: "671317354093",
  appId: "1:671317354093:web:6d17dd5013b7054fb3c164",
  measurementId: "G-SN4PKVB97H"
  };
  
// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app); 