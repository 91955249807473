import React from 'react'
import PropTypes from "prop-types"

const MdsLogo = ({ dark }) => {
    return (
        <>
            {dark ?
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={188}
                    height={72}
                    fill="none"
                >
                    <path
                        fill="#fff"
                        d="M104.673 54.84h-6.555V34.822l-8.073 12.379h-5.716l-8.074-12.437v15.983a4.095 4.095 0 0 1-4.097 4.093H66.28V20.93a3.779 3.779 0 0 1 3.773-3.77h5.658l11.49 17.662 9.531-14.672a6.525 6.525 0 0 1 5.496-2.987h5.862v34.263a3.41 3.41 0 0 1-3.417 3.414ZM146.18 36.001c0 10.978-6.247 18.84-19.709 18.84h-11.432a3.611 3.611 0 0 1-3.608-3.604V17.165h15.027c13.465 0 19.725 7.859 19.725 18.84L146.18 36Zm-19.726-10.227h-5.068v20.458h5.068c5.982 0 9.486-3.18 9.486-10.227 0-7.047-3.507-10.228-9.486-10.228v-.003ZM162.566 16.354c4.949 0 9.914 1.133 13.376 3.019v9.153c-3.417-2.43-7.912-3.605-12.537-3.546-3.873.058-5.068 1.016-5.068 2.43 0 4.901 20.726 3.384 20.726 16.733 0 8.933-7.234 11.524-15.952 11.524-5.746 0-11.109-1.236-15.322-3.442v-9.418c4.728 3.06 9.975 4.254 14.525 4.254 4.155 0 5.923-.647 5.923-2.53 0-5.432-20.726-3.604-20.726-17.057.016-7.551 6.849-11.11 15.055-11.11v-.01Z"
                    />
                    <path
                        fill="#fff"
                        fillRule="evenodd"
                        d="M8.937 36c0-14.799 12.011-26.799 26.824-26.799 14.816 0 26.825 12 26.825 26.799S50.574 62.799 35.761 62.799c-14.813 0-26.824-12-26.824-26.799Zm25.997 5.696a.23.23 0 0 1-.363.187l-6.347-3.598c.107 1.612.628 3.184 1.564 4.711.33.537.758 1.116 1.282 1.738a.273.273 0 0 1 .065.177v7.503c-3.614-3.148-6.198-6.114-7.753-8.9-1.655-2.97-2.484-6.445-2.484-10.418v-8.771c0-.913.978-1.489 1.778-1.045l11.87 6.561a.757.757 0 0 1 .391.666v11.195l-.003-.006Zm15.696-8.6c0 3.976-.83 7.447-2.484 10.418-1.555 2.785-4.139 5.752-7.753 8.9v-7.503c0-.065.022-.13.065-.178a14.05 14.05 0 0 0 1.282-1.737c.936-1.53 1.457-3.1 1.564-4.71l-6.347 3.597a.229.229 0 0 1-.363-.188V30.501a.76.76 0 0 1 .392-.667l11.626-6.428a1.36 1.36 0 0 1 2.018 1.187v8.503Z"
                        clipRule="evenodd"
                    />
                </svg>
                :
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={188}
                    height={72}
                    fill="none"
                >
                    <path
                        fill="#091F48"
                        d="M104.673 54.84h-6.555V34.822l-8.073 12.379h-5.716l-8.074-12.437v15.983a4.095 4.095 0 0 1-4.097 4.093H66.28V20.93a3.779 3.779 0 0 1 3.773-3.77h5.658l11.49 17.662 9.531-14.672a6.525 6.525 0 0 1 5.496-2.987h5.862v34.263a3.41 3.41 0 0 1-3.417 3.414ZM146.18 36.001c0 10.978-6.247 18.84-19.709 18.84h-11.432a3.611 3.611 0 0 1-3.608-3.604V17.165h15.027c13.465 0 19.725 7.859 19.725 18.84L146.18 36Zm-19.726-10.227h-5.068v20.458h5.068c5.982 0 9.486-3.18 9.486-10.227 0-7.047-3.507-10.228-9.486-10.228v-.003ZM162.566 16.354c4.949 0 9.913 1.133 13.375 3.019v9.153c-3.416-2.43-7.911-3.605-12.536-3.546-3.873.058-5.068 1.016-5.068 2.43 0 4.901 20.726 3.384 20.726 16.733 0 8.933-7.234 11.524-15.953 11.524-5.745 0-11.108-1.236-15.321-3.442v-9.418c4.728 3.06 9.975 4.254 14.525 4.254 4.155 0 5.923-.647 5.923-2.53 0-5.432-20.727-3.604-20.727-17.057.017-7.551 6.85-11.11 15.056-11.11v-.01ZM35.761 9.201c-14.813 0-26.824 12-26.824 26.799S20.948 62.799 35.76 62.799c14.813 0 26.825-12 26.825-26.799S50.578 9.201 35.761 9.201Z"
                    />
                    <path
                        fill="#fff"
                        d="M34.934 41.696a.23.23 0 0 1-.363.187l-6.347-3.598c.107 1.612.628 3.184 1.564 4.711.33.537.758 1.116 1.282 1.738a.273.273 0 0 1 .065.177v7.503c-3.614-3.148-6.198-6.114-7.753-8.9-1.655-2.97-2.484-6.445-2.484-10.418v-8.771c0-.913.978-1.489 1.778-1.045l11.87 6.561a.757.757 0 0 1 .391.666v11.195l-.003-.006ZM50.63 33.096c0 3.976-.83 7.447-2.484 10.418-1.555 2.785-4.139 5.752-7.753 8.9v-7.503c0-.065.022-.13.065-.178.524-.618.952-1.197 1.282-1.737.936-1.53 1.457-3.1 1.564-4.71l-6.347 3.597a.229.229 0 0 1-.363-.188V30.501a.76.76 0 0 1 .392-.667l11.626-6.428a1.36 1.36 0 0 1 2.018 1.187v8.503Z"
                    />
                </svg>}
        </>
    )
}

MdsLogo.propTypes = {
    dark: PropTypes.bool
}

export default MdsLogo