import React from "react";

const PartnerIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      className="admin-left-icons"
    >
      <path
        fillRule="evenodd"
        d="M12 5.95a1.65 1.65 0 1 0 0-3.3 1.65 1.65 0 0 0 0 3.3Zm3.224-.944a3.3 3.3 0 1 0-6.449 0 7.73 7.73 0 0 0-3.77 3.77 3.29 3.29 0 0 1 1.474.746 6.073 6.073 0 0 1 3.043-3.043A3.292 3.292 0 0 0 12 7.6c.987 0 1.873-.434 2.478-1.12a6.073 6.073 0 0 1 3.042 3.042 3.29 3.29 0 0 1 1.474-.747 7.73 7.73 0 0 0-3.77-3.77ZM19.7 13.65a1.65 1.65 0 1 0 0-3.3 1.65 1.65 0 0 0 0 3.3Zm0 1.65a3.3 3.3 0 1 0-2.18-.822 6.073 6.073 0 0 1-3.042 3.043c.363.412.625.917.746 1.473a7.73 7.73 0 0 0 3.77-3.77c.227.05.464.076.706.076ZM5.95 12a1.65 1.65 0 1 1-3.3 0 1.65 1.65 0 0 1 3.3 0Zm-.944 3.224a3.3 3.3 0 1 1 1.474-.746 6.073 6.073 0 0 0 3.042 3.043 3.29 3.29 0 0 0-.747 1.473 7.73 7.73 0 0 1-3.77-3.77ZM12 21.35a1.65 1.65 0 1 0 0-3.3 1.65 1.65 0 0 0 0 3.3ZM12 23a3.3 3.3 0 1 0 0-6.6 3.3 3.3 0 0 0 0 6.6Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default PartnerIcon;
