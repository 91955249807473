import { ApiRoutes } from "./apiRoutes";
import createApiInstance from "./createApiInstance";

export const extendedApi = createApiInstance.injectEndpoints({
  endpoints: (builder) => ({
    getCommunities: builder.query({
      query: (params) => {
        return {
          url: ApiRoutes.communities.getCommunities.url,
          method: ApiRoutes.communities.getCommunities.method,
          params,
        };
      },
      providesTags: ["COMMUNITIES"],
      transformResponse: (response) => response,
    }),
    getCommunity: builder.query({
      query: (community_id) => {
        return {
          url: ApiRoutes.communities.getCommunity.url.replace(
            ":community_id",
            community_id
          ),
          method: ApiRoutes.communities.getCommunity.method,
        };
      },
      providesTags: ["COMMUNITY"],
      transformResponse: (response) => response,
    }),
    createCommunity: builder.mutation({
      query(body) {
        return {
          url: ApiRoutes.communities.createCommunity.url,
          method: ApiRoutes.communities.createCommunity.method,
          body,
        };
      },
      invalidatesTags: ["COMMUNITIES"],
    }),
    editCommunity: builder.mutation({
      query({ community_id, body }) {
        return {
          url: ApiRoutes.communities.editCommunity.url.replace(
            ":community_id",
            community_id
          ),
          method: ApiRoutes.communities.editCommunity.method,
          body,
        };
      },
      invalidatesTags: ["COMMUNITIES", "COMMUNITY"],
    }),
    removeCommunity: builder.mutation({
      query(community_id) {
        return {
          url: ApiRoutes.communities.removeCommunity.url.replace(
            ":community_id",
            community_id
          ),
          method: ApiRoutes.communities.removeCommunity.method,
        };
      },
      invalidatesTags: ["COMMUNITIES"],
      transformResponse: (response) => response,
    }),
    generateSharableLink: builder.mutation({
      query(body) {
        return {
          url: ApiRoutes.communities.generateSharableLink.url,
          method: ApiRoutes.communities.generateSharableLink.method,
          body,
        };
      },
      invalidatesTags: ["COMMUNITY"],
      transformResponse: (response) => response,
    }),
    removeSharableLink: builder.mutation({
      query(sharable_link_id) {
        return {
          url: ApiRoutes.communities.removeSharableLink.url.replace(
            ":sharable_link_id",
            sharable_link_id
          ),
          method: ApiRoutes.communities.removeSharableLink.method,
        };
      },
      invalidatesTags: ["COMMUNITY"],
      transformResponse: (response) => response,
    }),
    getCommunityByNickname: builder.query({
      query: (nickname) => {
        return {
          url: ApiRoutes.communities.getCommunityByNickname.url.replace(
            ":nickname",
            nickname
          ),
          method: ApiRoutes.communities.getCommunityByNickname.method,
        };
      },
      providesTags: ["COMMUNITY"],
      transformResponse: (response) => response,
    }),
    validateSharableLink: builder.query({
      query: (params) => {
        return {
          url: ApiRoutes.communities.validateSharableLink.url,
          method: ApiRoutes.communities.validateSharableLink.method,
          params
        };
      },
      providesTags: ["COMMUNITY"],
      transformResponse: (response) => response,
    }),
    joinCommunity: builder.mutation({
      query(body) {
        return {
          url: ApiRoutes.communities.joinCommunity.url,
          method: ApiRoutes.communities.joinCommunity.method,
          body
        };
      },
      transformResponse: (response) => response,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetCommunitiesQuery,
  useGetCommunityQuery,
  useCreateCommunityMutation,
  useEditCommunityMutation,
  useRemoveCommunityMutation,
  useLazyGetCommunitiesQuery,
  useGenerateSharableLinkMutation,
  useRemoveSharableLinkMutation,
  useGetCommunityByNicknameQuery,
  useLazyGetCommunityByNicknameQuery,
  useValidateSharableLinkQuery,
  useLazyValidateSharableLinkQuery,
  useJoinCommunityMutation
} = extendedApi;
