import { IconButton } from "@mui/material";
import Toaster from "components/Toaster";
import { createContext, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
const NotificationContext = createContext({});

export const NotificationProvider = ({ children }) => {
  // function to close toaster
  const handleClose = () => {
    setToast((prev) => ({
      ...prev,
      open: false,
    }));
  };

  const defaultAction = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  const [toast, setToast] = useState({
    open: false,
    message: "",
    severity: "success",
    vertical: "top", //top, bottom
    horizontal: "right", //right, left, center
    autoHideDuration: 2 * 1000,
    variant: "filled", //filled, outlined, standard,
    action: defaultAction,
  });

  return (
    <NotificationContext.Provider value={{ toast, setToast }}>
      <>
        <Toaster
          open={toast.open}
          message={toast.message}
          severity={toast.severity}
          vertical={toast.vertical}
          horizontal={toast.horizontal}
          autoHideDuration={toast.autoHideDuration}
          variant={toast.variant}
          action={toast.action}
          handleClose={handleClose}
        />
        {children}
      </>
    </NotificationContext.Provider>
  );
};

export default NotificationContext;
