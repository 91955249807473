import { ApiRoutes } from "./apiRoutes";
import createApiInstance from "./createApiInstance";

export const extendedApi = createApiInstance.injectEndpoints({
  endpoints: (builder) => ({
    ssoLogin: builder.mutation({
      query(body) {
        return {
          url: ApiRoutes.authentication.ssoLogin.url,
          method: ApiRoutes.authentication.ssoLogin.method,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          body,
        };
      },
      providesTags: ["Authentication"],
      transformResponse: (response) => response,
    }),
    login: builder.mutation({
      query(body) {
        return {
          url: ApiRoutes.authentication.login.url,
          method: ApiRoutes.authentication.login.method,
          body,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
      providesTags: ["Authentication"],
      transformResponse: (response) => response,
    }),
    signUp: builder.mutation({
      query(body) {
        return {
          url: ApiRoutes.authentication.signup.url,
          method: ApiRoutes.authentication.signup.method,
          body,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
      transformResponse: (response) => response,
    }),
    validateLink: builder.query({
      query(params) {
        return {
          url: ApiRoutes.authentication.validateLink.url,
          method: ApiRoutes.authentication.validateLink.method,
          params,
        };
      },
      transformResponse: (response) => response,
    }),
    activateAccount: builder.mutation({
      query(body) {
        return {
          url: ApiRoutes.authentication.activateAccount.url,
          method: ApiRoutes.authentication.activateAccount.method,
          body,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
      transformResponse: (response) => response,
    }),
    getUserEdges: builder.query({
      query(params) {
        return {
          url: ApiRoutes.authentication.userEdges.url,
          method: ApiRoutes.authentication.userEdges.method,
          params,
        };
      },
      transformResponse: (response) => response,
    }),
    getUserInfo: builder.query({
      query(params) {
        return {
          url: ApiRoutes.authentication.userInfo.url,
          method: ApiRoutes.authentication.userInfo.method,
          params
        }
      },
      transformResponse: (response) => response
    })
  }),
  overrideExisting: false,
});

export const {
  useSsoLoginMutation,
  useLoginMutation,
  useSignUpMutation,
  useValidateLinkQuery,
  useActivateAccountMutation,
  useGetUserEdgesQuery,
  useLazyGetUserEdgesQuery,
  useGetUserInfoQuery
} = extendedApi;
