const profile = {};

const ReduMyProfile = (state = profile, action) => {
  switch (action.type) {
    case "UPDATEMYPROFILE":
      if (
        action.payload._id !== undefined &&
        action.payload._id !== null &&
        action.payload._id === localStorage.getItem("userid")
      )
        localStorage.setItem(
          "userprofile",
          action.payload.profileImg !== null &&
            action.payload.profileImg !== undefined
            ? action.payload.profileImg
            : localStorage.getItem("userprofile")
        );
      return action.payload;
    default:
      return state;
  }
};

export default ReduMyProfile;
