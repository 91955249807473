import React from 'react'
import PropTypes from "prop-types"
const DoubleTickIcon = ({ height = 24, width = 24, iconColor = "#000" }) => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={width}
                height={height}
                fill="none"
                viewBox="0 0 24 24"
            >
                <path
                    fill={iconColor}
                    fillRule="evenodd"
                    d="m11.632 15.993-2.215-2.215-2.368-2.366a.83.83 0 1 0-1.175 1.175l2.367 2.366-1.04 1.04-4.583-4.581a.83.83 0 1 0-1.175 1.175l5.17 5.169a.83.83 0 0 0 1.176 0l.845-.846-1.175-1.174.782-.783 2.804 2.803a.83.83 0 0 0 1.175 0L22.557 7.417a.83.83 0 0 0-1.175-1.175l-9.75 9.75Z"
                    clipRule="evenodd"
                />
                <path
                    fill={iconColor}
                    d="M18.126 7.418a.83.83 0 1 0-1.175-1.175l-7.534 7.535.783-.783 1.175 1.174 6.75-6.75Z"
                />
            </svg>
        </>
    )
}
DoubleTickIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    iconColor: PropTypes.string,
}
export default DoubleTickIcon
