import Header from "components/Header";
import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "./components/Sidebar";
import { scopesList } from "../../routes/SettingsRoutes/menuList";
import useAuth from "Hooks/useAuth";
import { scopesList as edgeScopesList } from "routes/EdgeRoutes/menuList";

const SettingsLayout = () => {
  const [globalmenu, setglobalmenu] = useState(false);
  const { auth } = useAuth();

  const finalScopeArray = [...scopesList, ...edgeScopesList];
  const filteredScopesList =
    auth.currentEdge.type !== "M"
      ? finalScopeArray.filter(
          (scope) => scope.id !== "users" && scope.id !== "subscription"
        )
      : finalScopeArray;

  return (
    <>
      <Header type={auth.currentEdge.type} setglobalmenu={setglobalmenu} />
      <div
        className={`groups-left-sidebar all-content-main cstm-all-content-main edit-profile`}
      >
        <div className="cstm-profile-ct">
          <div className="cstm-profile-sd">
            <Sidebar scopes={filteredScopesList} />
          </div>
          <div className="all-content-main-edit-profile">
            {/* <div className="all-content-main-edit-profile-main "> */}
            <Outlet />
            {/* </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default SettingsLayout;
