// Icons
import LoginIcon from "@mui/icons-material/Login";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";

export const ADMIN_SCOPE_ROUTES = {
  edgeLogin: {
    basePath: "edge-login",
  },
  edge: {
    basePath: "create-community",
  },
  discover: {
    basePath: "discover",
    actionPath: ":community_name",
  },
  profile: {
    basePath: "settings",
    actionPath: {
      edit: "edit",
      setting: "profile",
    },
  },
  contactUs: {
    basePath: "contact-us",
  },
};


export const scopesList = [
  {
    id: "EDGE_LOGIN",
    name: "Edge Login",
    path: ADMIN_SCOPE_ROUTES.edgeLogin.basePath,
    icon: <LoginIcon size="24px" className="admin-left-icons" />,
    actions: [],
  },
  {
    id: "COMMUNITY_DISCOVERY",
    name: "Discover Communities",
    path: ADMIN_SCOPE_ROUTES.discover.basePath,
    icon: <ViewCarouselIcon size="24px" className="admin-left-icons" />,
    actions: [],
  },
];
