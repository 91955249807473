import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Cropper from "cropperjs";
import moment from "moment";
import ChatDetails from "../Components/ChatDetails";
import authService from "../../../Services/auth.service";
import ChatDetailsNotifications from "../Components/ChatDetailsNotifications";
import { getMuteChatIdListForUser } from "../../../Action";
import ChatDetailsMembers from "../Components/ChatDetailsMembers";
import ModelPopup from "../../../components/ModalPopup/ModalPopup";
import ChatDetailsEdit from "../Components/ChatDetailsEdit";
import ChatDetailsMediaMembers from "../Components/ChatDetailsMediaMembers";
import { formatMediaPreviewMessagesAllMedia } from "../CommonFunctions/AllMedia";
import MediaPreview from "./MediaPreview";
import useSocket from "Hooks/useSocket";
import useAuth from "Hooks/useAuth";

const ChatRightSideBar = ({
  groupMemberList,
  handleAddMemberClick,
  editGroup,
  setEditGroup,
  handleRemoveMember,
}) => {
  const { socket } = useSocket();
  const { auth } = useAuth();
  let { userid, type } = useParams();
  const dispatch = useDispatch();
  const [sidebarDetail, setSidebarDetail] = useState({});
  const [isOpenSiderBar, setIsOpenSiderBar] = useState(false);
  const [isChatDetailsNotificationOpen, setIsChatDetailsNotificationOpen] =
    useState(false);
  const [isChatDetailsMembersOpen, setIsChatDetailsMembersOpen] =
    useState(false);
  const [isMediaFilesOpen, setIsMediaFilesOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState("");
  const [cropedFile, setCropedFile] = useState();
  const [outputBase64, setOutput] = useState(null);
  const [groupProfile, setGroupProfile] = useState(null);
  const [groupName, setGroupName] = useState(groupMemberList?.groupTitle || "");
  const [errmsg, setErrorMsg] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [cropper, setCropper] = useState(null);
  const [newMediaList, setNewMedeaList] = useState([]);
  const [isMediaSelected, setIsMediaSelected] = useState(true);
  const [isFileSelected, setIsFileSelected] = useState(false);
  const [isLinkSelected, setIsLinkSelected] = useState(false);
  const [showPreview, setPreview] = useState(false);
  const [showFileData, setFileData] = useState([]);
  const [filePreview, setFilePreview] = useState("");
  const [selectedImageVideo, setSelectedImageVideo] = useState("");
  const [previewDisplay, setPreviewDisplay] = useState(false);

  //Get Mute ChatList Data
  const muteNotificationList = useSelector(
    (state) => state?.muteNotificationReducer
  );

  const chatAllUsers = useSelector((state) => state.ReduAllUsersList);
  //Get media files and link Data
  const mediaList = useSelector((state) => state?.AllMediaPreview);

  useEffect(() => {
    const data = formatMediaPreviewMessagesAllMedia(mediaList);
    setNewMedeaList(data);
  }, [mediaList]);

  const filesList = useSelector((state) => state?.AllChatFileList);

  const linkList = useSelector((state) => state?.AllUrlList);

  // calling API For Mute ChatList
  const getMuteNotificationList = async () => {
    dispatch(getMuteChatIdListForUser());
  };

  // to Call Function for set Group name and profile
  useEffect(() => {
    setGroupName(groupMemberList?.groupTitle);
    setGroupProfile(groupMemberList?.groupImage);
  }, [groupMemberList]);

  // to set user profile data
  useEffect(() => {
    setIsOpenSiderBar(true);
    getMuteNotificationList();
    if (type === "user") {
      authService.getmemberprofile(userid).then((response) => {
        if (response.data.status) {
          let formatData = {
            name: `${
              response?.data?.data &&
              (response.data.data?.first_name || response.data.data?.first_name)
                ? `${response.data.data?.first_name} ${response.data.data?.last_name}`
                : ""
            }`,
            profilePic: response?.data?.data?.profileImg.length
              ? response.data.data.profileImg
              : "",
            dateTimeSinceBeenMember: "",
          };

          setSidebarDetail({ ...sidebarDetail, [userid]: formatData });
        }
      });
    }
  }, [userid, type]);

  // Edit group receive event to update data
  useEffect(() => {
    socket.on("edit-group-receive", (res) => {
      if (res.message.status) {
        if (res.message.data.created_by._id === auth.userId) {
          socket.emit("group-member-detail", {
            groupId: res.message.data._id,
          });
          socket.emit("newChatList", {
            id: auth.userId,
          });
          setIsLoader(false);
          setEditGroup(false);
        }
      } else {
        if (res.message.data.created_by._id === auth.userId) {
          setIsLoader(false);
        }
        socket.emit("group-member-detail", {
          groupId: res.message.data._id,
        });
        socket.emit("newChatList", {
          id: auth.userId,
        });
      }
    });
  }, [socket]);

  // onClick notification right side
  const onClickNotification = () => {
    setIsOpenSiderBar(false);
    setIsChatDetailsNotificationOpen(true);
  };
  // onClick back button in notification
  const onClickBackNotificationButton = () => {
    setIsOpenSiderBar(true);
    setIsChatDetailsNotificationOpen(false);
  };
  // to Change notification data and update that
  const onChangeNotificationSwitch = () => {
    const payload = {
      chatId: userid,
      unMute: muteNotificationList?.muteNotification?.filter(
        (muteData) => userid === muteData.chatId
      ).length
        ? true
        : false,
      dateTime: "",
      noLimit: muteNotificationList?.muteNotification?.filter(
        (muteData) => userid === muteData.chatId
      ).length
        ? false
        : true,
    };

    // Call the API with the payload
    authService
      .muteChatForAll(payload)
      .then((response) => {
        if (response.data.status) {
          getMuteNotificationList();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // For Change badge switch toggle
  const onChangeBadgesSwitch = () => {
    const payload = {
      chatId: userid,
    };
    authService.badgesForNotification(payload).then((response) => {
      if (response.data.status) {
        getMuteNotificationList();
      }
    });
  };

  //onClick members details right side
  const onClickMemberDetails = () => {
    setIsOpenSiderBar(false);
    setIsChatDetailsMembersOpen(true);
  };
  // onClick member details back button
  const onClickBackMemberDetails = () => {
    setIsOpenSiderBar(true);
    setIsChatDetailsMembersOpen(false);
  };
  // for to search group or channel members in right side
  const handleSearchClick = (e) => {
    setSearchValue(e.target.value);
  };
  // hanbdle search clear
  const handleSearchClear = () => {
    setSearchValue("");
  };

  // for heandle media and files sidebar

  const onClickMediaDetails = () => {
    setIsOpenSiderBar(false);
    setIsMediaFilesOpen(true);
  };

  const onClickBackMediaFiles = () => {
    setIsOpenSiderBar(true);
    setIsMediaFilesOpen(false);
  };

  // for preview
  //onClick preview handle
  const handlePreviewClick = (id, type) => {
    if (type === "file") {
      const data = {
        url: id.key,
        fileName: id.key?.split("?")[0].split("-GRPCHATUNIQUE-").pop(),
      };
      setFileData(data);
    }
    setSelectedImageVideo(id);
    setFilePreview(type);
    setPreview(true);
  };

  // onClick Close media preview popup
  const handleClosePreview = () => {
    setPreview(false);
    setPreviewDisplay(false);
    setSelectedImageVideo("");
  };

  // pass avatar letter in data
  const AvtarLetter =
    type === "user"
      ? sidebarDetail && sidebarDetail[userid]
        ? sidebarDetail[userid]?.name?.charAt(0).toUpperCase()
        : ""
      : type === "userChatGroup"
      ? groupMemberList?.groupTitle?.charAt(0).toUpperCase()
      : type === "chatChannel"
      ? groupMemberList?.channelData?.channelName?.charAt(0).toUpperCase()
      : "";

  //to pass profile data in group and channel
  const profileImage =
    type === "user"
      ? sidebarDetail && sidebarDetail[userid]
        ? sidebarDetail[userid].profilePic
        : ""
      : type === "userChatGroup"
      ? groupMemberList?.groupImage
      : type === "chatChannel"
      ? groupMemberList?.channelData?.channelIcon
      : "";

  // Modify the existing condition for src
  const src =
    profileImage &&
    profileImage.split("?")[0].split("/")[
      profileImage.split("?")[0].split("/").length - 1
    ].length &&
    profileImage.startsWith("https")
      ? profileImage
      : profileImage &&
        profileImage.split("?")[0].split("/")[
          profileImage.split("?")[0].split("/").length - 1
        ].length
      ? process.env.REACT_APP_AWS_IMG_VID_PATH + profileImage
      : "";

  //Will call on dropping or setting file to dropzone
  const assignFiles = (acceptedFile) => {
    setModalOpen(true);
    setFileName(Math.random().toString() + acceptedFile.name);
    setFile(URL.createObjectURL(acceptedFile));
  };

  //call after clicking crop and save button
  const CropDone = () => {
    var canvas;
    if (cropper) {
      canvas = cropper.getCroppedCanvas();
      if (canvas !== null) {
        const base64Image = canvas.toDataURL("image/jpeg");
        const convertedUrlToFile = dataURLtoFile(base64Image);
        setCropedFile(convertedUrlToFile);
        setModalOpen(false);
        setGroupProfile(URL.createObjectURL(convertedUrlToFile));
        setFile("");
      }
    }
  };

  useEffect(() => {
    if (outputBase64 != null) {
      const convertedUrlToFile = dataURLtoFile(outputBase64);
      setCropedFile(convertedUrlToFile);
    }
  }, [outputBase64]);

  //when file is accesing to image load
  useEffect(() => {
    if (file !== "") {
      var img = new Image();
      img.src = file;
      img.onload = function () {
        document.querySelector("#cropperImg").src = file;
        var coef = 0;
        img.width > img.height
          ? (coef = calculateCoeff(img.width, "width"))
          : (coef = calculateCoeff(img.height, "height"));
        createCropper(coef * img.width, coef * img.height);
      };
    }
  }, [file]);

  // calculate to width
  function calculateCoeff(img_value, property) {
    var x = 0;
    property == "width" ? (x = 200) : (x = 100);
    return ((x * 100) / img_value) * 0.01;
  }

  // crerate Cropper to crop image
  function createCropper(width, height) {
    var image = document.getElementById("cropperImg");
    if (image !== undefined && image !== null) {
      var cropper_ = new Cropper(image, {
        movable: false,
        minContainerHeight: height,
        minContainerWidth: width,
        minCanvasHeight: height,
        minCanvasWidth: width,
        zoomable: false,
        responsive: false,
        restore: false,
        modal: true,
        scalable: false,
        minCropBoxWidth: 200,
        minCropBoxHeight: 200,
        viewMode: 3,
        aspectRatio: 1,
        highlight: true,
        autoCrop: true,
      });

      setCropper(cropper_);
    }
  }

  //covert function dataURLtoFile
  const dataURLtoFile = (base64Img) => {
    const arr = base64Img.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) u8arr[n] = bstr.charCodeAt(n);

    return new File([u8arr], fileName, { type: mime });
  };

  //edit socket logic
  const editSocket = (image) => {
    let payload = {
      sender: auth.userId,
      groupid: userid,
      group_image: image.length > 0 ? image : undefined,
      group_name: groupName,
      participents: [],
      time_stamp: moment.utc().format("YYYY-MM-DDTHH:mm:ss.SSSSSS[Z]"),
    };
    socket.emit("edit-group", payload);
  };

  // onClick Edit group image and name
  const onClickEditSave = () => {
    if (groupName) {
      setIsLoader(true);
      var formdata = new FormData();
      formdata.append("media", cropedFile);
      if (cropedFile) {
        authService
          .uploadGroupImage(formdata)
          .then((res) => {
            editSocket(res.data.media[0].key);
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        editSocket("");
      }
    } else {
      setErrorMsg("Please add group name and image to change");
    }
  };

  //onChange Edit group title
  const handleChangeInput = (e) => {
    setGroupName(e.target.value);
  };

  // to pass this for muteNotification
  const isMuted = muteNotificationList?.muteNotification?.filter(
    (muteData) => userid === muteData.chatId
  ).length
    ? false
    : true;

  // to pass this for Badges
  const isBadges = muteNotificationList?.notificationBadgeNotDisplay?.filter(
    (muteData) => userid === muteData
  ).length
    ? false
    : true;

  // onClick back button in edit group
  const onClickBackButton = () => {
    setIsOpenSiderBar(true);
    setEditGroup(false);
  };

  const handleTabClick = (tabIndex) => {
    if (tabIndex === 0) {
      setIsMediaSelected(true);
      setIsFileSelected(false);
      setIsLinkSelected(false);
    } else if (tabIndex === 1) {
      setIsMediaSelected(false);
      setIsFileSelected(true);
      setIsLinkSelected(false);
    } else if (tabIndex === 2) {
      setIsMediaSelected(false);
      setIsFileSelected(false);
      setIsLinkSelected(true);
    }
  };
  const onClickRemoveMember = (removeMemberId) => {
    socket.emit("remove-member", {
      groupid: userid,
      authUserId: auth.userId,
      removemember: [removeMemberId],
      time_stamp: moment.utc().format("YYYY-MM-DDTHH:mm:ss.SSSSSS[Z]"),
    });
    socket.emit("remove-member-activity", {
      groupid: userid,
      authUserId: auth.userId,
      removemember: [removeMemberId],
      time_stamp: moment.utc().format("YYYY-MM-DDTHH:mm:ss.SSSSSS[Z]"),
    });
    handleRemoveMember(removeMemberId);
  };
  let onlineStatus =
    chatAllUsers &&
    chatAllUsers?.filter((user) => user?.id === userid && user?.onlineStatus)
      .length;

  return (
    <>
      {errmsg}
      {isOpenSiderBar && !editGroup && (
        <ChatDetails
          hasStatus={
            type === "chatChannel" || type === "userChatGroup" ? false : true
          }
          isOnline={onlineStatus ? "online" : "offline"}
          type={
            type === "chatChannel"
              ? "Channel"
              : type === "userChatGroup"
              ? "Group"
              : "Message"
          }
          channelOrGroupNameOrName={
            type === "user"
              ? sidebarDetail && sidebarDetail[userid]
                ? sidebarDetail[userid].name
                : ""
              : type === "userChatGroup"
              ? groupMemberList?.groupTitle
              : type === "chatChannel"
              ? groupMemberList?.channelData?.channelName
              : ""
          }
          src={src}
          letter={AvtarLetter}
          isAdmin={
            type === "userChatGroup" &&
            groupMemberList &&
            groupMemberList?.members?.filter(
              (member) =>
                member.userId === auth.userId &&
                member.user_type === "adminuser"
            ).length
              ? true
              : false
          }
          handleButtonClick={handleAddMemberClick}
          onClickNotification={onClickNotification}
          onClickMemberDetails={onClickMemberDetails}
          onClickMediaDetails={onClickMediaDetails}
          membersCount={
            type === "chatChannel"
              ? groupMemberList?.membersList?.length - 1
              : groupMemberList?.members?.length
          }
          membersText={`${
            type === "chatChannel"
              ? groupMemberList?.membersList?.length - 1
              : groupMemberList?.members?.length - 1
          } members ${type !== "chatChannel" ? `1 admin` : ""}`}
          notificationTitle={`${isMuted ? "On" : "Off"}: Push notifications${
            isBadges || !isMuted ? ", Badges" : ""
          }`}
          mediaText={`${
            mediaList.length === 0 &&
            filesList.length === 0 &&
            linkList.length === 0
              ? "No media"
              : mediaList.length !== 0
              ? `${
                  mediaList.reduce((accumulator, currentValue) => {
                    return accumulator + currentValue?.images.length;
                  }, 0) +
                  mediaList.reduce((accumulator, currentValue) => {
                    return accumulator + currentValue?.videos.length;
                  }, 0)
                } media `
              : ""
          }${mediaList.length !== 0 && filesList.length !== 0 ? ", " : ""}${
            filesList.length !== 0
              ? `${filesList.reduce((accumulator, currentValue) => {
                  return accumulator + currentValue?.documents.length;
                }, 0)} files `
              : ""
          }${filesList.length !== 0 && linkList.length !== 0 ? ", " : ""}${
            linkList.length !== 0 ? `${linkList.length} links` : ""
          }`}
        />
      )}

      {isChatDetailsNotificationOpen && !editGroup && (
        <ChatDetailsNotifications
          onClickBackButton={onClickBackNotificationButton}
          onChangeNotificationSwitch={onChangeNotificationSwitch}
          onChangeBadgesSwitch={onChangeBadgesSwitch}
          isMuted={isMuted}
          isBadges={isBadges}
        />
      )}

      {isChatDetailsMembersOpen && !editGroup && (
        <ChatDetailsMembers
          isAdmin={
            type === "userChatGroup" &&
            groupMemberList &&
            groupMemberList?.members?.filter(
              (member) =>
                member.userId === auth.userId &&
                member.user_type === "adminuser"
            ).length
              ? true
              : false
          }
          groupMemberList={
            type === "chatChannel"
              ? groupMemberList.membersList
              : groupMemberList.members
          }
          onClickBackMemberDetails={onClickBackMemberDetails}
          type={type}
          handleSearchClick={handleSearchClick}
          handleSearchClear={handleSearchClear}
          searchValue={searchValue}
          onClickRemoveMember={onClickRemoveMember}
          chatAllUsers={chatAllUsers}
        />
      )}
      {isMediaFilesOpen && !editGroup && (
        <ChatDetailsMediaMembers
          onClickBackMediaFiles={onClickBackMediaFiles}
          handleTabClick={handleTabClick}
          isSelected={isMediaSelected}
          isSelected1={isFileSelected}
          isSelected2={isLinkSelected}
          newMediaList={newMediaList}
          linkList={linkList}
          filesList={filesList}
          handlePreviewClick={handlePreviewClick}
        />
      )}

      <ModelPopup
        className="model-image-cropper"
        isFooter={true}
        isHeader={true}
        buttonType="onebutton"
        buttonText1="Crop & Save"
        isOpen={modalOpen}
        modelSize="480"
        onClose={() => setModalOpen(false)}
        handleClick2ndButton={CropDone}
        headingLable="Replace profile picture"
      >
        <div className="cstm-replace-pp">
          {file && (
            <div
              id="cropImgDis"
              className="cropImgDis"
              style={{ width: `${0}px` }}
            >
              <img id="cropperImg" className="cstmCropperImg" />
            </div>
          )}
        </div>
      </ModelPopup>

      {editGroup && (
        <ChatDetailsEdit
          onClickBackButton={onClickBackButton}
          handleImageUpload={assignFiles}
          handleChangeInput={handleChangeInput}
          groupName={groupName}
          isAdmin={false}
          src={groupProfile}
          onClickEditSave={onClickEditSave}
          isLoader={isLoader}
        />
      )}
      <MediaPreview
        mediaPreviewData={newMediaList} //formated data
        showPreview={showPreview} //  open model
        handleClosePreview={handleClosePreview} //close model
        mediaPreviewType={
          filePreview === "media"
            ? "media"
            : filePreview === "file"
            ? "file"
            : ""
        }
        selectedImageVideo={selectedImageVideo} //pass id
        showFileData={showFileData} // url name src object
        previewAttchamentDisplay={false}
      />
    </>
  );
};

export default ChatRightSideBar;
