import React, { useEffect, useState } from "react";
import Header from "components/Header";
import GlobalMenuPopup from "components/UserComponents/GlobalMenuPopup";
import useAuth from "Hooks/useAuth";
import ChatList from "pages/ChatModule/MainComponent/ChatList";
import ChatMessageComponents from "pages/ChatModule/MainComponent/ChatMessageComponents";
import { Outlet, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getAllValidUser } from "../../Action";

const ChatLayout = () => {
  const { auth, setAuth } = useAuth();
  const { userid, type } = useParams();
  const [globalmenu, setglobalmenu] = useState(false);
  const [addMemberSelected, setAddMemberSelected] = useState(false);
  const [alreadyExistingMembers, setAlreadyExistingMembers] = useState([]);
  const [editorClear, setEditorClear] = useState(false);
  const dispatch = useDispatch();

  const handleAddMemberClick = () => {
    setAddMemberSelected(true);
  };
  const removeAddMemberSelected = () => {
    setAddMemberSelected(false);
  };
  const getAllExistingMembers = (members) => {
    setAlreadyExistingMembers(members);
  };
  useEffect(() => {
    dispatch(getAllValidUser());
  }, [userid, type]);
  return (
    <>
      <Header type={auth.currentEdge.type} setglobalmenu={setglobalmenu} />
      <div className="chat-main-body flex">
        <div className="chat-sidebar-left">
          <ChatList
            addMemberSelected={addMemberSelected}
            alreadyExistingMembers={alreadyExistingMembers}
            removeAddMemberSelected={removeAddMemberSelected}
            setEditorClear={setEditorClear}
          />
        </div>
        <div className="chat-center-sec flex flex-row">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default ChatLayout;
