import React from "react";
import PropTypes from "prop-types";
import "./TabsFluid.css";
import "../Separators/Separators.css";
import "../../Styles/Theme/Theme.css";
import TabItemFluid from "./HelperComponent/TabItemFluid";

const TabsFluid = ({
  label = "Label",
  isSelected = true,
  counter = "3",
  hasCounter = true,
  hasBadge = true,
  hasTab3 = false,
  hasTab4 = false,
  hasTab5 = false,
  label1 = "Label",
  isSelected1 = true,
  counter1 = "3",
  hasCounter1 = true,
  hasBadge1 = true,
  label2 = "Label",
  isSelected2 = true,
  counter2 = "3",
  hasCounter2 = true,
  hasBadge2 = true,
  label3 = "Label",
  isSelected3 = true,
  counter3 = "3",
  hasCounter3 = true,
  hasBadge3 = true,
  label4 = "Label",
  isSelected4 = true,
  counter4 = "3",
  hasCounter4 = true,
  hasBadge4 = true,
  id, // Removed one id prop
  handleOnClick,
  hasCounterTabs,
  hasCounterTabs1,
  handleClick, // Removed one handleClick prop
}) => {
  return (
    <div className="tabs-fluid">
      <TabItemFluid
        counter={counter}
        isSelected={isSelected}
        label={label}
        hasBadge={hasBadge}
        hasCounter={hasCounterTabs}
        handleOnClick={() => handleClick(0)}
        id={id}
      />
      <TabItemFluid
        counter={counter1}
        isSelected={isSelected1}
        label={label1}
        hasBadge={hasBadge1}
        hasCounter={hasCounterTabs1}
        handleOnClick={() => handleClick(1)}
        id={id}
      />
      {hasTab3 && (
        <TabItemFluid
          counter={counter2}
          isSelected={isSelected2}
          label={label2}
          hasCounter={hasCounter2}
          hasBadge={hasBadge2}
          handleOnClick={() => handleClick(2)}
          id={id}
        />
      )}
      {hasTab4 && (
        <TabItemFluid
          counter={counter3}
          isSelected={isSelected3}
          label={label3}
          hasCounter={hasCounter3}
          hasBadge={hasBadge3}
          handleOnClick={handleOnClick}
          id={id}
        />
      )}
      {hasTab5 && (
        <TabItemFluid
          counter={counter4}
          isSelected={isSelected4}
          label={label4}
          hasCounter={hasCounter4}
          hasBadge={hasBadge4}
          handleOnClick={handleOnClick}
          id={id}
        />
      )}
    </div>
  );
};

TabsFluid.propTypes = {
  hasTab3: PropTypes.bool,
  hasTab4: PropTypes.bool,
  hasTab5: PropTypes.bool,
  label: PropTypes.string,
  isSelected: PropTypes.bool,
  id: PropTypes.string, // Removed one id prop
  hasBadge: PropTypes.bool,
  hasCounter: PropTypes.bool,
  counter: PropTypes.string,
  label1: PropTypes.string,
  isSelected1: PropTypes.bool,
  hasBadge1: PropTypes.bool,
  hasCounter1: PropTypes.bool,
  counter1: PropTypes.string,
  label2: PropTypes.string,
  isSelected2: PropTypes.bool,
  hasBadge2: PropTypes.bool,
  hasCounter2: PropTypes.bool,
  counter2: PropTypes.string,
  label3: PropTypes.string,
  isSelected3: PropTypes.bool,
  hasBadge3: PropTypes.bool,
  hasCounter3: PropTypes.bool,
  counter3: PropTypes.string,
  label4: PropTypes.string,
  isSelected4: PropTypes.bool,
  hasBadge4: PropTypes.bool,
  hasCounter4: PropTypes.bool,
  counter4: PropTypes.string,
  handleOnClick: PropTypes.func,
};

export default TabsFluid;
