import React from "react";
import AuthenticatedLayout from "layouts/AuthenticatedLayout";
import LazyLoadComponent from "components/LazyLoadComponent";

import SubscriptionLayout from "layouts/SubscriptionLayout";
import EdgeLayout from "layouts/EdgeLayout";
import SettingsLayout from "layouts/SettingsLayout";

import { ADMIN_SCOPE_ROUTES as EDGE_SCOPE_ADMIN_SCOPE_ROUTES } from "routes/EdgeRoutes/menuList";
import { ADMIN_SCOPE_ROUTES as SUBSCRIPTION_ADMIN_SCOPE_ROUTES } from "routes/SubscriptionRoutes/menuList.js";
import { ADMIN_SCOPE_ROUTES as SETTINGS_ADMIN_SCOPE_ROUTES } from "routes/SettingsRoutes/menuList";
import { ADMIN_SCOPE_ROUTES } from "routes/PublicRoutes/menuList";
import { SCOPE_ROUTES } from "routes/AuthenticatedRoutes/menuList";
import ChatLayout from "layouts/ChatLayout";

const NotFound = React.lazy(() => import("../../pages/NotFound"));
const Checkout = React.lazy(() => import("../../pages/CheckoutForm/index.jsx"));
const SubscriptionReturn = React.lazy(() =>
  import("../../pages/SubscriptionReturn/index.jsx")
);

const SubscriptionUpdate = React.lazy(() =>
  import("../../pages/SubscriptionUpdate/index.jsx")
);

const SubscriptionCancel = React.lazy(() =>
  import("../../pages/SubscriptionCancel/index.jsx")
);

const SubscriptionRenew = React.lazy(() =>
  import("../../pages/SubscriptionRenew/index.jsx")
);

// submit application form
const SubmitApplicationForm = React.lazy(() =>
  import("../../pages/SubmitApplicationForm/index.jsx")
);

const Pricing = React.lazy(() => import("../../pages/Pricing/index"));

// edge login routes
const EdgeLogin = React.lazy(() => import("../../pages/EdgeLogin/index.jsx"));
const CreateCommunity = React.lazy(() =>
  import("../../pages/CreateCommunity/index.jsx")
);

const DiscoverCommunities = React.lazy(() =>
  import("../../pages/DiscoverCommunities/DiscoverCommunities.jsx")
);

const CommunityProfile = React.lazy(() =>
  import("../../pages/CommunityProfile/index.jsx")
);

// subscription info
const SubscriptionInfo = React.lazy(() =>
  import("../../pages/SubscriptionInfo/index")
);

// auth required check
const RequireAuth = React.lazy(() =>
  import("../../components/RequireAuth/index")
);

// news
const NewsList = React.lazy(() =>
  import("../../pages/NewsManagement/HomePage")
);

const NesDetail = React.lazy(() =>
  import("../../pages/NewsManagement/NewsDetail")
);
// videos

const VideosList = React.lazy(() =>
  import("../../pages/FrontendContentLibrary/ContentListing")
);

const VideoDetail = React.lazy(() =>
  import("../../pages/FrontendContentLibrary/ContentDetail")
);

// documents
const DocumentList = React.lazy(() =>
  import("../../pages/DocumentManagement/DocumentMainPage")
);

// partners
const PartnersList = React.lazy(() =>
  import("../../pages/PartnerManagement/Partners")
);

const PartnerDetail = React.lazy(() =>
  import("../../pages/PartnerManagement/PartnerInfo")
);

// chats
const ChatMessageComponents = React.lazy(() =>
  import("../../pages/ChatModule/MainComponent/ChatMessageComponents")
);
const AuthenticatedRoutes = [
  {
    path: "/",
    element: LazyLoadComponent(<AuthenticatedLayout />),
    children: [
      {
        index: true,
        element: LazyLoadComponent(<>Hello</>),
      },
      {
        path: SCOPE_ROUTES.news.basePath,
        children: [
          {
            index: true,
            element: LazyLoadComponent(
              <RequireAuth access={"news"}>
                <NewsList />
              </RequireAuth>
            ),
          },
          {
            path: SCOPE_ROUTES.news.actionPath.detail,
            element: LazyLoadComponent(
              <RequireAuth access={"news"}>
                <NesDetail />
              </RequireAuth>
            ),
          },
        ],
      },
      {
        path: SCOPE_ROUTES.videos.basePath,
        children: [
          {
            index: true,
            element: LazyLoadComponent(
              <RequireAuth access={"videos"}>
                <VideosList />
              </RequireAuth>
            ),
          },
          {
            exact: true,
            path: SCOPE_ROUTES.videos.actionPath.detail,
            element: LazyLoadComponent(
              <RequireAuth access={"videos"}>
                <VideoDetail />
              </RequireAuth>
            ),
          },
        ],
      },
      {
        path: SCOPE_ROUTES.partners.basePath,
        children: [
          {
            exact: true,
            index: true,
            element: LazyLoadComponent(
              <RequireAuth access={"partners"}>
                <PartnersList />
              </RequireAuth>
            ),
          },
          {
            exact: true,
            path: SCOPE_ROUTES.partners.actionPath.detail,
            element: LazyLoadComponent(
              <RequireAuth access={"partners"}>
                <PartnerDetail />
              </RequireAuth>
            ),
          },
        ],
      },
      {
        path: SCOPE_ROUTES.events.basePath,

        element: LazyLoadComponent(
          <RequireAuth access={"events"}>
            <>events main page</>
          </RequireAuth>
        ),
      },
      {
        path: SCOPE_ROUTES.documents.basePath,

        element: LazyLoadComponent(
          <RequireAuth access={"documents"}>
            <DocumentList />
          </RequireAuth>
        ),
      },
      {
        path: SCOPE_ROUTES.network.actionPath.facebookGroups,
        element: LazyLoadComponent(<>facebookGroups</>),
      },
      {
        path: SCOPE_ROUTES.programs.actionPath.chapters,
        element: LazyLoadComponent(<>chapters</>),
      },
      {
        path: SCOPE_ROUTES.programs.actionPath.squads,
        element: LazyLoadComponent(<>squads</>),
      },
      {
        path: SCOPE_ROUTES.programs.actionPath.memberLink,
        element: LazyLoadComponent(<>memberLink</>),
      },
      {
        path: SCOPE_ROUTES.censusDataGoogleStudio.basePath,
        element: LazyLoadComponent(<>censusDataGoogleStudio</>),
      },
      {
        path: SCOPE_ROUTES.directories.actionPath.airbnbDirectory,
        element: LazyLoadComponent(<>airbnbDirectory</>),
      },
      {
        path: SCOPE_ROUTES.directories.actionPath.bookClub,
        element: LazyLoadComponent(<>bookClub</>),
      },
      {
        path: SCOPE_ROUTES.directories.actionPath.employeeBlocklist,
        element: LazyLoadComponent(<>employeeBlocklist</>),
      },
      {
        path: SCOPE_ROUTES.directories.actionPath.employeeHire,
        element: LazyLoadComponent(<>employeeHire</>),
      },
      {
        path: SCOPE_ROUTES.directories.actionPath.employeeMatch,
        element: LazyLoadComponent(<>employeeMatch</>),
      },
      {
        path: SCOPE_ROUTES.directories.actionPath.freelancers,
        element: LazyLoadComponent(<>freelancers</>),
      },
      {
        path: SCOPE_ROUTES.directories.actionPath.giftGuide,
        element: LazyLoadComponent(<>giftGuide</>),
      },
      {
        path: SCOPE_ROUTES.info.actionPath.team,
        element: LazyLoadComponent(<>team</>),
      },
      {
        path: SCOPE_ROUTES.info.actionPath.values,
        element: LazyLoadComponent(<>values</>),
      },
      {
        path: SCOPE_ROUTES.info.actionPath.photos,
        element: LazyLoadComponent(<>photos</>),
      },
      {
        path: SCOPE_ROUTES.info.actionPath.memberScore,
        element: LazyLoadComponent(<>memberScore</>),
      },
      {
        path: "*",
        element: LazyLoadComponent(<NotFound />),
      },
    ],
  },
  {
    path: SCOPE_ROUTES.chats.basePath,
    element: LazyLoadComponent(<ChatLayout />),
    children: [
      {
        exact: true,
        path: SCOPE_ROUTES.chats.actionPath.list,
        element: LazyLoadComponent(<ChatMessageComponents />),
      },
    ],
  },
  {
    path: SUBSCRIPTION_ADMIN_SCOPE_ROUTES.subscription.basePath,
    element: LazyLoadComponent(<SubscriptionLayout />),
    children: [
      {
        exact: true,
        path: SUBSCRIPTION_ADMIN_SCOPE_ROUTES.subscription.actionPath
          .subscriptionCheckout,
        element: LazyLoadComponent(<Checkout />),
      },
      {
        exact: true,
        path: SUBSCRIPTION_ADMIN_SCOPE_ROUTES.subscription.actionPath
          .tierUpgrade,
        element: LazyLoadComponent(<>tier upgrade</>),
      },
      {
        exact: true,
        path: SUBSCRIPTION_ADMIN_SCOPE_ROUTES.subscription.actionPath.update,
        element: LazyLoadComponent(<SubscriptionUpdate />),
      },
      {
        exact: true,
        path: SUBSCRIPTION_ADMIN_SCOPE_ROUTES.subscription.actionPath.cancel,
        element: LazyLoadComponent(<SubscriptionCancel />),
      },
      {
        exact: true,
        path: SUBSCRIPTION_ADMIN_SCOPE_ROUTES.subscription.actionPath
          .subscriptionReturn,
        element: LazyLoadComponent(<SubscriptionReturn />),
      },
      {
        exact: true,
        path: SUBSCRIPTION_ADMIN_SCOPE_ROUTES.subscription.actionPath.pricing,
        element: LazyLoadComponent(<Pricing />),
      },
      {
        exact: true,
        path: SUBSCRIPTION_ADMIN_SCOPE_ROUTES.subscription.actionPath.renew,
        element: LazyLoadComponent(<SubscriptionRenew />),
      },
      {
        exact: true,
        path: SUBSCRIPTION_ADMIN_SCOPE_ROUTES.subscription.actionPath.submit,
        element: LazyLoadComponent(<SubmitApplicationForm />),
      },
    ],
  },
  {
    path: SETTINGS_ADMIN_SCOPE_ROUTES.settings.basePath,
    element: LazyLoadComponent(<SettingsLayout />),
    children: [
      {
        exact: true,
        path: SETTINGS_ADMIN_SCOPE_ROUTES.settings.actionPath.edit,
        element: LazyLoadComponent(<>edit</>),
      },
      {
        exact: true,
        path: SETTINGS_ADMIN_SCOPE_ROUTES.settings.actionPath.profile,
        element: LazyLoadComponent(<>profile </>),
      },
      {
        exact: true,
        path: SETTINGS_ADMIN_SCOPE_ROUTES.settings.actionPath.users,
        element: LazyLoadComponent(<>users </>),
      },
      {
        exact: true,
        path: SETTINGS_ADMIN_SCOPE_ROUTES.settings.actionPath.contactUs,
        element: LazyLoadComponent(<>contactus </>),
      },
      {
        exact: true,
        path: SETTINGS_ADMIN_SCOPE_ROUTES.settings.actionPath.privacy,
        element: LazyLoadComponent(<>privacy </>),
      },
      {
        exact: true,
        path: SETTINGS_ADMIN_SCOPE_ROUTES.settings.actionPath.terms,
        element: LazyLoadComponent(<>terms </>),
      },
      {
        exact: true,
        path: SETTINGS_ADMIN_SCOPE_ROUTES.settings.actionPath.subscription,
        element: LazyLoadComponent(<SubscriptionInfo />),
      },
      {
        exact: true,
        path: EDGE_SCOPE_ADMIN_SCOPE_ROUTES.edgeLogin.basePath,
        element: LazyLoadComponent(<EdgeLogin />),
      },
      {
        exact: true,
        path: ADMIN_SCOPE_ROUTES.discover.basePath,
        element: LazyLoadComponent(<DiscoverCommunities />),
      },
    ],
  },
  {
    exact: true,
    path: EDGE_SCOPE_ADMIN_SCOPE_ROUTES.edge.basePath,
    element: LazyLoadComponent(<SettingsLayout />),
    children: [
      { index: true, element: LazyLoadComponent(<CreateCommunity />) },
    ],
  },
  {
    path: ADMIN_SCOPE_ROUTES.communityProfile.basePath,
    element: LazyLoadComponent(<SettingsLayout />),
    children: [
      { index: true, element: LazyLoadComponent(<CommunityProfile />) },
    ],
  },
];

export default AuthenticatedRoutes;
