export const ADMIN_SCOPE_ROUTES = {
  subscription: {
    basePath: "subscriptions",
    actionPath: {
      subscriptionCheckout: "checkout",
      tierUpgrade: "tier-upgrade",
      subscriptionSuccessful: "subscription-successful",
      subscriptionReturn: "subscription-return",
      pricing: "pricing",
      update: "update/:tier_id",
      cancel: "cancel/:tier_id",
      renew: "renew",
      submit: "application-forms/submit/:application_form_id",
    },
  },
};
