import React from "react";

const IconNavScorecardFilled = () => {
  return (
    <div className="mds-icons filled">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
      >
        <path
          fillRule="evenodd"
          d="M2 13C2 7.477 6.477 3 12 3s10 4.477 10 10a9.955 9.955 0 0 1-1.97 5.96c-.261.352-.683.54-1.12.54H5.09c-.437 0-.859-.188-1.12-.54A9.955 9.955 0 0 1 2 13Zm6.03-5.03a.75.75 0 0 0-1.06 1.06l2.445 2.446a3 3 0 1 0 1.06-1.06L8.031 7.97Zm2.52 4.641.92.92a.75.75 0 1 0 1.06-1.061l-.918-.92A1.503 1.503 0 0 1 13.5 13a1.5 1.5 0 1 1-2.95-.389Z"
          clipRule="evenodd"
        />
      </svg>
    </div>
  );
};

export default IconNavScorecardFilled;
