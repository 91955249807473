import React from 'react'
import PropTypes from "prop-types"
const EditIcon = ({ width = 24, height = 24, iconColor = "#000" }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 24 24"
        >
            <path
                fill={iconColor}
                fillRule="evenodd"
                d="M16.869 10.817c1.83-2.294 1.847-2.32 1.865-2.348.822-1.303.454-3.173-.895-3.976l-1.723-1.34c-1.26-1.01-3.128-.828-4.16.407a.062.062 0 0 0-.006.006l-.006.007-9.42 11.782a2.371 2.371 0 0 0-.46 2.037l.82 3.47a.75.75 0 0 0 .73.578h.01l3.638-.046a2.447 2.447 0 0 0 1.886-.914c3.914-4.896 6.225-7.789 7.59-9.5a.76.76 0 0 0 .13-.163Zm-5.974-3.53-7.2 9.005a.879.879 0 0 0-.17.755l.68 2.885 3.04-.038a.949.949 0 0 0 .732-.352c1.978-2.475 5.058-6.328 7.177-8.982l-4.26-3.272Zm.937-1.171 4.26 3.27 1.41-1.768c.395-.643.114-1.514-.51-1.891a328.898 328.898 0 0 1-1.809-1.398 1.497 1.497 0 0 0-2.073.188l-1.278 1.599Z"
                clipRule="evenodd"
            />
            <path
                fill={iconColor}
                d="M20.75 21.44h-7.253a.75.75 0 0 1 0-1.5h7.253a.75.75 0 0 1 0 1.5Z"
            />
        </svg>
    )
}
EditIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    iconColor: PropTypes.string
}
export default EditIcon