import { createContext, useCallback, useEffect, useState } from "react";

const PreventionContext = createContext({});

export const PreventionProvider = ({ children }) => {
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  const handleBeforeUnload = useCallback(
    (event) => {
      if (unsavedChanges) {
        event.preventDefault();
        event.returnValue = ""; // This line is for older browsers
      }
    },
    [unsavedChanges]
  );
  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [handleBeforeUnload]);

  // This function use for prevention
  const handleSidebarLinkClick = (e) => {
    if (unsavedChanges) {
      const confirmation = window.confirm(
        "Are you sure you want to leave? You have unsaved changes."
      );
      if (!confirmation) {
        e.preventDefault();
        return; // Prevent navigation if the user cancels
      } else {
        setUnsavedChanges(false);
      }
    }
  };
  return (
    <PreventionContext.Provider
      value={{ unsavedChanges, setUnsavedChanges, handleSidebarLinkClick }}
    >
      {children}
    </PreventionContext.Provider>
  );
};

export default PreventionContext;
