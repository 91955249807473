import React from "react";

const IconNavNewsFilled = () => {
  return (
    <div className="mds-icons filled">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
      >
        <path
          fillRule="evenodd"
          d="M8.5 2A3.5 3.5 0 0 0 5 5.5v14A2.5 2.5 0 0 0 7.5 22h11a3.5 3.5 0 0 0 3.5-3.5v-13A3.5 3.5 0 0 0 18.5 2h-10ZM3.25 8.5a.75.75 0 0 0-1.5 0v12a.75.75 0 0 0 1.5 0v-12Zm5.5.5a.75.75 0 0 1 .75-.75h8a.75.75 0 0 1 0 1.5h-8A.75.75 0 0 1 8.75 9Zm.75 2.25a.75.75 0 0 0 0 1.5h8a.75.75 0 0 0 0-1.5h-8Zm0 3a.75.75 0 0 0 0 1.5h8a.75.75 0 0 0 0-1.5h-8Z"
          clipRule="evenodd"
        />
      </svg>
    </div>
  );
};

export default IconNavNewsFilled;
