import React from "react";
import PropTypes from "prop-types"
function MealIcon({ height = 24, width = 24, iconColor = "#000" }) {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={width}
                height={height}
                fill="none"
                viewBox="0 0 24 24"
            >
                <path
                    fill={iconColor}
                    fillRule="evenodd"
                    d="M2 11.5C2 5.705 6.705 1 12.5 1S23 5.705 23 11.5 18.295 22 12.5 22c-2.04 0-3.94-.58-5.54-1.576l2.62-2.712c.88.417 1.877.649 2.92.649a6.86 6.86 0 0 0 6.86-6.86 6.86 6.86 0 0 0-6.86-6.862A6.86 6.86 0 0 0 5.639 11.5c0 1.043.232 2.04.649 2.92l-2.712 2.62C2.58 15.44 2 13.54 2 11.5Zm14.84-.629-3.824 3.83c-.642.666-1.663.69-2.376.12l-7.553 7.801a1.21 1.21 0 1 1-1.71-1.712l7.79-7.565a1.786 1.786 0 0 1 .12-2.378L13.11 7.16c.19-.215.522-.215.736 0 .214.19.214.523 0 .737l-3.159 3.14a.259.259 0 0 0 0 .357l.404.404a.258.258 0 0 0 .356 0l3.136-3.14c.19-.214.522-.19.736 0 .213.19.213.523 0 .738l-3.136 3.14a.259.259 0 0 0 0 .356l.404.405a.258.258 0 0 0 .356 0l3.16-3.164c.19-.214.522-.214.736 0 .213.19.213.523 0 .737Z"
                    clipRule="evenodd"
                />
            </svg >
        </>
    );
}
MealIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    iconColor: PropTypes.string
}
export default MealIcon;
