import { Box } from "@mui/material";
import useAuth from "Hooks/useAuth";
import Header from "components/Header";
import GlobalMenuPopup from "components/UserComponents/GlobalMenuPopup";
import React, { useState } from "react";
import { Outlet } from "react-router-dom";

const SubscriptionLayout = () => {
  const { auth } = useAuth();
  const [globalmenu, setglobalmenu] = useState(false);

  return (
    <>
      <Header type={auth.currentEdge.type} setglobalmenu={setglobalmenu} />
      <Box width="100%" padding="116px 16px 0px 16px">
        <Box className="all-content-main-center">
          <Outlet />
        </Box>
      </Box>
      {globalmenu && (
        <GlobalMenuPopup
          setglobalmenu={setglobalmenu}
          globalmenu={globalmenu}
        />
      )}
    </>
  );
};

export default SubscriptionLayout;
