import React from "react";
import PropTypes from "prop-types";
const StarIcon = ({
  height = 24,
  width = 24,
  iconColor = "#000",
  isFilled = true,
  rounded = true,
  halfFilled = false
}) => {
  return (
    <>
      {rounded && isFilled ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            fill={iconColor}
            fillRule="evenodd"
            d="M10.872 2.686c.458-.915 1.798-.915 2.256 0l2.461 4.92 5.837.833c1.013.145 1.431 1.346.717 2.06l-4.202 4.198.845 5.911c.14.98-.912 1.706-1.816 1.255L12 19.38l-4.97 2.483c-.904.451-1.956-.275-1.816-1.255l.845-5.91-4.202-4.2c-.714-.713-.296-1.914.717-2.06l5.836-.832 2.462-4.92Z"
            clipRule="evenodd"
          />
        </svg>
      ) : rounded ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            fill={iconColor}
            fillRule="evenodd"
            d="M10.872 2.686c.458-.915 1.798-.915 2.256 0l2.461 4.92 5.837.833c1.013.145 1.431 1.346.717 2.06l-4.202 4.198.845 5.911c.14.98-.912 1.706-1.816 1.255L12 19.38l-4.97 2.483c-.904.451-1.956-.275-1.816-1.255l.845-5.91-4.202-4.2c-.714-.713-.296-1.914.717-2.06l5.836-.832 2.462-4.92ZM12 3.78 9.637 8.502a.952.952 0 0 1-.72.512L3.28 9.82l4.047 4.045a.912.912 0 0 1 .262.775l-.816 5.706 4.655-2.326c.36-.18.786-.18 1.146 0l4.655 2.326-.816-5.706a.912.912 0 0 1 .262-.775l4.047-4.045-5.639-.805a.952.952 0 0 1-.719-.512L12 3.78Z"
            clipRule="evenodd"
          />
        </svg>
      ) : halfFilled ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          fill="none"
        >
          <path
            fill={iconColor}
            fillRule="evenodd"
            d="m18.49 22-6.288-3.757a.395.395 0 0 0-.405 0L5.51 22l1.625-7.148a.396.396 0 0 0-.124-.386L1.5 9.639l7.29-.66a.395.395 0 0 0 .328-.239L12 2l2.881 6.74a.395.395 0 0 0 .328.238l7.291.661-5.51 4.827a.396.396 0 0 0-.126.386L18.49 22Zm-3.064-6.809.852 3.747-3.328-1.989a1.853 1.853 0 0 0-.95-.263V5.766l1.527 3.571a1.874 1.874 0 0 0 1.55 1.135l3.816.346-2.868 2.513a1.911 1.911 0 0 0-.6 1.859l.001.001Z"
            clipRule="evenodd"
          />
        </svg>
      ) : isFilled ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          fill="none"
        >
          <path
            fill={iconColor}
            d="M7.135 14.852 5.51 22l6.287-3.757a.394.394 0 0 1 .405 0L18.49 22l-1.625-7.148a.396.396 0 0 1 .125-.386L22.5 9.639l-7.291-.66a.395.395 0 0 1-.328-.239L12 2 9.118 8.74a.395.395 0 0 1-.327.238L1.5 9.64l5.51 4.827c.11.096.158.244.126.386Z"
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          fill="none"
        >
          <path
            fill={iconColor}
            fillRule="evenodd"
            d="M12.202 18.243 18.49 22l-1.625-7.148a.396.396 0 0 1 .125-.386L22.5 9.639l-7.291-.66a.395.395 0 0 1-.328-.239L12 2 9.118 8.74a.395.395 0 0 1-.327.238L1.5 9.64l5.51 4.827c.11.096.158.244.125.386L5.51 22l6.287-3.757a.395.395 0 0 1 .405 0Zm-4.48.695 3.327-1.988a1.846 1.846 0 0 1 1.9 0l3.329 1.988-.852-3.747v-.001a1.911 1.911 0 0 1 .599-1.86l2.868-2.512-3.815-.346a1.874 1.874 0 0 1-1.551-1.135L12 5.765l-1.527 3.572a1.874 1.874 0 0 1-1.55 1.135l-3.816.346 2.869 2.513a1.91 1.91 0 0 1 .598 1.858m-.852 3.75.852-3.748-.852 3.747Z"
            clipRule="evenodd"
          />
        </svg>
      )}
    </>
  );
};
StarIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  iconColor: PropTypes.string,
  isFilled: PropTypes.bool,
  rounded: PropTypes.bool,
  halfFilled: PropTypes.bool
};

export default StarIcon;
