import React from "react";
import "./ModalHeader.css";
import "../../../Styles/Theme/Theme.css";
import PropTypes from "prop-types";
import Button from "../../Button/Button";
import CrossIcon from "../../Icons/GeneralIcons/CrossIcon";
import ArrowChevronLeft from "../../Icons/ArrowsIcon/ArrowChevronLeft";
import Typography from "../../Typography/Typography";

export default function ModalHeader({
  headingLable = "Heading",
  countStarts = "0",
  countEnds = "50",
  hasCounts = false,
  hasBackIcon = false,
  hasCross = false,
  hasContentCenter = false,
  handleClickCross,
  handleBack,
}) {
  return (
    <div className={`model-header-popup`}>
      {hasBackIcon && (
        <Button
          size="small"
          variant="ghost"
          elementIcon={<ArrowChevronLeft />}
          handleClick={handleBack}
          toolTipText="Back"
          hasText={false}
        />
      )}
      <div
        className={`model-header-content flex  flex-col ${
          hasContentCenter ? "items-center" : ""
        }`}
      >
        <Typography variant="h4">{headingLable}</Typography>
        {hasCounts && (
          <div className="model-counts flex items-center">
            <span>{countStarts}</span>
            <span>of</span>
            <span>{countEnds}</span>
          </div>
        )}
      </div>
      {hasCross && (
        <Button
          size="small"
          variant="ghost"
          elementIcon={<CrossIcon />}
          handleClick={handleClickCross}
          hasText={false}
          toolTipText="Close"
        />
      )}
    </div>
  );
}

ModalHeader.propTypes = {
  headingLable: PropTypes.string,
  countStarts: PropTypes.string,
  countEnds: PropTypes.string,
  hasCounts: PropTypes.bool,
  hasBackIcon: PropTypes.bool,
  hasCross: PropTypes.bool,
  hasContentCenter: PropTypes.bool,
  handleClickCross: PropTypes.func,
  handleBack: PropTypes.func,
};
