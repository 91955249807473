import React from 'react'
import PropTypes from "prop-types"
const SmallTickIcon = ({ height = 16, width = 16, iconColor = "#000" }) => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={width}
                height={height}
                fill="none"
                viewBox="0 0 16 16"
            >
                <path
                    fill={iconColor}
                    d="M13.83 5.004a.593.593 0 0 0 0-.832.576.576 0 0 0-.821 0l-6.815 6.9L2.991 7.83a.576.576 0 0 0-.821 0 .593.593 0 0 0 0 .831l3.614 3.658c.227.23.594.23.821 0l7.225-7.315Z"
                />
            </svg>
        </>
    )
}
SmallTickIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    iconColor: PropTypes.string,
}
export default SmallTickIcon
