import usePrevention from "Hooks/usePrevention";
import { Link, useLocation } from "react-router-dom";

const SubSidebarAdmin = ({ scope }) => {
  const { state } = useLocation();
  const { handleSidebarLinkClick } = usePrevention();
  return (
    <div className="users-info-sidebar">
      <div className="accordion" id="accordionExample">
        <div className="card">
          {/* card items */}
          {scope &&
            scope.actions.map((action) => {
              return (
                <div className="card-header" id="headingOne" key={action.path}>
                  <Link
                    state={{ id: scope.id, subId: action?.subId }}
                    className={`btn-link collapsed ${
                      window.location.pathname.includes(action.path) ||
                      state?.subId === action?.subId
                        ? "sd-active"
                        : ""
                    } `}
                    to={`/admin/${scope.path}/${action.path}`}
                    onClick={(e) => handleSidebarLinkClick(e)}
                  >
                    {action.name}
                  </Link>
                </div>
              );
            })}
          {/* card items */}
        </div>
      </div>
    </div>
  );
};
export default SubSidebarAdmin;
