import React from 'react'

const NotificationIcon = () => {
  return (
   <>
     <svg
    xmlns="http://www.w3.org/2000/svg"
    width={120}
    height={120}
    fill="none"
  >
    <g clipPath="url(#a)">
      <path
        fill="#6FCADF"
        fillOpacity={0.2}
        d="M59.392 119.945c-1.588 0-3.19.147-4.754-.034-2.457-.285-4.913-.635-7.333-1.147a58.985 58.985 0 0 1-24.126-11.242 59.24 59.24 0 0 1-6.402-5.722 62.252 62.252 0 0 1-8.4-11.044 59.098 59.098 0 0 1-4.357-8.818A57.254 57.254 0 0 1 .949 71.126a56.525 56.525 0 0 1-.863-6.084c-.194-2.951-.05-5.927 0-8.897.07-4.133.921-8.158 1.96-12.128A51.258 51.258 0 0 1 5.27 35.12a58.811 58.811 0 0 1 5.202-9.106 61.042 61.042 0 0 1 3.731-4.93 69.764 69.764 0 0 1 5.98-6.017 57.888 57.888 0 0 1 7.47-5.682 60.4 60.4 0 0 1 33.68-9.36 59.992 59.992 0 0 1 38.215 14.863 59.885 59.885 0 0 1 19.715 35.925c.654 4.11.871 8.279.645 12.434-.746 12.368-4.834 23.508-12.373 33.356a60.372 60.372 0 0 1-18.383 15.88 58.274 58.274 0 0 1-10.315 4.569 57.162 57.162 0 0 1-12.59 2.675 46.558 46.558 0 0 1-6.855.218Z"
      />
      <path
        fill="#060D14"
        fillRule="evenodd"
        d="M65.204 34.829c1.765.518 3.648 1.32 5.412 2.515 3.97 2.69 7.21 7.298 7.21 14.656v16c0 2.469 3.528 5.986 6.172 6L84 78H35.835l-.002-4c2.684-.015 6.174-3.537 6.174-6V52c0-7.358 3.24-11.966 7.21-14.656 1.824-1.236 3.775-2.05 5.59-2.567a5.335 5.335 0 0 1 10.397.052ZM59.917 38c-1.947.018-5.466.63-8.456 2.656-2.919 1.977-5.454 5.369-5.454 11.344v16c0 2.403-1.216 4.455-2.657 6h33.132c-1.44-1.545-2.655-3.597-2.655-6V52c0-5.975-2.536-9.367-5.454-11.344-2.99-2.026-6.51-2.638-8.456-2.656Zm-5.25 46a2.667 2.667 0 0 1 2.666-2.667h5.334a2.667 2.667 0 0 1 0 5.334h-5.334A2.667 2.667 0 0 1 54.667 84Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h120v120H0z" />
      </clipPath>
    </defs>
  </svg>
   </>
  )
}

export default NotificationIcon