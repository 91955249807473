import React from 'react'
import PropTypes from "prop-types"
const TickIcon = ({ height = 24, width = 24, iconColor = "#000" }) => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={width}
                height={height}
                fill="none"
                viewBox="0 0 24 24"
            >
                <path
                    fill={iconColor}
                    d="m19.151 6.243-9.75 9.75-4.583-4.581a.83.83 0 1 0-1.175 1.175l5.171 5.169a.83.83 0 0 0 1.175 0L20.326 7.417a.83.83 0 1 0-1.175-1.175Z"
                />
            </svg>
        </>
    )
}
TickIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    iconColor: PropTypes.string,
}
export default TickIcon
