import React from "react";
import PropTypes from "prop-types";
function ArrowChevronDown({ height = 24, width = 24, iconColor = "#000" }) {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
           fill={iconColor}
           d="M4.47 8.22a.75.75 0 0 1 .976-.073l.084.073L12 14.689l6.47-6.47a.75.75 0 0 1 .976-.072l.084.073a.75.75 0 0 1 .073.976l-.073.084-7 7a.75.75 0 0 1-.976.073l-.084-.073-7-7a.75.75 0 0 1 0-1.06Z"
        />
      </svg>
    </>
  );
}
ArrowChevronDown.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  iconColor: PropTypes.string,
};
export default ArrowChevronDown;

