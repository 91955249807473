import usePrevention from "Hooks/usePrevention";
import { Link, useLocation } from "react-router-dom";

const SidebarAdmin = ({ scopes, setCurrentScope }) => {
  const { state } = useLocation();
  const { handleSidebarLinkClick } = usePrevention();

  return (
    <div className="sidebar-menu">
      <ul>
        {/* module items */}
        {scopes.length &&
          scopes.map((scope) => {
            return (
              <li key={scope.id}>
                <Link
                  // TODO: add support of adding parent routes without child routes
                  to={`/admin/${scope.path}/${scope?.actions[0]?.path}`}
                  className={state?.id === scope.id ? "menu-active" : ""}
                  state={{ id: scope.id, subId: scope?.actions[0]?.subId }}
                  onClick={(e) => {
                    // sets the clicked scope as the current scope to reset the actions rendered in sub side bar menu
                    setCurrentScope(
                      scopes.find(
                        (availableScope) => availableScope.id === scope.id
                      )
                    );
                    handleSidebarLinkClick(e);
                  }}
                >
                  {scope.icon}
                  <span className="admin-left-title">{scope.name}</span>
                </Link>
              </li>
            );
          })}
        {/* module items */}
      </ul>
    </div>
  );
};

export default SidebarAdmin;
