import IconNavDoc from "components/GlobalComponents/Icons/IconsNavigation/IconNavDoc"
import IconNavDocFilled from "components/GlobalComponents/Icons/IconsNavigation/IconNavDocFilled"
import IconNavNews from "components/GlobalComponents/Icons/IconsNavigation/IconNavNews"
import IconNavNewsFilled from "components/GlobalComponents/Icons/IconsNavigation/IconNavNewsFilled"
import IconNavVideo from "components/GlobalComponents/Icons/IconsNavigation/IconNavVideo"
import IconNavVideoFilled from "components/GlobalComponents/Icons/IconsNavigation/IconNavVideoFilled"
import InfoIcon from "components/Icons/GeneralIcons/InfoIcon"
import NavChatIcon from "components/Icons/NavIcons/NavChatIcon"
import NavDirectoryIcon from "components/Icons/NavIcons/NavDirectoryIcon"
import NavEventIcon from "components/Icons/NavIcons/NavEventIcon"
import NavFolderIcon from "components/Icons/NavIcons/NavFolderIcon"
import NavGroupIcon from "components/Icons/NavIcons/NavGroupIcon"
import NavPartnerIcon from "components/Icons/NavIcons/NavPartnerIcon"

export const SCOPE_ROUTES = {
    news: {
        basePath: "homepage",
        actionPath: {
            detail: ":news_id"
        }
    },
    videos: {
        basePath: "videos",
        actionPath: {
            detail: ":video_id"
        }
    },
    partners: {
        basePath: "partners",
        actionPath: {
            detail: ":partner_id"
        }
    },
    documents: {
        basePath: "documents"
    },
    censusDataGoogleStudio: {
        basePath: "census-data-google-studio"
    },
    events: {
        basePath: "events",
        actionPath: {
            inperson: "https://member.milliondollarsellers.com/in-person",
            experience: "https://member.milliondollarsellers.com/Experiences",
            virtualevent: "https://member.milliondollarsellers.com/page-18313",
            summit: "https://member.milliondollarsellers.com/page-18487"
        }
    },
    network: {
        basePath: "network",
        actionPath: {
            facebookGroups: "facebook-groups",
            memberMap: "https://member.milliondollarsellers.com/Member-Map",
            memberDirectory: "https://member.milliondollarsellers.com/Member-Directory"
        }
    },
    programs: {
        basePath: "programs",
        actionPath: {
            chapters: "chapters",
            squads: "squads",
            memberLink: "member-link",
        }
    },
    directories: {
        basePath: "directories",
        actionPath: {
            airbnbDirectory: "airbnb-directory",
            bookClub: "book-club",
            employeeBlocklist: "employee-blocklist",
            employeeHire: "employee-hire",
            employeeMatch: "employee-match",
            freelancers: "freelancers",
            giftGuide: "gift-guide",
        }
    },
    info: {
        basePath: "info",
        actionPath: {
            team: "team",
            values: "values",
            merch: "https://www.milliondollarsellers.com/merch",
            photos: "photos",
            memberScore: "member-score",
            donations: "https://member.milliondollarsellers.com/Donations",
        }
    },
    chats: {
        basePath: "chats",
        actionPath: {
            list: ":userid/:type"
        }
    }
}

export const scopesList = [
    {
        id: "news",
        name: "Newsroom",
        path: SCOPE_ROUTES.news.basePath,
        icon: <IconNavNews size="24px" className="admin-left-icons" />,
        activeIcon: <IconNavNewsFilled />,
        actions: [
            {
                name: "Newsroom",
                path: SCOPE_ROUTES.news.actionPath.highLights,
            },
        ],
    },
    {
        id: "events",
        name: "Events",
        path: SCOPE_ROUTES.events.basePath,
        icon: <div className="mds-icons"><NavEventIcon /></div>,
        activeIcon: <div className="mds-icons filled"><NavEventIcon isFilled /></div>,
        actions: [
            {
                name: "In persons",
                path: SCOPE_ROUTES.events.actionPath.inperson,
            },
            {
                name: "Experiences",
                path: SCOPE_ROUTES.events.actionPath.experience,
            },
            {
                name: "Virtual events",
                path: SCOPE_ROUTES.events.actionPath.virtualevent,
            },
            {
                name: "Summit",
                path: SCOPE_ROUTES.events.actionPath.summit,
            },
        ],
    },
    {
        id: "network",
        name: "Network",
        path: SCOPE_ROUTES.network.basePath,
        icon: <div className="mds-icons"><NavGroupIcon /></div>,
        activeIcon: <div className="mds-icons filled"><NavGroupIcon isFilled /></div>,
        actions: [
            {
                name: "Facebook groups",
                path: SCOPE_ROUTES.network.actionPath.facebookGroups,
            },
            {
                name: "Member map",
                path: SCOPE_ROUTES.network.actionPath.memberMap,
            },
            {
                name: "Member directory",
                path: SCOPE_ROUTES.network.actionPath.memberDirectory,
            },
        ],
    },
    {
        id: "programs",
        name: "Programs",
        path: SCOPE_ROUTES.programs.basePath,
        icon: <div className="mds-icons"><IconNavDoc /></div>,
        activeIcon: <IconNavDocFilled />,
        actions: [
            {
                name: "Chapters",
                path: SCOPE_ROUTES.programs.actionPath.chapters,
            },
            {
                name: "Squads",
                path: SCOPE_ROUTES.programs.actionPath.squads,
            },
            {
                name: "Member link",
                path: SCOPE_ROUTES.programs.actionPath.memberLink,
            },
        ],
    },
    {
        id: "videos",
        name: "Videos",
        path: SCOPE_ROUTES.videos.basePath,
        icon: <IconNavVideo />,
        activeIcon: <IconNavVideoFilled />,
        actions: [
            {
                name: "Videos",
                path: SCOPE_ROUTES.videos.actionPath.list,
            },
        ],
    },
    {
        id: "partners",
        name: "Partner offers",
        path: SCOPE_ROUTES.partners.basePath,
        icon: <div className="mds-icons"><NavPartnerIcon /></div>,
        activeIcon: <div className="mds-icons filled"> <NavPartnerIcon isFilled /></div>,
        actions: [
            {
                name: "Partner offers",
                path: SCOPE_ROUTES.partners.basePath,
            }
        ],
    },
    {
        id: "documents",
        name: "Documents",
        path: SCOPE_ROUTES.documents.basePath,
        icon: <IconNavDoc />,
        activeIcon: <IconNavDocFilled />,
        actions: [
            {
                name: "Documents",
                path: SCOPE_ROUTES.documents.basePath,
            }
        ],
    },
    {
        id: "censusdata",
        name: "Census data",
        path: SCOPE_ROUTES.documents.basePath,
        icon: <div className="mds-icons"><NavFolderIcon /></div>,
        activeIcon: <div className="mds-icons filled"> <NavFolderIcon isFilled /></div>,
        actions: [
            {
                name: "Census data",
                path: SCOPE_ROUTES.documents.basePath,
            }
        ],
    },
    {
        id: "directories",
        name: "Directories",
        path: SCOPE_ROUTES.directories.basePath,
        icon: <div className="mds-icons"><NavDirectoryIcon /></div>,
        activeIcon: <div className="mds-icons filled"><NavDirectoryIcon isFilled /></div>,
        actions: [
            {
                name: "Airbnb",
                path: SCOPE_ROUTES.directories.actionPath.airbnbDirectory,
            },
            {
                name: "Book club",
                path: SCOPE_ROUTES.directories.actionPath.bookClub,
            },
            {
                name: "Employee blocklist",
                path: SCOPE_ROUTES.directories.actionPath.employeeBlocklist,
            },
            {
                name: "Employee hire",
                path: SCOPE_ROUTES.directories.actionPath.employeeHire,
            },
            {
                name: "Employee match",
                path: SCOPE_ROUTES.directories.actionPath.employeeMatch,
            },
            {
                name: "Freelancers",
                path: SCOPE_ROUTES.directories.actionPath.freelancers,
            },
            {
                name: "Gift guide",
                path: SCOPE_ROUTES.directories.actionPath.giftGuide,
            },
        ],
    },
    {
        id: "channels",
        name: "Chat",
        path: SCOPE_ROUTES.chats.basePath,
        icon: <div className="mds-icons"><NavChatIcon /></div>,
        activeIcon: <div className="mds-icons filled"><NavChatIcon isFilled /></div>,
        actions: [
            {
                name: "Chat",
                path: SCOPE_ROUTES.chats.actionPath.list,
            },
        ]
    },
    {
        id: "info",
        name: "Info",
        path: SCOPE_ROUTES.info.basePath,
        icon: <div className="mds-icons"><InfoIcon /></div>,
        activeIcon: <div className="mds-icons filled"><InfoIcon isFilled /></div>,
        actions: [
            {
                name: "Team",
                path: SCOPE_ROUTES.info.actionPath.team,
            },
            {
                name: "Values",
                path: SCOPE_ROUTES.info.actionPath.values,
            },
            {
                name: "Merch",
                path: SCOPE_ROUTES.info.actionPath.merch,
            },
            {
                name: "Photo Gallery",
                path: SCOPE_ROUTES.info.actionPath.photos,
            },
            {
                name: "Member's Score",
                path: SCOPE_ROUTES.info.actionPath.memberScore,
            },
            {
                name: "Donations",
                path: SCOPE_ROUTES.info.actionPath.donations,
            },
        ],
    },
]