import React from "react";

const IconNavScorecard = () => {
  return (
    <div className="mds-icons">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
      >
        <path
          d="M19.58 19.523c-.27.313-.746.311-1.038.02-.292-.293-.29-.765-.024-1.081a8.503 8.503 0 1 0-13.036 0c.265.316.268.788-.024 1.08-.292.292-.768.294-1.038-.02a10 10 0 1 1 15.16 0Z"
        />
        <path
          fillRule="evenodd"
          d="M6.97 7.97a.75.75 0 0 1 1.06 0l2.446 2.445a3 3 0 1 1-1.06 1.06L6.97 9.031a.75.75 0 0 1 0-1.06Zm4.5 5.56-.92-.918A1.503 1.503 0 0 0 12 14.5a1.5 1.5 0 1 0-.389-2.95l.92.92a.75.75 0 1 1-1.061 1.06Z"
          clipRule="evenodd"
        />
      </svg>
    </div>
  );
};

export default IconNavScorecard;
