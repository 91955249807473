import React from 'react'
import PropTypes from "prop-types"
const CopyIcon = ({ width = 24, height = 24, iconColor = "#000" }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 24 24"
        >
            <path
                fill={iconColor}
                fillRule="evenodd"
                d="M2 5.5A3.5 3.5 0 0 1 5.5 2h7A3.5 3.5 0 0 1 16 5.5V8h2.5a3.5 3.5 0 0 1 3.5 3.5v7a3.5 3.5 0 0 1-3.5 3.5h-7A3.5 3.5 0 0 1 8 18.5V16H5.5A3.5 3.5 0 0 1 2 12.5v-7Zm3.5-2h7a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2h-7a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2Zm4 12.5v2.5a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2v-7a2 2 0 0 0-2-2H16v3a3.5 3.5 0 0 1-3.5 3.5h-3Z"
                clipRule="evenodd"
            />
        </svg>
    )
}
CopyIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    iconColor: PropTypes.string
}
export default CopyIcon