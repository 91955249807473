import React from 'react'
import PropTypes from "prop-types"
const DownloadIcon = ({ width = 24, height = 24, iconColor = "#000" }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 24 24"
        >
            <path
                fill={iconColor}
                d="M12 16.658a.728.728 0 0 0 .547-.256l2.9-3.152a.861.861 0 0 0-.001-1.149.71.71 0 0 0-1.06.003L12.75 13.88V2.811c0-.447-.336-.811-.75-.811s-.75.364-.75.812V13.88l-1.635-1.777a.71.71 0 0 0-1.06-.002.861.861 0 0 0-.002 1.148l2.9 3.15c.138.161.335.258.547.258ZM6.435 22h11.14c2.206 0 4.041-1.93 4.372-4.252.058-.41-.283-.748-.697-.748s-.743.34-.83.744c-.306 1.438-1.466 2.632-2.845 2.632H6.435c-1.386 0-2.55-1.19-2.856-2.631-.086-.406-.415-.745-.83-.745-.413 0-.754.337-.696.748C2.383 20.075 4.223 22 6.435 22Z"
            />
        </svg>
    )
}
DownloadIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    iconColor: PropTypes.string
}
export default DownloadIcon