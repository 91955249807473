import React from 'react';
import PropTypes from "prop-types"
// CSS
import './Styles/Chips.css'
import "../../Styles/Theme/Theme.css"


function Chips({ isActive, label = "label", handleClick, className = "medium" }) {
    return (
        <button onClick={handleClick} className={`chips ${className} ${isActive ? "active-chips" : ""}`}>
            {label}
        </button>
    )
}

// define types to all props
Chips.propTypes = {
    label: PropTypes.string,
    className: PropTypes.oneOf(["small", "medium", "large"]),
    handleClick: PropTypes.func,
    isActive: PropTypes.bool
}


export default Chips