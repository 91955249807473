import React from 'react'
import PropTypes from "prop-types"
const IconSfile = ({ width = 16, height = 16, isFilled = true, iconColor = "#394046", scale = 1 }) => {
    return (
        <>
            {isFilled ?
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={width}
                    height={height}
                    fill="none"
                    viewBox="0 0 16 16"
                >
                    <path
                        fill={iconColor}
                        fillRule="evenodd"
                        d="M2.334 4.34c.039-1.666 1.4-3.007 3.037-3.007h4.431c.136 0 .265.056.36.155l3.366 3.542a.506.506 0 0 1 .139.348v6.152a3.086 3.086 0 0 1-2.957 3.129H5.308c-1.676-.038-3.01-1.446-2.974-3.14V4.34Zm7.792-1.436 1.998 2.103h-.789a1.221 1.221 0 0 1-1.21-1.225v-.878Zm-4.71 7.388c0 .278.223.503.499.503h3.588a.501.501 0 0 0 .499-.503.501.501 0 0 0-.499-.504H5.915a.501.501 0 0 0-.499.504Zm3.227-2.518a.501.501 0 0 1-.499.503h-2.23a.501.501 0 0 1-.499-.503c0-.278.224-.504.499-.504h2.23c.275 0 .499.226.499.504Z"
                        clipRule="evenodd"
                    />
                </svg> :
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={width}
                    height={height}
                    fill="none"
                    viewBox="0 0 16 16"
                >
                    <path
                        fill={iconColor}
                        d="M9.503 10.803H5.915a.501.501 0 0 1-.499-.504c0-.278.223-.503.499-.503h3.588c.275 0 .499.225.499.503a.501.501 0 0 1-.499.504ZM5.914 8.284h2.23a.501.501 0 0 0 .499-.503.501.501 0 0 0-.499-.503h-2.23a.501.501 0 0 0-.499.503c0 .278.224.503.499.503Z"
                    />
                    <path
                        fill={iconColor}
                        fillRule="evenodd"
                        d="M5.371 1.34c-1.636 0-2.998 1.342-3.037 3.007v7.18c-.036 1.693 1.298 3.102 2.974 3.14h5.402a3.086 3.086 0 0 0 2.957-3.13V5.386c0-.13-.05-.255-.14-.348l-3.365-3.542a.497.497 0 0 0-.36-.154h-4.43Zm6.753 3.674-1.998-2.102v.877c0 .674.543 1.223 1.21 1.225h.788ZM9.13 2.348v1.441c0 1.227.989 2.229 2.205 2.232h1.336v5.52c.008 1.142-.87 2.073-1.98 2.12H5.33c-1.126-.026-2.024-.973-1.999-2.123V4.359c.026-1.11.94-2.011 2.042-2.011h3.756Z"
                        clipRule="evenodd"
                    />
                </svg>
            }
        </>
    )
}
IconSfile.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    isFilled: PropTypes.bool,
    iconColor: PropTypes.string,
    scale: PropTypes.number
}
export default IconSfile