import { Link, useNavigate } from "react-router-dom";

import useAuth from "Hooks/useAuth";
import "./Header.css";
//import images
import LOGO from "Assets/groupos-logo.svg";
import EditPF from "Images/EditPF.svg";
import MessagePF from "Images/MessagePF.svg";
import LoginPF from "Images/LoginPF.svg";
import AccountSettingsPF from "Images/AccountSettingsPF.svg";
import ViewNav from "Images/ViewNav.svg";
import { useAuth0 } from "@auth0/auth0-react";
import NotificationBadge from "components/Badge/NotificationBadge";
import Button from "components/Button/Button";
import NotificationIcon from "components/Icons/GeneralIcons/NotificationIcon";
import { useSelector } from "react-redux";
import { useState } from "react";
import Notifications from "pages/Notifications/components/Notification";
import Cookies from "js-cookie";
import { CONFIGS } from "config";

const Header = ({ setglobalmenu, type }) => {
  const { logout } = useAuth0();
  const { auth } = useAuth();
  let history = useNavigate();
  const [showNotificationList, setshowNotificationList] = useState(false);
  const notificationData = useSelector((state) => {
    return state.NotificationReducer.notificationList;
  });

  const handleLogout = async () => {
    await logout({
      logoutParams: {
        returnTo: `${window.location.origin}/login`,
      },
    });
    Cookies.remove("x-app-token", {
      domain: CONFIGS.COOKIE_DOMAIN,
      path: "/",
    });
  };

  const showNotificationHandler = () => {
    setshowNotificationList((prev) => !showNotificationList);
  };

  const checkHasNotifications = () => {
    if (notificationData.notification.length > 0) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <div
      className="groups-header"
      style={{ boxShadow: "0 2px 4px 0 rgba(0,0,0,.2)", height: "84px" }}
    >
      <div className="header-inner-ft">
        <div
          className="header-ft-view-nav cstm-menu-mobile "
          onClick={() => {
            setglobalmenu((prev) => !prev);
            //   document.body.classList.add("menuview");
          }}
        >
          <img className="header-ft-vn" src={ViewNav} alt="nav-toggle" />
        </div>
        <div
          className="header-ft-view-nav  cstm-menu-responsive"
          onClick={() => {
            setglobalmenu((prev) => !prev);
            //   document.body.classList.remove("menuview");
          }}
        >
          <img className="header-ft-vn" src={ViewNav} alt="nav-toggle" />
        </div>
        <Link to="/">
          {type === "DEFAULT" ? (
            <img src={LOGO} alt="logo" width={"50px"} height={"50px"} />
          ) : auth.currentEdge.relation_id.logo ? (
            <img
              src={auth.currentEdge.relation_id.logo}
              alt="logo"
              width={"50px"}
              height={"50px"}
            />
          ) : (
            auth.currentEdge.relation_id.name.toUpperCase()
          )}
        </Link>
        <div className="header-ft-element-sec">
          {showNotificationList && (
            <Notifications
              setshowNotificationList={setshowNotificationList}
              showNotificationList={showNotificationList}
              hasNotifications={checkHasNotifications}
              // notificationData={notificationData.notification}
            />
          )}
          <div className="header-ft-menu">
            <div className="header-ft-menu-user">
              {auth?.currentEdge?.type === "M" &&
                auth?.currentEdge?.scopes &&
                auth?.currentEdge?.scopes.includes("notifications") && (
                  <div className="header-ft-icon">
                    {notificationData?.notification.map(
                      (itme) => !itme.isRead && <NotificationBadge />
                    )}
                    {/* {process.env.REACT_APP_TESTSITE_HOSTNAME !== "app.milliondollarsellers.com" && */}
                    <Button
                      elementIcon={<NotificationIcon />}
                      onContextMenu={() => {}}
                      onMouseEnter={() => {}}
                      onMouseLeave={() => {}}
                      handleClick={showNotificationHandler}
                      label="Button"
                      isIconFilled
                      size="small"
                      toolTipId=""
                      toolTipText="Notifications"
                      variant="ghost"
                      hasText={false}
                    ></Button>
                    {/* } */}
                  </div>
                )}
              {/** Profile Menu Toggle Button */}
              <div className="header-ft-menu-user-main" data-toggle="dropdown">
                <span className="header-ft-photo-user">
                  <span
                    className="chat-on-off"
                    style={{
                      background: "#2CBF88",
                    }}
                  ></span>
                  {auth.userAvatar ? (
                    <img src={auth.userAvatar} alt="avatar" />
                  ) : (
                    <div className="cstm-avat-header-tp">
                      {auth.userName ? auth.userName.charAt(0) : "t"}
                    </div>
                  )}
                </span>
              </div>
              {/** Profile Menu Toggle Button */}
              {/** Profile Menu */}
              <div className="dropdown-menu header-menu-dp">
                <ul>
                  <li>
                    <div className="cstm-profile-info">
                      <div className="cstm-profile-info-user">
                        {auth.userAvatar ? (
                          <img src={auth.userAvatar} alt="avatar" />
                        ) : (
                          <div className="cstm-avat-header-img">
                            {auth.userName ? auth.userName.charAt(0) : "t"}
                          </div>
                        )}
                      </div>
                      <div className="cstm-profile-info-name">
                        {auth.userName ? auth.userName : "test"}
                      </div>
                    </div>
                  </li>

                  <li>
                    <a
                      onClick={() => {
                        history(`/settings/edit`);
                      }}
                    >
                      {/* <i className="fa fa-cog"></i> */}
                      <img src={EditPF} />
                      Edit profile
                    </a>
                  </li>

                  <li className="cstm-hd-as">
                    <a
                      onClick={() => {
                        history(`/settings/profile`);
                      }}
                    >
                      <img src={AccountSettingsPF} />
                      Account settings
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        history(`/contact-us`);
                      }}
                    >
                      <img src={MessagePF} />
                      Contact us
                    </a>
                  </li>
                  <li>
                    <a onClick={() => handleLogout()}>
                      <img src={LoginPF} /> Sign out
                    </a>
                  </li>
                  <li>
                    <div className="cstm-pt-link">
                      <a href="https://www.milliondollarsellers.com/privacy-policy">
                        Privacy
                      </a>
                      <a href="https://www.milliondollarsellers.com/terms-of-service">
                        Terms
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
              {/** Profile Menu */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Header;
