/* eslint-disable array-callback-return */
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import useAuth from "../../../../Hooks/useAuth";
import { getModuleName } from "../../../../utils";
import { adminScopesList, scopesList } from "routes/Admin/community/menuList";

// Import images
import SidebarAdmin from "components/Admin/community/SidebarAdmin";
import SubSidebarAdmin from "components/Admin/community/SubSidebarAdmin";
import AdminHeader from "components/Admin/Header";
import authServicePartner from "Services/auth.service.partner";
import { useDispatch } from "react-redux";
import { useLazyGetTierByNameQuery } from "Services/tiers";
import { useStripeCheckoutSessionMutation } from "Services/stripe";
import moment from "moment";

const AuthenticatedAdminLayout = () => {
  const { auth } = useAuth();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  // get tier by name for mds migration
  const [getTier] = useLazyGetTierByNameQuery();

  // create checkout session
  const [createCheckoutSession] = useStripeCheckoutSessionMutation();
  const finalScopeArray = [...scopesList, ...adminScopesList];
  const authScopes = auth.currentEdge?.scopes ? auth.currentEdge?.scopes : [];
  const availableFinalScopeArray = [
    ...authScopes,
    ...auth.currentEdge?.AdminScopes,
  ];

  const availableScopes = finalScopeArray.filter((scope) =>
    availableFinalScopeArray.includes(scope.id)
  );

  const [currentScope, setCurrentScope] = useState(availableScopes[0]);

  useEffect(() => {
    const module = getModuleName("/admin/", location.pathname);
    if (currentScope?.path !== module) {
      setCurrentScope(availableScopes.find((alvS) => alvS.path === module));
    }
    if (sessionStorage.getItem("x-app-redirect")) {
      const redirectPath = sessionStorage.getItem("x-app-redirect");

      // bypass create-community page when logged in as community admin
      if (redirectPath === "/create-community") {
        navigate("/community/subscription"); // instead goto subscriptions page
      } else {
        navigate(sessionStorage.getItem("x-app-redirect"));
      }

      sessionStorage.removeItem("x-app-redirect");
    }
  }, [availableScopes, currentScope?.path, location.pathname, navigate]);

  // MDS migration
  useEffect(() => {
    if (
      !auth.currentEdge.subscription_id &&
      auth.currentEdge.relation_id.nickname === "mds"
    ) {
      (async function () {
        // get tier by name for mds migration
        const tier = await getTier({
          name: auth?.migrationData?.tier_name,
          relation_id: auth.platform._id,
        });
        const days = moment().diff(
          moment(auth?.migrationData?.subscription_end),
          "days"
        );
        let body = {
          tierId: tier?.data?._id,
          type: auth?.migrationData?.subscription_type, // monthly, yearly
          customerId: auth?.currentEdge?.stripe_customer_db_id,
        };

        if (new Date(auth?.migrationData?.subscription_end) > new Date()) {
          body = { ...body, freeTrialDays: days > 0 ? 0 : Math.abs(days) };
        }

        const checkoutSessionData = await createCheckoutSession(body);
        navigate("/subscriptions/checkout", {
          state: {
            clientSecret: checkoutSessionData.data,
            platForm: true,
          },
        });
      })();
    }
  }, [
    auth?.currentEdge?.relation_id?.nickname,
    auth?.currentEdge?.stripe_customer_db_id,
    auth?.currentEdge?.subscription_id,
    auth?.migrationData?.subscription_end,
    auth?.migrationData?.subscription_type,
    auth?.migrationData?.tier_name,
    auth?.platform?._id,
    createCheckoutSession,
    getTier,
    navigate,
  ]);

  useEffect(() => {
    const partnerLabels = null;
    authServicePartner.getPartnerSetting().then((response) => {
      response.data.data.map((item) => {
        if (response.data.status) {
          const payload = {
            featuredLabel:
              item.featurePartner && item.featurePartner !== ""
                ? item.featurePartner
                : partnerLabels
                ? partnerLabels.featuredLabel
                : "",
            featuredSubLabel:
              item.featurePartnerSubTitle && item.featurePartnerSubTitle !== ""
                ? item.featurePartnerSubTitle
                : partnerLabels
                ? partnerLabels.featuredSubLabel
                : "",
            freshDealLabel:
              item.freshDealTitle && item.freshDealTitle !== ""
                ? item.freshDealTitle
                : partnerLabels
                ? partnerLabels.freshDealLabel
                : "",
            freshDealSubLabel:
              item.freshDealSubTitle && item.freshDealSubTitle !== ""
                ? item.freshDealSubTitle
                : partnerLabels
                ? partnerLabels.freshDealSubLabel
                : "",
          };
          dispatch({
            type: "SETDYNAMICLABEL",
            payload: payload,
          });
          // setFeatureTitle(item.featurePartner)
          // setFreshTitle(item.freshDealTitle)
        }
      });
    });
  }, [dispatch]);
  return (
    <div className="cstm-all-admin">
      <AdminHeader type={auth.currentEdge?.type} />
      <SidebarAdmin
        scopes={availableScopes}
        setCurrentScope={setCurrentScope}
      />
      <div className="content-main">
        <div className="users-info">
          <SubSidebarAdmin scope={currentScope} />

          {/* MAIN PAGE AREA */}
          <div className="users-info-content">
            <div className="users-info-content-main">
              <Outlet />
            </div>
          </div>
        </div>
        {/* MAIN PAGE AREA */}
      </div>
    </div>
  );
};

export default AuthenticatedAdminLayout;
