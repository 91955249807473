import React from "react";
import "../Styles/ChatDataCell.css";
import PropTypes from "prop-types";
import "../../../../Styles/Theme/Theme.css";
import Typography from "../../../../components/Typography/Typography";
import ArrowChevronRight from "../../../../components/Icons/ArrowsIcon/ArrowChevronRight";

const ChatDataCell = ({
  title = "milliondollarsellers.com",
  text = "On: Push notifications, Badges",
  handleClick,
}) => {
  return (
    <>
      <div class="chat-data-cell" onClick={handleClick}>
        <div class="chat-data-cell-text">
          <Typography variant="h5">{title}</Typography>
          <Typography variant="p">{text}</Typography>
        </div>
        <div class="chat-data-cell-icon">
          <ArrowChevronRight
            height={24}
            width={24}
            iconColor="var(--color-icon-neutral-strong)"
          />
        </div>
      </div>
    </>
  );
};

ChatDataCell.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  handleClick: PropTypes.string,
};

export default ChatDataCell;
