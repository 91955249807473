import React from "react";
import ModalFooter from "./ModalFooter/ModalFooter";
import ModelHeader from "./ModalHeader/ModalHeader";
import PropTypes from "prop-types";
import "../../Styles/Theme/Theme.css";
import "./ModalPopup.css";

export default function ModelPopup({
  onClose,
  handleClick2ndButton = () => { },
  children,
  isHeader = true,
  isFooter = true,
  modelSize = "480",
  disabled = false,
  headingLable = "New Chat",
  hasBackIcon = false,
  hasCross = true,
  countStarts = "5",
  countEnds = "10",
  hasCounts = false,
  hasContentCenter = false,
  isOpen,
  buttonText = "lable",
  buttonText1 = "lable",
  buttonType = "twobuttons",
  className = "",
  handleBack,
  spinnersType,
  hasLoading,
  btn2variant = "primary",
  disabled1stbtn,
}) {
  return (
    <div
      className={`model-popup-main ${className} ${isOpen ? "fadein" : "fadeout"
        }`}
    >
      {isOpen && (
        <div className={`model-popup popup-size-${modelSize}`}>
          {isHeader && (
            <ModelHeader
              handleClickCross={onClose}
              headingLable={headingLable}
              hasBackIcon={hasBackIcon}
              hasCross={hasCross}
              handleBack={handleBack}
              countStarts={countStarts}
              countEnds={countEnds}
              hasCounts={hasCounts}
              hasContentCenter={hasContentCenter}
            />
          )}
          {children}
          {isFooter && (
            <ModalFooter
              handleClick1stButton={onClose}
              handleClick2ndButton={handleClick2ndButton}
              handleClick1stButtonType={spinnersType}
              label={buttonText}
              label2={buttonText1}
              btn2variant={btn2variant}
              type={buttonType}
              disabled={disabled}
              spinnersType={spinnersType}
              hasLoading={hasLoading}
              disabled1stbtn={disabled1stbtn}
            />
          )}
        </div>
      )}
    </div>
  );
}

ModelPopup.propTypes = {
  modelSize: PropTypes.oneOf(["480", "648", "880", "1200"]),
  headingLable: PropTypes.string,
  className: PropTypes.string,
  isHeader: PropTypes.bool,
  isFooter: PropTypes.bool,
  spinnersType: PropTypes.string,
  hasLoading: PropTypes.bool,
  countStarts: PropTypes.string,
  countEnds: PropTypes.string,
  hasCounts: PropTypes.bool,
  hasContentCenter: PropTypes.bool,
  disabled1stbtn: PropTypes.bool,
  hndleClick: PropTypes.func,
  onClose: PropTypes.func,
  handleClick2ndButton: PropTypes.func,
  handleBack: PropTypes.func,
};
