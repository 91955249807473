const notificationList = [];

const NotificationReducer = (state = notificationList, action) => {
  switch (action.type) {
    case "NOTIFICATION_LIST":
      return { ...state, notificationList: action.payload };
    case "MARK_NOTIFICATION":
      return action.payload;
    default:
      return state;
  }
};

export default NotificationReducer;
