import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import LazyLoadComponent from "components/LazyLoadComponent.jsx";

import SubscriptionLayout from "layouts/SubscriptionLayout";
import AuthenticatedAdminLayout from "layouts/Admin/community/AuthenticatedAdminLayout";

import { ADMIN_SCOPE_ROUTES } from "routes/Admin/community/menuList.js";
import { SCOPE_ROUTES } from "routes/Admin/community/menuList.js";
import { ADMIN_SCOPE_ROUTES as SUBSCRIPTION_ADMIN_SCOPE_ROUTES } from "routes/SubscriptionRoutes/menuList.js";
import { ADMIN_SCOPE_ROUTES as SETTINGS_ADMIN_SCOPE_ROUTES } from "routes/SettingsRoutes/menuList";
import { ADMIN_SCOPE_ROUTES as EDGE_SCOPE_ADMIN_SCOPE_ROUTES } from "routes/EdgeRoutes/menuList";
import NotFound from "pages/NotFound/index.jsx";
import SettingsLayout from "layouts/SettingsLayout/index.jsx";

// auth required check
const RequireAuth = React.lazy(() =>
  import("../../../../components/RequireAuth/index.jsx")
);

// users
const UserList = React.lazy(() =>
  import("../../../../components/BackendUserlisting/UserListing.js")
);

const DeleteRequestUserlisting = React.lazy(() =>
  import(
    "../../../../components/BackendDeleteRequestUser/DeleteRequestUserlisting.js"
  )
);

const QuestionBackend = React.lazy(() =>
  import("../../../../components/QuestionBackend/index.js")
);

const AttendeeUsersList = React.lazy(() =>
  import("../../../../components/BackendUserlisting/AttendeeUsersList.js")
);

const PartnersList = React.lazy(() =>
  import("../../../../components/BackendUserlisting/PartnersList.js")
);

const Blockeduserslist = React.lazy(() =>
  import("../../../../components/BackendUserlisting/BlockedUsers.js")
);

const AdminRegistrationForm = React.lazy(() =>
  import("../../../../components/AdminSideDynamicRegistrationForm/index.js")
);

const EditUserProfile = React.lazy(() =>
  import("../../../../components/BackendUserlisting/EditUserProfile.js")
);

// Events
const EventList = React.lazy(() =>
  import(
    "../../../../pages/Admin/community/EventManagement/MainEventManagement/ListEvent.js"
  )
);

const CreateEvent = React.lazy(() =>
  import(
    "../../../../pages/Admin/community/EventManagement/MainEventManagement/CreateEvent.js"
  )
);

const EventDetail = React.lazy(() =>
  import(
    "../../../../pages/Admin/community/EventManagement/MainEventManagement/EventDetail.js"
  )
);

const EventType = React.lazy(() =>
  import(
    "../../../../pages/Admin/community/EventManagement/EventTypeManagement/EventType.js"
  )
);

const EventCategories = React.lazy(() =>
  import(
    "../../../../pages/Admin/community/EventManagement/CategoriesManagement/CategoriesManagement.jsx"
  )
);

// channel
const ChannelList = React.lazy(() =>
  import(
    "../../../../pages/Admin/community/ChannelManagement/ChannelManagement/Channels.js"
  )
);

const CreateChannel = React.lazy(() =>
  import(
    "../../../../pages/Admin/community/ChannelManagement/CreateChannelManagement/CreateChannel.js"
  )
);

const EditChannel = React.lazy(() =>
  import(
    "../../../../pages/Admin/community/ChannelManagement/EditChannelManagement/EditChannel.js"
  )
);

// medias
const Media = React.lazy(() =>
  import(
    "../../../../pages/Admin/community/AdminMediaManagement/MainMediaManagement/MediaListing.js"
  )
);

// news
const Banners = React.lazy(() =>
  import(
    "../../../../pages/Admin/community/NewsManagemnet/BannerManagement/Banners.js"
  )
);

const News = React.lazy(() =>
  import(
    "../../../../pages/Admin/community/NewsManagemnet/NewsManagement/News.js"
  )
);

const Posts = React.lazy(() =>
  import(
    "../../../../pages/Admin/community/NewsManagemnet/PostManagement/Posts.js"
  )
);

const ContentListing = React.lazy(() =>
  import(
    "../../../../pages/Admin/community/NewsManagemnet/ContentManagement/ContentListing.js"
  )
);

// partner
const PartnerList = React.lazy(() =>
  import(
    "../../../../pages/Admin/community/PartnerManagement/MainPartnerManagement/PartnerList.js"
  )
);

const CreatePartner = React.lazy(() =>
  import(
    "../../../../pages/Admin/community/PartnerManagement/MainPartnerManagement/CreatePartner.js"
  )
);

const EditPartner = React.lazy(() =>
  import(
    "../../../../pages/Admin/community/PartnerManagement/MainPartnerManagement/PartnerDetail.js"
  )
);

const FeaturedPartner = React.lazy(() =>
  import(
    "../../../../pages/Admin/community/PartnerManagement/FeaturedPartnerManagement/FeaturedPartner.js"
  )
);

const FreshPartner = React.lazy(() =>
  import(
    "../../../../pages/Admin/community/PartnerManagement/FreshDealManagement/FreshDeals.js"
  )
);

const PartnerReason = React.lazy(() =>
  import(
    "../../../../pages/Admin/community/PartnerManagement/ReasonManagement/Reason.js"
  )
);

const PartnerReviews = React.lazy(() =>
  import(
    "../../../../pages/Admin/community/PartnerManagement/ReviewManagement/AllReview.js"
  )
);

const PartnerTypes = React.lazy(() =>
  import(
    "../../../../pages/Admin/community/PartnerManagement/BadgeManagement/BadgeManagement.js"
  )
);

const PartnerBanner = React.lazy(() =>
  import(
    "../../../../pages/Admin/community/PartnerManagement/MainContent/MainContent.js"
  )
);

const PartnerCategories = React.lazy(() =>
  import(
    "../../../../pages/Admin/community/PartnerManagement/PartnerCategoryManagement/PartnerCategory.js"
  )
);

const PartnerStatistics = React.lazy(() =>
  import(
    "../../../../pages/Admin/community/PartnerManagement/StatisticsManagement/StatisticsManagement.js"
  )
);

// documents

const DocumentList = React.lazy(() =>
  import(
    "../../../../pages/Admin/community/DocumentManagement/MainDocumentManagement/DocumentList.js"
  )
);

const DocumentCreate = React.lazy(() =>
  import(
    "../../../../pages/Admin/community/DocumentManagement/MainDocumentManagement/CreateDocument.js"
  )
);

const DocumentEdit = React.lazy(() =>
  import(
    "../../../../pages/Admin/community/DocumentManagement/MainDocumentManagement/DocumentDetail.js"
  )
);

const DocumentCategory = React.lazy(() =>
  import(
    "../../../../pages/Admin/community/DocumentManagement/CategoriesManagement/CategoriesManagement.js"
  )
);

const DocumentReason = React.lazy(() =>
  import(
    "../../../../pages/Admin/community/DocumentManagement/ReasonManagement/ReasonManagement.jsx"
  )
);

const DocumentContentType = React.lazy(() =>
  import(
    "../../../../pages/Admin/community/DocumentManagement/ContentTypesManagement/ContentTypeManagement.js"
  )
);

const DocumentUserDownload = React.lazy(() =>
  import(
    "../../../../pages/Admin/community/DocumentManagement/DownloadManagement/DownloadManagement.js"
  )
);

// videos

const VideoCreate = React.lazy(() =>
  import("../../../../pages/Admin/community/ContentLibrary/AddVideo.js")
);

const VideoDetail = React.lazy(() =>
  import("../../../../pages/Admin/community/ContentLibrary/VideoDetail.js")
);

const VideoEdit = React.lazy(() =>
  import("../../../../pages/Admin/community/ContentLibrary/EditVideo.js")
);

const VideoListing = React.lazy(() =>
  import("../../../../pages/Admin/community/ContentLibrary/VideoListing.js")
);

const DeletedVideoListing = React.lazy(() =>
  import("../../../../pages/Admin/community/ContentLibrary/DeletedVideo.js")
);

const VideoEventListing = React.lazy(() =>
  import("../../../../pages/Admin/community/ContentLibrary/VideoEvent.js")
);

const VideoCategories = React.lazy(() =>
  import("../../../../pages/Admin/community/ContentLibrary/VideoCategory.js")
);

// reports

const Reports = React.lazy(() =>
  import("../../../../pages/Admin/community/AdminMessageReport/index.js")
);

// statistics

const VideoStatistic = React.lazy(() =>
  import("../../../../pages/Admin/community/Statistic/VideoStatistic.js")
);

const CategoryStatistic = React.lazy(() =>
  import("../../../../pages/Admin/community/Statistic/CategoryStatistic.js")
);

const SpeakerStatistic = React.lazy(() =>
  import("../../../../pages/Admin/community/Statistic/SpeakerStatistic.js")
);

const TagStatistic = React.lazy(() =>
  import("../../../../pages/Admin/community/Statistic/TagsStatistic.js")
);

const EventStatistic = React.lazy(() =>
  import("../../../../pages/Admin/community/Statistic/EventStatistic.js")
);

const UserStatistic = React.lazy(() =>
  import("../../../../pages/Admin/community/Statistic/UserStatistic.js")
);
// comunnity profile
const CommunityInfo = React.lazy(() =>
  import("../../../../pages/Admin/community/CommunityInfo/index.jsx")
);

// dashboard
const Dashboard = React.lazy(() =>
  import("../../../../pages/Admin/community/Dashboard/index.jsx")
);

const Payouts = React.lazy(() =>
  import("../../../../pages/Admin/community/Payouts/index.jsx")
);
const SubscriptionInfo = React.lazy(() =>
  import("../../../../pages/Admin/community/SubscriptionInfo/index.jsx")
);
const Pricing = React.lazy(() => import("../../../../pages/Pricing/index.jsx"));

// submit application form
const SubmitApplicationForm = React.lazy(() =>
  import("../../../../pages/SubmitApplicationForm/index.jsx")
);

// discover communities
const DiscoverCommunities = React.lazy(() =>
  import("../../../../pages/DiscoverCommunities/DiscoverCommunities.jsx")
);

// application forms
const ApplicationFormList = React.lazy(() =>
  import(
    "../../../../pages/Admin/community/ApplicationForms/ApplicationFormList/index.jsx"
  )
);

const ApplicationFormView = React.lazy(() =>
  import(
    "../../../../pages/Admin/community/ApplicationForms/ApplicationFormView/index.jsx"
  )
);

const ApplicationFromCreate = React.lazy(() =>
  import(
    "../../../../pages/Admin/community/ApplicationForms/ApplicationFormCreate/index.jsx"
  )
);

const ApplicationFormEdit = React.lazy(() =>
  import(
    "../../../../pages/Admin/community/ApplicationForms/ApplicationFormEdit/index.jsx"
  )
);

// roles
const RolesList = React.lazy(() =>
  import("../../../../pages/Admin/community/Roles/RolesList/index.jsx")
);

const RoleCreate = React.lazy(() =>
  import("../../../../pages/Admin/community/Roles/RoleCreate/index.jsx")
);

const RoleEdit = React.lazy(() =>
  import("../../../../pages/Admin/community/Roles/RoleEdit/index.jsx")
);

// Tiers
const TiersList = React.lazy(() =>
  import("../../../../pages/Admin/community/Tiers/TiersList/index.jsx")
);
const TiersEdit = React.lazy(() =>
  import("../../../../pages/Admin/community/Tiers/TiersEdit/index.jsx")
);
const TiersCreate = React.lazy(() =>
  import("../../../../pages/Admin/community/Tiers/TiersCreate/index.jsx")
);

const TiersView = React.lazy(() =>
  import("../../../../pages/Admin/community/Tiers/TiersView/index.jsx")
);

// subscription submissions
const SubscriptionSubmissionsList = React.lazy(() =>
  import(
    "../../../../pages/Admin/community/SubscriptionSubmissions/SubscriptionSubmissionsList/index.jsx"
  )
);

const ViewSubscriptionSubmissionsForm = React.lazy(() =>
  import(
    "../../../../pages/Admin/community/SubscriptionSubmissions/ViewSubscriptionSubmissionsForm/index.jsx"
  )
);

// community owners
const CommunityOwnersList = React.lazy(() =>
  import(
    "../../../../pages/Admin/community/CommunityOwners/CommunityOwnersList/index.jsx"
  )
);
const CommunityOwnerCreate = React.lazy(() =>
  import(
    "../../../../pages/Admin/community/CommunityOwners/CommunityOwnerCreate/index.jsx"
  )
);
const CommunityOwnersEdit = React.lazy(() =>
  import(
    "../../../../pages/Admin/community/CommunityOwners/CommunityOwnersEdit/index.jsx"
  )
);

// subscription
const Checkout = React.lazy(() =>
  import("../../../../pages/CheckoutForm/index.jsx")
);
const SubscriptionReturn = React.lazy(() =>
  import("../../../../pages/SubscriptionReturn/index.jsx")
);

const SubscriptionUpdate = React.lazy(() =>
  import("../../../../pages/SubscriptionUpdate/index.jsx")
);

const SubscriptionCancel = React.lazy(() =>
  import("../../../../pages/SubscriptionCancel/index.jsx")
);

const SubscriptionRenew = React.lazy(() =>
  import("../../../../pages/SubscriptionRenew/index.jsx")
);
// edge login
const EdgeLogin = React.lazy(() =>
  import("../../../../pages/EdgeLogin/index.jsx")
);
// subscription reminder
const SubscriptionReminder = React.lazy(() =>
  import("../../../../pages/Admin/community/SubscriptionReminder/index")
);

const CommunityProfile = React.lazy(() =>
  import("../../../../pages/CommunityProfile/index.jsx")
);
const CommunityUsersList = React.lazy(() =>
  import(
    "../../../../pages/Admin/community/CommunityMembers/CommunityUsersList/index"
  )
);

// const InviteCommunityMember = React.lazy(() =>
//   import(
//     "../../../../pages/Admin/community/CommunityMembers/CommunityMemberInvite/index.jsx"
//   )
// );

const CommunityUsersSubscriptions = React.lazy(() =>
  import(
    "../../../../pages/Admin/community/CommunityMembers/CommunityUsersSubscriptions/index.jsx"
  )
);

const CommunityUserProfileSummary = React.lazy(() =>
  import(
    "../../../../pages/Admin/community/CommunityMembers/CommunityUserProfileSummary/index.jsx"
  )
);

// tags
const TagsList = React.lazy(() =>
  import("../../../../pages/Admin/community/TagManagement/Tags.js")
);

// notifications
const NotificationList = React.lazy(() =>
  import(
    "../../../../pages/Admin/community/AdminNotification/AllNotification.js"
  )
);

const NotificationCreate = React.lazy(() =>
  import(
    "../../../../pages/Admin/community/AdminNotification/CreateNotification.js"
  )
);

const NotificationEdit = React.lazy(() =>
  import(
    "../../../../pages/Admin/community/AdminNotification/EditNotification.js"
  )
);

// groups

const GroupsList = React.lazy(() =>
  import("../../../../pages/Admin/community/GroupManagement/GroupList.js")
);

const GroupsCreate = React.lazy(() =>
  import("../../../../pages/Admin/community/GroupManagement/CreateGroup.js")
);

const GroupEdit = React.lazy(() =>
  import("../../../../pages/Admin/community/GroupManagement/GroupInfo.js")
);

const CreateCommunity = React.lazy(() =>
  import("../../../../pages/CreateCommunity/index")
);
const AuthenticatedAdminRoutes = [
  {
    path: "/admin",
    element: LazyLoadComponent(<AuthenticatedAdminLayout />),
    children: [
      {
        index: true,
        skipLazyLoad: true,
        element: (
          <Navigate
            to="/admin/community/dashboard"
            state={{ id: "COMMUNITY_PROFILE_MANAGEMENT" }}
          />
        ),
      },
      {
        path: ADMIN_SCOPE_ROUTES.communityProfile.basePath,
        children: [
          {
            exact: true,
            path: ADMIN_SCOPE_ROUTES.communityProfile.actionPath.dashboard,
            element: LazyLoadComponent(
              <RequireAuth access={"COMMUNITY_PROFILE_MANAGEMENT"}>
                <Dashboard />
              </RequireAuth>
            ),
          },
          {
            exact: true,
            path: ADMIN_SCOPE_ROUTES.communityProfile.actionPath.info,
            element: LazyLoadComponent(
              <RequireAuth access={"COMMUNITY_PROFILE_MANAGEMENT"}>
                <CommunityInfo />
              </RequireAuth>
            ),
          },
          {
            exact: true,
            path: ADMIN_SCOPE_ROUTES.communityProfile.actionPath.subscription,
            element: LazyLoadComponent(
              <RequireAuth access={"COMMUNITY_PROFILE_MANAGEMENT"}>
                <SubscriptionInfo />
              </RequireAuth>
            ),
          },
          {
            exact: true,
            path: ADMIN_SCOPE_ROUTES.communityProfile.actionPath.payouts,
            element: LazyLoadComponent(
              <RequireAuth access={"COMMUNITY_PROFILE_MANAGEMENT"}>
                <Payouts />
              </RequireAuth>
            ),
          },
        ],
      },
      {
        path: ADMIN_SCOPE_ROUTES.reminder.basePath,
        children: [
          {
            exact: true,
            path: ADMIN_SCOPE_ROUTES.reminder.actionPath.list,
            element: LazyLoadComponent(
              <RequireAuth access={"SUBSCRIPTION_REMINDER_MANAGEMENT"}>
                <SubscriptionReminder />
              </RequireAuth>
            ),
          },
        ],
      },
      {
        path: ADMIN_SCOPE_ROUTES.applicationForms.basePath,
        children: [
          {
            exact: true,
            path: ADMIN_SCOPE_ROUTES.applicationForms.actionPath.list,
            element: LazyLoadComponent(
              <RequireAuth access={"APPLICATION_FORMS_MANAGEMENT"}>
                <ApplicationFormList />
              </RequireAuth>
            ),
          },
          {
            exact: true,
            path: ADMIN_SCOPE_ROUTES.applicationForms.actionPath.view,
            element: LazyLoadComponent(
              <RequireAuth access={"APPLICATION_FORMS_MANAGEMENT"}>
                <ApplicationFormView />
              </RequireAuth>
            ),
          },
          {
            exact: true,
            path: ADMIN_SCOPE_ROUTES.applicationForms.actionPath.create,
            element: LazyLoadComponent(
              <RequireAuth access={"APPLICATION_FORMS_MANAGEMENT"}>
                <ApplicationFromCreate />
              </RequireAuth>
            ),
          },
          {
            exact: true,
            path: ADMIN_SCOPE_ROUTES.applicationForms.actionPath.edit,
            element: LazyLoadComponent(
              <RequireAuth access={"APPLICATION_FORMS_MANAGEMENT"}>
                <ApplicationFormEdit />
              </RequireAuth>
            ),
          },
        ],
      },
      {
        path: ADMIN_SCOPE_ROUTES.communityMembers.basePath,
        children: [
          {
            path: ADMIN_SCOPE_ROUTES.communityMembers.actionPath.list,
            exact: true,
            element: LazyLoadComponent(
              <RequireAuth access={"MEMBER_PROFILE_MANAGEMENT"}>
                <CommunityUsersList />
              </RequireAuth>
            ),
          },
          {
            path: ADMIN_SCOPE_ROUTES.communityMembers.actionPath.subscriptions,
            exact: true,
            element: LazyLoadComponent(
              <RequireAuth access={"MEMBER_PROFILE_MANAGEMENT"}>
                <CommunityUsersSubscriptions />
              </RequireAuth>
            ),
          },
          {
            path: ADMIN_SCOPE_ROUTES.communityMembers.actionPath.view,
            exact: true,
            element: LazyLoadComponent(
              <RequireAuth access={"MEMBER_PROFILE_MANAGEMENT"}>
                <CommunityUserProfileSummary />
              </RequireAuth>
            ),
          },
          {
            path: ADMIN_SCOPE_ROUTES.communityMembers.actionPath.invite,
            exact: true,
            element: LazyLoadComponent(
              <RequireAuth access={"MEMBER_PROFILE_MANAGEMENT"}>
                {/* <InviteCommunityMember /> */}
              </RequireAuth>
            ),
          },
        ],
      },
      {
        path: ADMIN_SCOPE_ROUTES.tiers.basePath,
        children: [
          {
            exact: true,
            path: ADMIN_SCOPE_ROUTES.tiers.actionPath.list,
            element: LazyLoadComponent(
              <RequireAuth access={"TIER_MANAGEMENT"}>
                <TiersList />
              </RequireAuth>
            ),
          },

          {
            exact: true,
            path: ADMIN_SCOPE_ROUTES.tiers.actionPath.create,
            element: LazyLoadComponent(
              <RequireAuth access={"TIER_MANAGEMENT"}>
                <TiersCreate />
              </RequireAuth>
            ),
          },
          {
            exact: true,
            path: ADMIN_SCOPE_ROUTES.tiers.actionPath.edit,
            element: LazyLoadComponent(
              <RequireAuth access={"TIER_MANAGEMENT"}>
                <TiersEdit />
              </RequireAuth>
            ),
          },
          {
            exact: true,
            path: ADMIN_SCOPE_ROUTES.tiers.actionPath.viewTiers,
            element: LazyLoadComponent(
              <RequireAuth access={"TIER_MANAGEMENT"}>
                <TiersView />
              </RequireAuth>
            ),
          },
        ],
      },
      {
        path: ADMIN_SCOPE_ROUTES.subscriptionSubmissions.basePath,
        children: [
          {
            exact: true,
            path: ADMIN_SCOPE_ROUTES.subscriptionSubmissions.actionPath.list,
            element: LazyLoadComponent(
              <RequireAuth access={"SUBSCRIPTION_SUBMISSIONS_MANAGEMENT"}>
                <SubscriptionSubmissionsList />
              </RequireAuth>
            ),
          },
          {
            exact: true,
            path: ADMIN_SCOPE_ROUTES.subscriptionSubmissions.actionPath.view,
            element: LazyLoadComponent(
              <RequireAuth access={"SUBSCRIPTION_SUBMISSIONS_MANAGEMENT"}>
                <ViewSubscriptionSubmissionsForm />
              </RequireAuth>
            ),
          },
        ],
      },
      {
        path: ADMIN_SCOPE_ROUTES.communityOwners.basePath,
        children: [
          {
            exact: true,
            path: ADMIN_SCOPE_ROUTES.communityOwners.actionPath.list,
            element: LazyLoadComponent(
              <RequireAuth access={"COMMUNITY_OWNERS_MANAGEMENT"}>
                <CommunityOwnersList />
              </RequireAuth>
            ),
          },
          {
            exact: true,
            path: ADMIN_SCOPE_ROUTES.communityOwners.actionPath.create,
            element: LazyLoadComponent(
              <RequireAuth access={"COMMUNITY_OWNERS_MANAGEMENT"}>
                <CommunityOwnerCreate />
              </RequireAuth>
            ),
          },
          {
            exact: true,
            path: ADMIN_SCOPE_ROUTES.communityOwners.actionPath.edit,
            element: LazyLoadComponent(
              <RequireAuth access={"COMMUNITY_OWNERS_MANAGEMENT"}>
                <CommunityOwnersEdit />
              </RequireAuth>
            ),
          },
        ],
      },
      {
        path: ADMIN_SCOPE_ROUTES.roles.basePath,
        children: [
          {
            exact: true,
            path: ADMIN_SCOPE_ROUTES.roles.actionPath.list,
            element: LazyLoadComponent(
              <RequireAuth access={"ROLE_MANAGEMENT"}>
                <RolesList />
              </RequireAuth>
            ),
          },
          {
            exact: true,
            path: ADMIN_SCOPE_ROUTES.roles.actionPath.create,
            element: LazyLoadComponent(
              <RequireAuth access={"ROLE_MANAGEMENT"}>
                <RoleCreate />
              </RequireAuth>
            ),
          },
          {
            exact: true,
            path: ADMIN_SCOPE_ROUTES.roles.actionPath.edit,
            element: LazyLoadComponent(
              <RequireAuth access={"ROLE_MANAGEMENT"}>
                <RoleEdit />
              </RequireAuth>
            ),
          },
        ],
      },
      {
        path: ADMIN_SCOPE_ROUTES.edgeLogin.basePath,
        children: [
          {
            path: ADMIN_SCOPE_ROUTES.edgeLogin.actionPath.list,
            element: LazyLoadComponent(<EdgeLogin />),
          },
        ],
      },
      {
        path: ADMIN_SCOPE_ROUTES.discover.basePath,
        children: [
          {
            path: ADMIN_SCOPE_ROUTES.discover.actionPath.list,
            exact: true,
            element: LazyLoadComponent(<DiscoverCommunities />),
          },
        ],
      },
      {
        path: SCOPE_ROUTES.events.basePath,
        children: [
          {
            path: SCOPE_ROUTES.events.actionPath.list,
            exact: true,
            element: LazyLoadComponent(
              <RequireAuth access={"events"}>
                <EventList />
              </RequireAuth>
            ),
          },
          {
            path: SCOPE_ROUTES.events.actionPath.create,
            exact: true,
            element: LazyLoadComponent(
              <RequireAuth access={"events"}>
                <CreateEvent />
              </RequireAuth>
            ),
          },
          {
            path: SCOPE_ROUTES.events.actionPath.type,
            exact: true,
            element: LazyLoadComponent(
              <RequireAuth access={"events"}>
                <EventType />
              </RequireAuth>
            ),
          },
          {
            path: SCOPE_ROUTES.events.actionPath.categoeis,
            exact: true,
            element: LazyLoadComponent(
              <RequireAuth access={"events"}>
                <EventCategories />
              </RequireAuth>
            ),
          },
          {
            path: SCOPE_ROUTES.events.actionPath.edit,
            exact: true,
            element: LazyLoadComponent(
              <RequireAuth access={"events"}>
                <EventDetail />
              </RequireAuth>
            ),
          },
        ],
      },
      {
        path: SCOPE_ROUTES.news.basePath,
        children: [
          {
            path: SCOPE_ROUTES.news.actionPath.banners,
            exact: true,
            element: LazyLoadComponent(
              <RequireAuth access={"news"}>
                <Banners />
              </RequireAuth>
            ),
          },
          {
            path: SCOPE_ROUTES.news.actionPath.content,
            exact: true,
            element: LazyLoadComponent(
              <RequireAuth access={"news"}>
                <ContentListing />
              </RequireAuth>
            ),
          },
          {
            path: SCOPE_ROUTES.news.actionPath.posts,
            exact: true,
            element: LazyLoadComponent(
              <RequireAuth access={"news"}>
                <Posts />
              </RequireAuth>
            ),
          },
          {
            path: SCOPE_ROUTES.news.actionPath.news,
            exact: true,
            element: LazyLoadComponent(
              <RequireAuth access={"news"}>
                <News />
              </RequireAuth>
            ),
          },
        ],
      },
      {
        path: SCOPE_ROUTES.channel.basePath,
        children: [
          {
            path: SCOPE_ROUTES.channel.actionPath.create,
            exact: true,
            element: LazyLoadComponent(
              <RequireAuth access={"channels"}>
                <CreateChannel />
              </RequireAuth>
            ),
          },
          {
            path: SCOPE_ROUTES.channel.actionPath.list,
            exact: true,
            element: LazyLoadComponent(
              <RequireAuth access={"channels"}>
                <ChannelList />
              </RequireAuth>
            ),
          },
          {
            path: SCOPE_ROUTES.channel.actionPath.edit,
            exact: true,
            element: LazyLoadComponent(
              <RequireAuth access={"channels"}>
                <EditChannel />
              </RequireAuth>
            ),
          },
        ],
      },
      {
        path: SCOPE_ROUTES.partner.basePath,
        children: [
          {
            path: SCOPE_ROUTES.partner.actionPath.list,
            exact: true,
            element: LazyLoadComponent(
              <RequireAuth access={"partners"}>
                <PartnerList />
              </RequireAuth>
            ),
          },
          {
            path: SCOPE_ROUTES.partner.actionPath.create,
            exact: true,
            element: LazyLoadComponent(
              <RequireAuth access={"partners"}>
                <CreatePartner />
              </RequireAuth>
            ),
          },
          {
            path: SCOPE_ROUTES.partner.actionPath.edit,
            exact: true,
            element: LazyLoadComponent(
              <RequireAuth access={"partners"}>
                <EditPartner />
              </RequireAuth>
            ),
          },
          {
            path: SCOPE_ROUTES.partner.actionPath.freshdeals,
            exact: true,
            element: LazyLoadComponent(
              <RequireAuth access={"partners"}>
                <FreshPartner />
              </RequireAuth>
            ),
          },
          {
            path: SCOPE_ROUTES.partner.actionPath.featuredlist,
            exact: true,
            element: LazyLoadComponent(
              <RequireAuth access={"partners"}>
                <FeaturedPartner />
              </RequireAuth>
            ),
          },
          {
            path: SCOPE_ROUTES.partner.actionPath.reason,
            exact: true,
            element: LazyLoadComponent(
              <RequireAuth access={"partners"}>
                <PartnerReason />
              </RequireAuth>
            ),
          },
          {
            path: SCOPE_ROUTES.partner.actionPath.reviews,
            exact: true,
            element: LazyLoadComponent(
              <RequireAuth access={"partners"}>
                <PartnerReviews />
              </RequireAuth>
            ),
          },
          {
            path: SCOPE_ROUTES.partner.actionPath.badges,
            exact: true,
            element: LazyLoadComponent(
              <RequireAuth access={"partners"}>
                <PartnerTypes />
              </RequireAuth>
            ),
          },
          {
            path: SCOPE_ROUTES.partner.actionPath.maincontent,
            exact: true,
            element: LazyLoadComponent(
              <RequireAuth access={"partners"}>
                <PartnerBanner />
              </RequireAuth>
            ),
          },
          {
            path: SCOPE_ROUTES.partner.actionPath.partnercategories,
            exact: true,
            element: LazyLoadComponent(
              <RequireAuth access={"partners"}>
                <PartnerCategories />
              </RequireAuth>
            ),
          },
          {
            path: SCOPE_ROUTES.partner.actionPath.partnerstatistics,
            exact: true,
            element: LazyLoadComponent(
              <RequireAuth access={"partners"}>
                <PartnerStatistics />
              </RequireAuth>
            ),
          },
        ],
      },
      {
        path: SCOPE_ROUTES.media.basePath,
        children: [
          {
            path: SCOPE_ROUTES.media.basePath,
            exact: true,
            element: LazyLoadComponent(
              <RequireAuth access={"medias"}>
                <Media />
              </RequireAuth>
            ),
          },
        ],
      },
      {
        path: SCOPE_ROUTES.document.basePath,
        children: [
          {
            path: SCOPE_ROUTES.document.actionPath.list,
            exact: true,
            element: LazyLoadComponent(
              <RequireAuth access={"documents"}>
                <DocumentList />
              </RequireAuth>
            ),
          },
          {
            path: SCOPE_ROUTES.document.actionPath.create,
            exact: true,
            element: LazyLoadComponent(
              <RequireAuth access={"documents"}>
                <DocumentCreate />
              </RequireAuth>
            ),
          },
          {
            path: SCOPE_ROUTES.document.actionPath.edit,
            exact: true,
            element: LazyLoadComponent(
              <RequireAuth access={"documents"}>
                <DocumentEdit />
              </RequireAuth>
            ),
          },
          {
            path: SCOPE_ROUTES.document.actionPath.categories,
            exact: true,
            element: LazyLoadComponent(
              <RequireAuth access={"documents"}>
                <DocumentCategory />
              </RequireAuth>
            ),
          },
          {
            path: SCOPE_ROUTES.document.actionPath.marcros,
            exact: true,
            element: LazyLoadComponent(
              <RequireAuth access={"documents"}>
                <DocumentReason />
              </RequireAuth>
            ),
          },
          {
            path: SCOPE_ROUTES.document.actionPath.contenttypes,
            exact: true,
            element: LazyLoadComponent(
              <RequireAuth access={"documents"}>
                <DocumentContentType />
              </RequireAuth>
            ),
          },
          {
            path: SCOPE_ROUTES.document.actionPath.userdownloads,
            exact: true,
            element: LazyLoadComponent(
              <RequireAuth access={"documents"}>
                <DocumentUserDownload />
              </RequireAuth>
            ),
          },
        ],
      },
      {
        path: SCOPE_ROUTES.videos.basePath,
        children: [
          {
            path: SCOPE_ROUTES.videos.actionPath.list,
            exact: true,
            element: LazyLoadComponent(
              <RequireAuth access={"videos"}>
                <VideoListing />
              </RequireAuth>
            ),
          },
          {
            path: SCOPE_ROUTES.videos.actionPath.deletedvideo,
            exact: true,
            element: LazyLoadComponent(
              <RequireAuth access={"videos"}>
                <DeletedVideoListing />
              </RequireAuth>
            ),
          },
          {
            path: SCOPE_ROUTES.videos.actionPath.videoevent,
            exact: true,
            element: LazyLoadComponent(
              <RequireAuth access={"videos"}>
                <VideoEventListing />
              </RequireAuth>
            ),
          },
          {
            path: SCOPE_ROUTES.videos.actionPath.categories,
            exact: true,
            element: LazyLoadComponent(
              <RequireAuth access={"videos"}>
                <VideoCategories />
              </RequireAuth>
            ),
          },
          {
            path: SCOPE_ROUTES.videos.actionPath.create,
            exact: true,
            element: LazyLoadComponent(
              <RequireAuth access={"videos"}>
                <VideoCreate />
              </RequireAuth>
            ),
          },
          {
            path: SCOPE_ROUTES.videos.actionPath.detail,
            exact: true,
            element: LazyLoadComponent(
              <RequireAuth access={"videos"}>
                <VideoDetail />
              </RequireAuth>
            ),
          },
          {
            path: SCOPE_ROUTES.videos.actionPath.edit,
            exact: true,
            element: LazyLoadComponent(
              <RequireAuth access={"videos"}>
                <VideoEdit />
              </RequireAuth>
            ),
          },
        ],
      },
      {
        path: SCOPE_ROUTES.messagereport.basePath,
        children: [
          {
            path: SCOPE_ROUTES.messagereport.basePath,
            exact: true,
            element: LazyLoadComponent(
              <RequireAuth access={"reports"}>
                <Reports />
              </RequireAuth>
            ),
          },
        ],
      },
      {
        path: SCOPE_ROUTES.tags.basePath,
        children: [
          {
            path: SCOPE_ROUTES.tags.actionPath.list,
            exact: true,
            element: LazyLoadComponent(
              <RequireAuth access={"tags"}>
                <TagsList />
              </RequireAuth>
            ),
          },
        ],
      },
      {
        path: SCOPE_ROUTES.statistic.basePath,
        children: [
          {
            path: SCOPE_ROUTES.statistic.actionPath.videostatistic,
            exact: true,
            element: LazyLoadComponent(
              <RequireAuth access={"statistics"}>
                <VideoStatistic />
              </RequireAuth>
            ),
          },
          {
            path: SCOPE_ROUTES.statistic.actionPath.categorystatistic,
            exact: true,
            element: LazyLoadComponent(
              <RequireAuth access={"statistics"}>
                <CategoryStatistic />
              </RequireAuth>
            ),
          },
          {
            path: SCOPE_ROUTES.statistic.actionPath.speakerstatistic,
            exact: true,
            element: LazyLoadComponent(
              <RequireAuth access={"statistics"}>
                <SpeakerStatistic />
              </RequireAuth>
            ),
          },
          {
            path: SCOPE_ROUTES.statistic.actionPath.tagstatistic,
            exact: true,
            element: LazyLoadComponent(
              <RequireAuth access={"statistics"}>
                <TagStatistic />
              </RequireAuth>
            ),
          },
          {
            path: SCOPE_ROUTES.statistic.actionPath.eventstatistic,
            exact: true,
            element: LazyLoadComponent(
              <RequireAuth access={"statistics"}>
                <EventStatistic />
              </RequireAuth>
            ),
          },
          {
            path: SCOPE_ROUTES.statistic.actionPath.userstatistic,
            exact: true,
            element: LazyLoadComponent(
              <RequireAuth access={"statistics"}>
                <UserStatistic />
              </RequireAuth>
            ),
          },
        ],
      },
      {
        path: SCOPE_ROUTES.notifications.basePath,
        children: [
          {
            path: SCOPE_ROUTES.notifications.actionPath.list,
            exact: true,
            element: LazyLoadComponent(
              <RequireAuth access={"notifications"}>
                <NotificationList />
              </RequireAuth>
            ),
          },
          {
            path: SCOPE_ROUTES.notifications.actionPath.create,
            exact: true,
            element: LazyLoadComponent(
              <RequireAuth access={"notifications"}>
                <NotificationCreate />
              </RequireAuth>
            ),
          },
          {
            path: SCOPE_ROUTES.notifications.actionPath.edit,
            exact: true,
            element: LazyLoadComponent(
              <RequireAuth access={"notifications"}>
                <NotificationEdit />
              </RequireAuth>
            ),
          },
        ],
      },
      {
        path: SCOPE_ROUTES.group.basePath,
        children: [
          {
            path: SCOPE_ROUTES.group.actionPath.list,
            exact: true,
            element: LazyLoadComponent(
              <RequireAuth access={"groups"}>
                <GroupsList />
              </RequireAuth>
            ),
          },
          {
            path: SCOPE_ROUTES.group.actionPath.create,
            exact: true,
            element: LazyLoadComponent(
              <RequireAuth access={"groups"}>
                <GroupsCreate />
              </RequireAuth>
            ),
          },
          {
            path: SCOPE_ROUTES.group.actionPath.edit,
            exact: true,
            element: LazyLoadComponent(
              <RequireAuth access={"groups"}>
                <GroupEdit />
              </RequireAuth>
            ),
          },
        ],
      },
      {
        path: "*",
        element: LazyLoadComponent(<NotFound />),
      },
    ],
  },
  {
    path: ADMIN_SCOPE_ROUTES.community.basePath,
    element: LazyLoadComponent(<AuthenticatedAdminLayout />),
    children: [
      {
        index: true,
        element: LazyLoadComponent(<CommunityProfile />),
      },
    ],
  },
  {
    path: SUBSCRIPTION_ADMIN_SCOPE_ROUTES.subscription.basePath,
    element: LazyLoadComponent(<SubscriptionLayout />),
    children: [
      {
        exact: true,
        path: SUBSCRIPTION_ADMIN_SCOPE_ROUTES.subscription.actionPath
          .subscriptionCheckout,
        element: LazyLoadComponent(<Checkout />),
      },
      {
        exact: true,
        path: SUBSCRIPTION_ADMIN_SCOPE_ROUTES.subscription.actionPath
          .tierUpgrade,
        element: LazyLoadComponent(<>tier upgrade</>),
      },
      {
        exact: true,
        path: SUBSCRIPTION_ADMIN_SCOPE_ROUTES.subscription.actionPath.update,
        element: LazyLoadComponent(<SubscriptionUpdate />),
      },
      {
        exact: true,
        path: SUBSCRIPTION_ADMIN_SCOPE_ROUTES.subscription.actionPath.cancel,
        element: LazyLoadComponent(<SubscriptionCancel />),
      },
      {
        exact: true,
        path: SUBSCRIPTION_ADMIN_SCOPE_ROUTES.subscription.actionPath
          .subscriptionReturn,
        element: LazyLoadComponent(<SubscriptionReturn />),
      },
      {
        exact: true,
        path: SUBSCRIPTION_ADMIN_SCOPE_ROUTES.subscription.actionPath.pricing,
        element: LazyLoadComponent(<Pricing />),
      },
      {
        exact: true,
        path: SUBSCRIPTION_ADMIN_SCOPE_ROUTES.subscription.actionPath.renew,
        element: LazyLoadComponent(<SubscriptionRenew />),
      },
      {
        exact: true,
        path: SUBSCRIPTION_ADMIN_SCOPE_ROUTES.subscription.actionPath.submit,
        element: LazyLoadComponent(<SubmitApplicationForm />),
      },
    ],
  },
  {
    path: SETTINGS_ADMIN_SCOPE_ROUTES.settings.basePath,
    element: LazyLoadComponent(<SettingsLayout />),
    children: [
      {
        exact: true,
        path: SETTINGS_ADMIN_SCOPE_ROUTES.settings.actionPath.edit,
        element: LazyLoadComponent(<>edit</>),
      },
      {
        exact: true,
        path: SETTINGS_ADMIN_SCOPE_ROUTES.settings.actionPath.profile,
        element: LazyLoadComponent(<>profile </>),
      },
      {
        exact: true,
        path: SETTINGS_ADMIN_SCOPE_ROUTES.settings.actionPath.users,
        element: LazyLoadComponent(<>users </>),
      },
      {
        exact: true,
        path: SETTINGS_ADMIN_SCOPE_ROUTES.settings.actionPath.contactUs,
        element: LazyLoadComponent(<>contactus </>),
      },
      {
        exact: true,
        path: SETTINGS_ADMIN_SCOPE_ROUTES.settings.actionPath.privacy,
        element: LazyLoadComponent(<>privacy </>),
      },
      {
        exact: true,
        path: SETTINGS_ADMIN_SCOPE_ROUTES.settings.actionPath.terms,
        element: LazyLoadComponent(<>terms </>),
      },
      {
        exact: true,
        path: SETTINGS_ADMIN_SCOPE_ROUTES.settings.actionPath.subscription,
        element: LazyLoadComponent(<SubscriptionInfo />),
      },
      {
        exact: true,
        path: EDGE_SCOPE_ADMIN_SCOPE_ROUTES.edgeLogin.basePath,
        element: LazyLoadComponent(<EdgeLogin />),
      },
      {
        exact: true,
        path: ADMIN_SCOPE_ROUTES.discover.basePath,
        element: LazyLoadComponent(<DiscoverCommunities />),
      },
    ],
  },
  {
    path: EDGE_SCOPE_ADMIN_SCOPE_ROUTES.edge.basePath,
    element: LazyLoadComponent(<AuthenticatedAdminLayout />),
    children: [
      {
        index: true,
        element: LazyLoadComponent(<CreateCommunity />),
      },
    ],
  },
  {
    path: "*",
    skipLazyLoad: true,
    element: <Navigate to="/admin" />,
  },
];

export default AuthenticatedAdminRoutes;
