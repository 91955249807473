import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./Store.js";
import { AuthProvider } from "./context/AuthProvider";
import { Auth0Provider } from "@auth0/auth0-react";

//Import css files
// import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Styles/Theme/Theme.css";
import "./Styles/frontendGlobal.css";
import "./Styles/adminGlobal.css";
import "./Styles/registration.css";
import "./Styles/responsive.css";
import "./Styles/chatmodule.css";
import "./Styles/frontendcontentlibrary.css";
import "./Styles/dashboardpopup.css";
import "./Styles/header.css";
import "./Styles/collaborotor.css";
import "./Styles/docManagement.css";

// Import component
import App from "./App.jsx";
import { NavigationProvider } from "./context/NavigationProvider.jsx";
import { NotificationProvider } from "context/NotificationProvider.jsx";
import { SocketProvider } from "context/SocketProvider.jsx";
import { CONFIGS } from "config/index.js";
import { PreventionProvider } from "context/PreventionProvider.jsx";


if (module.hot) {
  module.hot.accept();
}

const root = createRoot(document.getElementById("root"));
root.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
    authorizationParams={{
      redirect_uri: `${window.location.origin}/login`,
      scope: "openid profile email full_access",
    }}
    cookieDomain={CONFIGS.COOKIE_DOMAIN}
  >
    <Provider store={store}>
      <AuthProvider>
        <SocketProvider>
          <NavigationProvider>
            <BrowserRouter>
              <NotificationProvider>
                <PreventionProvider>
                  <App />
                </PreventionProvider>
              </NotificationProvider>
            </BrowserRouter>
          </NavigationProvider>
        </SocketProvider>
      </AuthProvider>
    </Provider>
  </Auth0Provider>
);
