/* eslint-disable jsx-a11y/anchor-is-valid */
import { useAuth0 } from "@auth0/auth0-react";
import useAuth from "Hooks/useAuth";
import React, { useEffect } from "react";
import { Alert, Box, Button, Snackbar } from "@mui/material";
import Logouticon from "../../../Images/logouticon.svg";
import { useCreateAccountLinkMutation } from "Services/stripe";
import { useLazyGetUserEdgesQuery } from "Services/authentication";
import { useLazyFetchOnboardingStatusQuery } from "Services/stripe";
import Cookies from "js-cookie";
import { CONFIGS } from "config";

const AdminHeader = ({ type }) => {
  const { auth } = useAuth();
  const { logout } = useAuth0();
  const handleLogout = async () => {
    await logout({
      logoutParams: {
        returnTo: `${window.location.origin}/login`,
      },
    });
    Cookies.remove("x-app-token", { domain: CONFIGS.COOKIE_DOMAIN, path: "/" });
  };

  const [createAccountLink, { isLoading: createLinkLoading }] =
    useCreateAccountLinkMutation();

  // fetch onboarding status api
  const [onboarding, { data: onboardingStatus, isLoading: statusLoading }] =
    useLazyFetchOnboardingStatusQuery();

  useEffect(() => {
    if (auth.currentEdge.type === "CO") {
      onboarding({ accountId: auth.currentEdge.stripe_account_db_id });
    }
  }, [auth, onboarding]);
  // get user edges
  const [getUserEdges, { isLoading: edgeLoading }] = useLazyGetUserEdgesQuery();
  // handle create account link
  const handleCreateAccountLink = async () => {
    if (!auth?.currentEdge?.stripe_account_db_id) {
      const userEdge = await getUserEdges({
        user_id: auth.userId,
        relation_id: auth.currentEdge.relation_id._id,
      });
      const createLink = await createAccountLink({
        accountId: userEdge.data.stripe_account_db_id,
      });
      window.location.href = createLink.data;
    } else {
      const createLink = await createAccountLink({
        accountId: auth?.currentEdge?.stripe_account_db_id,
      });
      window.location.href = createLink.data;
    }
  };

  return (
    <Box sx={{ boxShadow: "0 2px 4px 0 rgba(0,0,0,.2)" }} className="header-bk">
      <Box className="header-bk-left">
        <h1>
          {type !== "PO" ? (
            auth.currentEdge?.relation_id?.logo ? (
              <a>
                <img
                  width={"100px"}
                  height={"100px"}
                  style={{ objectFit: "contain" }}
                  src={auth.currentEdge?.relation_id?.logo}
                  alt="Logo"
                />
              </a>
            ) : (
              auth.currentEdge?.relation_id.name.toUpperCase()
            )
          ) : auth.currentEdge?.relation_id?.logo ? (
            <a>
              <img
                width={"100px"}
                height={"100px"}
                style={{ objectFit: "contain" }}
                src={auth.currentEdge?.relation_id?.logo}
                alt="logo"
              />
            </a>
          ) : (
            "GROUPOS ADMIN PANEL"
          )}
        </h1>
      </Box>
      <Box className="header-bk-right">
        <Box
          className="header-bk-right-user dropdown"
          sx={{
            "& .dropdown-toggle": {
              display: "flex",
              alignItems: "center",
            },
          }}
        >
          <a
            className="dropdown-toggle"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <Box
              sx={{
                width: "30px",
                height: "30px",
                borderRadius: "50%",
                overflow: "hidden",
                backgroundColor: "#000",
                color: "#fff",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mr: "8px",
                flexShrink: "0",
                "& img": {
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  objectPosition: "center",
                },
              }}
            >
              {auth?.userAvatar.length > 0 ? (
                <img src={auth.userAvatar} className="user-icon" alt="user" />
              ) : (
                auth.userName.charAt(0)
              )}
            </Box>
            {auth.userName}
          </a>
          <Box className="dropdown-menu" aria-labelledby="navbarDropdown">
            <a onClick={handleLogout}>
              Logout <img src={Logouticon} alt="logout" />
            </a>
          </Box>
        </Box>
      </Box>
      {type === "CO" && !statusLoading && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={!onboardingStatus?.status && auth.currentEdge.owner}
        >
          <Alert
            severity="warning"
            action={
              <Button
                disabled={createLinkLoading || edgeLoading}
                onClick={handleCreateAccountLink}
              >
                Get Started
              </Button>
            }
          >
            Account onboarding is pending.
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};

export default AdminHeader;
