import React from 'react'
import PropTypes from "prop-types"
const ClearIcon = ({ width = 24, height = 24, iconColor = "#000" }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 24 24"
        >
            <path
                fill={iconColor}
                fillRule="evenodd"
                d="M20.621.303c-1.252-.686-2.792-.168-3.44 1.156L14.35 7.242a.845.845 0 0 0-.04.094c-1.386-.05-2.76.598-3.686 1.746-1.092 1.351-2.435 2.826-3.583 3.58-1.636 1.073-2.988 1.46-3.828 1.598-.492.08-.89.415-1.085.849a1.414 1.414 0 0 0 .207 1.507c.857 1 2.528 2.636 5.594 4.698 2.123 1.429 4.026 2.219 5.05 2.585.786.28 1.625-.04 2.075-.754a31.98 31.98 0 0 0 2.429-4.675c.635-1.532 1.182-3.111 1.706-4.687a4.612 4.612 0 0 0-.429-3.841l2.95-5.994c.653-1.325.165-2.958-1.089-3.645Zm-2.974 8.403 2.71-5.504c.262-.532.066-1.189-.438-1.465-.503-.275-1.122-.068-1.382.465l-2.69 5.493c.09.04.18.085.268.132l.983.528c.194.103.377.221.55.351Zm.102 4.541c-.109.328-.224.668-.342 1.014l-6.195-3.439c.2-.235.391-.467.572-.691.922-1.142 2.435-1.51 3.64-.863l.984.527c1.21.65 1.783 2.121 1.34 3.452Zm-9.903.787c.753-.494 1.54-1.217 2.277-1.993l.01.005 6.736 3.74c-.26.714-.526 1.41-.784 2.035a30.327 30.327 0 0 1-2.3 4.427c-.078.122-.202.162-.317.121a19.721 19.721 0 0 1-1.861-.781c.356-.398.872-1.047 1.367-1.94.214-.385.09-.875-.276-1.093a.757.757 0 0 0-1.05.303c-.399.718-.885 1.424-1.48 1.975-.455-.26-.931-.552-1.42-.881a43.28 43.28 0 0 1-1.118-.778c.18-.129.38-.287.593-.48a.814.814 0 0 0 .071-1.125.757.757 0 0 0-1.09-.07c-.473.429-.803.592-.936.647-1.216-.966-2.03-1.76-2.557-2.338 1.021-.2 2.463-.677 4.135-1.774Z"
                clipRule="evenodd"
            />
        </svg>
    )
}
ClearIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    iconColor: PropTypes.string
}
export default ClearIcon