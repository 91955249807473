import React from "react";
import LazyLoadComponent from "../../../../components/LazyLoadComponent";
import AuthenticatedGrouposAdminLayout from "../../../../layouts/Admin/groupos/AuthenticatedGrouposAdminLayout";

import { ADMIN_SCOPE_ROUTES } from "routes/Admin/groupos/menuList";
import { ADMIN_SCOPE_ROUTES as EDGE_SCOPE_ADMIN_SCOPE_ROUTES } from "routes/EdgeRoutes/menuList";
import { Navigate } from "react-router-dom";

// auth required check
const RequireAuth = React.lazy(() =>
  import("../../../../components/RequireAuth/index")
);

// Application Form
const ApplicationFormEdit = React.lazy(() =>
  import("../../../../pages/Admin/groupos/ApplicationForms/ApplicationFormEdit")
);

const ApplicationFormList = React.lazy(() =>
  import("../../../../pages/Admin/groupos/ApplicationForms/ApplicationFormList")
);

const ApplicationFromCreate = React.lazy(() =>
  import(
    "../../../../pages/Admin/groupos/ApplicationForms/ApplicationFormCreate"
  )
);

const ApplicationFormView = React.lazy(() =>
  import("../../../../pages/Admin/groupos/ApplicationForms/ApplicationFormView")
);

// Roles
const RolesList = React.lazy(() =>
  import("../../../../pages/Admin/groupos/Roles/RolesList")
);
const RoleCreate = React.lazy(() =>
  import("../../../../pages/Admin/groupos/Roles/RoleCreate")
);
const RoleEdit = React.lazy(() =>
  import("../../../../pages/Admin/groupos/Roles/RoleEdit")
);

const PlatformOwnersList = React.lazy(() =>
  import("../../../../pages/Admin/groupos/PlatformOwners/PlatformOwnersList")
);
const PlatformOwnerCreate = React.lazy(() =>
  import("../../../../pages/Admin/groupos/PlatformOwners/PlatformOwnerCreate")
);
const PlatformOwnersEdit = React.lazy(() =>
  import("../../../../pages/Admin/groupos/PlatformOwners/PlatformOwnersEdit")
);
const ModulesList = React.lazy(() =>
  import("../../../../pages/Admin/groupos/Modules/ModulesList")
);
const ModulesCreate = React.lazy(() =>
  import("../../../../pages/Admin/groupos/Modules/ModulesCreate")
);
const ModuleEdit = React.lazy(() =>
  import("../../../../pages/Admin/groupos/Modules/ModuleEdit")
);
const NotFound = React.lazy(() => import("../../../../pages/NotFound"));

// Tiers
const TiersList = React.lazy(() =>
  import("../../../../pages/Admin/groupos/Tiers/TiersList")
);
const TiersEdit = React.lazy(() =>
  import("../../../../pages/Admin/groupos/Tiers/TiersEdit")
);
const TiersCreate = React.lazy(() =>
  import("../../../../pages/Admin/groupos/Tiers/TiersCreate")
);

const TiersView = React.lazy(() =>
  import("../../../../pages/Admin/groupos/Tiers/TiersView")
);

// discover communities
const DiscoverCommunities = React.lazy(() =>
  import("../../../../pages/DiscoverCommunities/DiscoverCommunities.jsx")
);

// subscription submissions
const SubscriptionSubmissionsList = React.lazy(() =>
  import(
    "../../../../pages/Admin/groupos/SubscriptionSubmissions/SubscriptionSubmissionsList"
  )
);

const ViewSubscriptionSubmissionsForm = React.lazy(() =>
  import(
    "../../../../pages/Admin/groupos/SubscriptionSubmissions/ViewSubscriptionSubmissionsForm"
  )
);

// edge login
const EdgeLogin = React.lazy(() =>
  import("../../../../pages/EdgeLogin/index.jsx")
);

// subscription reminder
const SubscriptionReminder = React.lazy(() =>
  import("../../../../pages/Admin/groupos/SubscriptionReminder/index")
);
const CommunityProfile = React.lazy(() =>
  import("../../../../pages/CommunityProfile/index.jsx")
);

const CreateCommunity = React.lazy(() =>
  import("../../../../pages/CreateCommunity/index")
);
const PlatformUsersList = React.lazy(() =>
  import(
    "../../../../pages/Admin/groupos/PlatformUsers/PlatformUsersList/index"
  )
);

const Communities = React.lazy(() =>
  import("../../../../pages/Admin/groupos/PlatformUsers/Communities/index")
);

const PlatformUsersSubscriptions = React.lazy(() =>
  import(
    "../../../../pages/Admin/groupos/PlatformUsers/PlatformUsersSubscriptions/index"
  )
);

const PlatformUserProfileSummary = React.lazy(() =>
  import(
    "../../../../pages/Admin/groupos/PlatformUsers/PlatformUserProfileSummary/index"
  )
);

const MemberInfo = React.lazy(() =>
  import(
    "../../../../pages/Admin/groupos/PlatformUsers/CommunityUserProfileSummary/index"
  )
);

const LeadUsers = React.lazy(() =>
  import("../../../../pages/Admin/groupos/PlatformUsers/LeadUsers/index")
);

const CommunityUserProfileSummary = React.lazy(() =>
  import("../../../../pages/Admin/groupos/PlatformUsers/CommunitySummary/index")
);
const PlatformInfo = React.lazy(() =>
  import("../../../../pages/Admin/groupos/PlatformInfo/index")
);

// dashboard
const Dashboard = React.lazy(() =>
  import("../../../../pages/Admin/groupos/Dashboard/index.jsx")
);

const Payouts = React.lazy(() =>
  import("../../../../pages/Admin/groupos/Payouts/index")
);

const AuthenticatedGrouposAdminRoutes = [
  {
    path: "/admin/groupos",
    element: LazyLoadComponent(<AuthenticatedGrouposAdminLayout />),
    children: [
      {
        index: true,
        skipLazyLoad: true,
        element: (
          <Navigate
            to="/admin/groupos/platform/dashboard"
            state={{ id: "PLATFORM_PROFILE_MANAGEMENT" }}
          />
        ),
      },
      {
        path: ADMIN_SCOPE_ROUTES.platform.basePath,
        children: [
          {
            exact: true,
            path: ADMIN_SCOPE_ROUTES.platform.actionPath.dashboard,
            element: LazyLoadComponent(
              <RequireAuth access={"PLATFORM_PROFILE_MANAGEMENT"}>
                <Dashboard />
              </RequireAuth>
            ),
          },
          {
            exact: true,
            path: ADMIN_SCOPE_ROUTES.platform.actionPath.info,
            element: LazyLoadComponent(
              <RequireAuth access={"PLATFORM_PROFILE_MANAGEMENT"}>
                <PlatformInfo />
              </RequireAuth>
            ),
          },
          {
            exact: true,
            path: ADMIN_SCOPE_ROUTES.platform.actionPath.payouts,
            element: LazyLoadComponent(
              <RequireAuth access={"PLATFORM_PROFILE_MANAGEMENT"}>
                <Payouts />
              </RequireAuth>
            ),
          },
        ],
      },
      {
        path: ADMIN_SCOPE_ROUTES.roles.basePath,
        children: [
          {
            exact: true,
            path: ADMIN_SCOPE_ROUTES.roles.actionPath.list,
            element: LazyLoadComponent(
              <RequireAuth access={"ROLE_MANAGEMENT"}>
                <RolesList />
              </RequireAuth>
            ),
          },
          {
            exact: true,
            path: ADMIN_SCOPE_ROUTES.roles.actionPath.create,
            element: LazyLoadComponent(
              <RequireAuth access={"ROLE_MANAGEMENT"}>
                <RoleCreate />
              </RequireAuth>
            ),
          },
          {
            exact: true,
            path: ADMIN_SCOPE_ROUTES.roles.actionPath.edit,
            element: LazyLoadComponent(
              <RequireAuth access={"ROLE_MANAGEMENT"}>
                <RoleEdit />
              </RequireAuth>
            ),
          },
        ],
      },
      {
        path: ADMIN_SCOPE_ROUTES.platformOwners.basePath,
        children: [
          {
            exact: true,
            path: ADMIN_SCOPE_ROUTES.platformOwners.actionPath.list,
            element: LazyLoadComponent(
              <RequireAuth access={"PLATFORM_OWNERS_MANAGEMENT"}>
                <PlatformOwnersList />
              </RequireAuth>
            ),
          },
          {
            exact: true,
            path: ADMIN_SCOPE_ROUTES.platformOwners.actionPath.create,
            element: LazyLoadComponent(
              <RequireAuth access={"PLATFORM_OWNERS_MANAGEMENT"}>
                <PlatformOwnerCreate />
              </RequireAuth>
            ),
          },
          {
            exact: true,
            path: ADMIN_SCOPE_ROUTES.platformOwners.actionPath.edit,
            element: LazyLoadComponent(
              <RequireAuth access={"PLATFORM_OWNERS_MANAGEMENT"}>
                <PlatformOwnersEdit />
              </RequireAuth>
            ),
          },
        ],
      },
      {
        path: ADMIN_SCOPE_ROUTES.modules.basePath,
        children: [
          {
            exact: true,
            path: ADMIN_SCOPE_ROUTES.modules.actionPath.list,
            element: LazyLoadComponent(
              <RequireAuth access={"MODULE_MANAGEMENT"}>
                <ModulesList />
              </RequireAuth>
            ),
          },
          {
            exact: true,
            path: ADMIN_SCOPE_ROUTES.modules.actionPath.create,
            element: LazyLoadComponent(
              <RequireAuth access={"MODULE_MANAGEMENT"}>
                <ModulesCreate />
              </RequireAuth>
            ),
          },
          {
            exact: true,
            path: ADMIN_SCOPE_ROUTES.modules.actionPath.edit,
            element: LazyLoadComponent(
              <RequireAuth access={"MODULE_MANAGEMENT"}>
                <ModuleEdit />
              </RequireAuth>
            ),
          },
        ],
      },
      {
        path: ADMIN_SCOPE_ROUTES.applicationForms.basePath,
        children: [
          {
            exact: true,
            path: ADMIN_SCOPE_ROUTES.applicationForms.actionPath.list,
            element: LazyLoadComponent(
              <RequireAuth access={"APPLICATION_FORMS_MANAGEMENT"}>
                <ApplicationFormList />
              </RequireAuth>
            ),
          },
          {
            exact: true,
            path: ADMIN_SCOPE_ROUTES.applicationForms.actionPath.view,
            element: LazyLoadComponent(
              <RequireAuth access={"APPLICATION_FORMS_MANAGEMENT"}>
                <ApplicationFormView />
              </RequireAuth>
            ),
          },
          {
            exact: true,
            path: ADMIN_SCOPE_ROUTES.applicationForms.actionPath.create,
            element: LazyLoadComponent(
              <RequireAuth access={"APPLICATION_FORMS_MANAGEMENT"}>
                <ApplicationFromCreate />
              </RequireAuth>
            ),
          },
          {
            exact: true,
            path: ADMIN_SCOPE_ROUTES.applicationForms.actionPath.edit,
            element: LazyLoadComponent(
              <RequireAuth access={"APPLICATION_FORMS_MANAGEMENT"}>
                <ApplicationFormEdit />
              </RequireAuth>
            ),
          },
        ],
      },
      {
        path: ADMIN_SCOPE_ROUTES.tiers.basePath,
        children: [
          {
            exact: true,
            path: ADMIN_SCOPE_ROUTES.tiers.actionPath.list,
            element: LazyLoadComponent(
              <RequireAuth access={"TIER_MANAGEMENT"}>
                <TiersList />
              </RequireAuth>
            ),
          },

          {
            exact: true,
            path: ADMIN_SCOPE_ROUTES.tiers.actionPath.create,
            element: LazyLoadComponent(
              <RequireAuth access={"TIER_MANAGEMENT"}>
                <TiersCreate />
              </RequireAuth>
            ),
          },
          {
            exact: true,
            path: ADMIN_SCOPE_ROUTES.tiers.actionPath.edit,
            element: LazyLoadComponent(
              <RequireAuth access={"TIER_MANAGEMENT"}>
                <TiersEdit />
              </RequireAuth>
            ),
          },
          {
            exact: true,
            path: ADMIN_SCOPE_ROUTES.tiers.actionPath.viewTiers,
            element: LazyLoadComponent(
              <RequireAuth access={"TIER_MANAGEMENT"}>
                <TiersView />
              </RequireAuth>
            ),
          },
        ],
      },
      {
        path: ADMIN_SCOPE_ROUTES.platformUsers.basePath,
        children: [
          {
            path: ADMIN_SCOPE_ROUTES.platformUsers.actionPath.list,
            exact: true,
            element: LazyLoadComponent(
              <RequireAuth access={"USER_PROFILE_MANAGEMENT"}>
                <PlatformUsersList />
              </RequireAuth>
            ),
          },
          {
            path: ADMIN_SCOPE_ROUTES.platformUsers.actionPath.subscriptions,
            exact: true,
            element: LazyLoadComponent(
              <RequireAuth access={"USER_PROFILE_MANAGEMENT"}>
                <PlatformUsersSubscriptions />
              </RequireAuth>
            ),
          },
          {
            path: ADMIN_SCOPE_ROUTES.platformUsers.actionPath.view,
            exact: true,
            element: LazyLoadComponent(
              <RequireAuth access={"USER_PROFILE_MANAGEMENT"}>
                <PlatformUserProfileSummary />
              </RequireAuth>
            ),
          },
          {
            path: ADMIN_SCOPE_ROUTES.platformUsers.actionPath.communities,
            exact: true,
            element: LazyLoadComponent(
              <RequireAuth access={"USER_PROFILE_MANAGEMENT"}>
                <Communities />
              </RequireAuth>
            ),
          },
          {
            path: ADMIN_SCOPE_ROUTES.platformUsers.actionPath.communityView,
            exact: true,
            element: LazyLoadComponent(
              <RequireAuth access={"USER_PROFILE_MANAGEMENT"}>
                <CommunityUserProfileSummary />
              </RequireAuth>
            ),
          },
          {
            path: ADMIN_SCOPE_ROUTES.platformUsers.actionPath.memberView,
            exact: true,
            element: LazyLoadComponent(
              <RequireAuth access={"USER_PROFILE_MANAGEMENT"}>
                <MemberInfo />
              </RequireAuth>
            ),
          },
          {
            path: ADMIN_SCOPE_ROUTES.platformUsers.actionPath.leadUsers,
            exact: true,
            element: LazyLoadComponent(
              <RequireAuth access={"USER_PROFILE_MANAGEMENT"}>
                <LeadUsers />
              </RequireAuth>
            ),
          },
        ],
      },
      {
        path: ADMIN_SCOPE_ROUTES.subscriptionSubmissions.basePath,
        children: [
          {
            exact: true,
            path: ADMIN_SCOPE_ROUTES.subscriptionSubmissions.actionPath.list,
            element: LazyLoadComponent(
              <RequireAuth access={"SUBSCRIPTION_SUBMISSIONS_MANAGEMENT"}>
                <SubscriptionSubmissionsList />
              </RequireAuth>
            ),
          },
          {
            exact: true,
            path: ADMIN_SCOPE_ROUTES.subscriptionSubmissions.actionPath.view,
            element: LazyLoadComponent(
              <RequireAuth access={"SUBSCRIPTION_SUBMISSIONS_MANAGEMENT"}>
                <ViewSubscriptionSubmissionsForm />
              </RequireAuth>
            ),
          },
        ],
      },
      {
        path: ADMIN_SCOPE_ROUTES.reminder.basePath,
        children: [
          {
            exact: true,
            path: ADMIN_SCOPE_ROUTES.reminder.actionPath.list,
            element: LazyLoadComponent(
              <RequireAuth access={"SUBSCRIPTION_REMINDER_MANAGEMENT"}>
                <SubscriptionReminder />
              </RequireAuth>
            ),
          },
        ],
      },
      {
        path: ADMIN_SCOPE_ROUTES.edgeLogin.basePath,

        children: [
          {
            exact: true,
            path: ADMIN_SCOPE_ROUTES.edgeLogin.actionPath.list,
            element: LazyLoadComponent(<EdgeLogin />),
          },
        ],
      },
      {
        path: ADMIN_SCOPE_ROUTES.discover.basePath,
        children: [
          {
            exact: true,
            path: ADMIN_SCOPE_ROUTES.discover.actionPath.list,
            element: LazyLoadComponent(<DiscoverCommunities />),
          },
        ],
      },
      {
        path: "*",
        element: LazyLoadComponent(<NotFound />),
      },
    ],
  },
  {
    path: ADMIN_SCOPE_ROUTES.discover.actionPath.nickname,
    element: LazyLoadComponent(<AuthenticatedGrouposAdminLayout />),
    children: [
      {
        index: true,
        element: LazyLoadComponent(<CommunityProfile />),
      },
    ],
  },
  {
    path: EDGE_SCOPE_ADMIN_SCOPE_ROUTES.edge.basePath,
    element: LazyLoadComponent(<AuthenticatedGrouposAdminLayout />),
    children: [
      {
        index: true,
        element: LazyLoadComponent(<CreateCommunity />),
      },
    ],
  },
  {
    path: "*",
    skipLazyLoad: true,
    element: <Navigate to="/admin/groupos" />,
  },
];

export default AuthenticatedGrouposAdminRoutes;
