// Icons
import KeyIcon from "@mui/icons-material/Key";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import PersonIcon from "@mui/icons-material/Person";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import StarsIcon from "@mui/icons-material/Stars";
import LoginIcon from "@mui/icons-material/Login";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
// routes
export const ADMIN_SCOPE_ROUTES = {
  roles: {
    basePath: "roles",
    actionPath: {
      list: "list",
      create: "create",
      edit: "edit/:role_id",
    },
  },
  platformOwners: {
    basePath: "owners",
    actionPath: {
      list: "list",
      create: "create",
      edit: "edit/:platform_owner_id",
    },
  },
  modules: {
    basePath: "modules",
    actionPath: {
      list: "list",
      create: "create",
      edit: "edit/:module_id",
    },
  },
  tiers: {
    basePath: "tiers",
    actionPath: {
      list: "list",
      create: "create",
      edit: "edit/:tier_id",
      viewTiers: "view-tiers",
    },
  },
  subscriptionSubmissions: {
    basePath: "subscription-submissions",
    actionPath: {
      list: "list",
      view: "view/:subscription_submission_id",
    },
  },
  platformUsers: {
    basePath: "platform-users",
    actionPath: {
      subscriptions: "subscriptions",
      list: "list",
      create: "create",
      view: "view/:platform_user_id",
      communities: "communities",
      communityView: "community/view/:platform_user_id",
      memberView: "view/:platform_user_id/members/:member_id",
      leadUsers: "lead-users"
    },
  },
  platform: {
    basePath: "platform",
    actionPath: {
      dashboard: "dashboard",
      info: "info",
      payouts: "payouts",
      edit: "edit/:platform_id",
    },
  },
  profile: {
    basePath: "profile",
    actionPath: {
      info: "info",
      edit: "edit/:profile_id",
    },
  },
  edgeLogin: {
    basePath: "edge-login",
    actionPath: {
      list: "",
    },
  },
  discover: {
    basePath: "discover",
    actionPath: {
      list: "discover-communities",
      nickname: ":community_name",
    },
  },
  applicationForms: {
    basePath: "application-forms",
    actionPath: {
      list: "list",
      view: "view/:application_form_id",
      create: "create",
      edit: "edit/:application_form_id",
    },
  },
  reminder: {
    basePath: "reminder",
    actionPath: {
      list: "",
    },
  },
};

// list of admin scopes and their actions
export const grouposAdminScopesList = [
  {
    id: "PLATFORM_PROFILE_MANAGEMENT",
    name: "Platform",
    path: ADMIN_SCOPE_ROUTES.platform.basePath,
    icon: <StarsIcon size="24px" className="admin-left-icons" />,
    actions: [
      {
        name: "Platform Dashboard",
        path: ADMIN_SCOPE_ROUTES.platform.actionPath.dashboard,
        subId: "Platform_Dashboard"
      },
      {
        name: "Platform Info",
        path: ADMIN_SCOPE_ROUTES.platform.actionPath.info,
        subId: "Platform_Info"
      },
      {
        name: "Payouts",
        path: ADMIN_SCOPE_ROUTES.platform.actionPath.payouts,
        subId: "Payouts"
      },
    ],
  },
  {
    id: "ROLE_MANAGEMENT",
    name: "Roles",
    path: ADMIN_SCOPE_ROUTES.roles.basePath,
    icon: <KeyIcon size="24px" className="admin-left-icons" />,
    actions: [
      {
        name: "Roles List",
        path: ADMIN_SCOPE_ROUTES.roles.actionPath.list,
        subId: "Roles_List"
      }
    ],
  },
  {
    id: "PLATFORM_OWNERS_MANAGEMENT",
    name: "Platform Owners",
    path: ADMIN_SCOPE_ROUTES.platformOwners.basePath,
    icon: <SupervisedUserCircleIcon size="24px" className="admin-left-icons" />,
    actions: [
      {
        name: "Platform Owners List",
        path: ADMIN_SCOPE_ROUTES.platformOwners.actionPath.list,
        subId: "Platform_Owners_List"
      }
    ],
  },
  {
    id: "MODULE_MANAGEMENT",
    name: "Modules",
    path: ADMIN_SCOPE_ROUTES.modules.basePath,
    icon: <ViewModuleIcon size="24px" className="admin-left-icons" />,
    actions: [
      {
        name: "Modules List",
        path: ADMIN_SCOPE_ROUTES.modules.actionPath.list,
        subId: "Modules_List"
      }
    ],
  },
  {
    id: "TIER_MANAGEMENT",
    name: "Tiers",
    path: ADMIN_SCOPE_ROUTES.tiers.basePath,
    icon: <AttachMoneyIcon size="24px" className="admin-left-icons" />,
    actions: [
      {
        name: "Platform Tiers List",
        path: ADMIN_SCOPE_ROUTES.tiers.actionPath.list,
        subId: "Platform_Tiers_List"
      },
      {
        name: "Preview Tiers",
        path: ADMIN_SCOPE_ROUTES.tiers.actionPath.viewTiers,
        subId: "Preview_Tiers"
      },
    ],
  },
  {
    id: "APPLICATION_FORMS_MANAGEMENT",
    name: "Application Forms",
    path: ADMIN_SCOPE_ROUTES.applicationForms.basePath,
    icon: <AttachFileIcon size="24px" className="admin-left-icons" />,
    actions: [
      {
        name: "Application Forms List",
        path: ADMIN_SCOPE_ROUTES.applicationForms.actionPath.list,
        subId: "Application_Forms_List"
      },
    ],
  },
  {
    id: "SUBSCRIPTION_SUBMISSIONS_MANAGEMENT",
    name: "Subscription Submissions",
    path: ADMIN_SCOPE_ROUTES.subscriptionSubmissions.basePath,
    icon: <PendingActionsIcon size="24px" className="admin-left-icons" />,
    actions: [
      {
        name: "Subscription Submissions List",
        path: ADMIN_SCOPE_ROUTES.subscriptionSubmissions.actionPath.list,
        subId: "Subscription_Submissions_List"
      },
    ],
  },
  {
    id: "USER_PROFILE_MANAGEMENT",
    name: "Platform Users",
    path: ADMIN_SCOPE_ROUTES.platformUsers.basePath,
    icon: <RecentActorsIcon size="24px" className="admin-left-icons" />,
    actions: [
      {
        name: "Platform Users List",
        path: ADMIN_SCOPE_ROUTES.platformUsers.actionPath.communities,
        subId: "Platform_Users_List"
      },
      {
        name: "Platform Users Subscriptions",
        path: ADMIN_SCOPE_ROUTES.platformUsers.actionPath.subscriptions,
        subId: "Platform_Users_Subscriptions"
      },
      {
        name: "Lead Users",
        path: ADMIN_SCOPE_ROUTES.platformUsers.actionPath.leadUsers,
        subId: "Lead_Users"
      }
    ],
  },
  {
    id: "PROFILE_MANAGEMENT",
    name: "Profile",
    path: ADMIN_SCOPE_ROUTES.profile.basePath,
    icon: <PersonIcon size="24px" className="admin-left-icons" />,
    actions: [
      {
        name: "Profile Info",
        path: ADMIN_SCOPE_ROUTES.profile.actionPath.info,
        subId: "Profile_Info"
      },
    ],
  },
  {
    id: "SUBSCRIPTION_REMINDER_MANAGEMENT",
    name: "Subscription Reminder",
    path: ADMIN_SCOPE_ROUTES.reminder.basePath,
    icon: <NotificationsNoneIcon size="24px" className="admin-left-icons" />,
    actions: [
      {
        name: "Reminders",
        path: ADMIN_SCOPE_ROUTES.reminder.actionPath.list,
        subId: "Reminders"
      },
    ],
  },
  {
    id: "EDGE_LOGIN",
    name: "Edge Login",
    path: ADMIN_SCOPE_ROUTES.edgeLogin.basePath,
    icon: <LoginIcon size="24px" className="admin-left-icons" />,
    actions: [
      {
        name: "Edge Logins",
        path: ADMIN_SCOPE_ROUTES.edgeLogin.actionPath.list,
        subId: "Edge_Logins"
      },
    ],
  },
  {
    id: "COMMUNITY_DISCOVERY",
    name: "Discover Communities",
    path: ADMIN_SCOPE_ROUTES.discover.basePath,
    icon: <ViewCarouselIcon size="24px" className="admin-left-icons" />,
    actions: [
      {
        name: "Discover Communities",
        path: ADMIN_SCOPE_ROUTES.discover.actionPath.list,
        subId: "Discover_Communities"
      },
    ],
  },
];
