export const ADMIN_SCOPE_ROUTES = {
  authentication: {
    basePath: "",
    actionPath: {
      login: "login",
      signUp: "signup",
      activateAccount: "activate",
    },
  },
  discover: {
    basePath: "discover",
  },
  communityProfile: {
    basePath: ":community_name",
  },
  pricing: {
    basePath: "pricing",
  },
  contactUs: {
    basePath: "contact-us",
  },
};
