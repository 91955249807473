export const ADMIN_SCOPE_ROUTES = {
  settings: {
    basePath: "settings",
    actionPath: {
      edit: "edit",
      profile: "profile",
      users: "users",
      userinvite: "userinvite",
      subscription: "subscription"
    },
  },
};

// list of scopes and their actions
export const scopesList = [
  {
    id: "edit",
    name: "Public profile",
    path: ADMIN_SCOPE_ROUTES.settings.actionPath.edit,
    actions: [],
  },
  {
    id: "profile",
    name: "Account settings",
    path: ADMIN_SCOPE_ROUTES.settings.actionPath.profile,
    actions: [],
  },
  {
    id: "users",
    name: "Co-users",
    path: ADMIN_SCOPE_ROUTES.settings.actionPath.users,
    actions: [],
  },
  {
    id: "subscription",
    name: "Subscription info",
    path: ADMIN_SCOPE_ROUTES.settings.actionPath.subscription,
    actions: [],
  },
];
