import React from "react";
import { Alert, Snackbar } from "@mui/material";

const Toaster = ({
  open,
  severity,
  message,
  handleClose,
  vertical,
  horizontal,
  autoHideDuration,
  variant,
  action,
}) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: vertical, horizontal: horizontal }}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
    >
      <Alert variant={variant} action={action} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default Toaster;
