import React from 'react'
import PropTypes from "prop-types"
const LinkIcon = ({ height = 24, width = 24, iconColor = "#000" }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 24 24"
        >
            <path
                fill={iconColor}
                fillRule="evenodd"
                d="M13.25 4c0 .414.336.75.75.75h4.19l-7.72 7.72a.75.75 0 1 0 1.06 1.06l7.72-7.72V10a.75.75 0 0 0 1.5 0V3.25H14a.75.75 0 0 0-.75.75ZM9.5 5h.75a.75.75 0 0 1 0 1.5H9.5a4 4 0 0 0-4 4v5a4 4 0 0 0 4 4h5a4 4 0 0 0 4-4v-1.75a.75.75 0 0 1 1.5 0v1.75a5.5 5.5 0 0 1-5.5 5.5h-5A5.5 5.5 0 0 1 4 15.5v-5A5.5 5.5 0 0 1 9.5 5Z"
                clipRule="evenodd"
            />
        </svg>
    )
}
LinkIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    iconColor: PropTypes.string
}
export default LinkIcon