import React from "react";

const IconTimeLapse = () => {
  return (
    <div className="mds-icons-empty">
   <svg
    xmlns="http://www.w3.org/2000/svg"
    width={120}
    height={120}
    fill="none"
  >
    <circle cx={60} cy={60} r={60} fill="#6FCADF" fillOpacity={0.2} />
    <path
      fill="#060D14"
      d="M43.993 82v-3.999c0-4.72.031-9.267 3.74-12.785a26.481 26.481 0 0 1 6.532-4.666l1.06-.547-1.094-.6a25.17 25.17 0 0 1-6.985-5.179 12.165 12.165 0 0 1-3.253-8.545v-7.673h-1.84a2.02 2.02 0 0 1-2.016-1.983 2.02 2.02 0 0 1 1.983-2.016h35.755c1.09 0 2 .91 2 2 0 1.089-.91 2-2 2h-1.9v7.605a12.279 12.279 0 0 1-3.5 8.819 26.326 26.326 0 0 1-7.225 5.18l-.666.366c.246.146.426.266.62.366a26.531 26.531 0 0 1 7.232 5.16 12.272 12.272 0 0 1 2.675 4.076c1.565 3.907.878 8.322.878 12.422h1.9c1.089 0 2 .91 2 2 0 1.088-.911 1.999-2 1.999h-35.77a2.02 2.02 0 0 1-1.982-2.016A2.02 2.02 0 0 1 42.153 82h1.84Zm27.996 0c0-2.932.196-5.926-.06-8.852-.286-2.486-1.68-4.34-3.52-5.912-1.951-1.664-5.709-4.728-8.465-4.728-2.795 0-6.653 3.132-8.605 4.848a9.018 9.018 0 0 0-2.534 3.262c-1.457 3.184-.813 7.278-.813 10.676v.667L71.99 82ZM47.992 38.007c0 2.467.067 4.866 0 7.253a9.128 9.128 0 0 0 3.387 7.452c1.96 1.717 5.78 4.836 8.582 4.836 2.766 0 6.58-3.112 8.535-4.79 2.327-2 3.667-4.405 3.533-7.565-.096-2.394 0-4.791 0-7.186H47.992Z"
    />
  </svg>
    </div>
  );
};

export default IconTimeLapse;
