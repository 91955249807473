import React from "react";

function IconNavVideo() {
  return (
    <div className="mds-icons">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
      >
        <path
          fillRule="evenodd"
          d="M6.5 3.5h11a4 4 0 0 1 4 4v9a4 4 0 0 1-4 4h-11a4 4 0 0 1-4-4v-9a4 4 0 0 1 4-4ZM1 7.5A5.5 5.5 0 0 1 6.5 2h11A5.5 5.5 0 0 1 23 7.5v9a5.5 5.5 0 0 1-5.5 5.5h-11A5.5 5.5 0 0 1 1 16.5v-9Zm9.728 6.428c-.177-.95-.174-3.342.003-4.342.9.338 2.779 1.562 3.289 2.162-.512.62-2.414 1.87-3.292 2.18ZM9.69 15.215c.479.477 1.204.263 1.784.021.839-.35 4.123-2.181 4.123-3.492 0-1.397-3.65-3.266-4.17-3.483-.362-.152-1.214-.507-1.737.01l-.001.001c-.148.148-.593.594-.592 3.56.002 2.8.432 3.224.593 3.383Z"
          clipRule="evenodd"
        />
      </svg>
    </div>
  );
}

export default IconNavVideo;
