import React from 'react'
import PropTypes from "prop-types"
const HideIcon = ({ height = 24, width = 24, iconColor = "#130F26" }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 24 24"
        >
            <path
                fill={iconColor}
                fillRule="evenodd"
                d="M20.417 4.78a.75.75 0 0 0-1.06-1.06l-2.088 2.087c-1.588-1.108-3.386-1.723-5.27-1.723-4.684 0-8.196 3.72-9.938 7.756a.75.75 0 0 0 0 .593c.884 2.061 2.073 3.793 3.493 5.089l-1.972 1.971a.75.75 0 1 0 1.061 1.06L20.417 4.78ZM6.616 16.46l2.138-2.138a3.865 3.865 0 0 1-.669-2.184A3.91 3.91 0 0 1 12 8.222c.803 0 1.561.244 2.187.668l2.003-2.003c-1.304-.854-2.728-1.303-4.19-1.303-3.973 0-6.902 3.195-8.43 6.554.81 1.789 1.853 3.247 3.047 4.322Zm6.477-6.477a2.406 2.406 0 0 0-1.094-.261 2.41 2.41 0 0 0-2.414 2.416c0 .396.094.765.261 1.091l3.247-3.246Z"
                clipRule="evenodd"
            />
            <path
                fill={iconColor}
                d="M15.237 11.96a.75.75 0 0 1 .606.872 3.908 3.908 0 0 1-3.142 3.147.75.75 0 1 1-.268-1.476 2.408 2.408 0 0 0 1.933-1.937.75.75 0 0 1 .871-.605Z"
            />
            <path
                fill={iconColor}
                d="M18.996 8.392a.75.75 0 0 1 1.05.148 16.104 16.104 0 0 1 1.892 3.3.75.75 0 0 1 0 .594c-2.042 4.73-5.735 7.755-9.938 7.755a8.73 8.73 0 0 1-2.77-.452.75.75 0 1 1 .475-1.423 7.233 7.233 0 0 0 2.294.375c3.333 0 6.52-2.355 8.43-6.552a14.389 14.389 0 0 0-1.58-2.695.75.75 0 0 1 .147-1.05Z"
            />
        </svg>
    )
}
HideIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    iconColor: PropTypes.string
}
export default HideIcon