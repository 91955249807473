var allpost = { allpost: [], totalpost: 0 };
const ReduPost = (state = allpost, action) => {
  const USER_ID = localStorage.getItem("userid");
  switch (action.type) {
    case "ADDNORMALPOST":
      if (state.allpost.length === action.payload.total) {
        return { allpost: [...state.allpost], totalpost: state.totalpost };
      } else
        return {
          allpost: [...state.allpost, ...action.payload.data],
          totalpost: state.totalpost,
        };
    case "FETCHPOSTS":
      return {
        allpost: [...action.payload.data],
        totalpost: action.payload.total,
      };
    case "SINGLEADDPOST":
      const data_res = {
        ...action.payload,
        likes: {
          count: action.payload.likes.length,
          list: action.payload.likes,
        },
      };
      console.log([data_res, ...state.allpost]);
      return {
        allpost: [data_res, ...state.allpost],
        totalpost: state.totalpost + 1,
      };
    case "HANDLELIKE":
      var arr = state.allpost.map((post) => {
        if (post._id === action.payload) {
          if (post.likes.list.includes(USER_ID)) {
            console.log({
              ...post,
              likes: {
                count: post.likes.count - 1,
                list: post.likes.list.splice(
                  post.likes.list.indexOf(USER_ID),
                  1
                ),
              },
            });
            return {
              ...post,
              likes: {
                count: post.likes.count - 1,
                list: post.likes.list.splice(
                  post.likes.list.indexOf(USER_ID),
                  1
                ),
              },
            };
          } else {
            return {
              ...post,
              likes: {
                count: post.likes.count + 1,
                list: [...post.likes.list, USER_ID],
              },
            };
          }
        } else {
          return post;
        }
      });
      return { allpost: arr, totalpost: state.totalpost };
    case "HANDLEVOTE":
      var arr = state.allpost.map((post) => {
        if (post._id === action.payload.postid) {
          if (!post.pollTotalVotes.includes(USER_ID)) {
            var pchoice = post.pollChoices.map((choice) => {
              if (action.payload.pollid === choice._id) {
                return { ...choice, votes: [...choice.votes, USER_ID] };
              } else return choice;
            });
            return {
              ...post,
              pollTotalVotes: [...post.pollTotalVotes, USER_ID],
              pollChoices: pchoice,
            };
          } else {
            var pchoice = post.pollChoices.map((choice) => {
              if (action.payload.pollid === choice._id) {
                console.log(choice.votes.includes(USER_ID));
                if (!choice.votes.includes(USER_ID)) {
                  return { ...choice, votes: [...choice.votes, USER_ID] };
                } else return choice;
              } else if (choice.votes.includes(USER_ID)) {
                return {
                  ...choice,
                  votes: choice.votes.filter((id) => {
                    if (id !== USER_ID) return id;
                  }),
                };
              } else return choice;
            });
            return {
              ...post,
              pollChoices: pchoice,
            };
            return post;
          }
        } else {
          return post;
        }
      });
      return { allpost: arr, totalpost: state.totalpost };
    case "ADDCOMMENT":
      var arr = state.allpost.map((post) => {
        if (post._id === action.payload.postid) {
          return {
            ...post,
            comments: [...post.comments, action.payload.commid],
          };
        } else {
          return post;
        }
      });
      return { allpost: arr, totalpost: state.totalpost };
    case "DELETECOMMENT":
      var arr = state.allpost.map((post) => {
        if (post._id === action.payload.postid) {
          console.log(
            post.comments.splice(
              post.comments.indexOf(action.payload.commid),
              1
            )
          );
          return {
            ...post,
            comments: post.comments.splice(
              post.comments.indexOf(action.payload.commid),
              1
            ),
          };
        } else {
          return post;
        }
      });
      return { allpost: arr, totalpost: state.totalpost };
    default:
      return state;
  }
};

export default ReduPost;
