import React from 'react'
import PropTypes from "prop-types"
const SendIcon = ({ height = 24, width = 24, iconColor = "#000" }) => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={width}
                height={height}
                fill="none"
                viewBox="0 0 24 24"
            >
                <path
                    fill={iconColor}
                    d="M20.653 10.322c1.331.73 1.319 2.722-.025 3.435L5.886 21.75c-1.48.789-3.239-.545-2.824-2.184l1.42-5.769a1.324 1.324 0 0 1 1.28-1.008l8.184-.019c.965-.02.984-1.524.005-1.504l-8.176.025A1.304 1.304 0 0 1 4.5 10.29L3.13 4.543C2.739 2.91 4.43 1.386 5.96 2.25l14.692 8.072Z"
                />
            </svg>
        </>
    )
}
SendIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    iconColor: PropTypes.string,
}
export default SendIcon
